import * as St from 'components/Style.styled';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import apis from '../api';
import CoalitionDetailsDTO, {
  defaultCoalitionDetailsDTO,
} from '../dtos/CoalitionDetailsDTO';
import { ChurchProfileParams } from './ChurchProfile';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CoalitionPage: React.FC = () => {
  const { id } = useParams<ChurchProfileParams>();
  const [coalition, setCoalition] = useState<CoalitionDetailsDTO>(
    defaultCoalitionDetailsDTO,
  );
  useEffect(() => {
    if (!id) return;
    apis.coalitions.get_by_id(id).then((res) => {
      if (!res) return;
      setCoalition(res);
    });
  }, [id]);

  console.log(coalition);
  return (
    <>
      <St.Header>
        <St.HeaderText ml="10px">{coalition.name}</St.HeaderText>
      </St.Header>
      <Container></Container>
    </>
  );
};

export default CoalitionPage;
