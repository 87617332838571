export const buildGetAddressComponentFields = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): { getField: (field: string) => string } => {
  const getField = (field: string) => {
    const component = addressComponents.find((component) =>
      component.types.includes(field),
    );
    return component?.long_name;
  };
  return { getField };
};
