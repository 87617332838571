import apis from 'api';
import * as St from 'components/Style.styled';
import routes from 'config/routes';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add_programs } from 'store/churches';
import { getEventsForChurch, updateChurchById } from 'store/helpers';
import styled from 'styled-components';
import EditButtons from '../../components/EditButtons';
import StatisticHighlight from '../../components/StatisticHighlight';
import assetTypeIcons from '../../config/assetTypeIcons';
import { ModalContext } from '../../contexts/ModalContext';
import { ToastContext } from '../../contexts/ToastContext';
import ChurchStatisticDetailsDTO from '../../dtos/ChurchStatisticDetailsDTO';
import { useJoinChurch } from '../../hooks/updateHooks';
import usePlacesService from '../../hooks/usePlacesService';
import useUserAccess from '../../hooks/useUserAccess';
import {
  useAppDispatch,
  useGetChurchById,
  useGetChurchStatisticTypes,
  useGetCoalitions,
  useGetCurrentUser,
  useGetEventState,
} from '../../store/hooks';
import { stringNullOrEmpty } from '../../utils/strings';
import ChurchMembersModal from '../ChurchMembersModal';
import AssetPrograms from './AssetPrograms';
import ChurchCalendar from './ChurchCalendar';
import ChurchCoalitions from './ChurchCoalitions';
import EditChurchModal from './EditChurchModal';
import KeyInitiatives from './KeyInitiatives';

const MainImage = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 500px;
  background: url('/images/home-image-filter.png') no-repeat,
    url(${(props) => props.src}) no-repeat;
  background-position: 0;
  background-size: cover;

  margin-bottom: 12.5px;
`;

const HeroText = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 24px;
  color: white;
`;

const Highlights = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export type ChurchProfileParams = {
  id: string;
};

const ChurchProfile: React.FC = () => {
  // PREVIOUS NOTE
  // todo grey out & disable edit icons if there's no items to edit in that section
  const service = usePlacesService();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { show } = useContext(ToastContext);
  const [photo, setPhoto] = useState(null);
  const { id: church_id } = useParams<ChurchProfileParams>();
  const church = useGetChurchById(church_id);
  const { openChurchMembers, toggleEditChurch } = useContext(ModalContext);
  const { isChurchAdminOf, isChurchMemberOf } = useUserAccess();
  const user = useGetCurrentUser();
  const statisticTypes = useGetChurchStatisticTypes();
  const isChurchMember = isChurchMemberOf(church);
  const isChurchAdmin = isChurchAdminOf(church);
  const events = useGetEventState();
  const [assets, setAssets] = useState<ChurchStatisticDetailsDTO[]>([]);
  const coalitions = useGetCoalitions();
  const { onJoinChurch } = useJoinChurch();

  const handleJoinChurch = () => {
    onJoinChurch(user._id, church_id).then(() => {
      show('joined-church-success');
    });
  };

  const renderStatistic = (asset: ChurchStatisticDetailsDTO) => {
    const assetType = statisticTypes.find(
      (assetType) => assetType._id === asset.type_id,
    );

    return (
      <StatisticHighlight
        key={asset._id}
        name={assetType?.name}
        icon={assetTypeIcons[assetType?.name]}
        value={asset.quantity?.toString() ?? '0'}
      />
    );
  };

  const renderMembershipButton = () => {
    if (!isChurchMember)
      return (
        <St.SecondaryButton onClick={handleJoinChurch}>
          Join as member
        </St.SecondaryButton>
      );
    return (
      <St.PrimaryButton
        type="button"
        disabled={!isChurchAdmin}
        onClick={() => toggleEditChurch()}
      >
        {!!isChurchAdmin
          ? 'You are the Admin. Edit Church Details.'
          : 'You are a member of this church'}
      </St.PrimaryButton>
    );
  };

  useEffect(() => {
    if (church.google_places_id && service) {
      service.getDetails({ placeId: church.google_places_id }, (place) => {
        if (place?.photos && place.photos.length > 0)
          setPhoto(place.photos[0]?.getUrl());
      });
    }
  }, [church.google_places_id, service]);

  const refreshChurch = useCallback(async () => {
    updateChurchById(dispatch, church_id);
  }, [dispatch, church_id]);

  useEffect(() => {
    if (church._id) {
      apis.general.get_church_programs(church._id).then((programs) => {
        programs.length > 0
          ? dispatch(add_programs({ churchId: church._id, programs }))
          : null;
      });
    }
  }, [church._id]);

  useEffect(() => {
    if (!church_id) return;

    refreshChurch();
    apis.churches
      .get_church_statistics(church_id)
      .then((eventsRes) => eventsRes && setAssets(eventsRes));

    getEventsForChurch(dispatch, church_id);
  }, [church_id]);

  useEffect(() => {
    getEventsForChurch(dispatch, church_id);
  }, []);

  if (!church_id) return;

  if (church.status !== 2) {
    return (
      <>
        <div className="text-2xl font-bold">{church.name} is not approved</div>
        <div className="text-lg">
          This church is pending approval from an admin. Please check back
          later.
        </div>
        {user.is_site_admin && (
          <St.PrimaryButtonLink to={routes.RETOOL.href} mt="10px">
            View pending churches
          </St.PrimaryButtonLink>
        )}
      </>
    );
  }

  return (
    <>
      <ChurchMembersModal />
      <EditChurchModal church={church} refreshChurch={() => refreshChurch()} />
      <St.Div pb="20px">
        <MainImage src={photo ?? '/images/churches/2.png'}>
          <HeroText>{church.name}</HeroText>
        </MainImage>
        {/* <St.Row alignItems="flex-start" mt="18px" ml="10px"> */}
        <St.Column
          justifyContent="flex-start"
          alignItems="flex-start"
          mr="5px"
          width="100%"
        >
          <St.Div width="100%">
            <St.Row
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              gap="10px"
            >
              <St.Text fontWeight="800" fontSize="20px" color="#454545">
                Basic Information
              </St.Text>
              {isChurchMemberOf(church) && (
                <St.SecondaryButton ml="auto" onClick={openChurchMembers}>
                  View members
                </St.SecondaryButton>
              )}
              {renderMembershipButton()}
            </St.Row>
            {!stringNullOrEmpty(church.description) && (
              <>
                <St.Text>{church.description}</St.Text>
                <br />
              </>
            )}
            <St.Text>{church.address1}</St.Text>
            <St.Text>
              {church.city}, {church.state} {church.zip_code}
            </St.Text>
            <br />
            {church.primary_contact && (
              <div>
                Contact: <strong>{church.primary_contact}</strong>
              </div>
            )}
            {church.primary_phone && (
              <div>
                Phone: <strong>{church.primary_phone}</strong>
              </div>
            )}
            {church.primary_email && (
              <div>
                Email: <strong>{church.primary_email}</strong>
              </div>
            )}
          </St.Div>
          <St.ProfileSectionContainer>
            <KeyInitiatives />
          </St.ProfileSectionContainer>
          <St.ProfileSectionContainer>
            <ChurchCalendar
              events={events.data.filter(
                (e) => !!e.scheduled && e.church_id === church_id,
              )}
              church={church}
            />
          </St.ProfileSectionContainer>
          {/* </St.Column>
          <St.Column
            ml="5px"
            flex={1}
            justifyContent="start"
            overflowX="hidden"
          > */}
          <St.ProfileSectionContainer>
            <St.Div width="100%">
              <St.HighlightsBanner justifyContent="space-between" mb="5px">
                Statistics
                {isChurchAdmin && (
                  <EditButtons
                    onClickEdit={
                      (assets ?? []).length > 0
                        ? () => navigate(`statistics/edit`)
                        : null
                    }
                    onClickAdd={() => navigate(`statistics/add`)}
                    white
                  />
                )}
              </St.HighlightsBanner>
              <Highlights
                style={{
                  marginTop: (assets ?? []).length > 0 ? '16px' : 0,
                }}
              >
                {(assets ?? []).length > 0 ? (
                  (assets ?? []).map((asset) => renderStatistic(asset))
                ) : (
                  <St.NotApplicableContainer>
                    This church has no statistics
                  </St.NotApplicableContainer>
                )}
              </Highlights>
            </St.Div>
          </St.ProfileSectionContainer>

          <St.ProfileSectionContainer>
            <AssetPrograms />
          </St.ProfileSectionContainer>

          {/* <St.ProfileSectionContainer>
            <ChurchCoalitions
              church={church}
              coalitions={coalitions}
              refreshProfile={refreshChurch}
            />
          </St.ProfileSectionContainer> */}
        </St.Column>
        {/* </St.Row> */}
      </St.Div>
    </>
  );
};
export default ChurchProfile;
