export enum PointOfInterestType {
  PRIMARY_SCHOOL,
  SECONDARY_SCHOOL,
  UNIVERSITY,
  LIBRARY,
  SUPERMARKET,
  DOCTOR,
  DRUGSTORE,
  HOSPITAL,
  GYM,
  PHARMACY,
  CITY_HALL,
  COURTHOUSE,
  LAWYER,
  POLICE,
  CHURCH,
}

export default interface PointOfInterestDetailsDTO {
  id: string;
  name: string;
  location: google.maps.LatLngLiteral;
  latitude: number;
  longitude: number;
  types: PointOfInterestType[];
  iconColor: string;
}
