import { PointOfInterestType } from '../../../dtos/PointOfInterestDetailsDTO';
import Demographic, {
  EducationLevel,
  EthnicGroup,
  IncomeBand,
} from '../../../types/Demographic';
import {
  Band0Icon,
  Band10Icon,
  Band11Icon,
  Band12Icon,
  Band1Icon,
  Band2Icon,
  Band3Icon,
  Band4Icon,
  Band5Icon,
  Band6Icon,
  Band7Icon,
  Band8Icon,
  Band9Icon,
} from '../Icons';
import { DemographicType, FilterItem } from './types';
import { mapStyles } from './config';

export const POI_PAN_ZOOM = 13;
export const MAX_POIS_ON_SCREEN = 100;

export const incomeIcons: { [key in IncomeBand]: string } = {
  [IncomeBand.BAND0]: Band0Icon,
  [IncomeBand.BAND1]: Band1Icon,
  [IncomeBand.BAND2]: Band2Icon,
  [IncomeBand.BAND3]: Band3Icon,
  [IncomeBand.BAND4]: Band4Icon,
  [IncomeBand.BAND5]: Band5Icon,
  [IncomeBand.BAND6]: Band6Icon,
  [IncomeBand.BAND7]: Band7Icon,
  [IncomeBand.BAND8]: Band8Icon,
  [IncomeBand.BAND9]: Band9Icon,
  [IncomeBand.BAND10]: Band10Icon,
  [IncomeBand.BAND11]: Band11Icon,
  [IncomeBand.BAND12]: Band12Icon,
};

export const demographicColor = (demographic: Demographic): string => {
  const colors = [
    '#612087',
    '#2F64AF',
    '#2F64AF',
    '#4089C4',
    '#3A8276',
    '#559F52',
    '#A8B997',
    '#C3CF55',
    '#F5E35C',
    '#E1AD41',
    '#E38B35',
    '#C1725A',
    'rgb(76,76,76)',
  ];
  // let numDemographic;

  // if (demographic in IncomeBand)
  //   numDemographic = IncomeBand[demographic as IncomeBand];
  // if (demographic in EthnicGroup)
  //   numDemographic = EthnicGroup[demographic as EthnicGroup];
  // if (demographic in EducationLevel)
  //   numDemographic = EducationLevel[demographic as EducationLevel];
  return colors[demographic];
};

// const defaultStyle: { height: number; width: number } = {
//   // todo have a set of styles with different size circles for larger clusters
//   height: 53,
//   width: 53,
// };

// export const clusterStyles = incomeBands.map((band) => ({
//   ...defaultStyle,
//   url: incomeIcons[band],
// }));

export { default as mapStyles } from './mapStyles.json';

export const getMarkerSize = (sizeDecimal: number) => {
  if (sizeDecimal === 0) return 7;
  const min = 30;
  const scale = 60;

  return min + amplify(sizeDecimal) * scale;
};

// scales smaller points more than larger ones
const amplify = (x: number): number => {
  const n = 20;
  return ((n + 1) * x) / (n * x + 1);
};

export const filters: FilterItem[] = [
  { id: DemographicType.INCOME_BAND, label: 'Income' },
  { id: DemographicType.ETHNIC_GROUP, label: 'Ethnic Group' },
  { id: DemographicType.EDUCATION_LEVEL, label: 'Education' },
  // { id: 4, name: 'Religion' },
];

export const HOUSTON_BOUNDS: google.maps.LatLngBoundsLiteral = {
  north: 30.1107,
  south: 29.5235,
  east: -94.9886,
  west: -95.8218,
};

export const defaultFilterDemographic = DemographicType.INCOME_BAND;

export interface DemographicItem {
  demographic: Demographic;
  name: string;
}

export interface DemographicItemWithValue extends DemographicItem {
  value: number;
}

export const incomeBands: DemographicItem[] = [
  { demographic: IncomeBand.BAND0, name: '$0 - $14,999' },
  { demographic: IncomeBand.BAND1, name: '$15,000 - $19,999' },
  { demographic: IncomeBand.BAND2, name: '$20,000 - $29,999' },
  { demographic: IncomeBand.BAND3, name: '$30,000 - $39,999' },
  { demographic: IncomeBand.BAND4, name: '$40,000 - $49,999' },
  { demographic: IncomeBand.BAND5, name: '$50,000 - $74,999' },
  { demographic: IncomeBand.BAND6, name: '$75,000 - $99,999' },
  { demographic: IncomeBand.BAND7, name: '$100,000 - $124,999' },
  { demographic: IncomeBand.BAND8, name: '$125,000 - $149,999' },
  { demographic: IncomeBand.BAND9, name: '$150,000 - $174,999' },
  { demographic: IncomeBand.BAND10, name: '$175,000 - $199,999' },
  { demographic: IncomeBand.BAND11, name: '$200,000 - $249,999' },
  { demographic: IncomeBand.BAND12, name: '$250,000 or More' },
];

export const ethnicGroups: DemographicItem[] = [
  { demographic: EthnicGroup.WESTERN_EUROPEAN, name: 'Western European' },
  { demographic: EthnicGroup.EASTERN_EUROPEAN, name: 'Eastern European' },
  { demographic: EthnicGroup.HISPANIC, name: 'Hispanic' },
  { demographic: EthnicGroup.POLYNESIAN, name: 'Polynesian' },
  { demographic: EthnicGroup.MEDITERRANEAN, name: 'Mediterranean' },
  { demographic: EthnicGroup.JEWISH, name: 'Jewish' },
  { demographic: EthnicGroup.AFRICAN_AMERICAN, name: 'African American' },
  { demographic: EthnicGroup.SOUTHEAST_ASIAN, name: 'Southeast Asian' },
  {
    demographic: EthnicGroup.CENTRAL_SOUTHWEST_ASIAN,
    name: 'Central/Southwest Asian',
  },
  { demographic: EthnicGroup.SCANDINAVIAN, name: 'Scandinavian' },
  { demographic: EthnicGroup.FAR_EASTERN, name: 'Far Eastern' },
  { demographic: EthnicGroup.MIDDLE_EASTERN, name: 'Middle Eastern' },
  { demographic: EthnicGroup.NATIVE_AMERICAN, name: 'Native American' },
  { demographic: EthnicGroup.UNKNOWN, name: 'Unknown' },
  { demographic: EthnicGroup.OTHER, name: 'Other' },
];

export const educationLevels: DemographicItem[] = [
  { demographic: EducationLevel.HIGH_SCHOOL, name: 'High School' },
  { demographic: EducationLevel.SOME_COLLEGE, name: 'Some College' },
  { demographic: EducationLevel.COLLEGE, name: 'College' },
  { demographic: EducationLevel.GRADUATE_SCHOOL, name: 'Graduate School' },
  { demographic: EducationLevel.UNKNOWN, name: 'Unknown' },
];

export const demographicTypeInfo = {
  [DemographicType.INCOME_BAND]: {
    label: 'Household Income',
    key: 'income_bands',
    fields: incomeBands,
  },
  [DemographicType.ETHNIC_GROUP]: {
    label: 'Ethnic Group',
    key: 'ethnic_groups',
    fields: ethnicGroups,
  },

  [DemographicType.EDUCATION_LEVEL]: {
    label: 'Education Level',
    key: 'education_levels',
    fields: educationLevels,
  },
};

export const pointOfInterestTypeLabel: {
  [key in PointOfInterestType]: string;
} = {
  [PointOfInterestType.PRIMARY_SCHOOL]: 'Primary School',
  [PointOfInterestType.SECONDARY_SCHOOL]: 'Secondary School',
  [PointOfInterestType.UNIVERSITY]: 'University',
  [PointOfInterestType.LIBRARY]: 'Library',
  [PointOfInterestType.SUPERMARKET]: 'Supermarket',
  [PointOfInterestType.DOCTOR]: 'Doctor',
  [PointOfInterestType.DRUGSTORE]: 'Drugstore',
  [PointOfInterestType.HOSPITAL]: 'Hospital',
  [PointOfInterestType.GYM]: 'Gym',
  [PointOfInterestType.PHARMACY]: 'Pharmacy',
  [PointOfInterestType.CITY_HALL]: 'City Hall',
  [PointOfInterestType.COURTHOUSE]: 'Courthouse',
  [PointOfInterestType.LAWYER]: 'Lawyer',
  [PointOfInterestType.POLICE]: 'Police',
  [PointOfInterestType.CHURCH]: 'Church',
};

export const MAP_OPTIONS: google.maps.MapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  clickableIcons: false,
  center: {
    lng: -95.39936351318359,
    lat: 29.721429166683873,
  },
  zoom: 11,
  restriction: {
    latLngBounds: {
      north: HOUSTON_BOUNDS.north + 0.3,
      south: HOUSTON_BOUNDS.south - 0.3,
      east: HOUSTON_BOUNDS.east + 0.3,
      west: HOUSTON_BOUNDS.west - 0.3,
    },
    strictBounds: false,
  },
};
