import { useCallback } from 'react';
import { useGetDomains } from 'store/hooks';

export const useOnGetDomainById = () => {
  const domains = useGetDomains();
  const onGetDomainById = useCallback(
    (domainId: string) =>
      domains.find((domain) => domain.domainId === domainId),
    [domains],
  );
  return { onGetDomainById };
};

export default useOnGetDomainById;
