import UserDetailsDTO from 'dtos/UserDetailsDTO';
import RelatedObjectDTO from '../dtos/RelatedObjectDTO';

export const defaultUserState: UserState = {
  _id: '',
  id: '',
  logged_in: false,
  is_site_admin: false,
  is_admin: false,
  name: '',
  username: '',
  email: '',
  church_memberships: [],
  domain_memberships: [],
  coalition_memberships: [],
  grants: [],
  related_coalition_objs: [],
  related_church_objs: [],
};

export default interface UserState extends UserDetailsDTO {
  logged_in: boolean;
  is_site_admin: boolean;
  is_admin: boolean;
  related_coalition_objs: string[];
  related_church_objs: RelatedObjectDTO[];
}
