import * as St from 'components/Style.styled';
import { TertiaryButtonLink } from 'components/Style.styled';
import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import styled, { StyledComponent } from 'styled-components';
import apis from '../../api';
import CheckboxDropdown from '../../components/CheckboxDropdown';
import EventCard from '../../components/EventCard';
import {
  DROPDOWN_NO_COALITIONS,
  DROPDOWN_NO_DOMAINS,
  HOUSTON_LATLNG_BOUNDS,
} from '../../config/constants';

import { ToastContext } from '../../contexts/ToastContext';
import { defaultAutocompleteDetails } from '../../dtos/AutocompleteDetails';
// import useFetchGooglePlacesPhotos from '../../hooks/useFetchGooglePlacesPhotos';
import 'react-datepicker/dist/react-datepicker.css';
import useUserAccess from '../../hooks/useUserAccess';
import { updateChurchById, updateEvents } from '../../store/helpers';
import {
  useAppDispatch,
  useGetChurchMemberships,
  useGetCoalitions,
  useGetCurrentUser,
  useGetDomains,
  useGetEvents,
} from '../../store/hooks';
import {
  dropdownGetSelected,
  dropdownSelectOne,
} from '../../utils/dropdownUtils';
import firstOrNull from '../../utils/firstOrNull';
import { stringNullOrEmpty } from '../../utils/strings';
import toDropdownItems from '../../utils/toDropdownItems';

const StyledAutocomplete: StyledComponent<
  typeof Autocomplete,
  any,
  { name: string; value: string }
> = styled(Autocomplete)`
  ${St.textInputStyles};
  box-sizing: border-box;
  padding-right: 1.5rem;
`;

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 124px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const options = {
  fields: [
    'place_id',
    'address_components',
    'name',
    'photos',
    'geometry.location',
    'formatted_address',
  ],
  types: ['geocode', 'establishment'],
  bounds: HOUSTON_LATLNG_BOUNDS,
  strictBounds: true,
};

const CreateEvent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isDomainMemberOf, userCoalitionAffiliation } = useUserAccess();
  const events = useGetEvents();
  let filteredEvents = [];
  const { churches } = useGetChurchMemberships();
  const church_id = churches[0].church_id;
  const currentUser = useGetCurrentUser();
  const coalition = userCoalitionAffiliation();

  const [placesInfo, setPlacesInfo] = useState(defaultAutocompleteDetails);
  const [selectedDate, setSelectedDate] = useState(null);
  const userDomains = useGetDomains().filter((d) => isDomainMemberOf(d));
  const domainFilterState = useState(toDropdownItems(userDomains));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const selectedDomain = firstOrNull(dropdownGetSelected(domainFilterState));
  const { show } = useContext(ToastContext);

  useEffect(() => {
    if (church_id) {
      filteredEvents = events.filter((e) => e?.church_id === church_id);
    }
  }, [church_id]);

  const onAutocomplete = (place: google.maps.places.PlaceResult) => {
    setPlacesInfo({
      name: place.formatted_address,
      place_id: place.place_id,
      coords: [place.geometry?.location.lng(), place.geometry?.location.lat()],
    });
  };

  const onLocationInputChange = (e) => {
    setPlacesInfo({
      ...defaultAutocompleteDetails,
      name: e.target.value,
    });
  };

  const refreshEvents = () => {
    if (church_id) updateChurchById(dispatch, church_id);
    updateEvents(dispatch, currentUser._id);
  };

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (stringNullOrEmpty(form.title.value)) {
      show('must-enter-title-failure');
      return;
    }
    if (!selectedDate) {
      show('must-enter-date-failure');
      return;
    }
    apis.events
      .create({
        name: form.title.value,
        description: form.description.value,
        scheduled: selectedDate.toISOString(),
        location: placesInfo.name,
        latitude: placesInfo.coords[1],
        longitude: placesInfo.coords[0],
        church_id: church_id,
        coalition_id: coalition.coalition_id,
        domain_id: selectedDomain?.id,
        user_id: currentUser._id,
      })
      .then(() => {
        refreshEvents();
        show('event-created-success');
        form.reset();
        setSelectedDate(null);
        setPlacesInfo(defaultAutocompleteDetails);
      });
  };

  return (
    <>
      <St.Header style={{ marginLeft: '30px' }}>
        <St.HeaderText>Create calendar event</St.HeaderText>
      </St.Header>
      <Container>
        <St.Column width="40%" alignItems="flex-start">
          <St.Text fontWeight="800" mb="10px">
            Event Details
          </St.Text>
          <St.Form gap="14px" width="100%" onSubmit={submit}>
            <St.Row justifyContent="flex-start" gap="10px">
              <CheckboxDropdown
                items={
                  domainFilterState[0].length > 0
                    ? domainFilterState[0]
                    : DROPDOWN_NO_DOMAINS
                }
                parentSetOpen={setDropdownOpen}
                width="100%"
                onClickItem={(i) => dropdownSelectOne(i, domainFilterState)}
              >
                {selectedDomain?.name ?? 'No domain'}
              </CheckboxDropdown>
              {/* <CheckboxDropdown
                items={
                  coalitionFilterState[0].length > 0
                    ? coalitionFilterState[0]
                    : DROPDOWN_NO_COALITIONS
                }
                parentSetOpen={setDropdownOpen}
                width="230px"
                onClickItem={(i) => dropdownSelectOne(i, coalitionFilterState)}
              >
                {selectedCoalition?.name ?? 'No Coalition'}
              </CheckboxDropdown> */}
            </St.Row>
            <St.TextInput placeholder="Title" name="title" />
            <div
              style={{
                zIndex: dropdownOpen ? '-1' : 100,
                width: '100%',
              }}
            >
              <St.DateInput
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MMMM d, yyyy h:mm aa"
                isClearable
                showTimeSelect
                placeholderText="Select a date"
              />
            </div>
            <StyledAutocomplete
              options={options}
              onPlaceSelected={onAutocomplete}
              onChange={onLocationInputChange}
              name="name"
              id="name"
              value={placesInfo.name}
              // onLoad={() => null}
            />
            <St.LargeInput name="description" placeholder="Description..." />
            <St.Row gap="14px" justifyContent="flex-start">
              <TertiaryButtonLink
                to={church_id ? `/churches/${church_id}` : '/calendar'}
              >
                Back
              </TertiaryButtonLink>
              <St.PrimaryButton type="submit">Add Event</St.PrimaryButton>
            </St.Row>
          </St.Form>
        </St.Column>
        <St.Column width="50%">
          <St.HighlightsBanner>Current Listed Events</St.HighlightsBanner>
          <St.Column width="100%" pl="15px" pt="25px" gap="13px">
            {filteredEvents.length > 0
              ? filteredEvents.map((e) => <EventCard event={e} key={e._id} />)
              : events.length > 0
              ? events.map((e) => <EventCard event={e} key={e._id} />)
              : 'No upcoming events'}
          </St.Column>
        </St.Column>
      </Container>
    </>
  );
};

export default CreateEvent;
