import React from 'react';
import { useNavigate } from 'react-router-dom';
import apis from 'api';
import * as St from '../components/Style.styled';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 124px;
`;

const INPUT_MARGIN = '7px';
const Form = styled.form`
  display: flex;
`;

const AddDomain: React.FC = () => {
  const navigate = useNavigate();

  const submit = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const result = await apis.domains.create({
      name: form.name.value,
      is_expert: form.is_expert.checked,
    });

    if (!result) return;
    navigate('/domains/' + result.id);
  };

  return (
    <Container>
      <St.Text fontSize="20px" fontWeight="800">
        Add New Domain
      </St.Text>
      <Form className="flex-row" onSubmit={submit}>
        <St.Column width="50%" mr="29px" mt="20px">
          <St.Row justifyContent="start" width="100%">
            <St.Text fontWeight="800">Domain Details</St.Text>
          </St.Row>
          <St.TextInput
            type="text"
            name="name"
            id="name"
            placeholder="Domain name"
            required
            m={INPUT_MARGIN}
          />
          <St.Row justifyContent="start" mb="5px" width="100%">
            <input type="checkbox" name="is_expert" />
            <St.Text ml="7.5px">
              Are you a partner / expert in this domain?
            </St.Text>
          </St.Row>
          <St.Row className="justify-start" style={{ width: '100%' }}>
            <St.TertiaryButton mr={INPUT_MARGIN} mt={INPUT_MARGIN}>
              Cancel
            </St.TertiaryButton>
            <St.PrimaryButton
              type="submit"
              ml={INPUT_MARGIN}
              mt={INPUT_MARGIN}
              width="150px"
            >
              Add Domain
            </St.PrimaryButton>
          </St.Row>
        </St.Column>
      </Form>
    </Container>
  );
};

export default AddDomain;
