import apis from 'api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DropdownItem } from '../components/CheckboxDropdown';
import ChurchForm from './ChurchForm';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 124px;
`;

const CreateChurch: React.FC = () => {
  const navigate = useNavigate();
  const [placesInfo, setPlacesInfo] = useState({
    place_id: '',
    name: '',
    description: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    photo: '',
    coords: [0, 0] as [number, number],
    primary_contact: '',
    primary_email: '',
    primary_phone: '',
  });

  const [coalitions, setCoalitions] = useState([] as DropdownItem[]);

  useEffect(() => {
    apis.coalitions.get_all().then((res) => {
      if (!res) return;
      setCoalitions(
        res.map((c) => ({
          id: c._id,
          name: c.name,
          checked: false,
        })),
      );
    });
  }, []);

  const submit = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const result = await apis.churches.create({
      google_places_id: placesInfo.place_id,
      name: form.name.value,
      address1: form.address1.value,
      address2: form.address2.value,
      city: form.city.value,
      state: form.state.value,
      zip_code: form.zip_code.value,
      description: form.description.value,
      image: placesInfo.photo,
      longitude: placesInfo.coords[0],
      latitude: placesInfo.coords[1],
      key_initiatives: [],
      primary_contact:
        `${form.primary_first_name.value} ${form.primary_last_name.value}`.trim(),
      primary_phone: form.primary_phone.value,
      primary_email: form.primary_email.value,
      coalitions: coalitions.filter((c) => c.checked).map((c) => c.id),
    });

    if (!result) return;
    navigate('/churches/' + result);
  };

  const onFormInput = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    if (field in placesInfo) {
      setPlacesInfo({ ...placesInfo, [field]: value });
    }
  };

  return (
    <Container>
      <ChurchForm
        onFormInput={onFormInput}
        placesInfo={placesInfo}
        setPlacesInfo={setPlacesInfo}
        submit={submit}
      />
    </Container>
  );
};

export default CreateChurch;
