import React, { useContext } from 'react';
import styled from 'styled-components';
import * as St from '../../../components/Style.styled';
import { ToastContext } from '../../../contexts/ToastContext';
import CoalitionDetailsDTO from '../../../dtos/CoalitionDetailsDTO';
import { useJoinCoalition } from '../../../hooks/updateHooks';
import { useGetCoalitions, useGetCurrentUser } from '../../../store/hooks';
import UserConfigStep from '../../../types/UserConfigStep';
import { StepProps } from './index';
import useUserAccess from 'hooks/useUserAccess';

const Coalitions = styled(St.Column)`
  background-color: var(--color-light-grey);
  border-radius: 4px;
  padding: 25px 16px;
  height: 300px;
  gap: 10px;
  overflow-y: scroll;
  justify-content: flex-start;
`;

const CoalitionCard: React.FC<{ coalition: CoalitionDetailsDTO }> = ({
  coalition,
}) => {
  const { onJoinCoalition } = useJoinCoalition();
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();
  const { churchIdIfAdmin } = useUserAccess();
  const church_id = churchIdIfAdmin();

  const handleJoinCoalition = async () => {
    if (!church_id)
      return alert('You must be a church admin to join a coalition');

    await onJoinCoalition(church_id, coalition._id, currentUser._id);
    show('joined-coalition-success');
  };

  const renderRequestButton = () => {
    const joined = currentUser.coalition_memberships.some(
      (membership) => membership.coalition_id === coalition.id,
    );

    return (
      <St.PrimaryButton
        type="button"
        disabled={joined}
        onClick={handleJoinCoalition}
      >
        {!joined ? 'Join' : 'Joined'}
      </St.PrimaryButton>
    );
  };

  return (
    <St.Row
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      width="100%"
    >
      <St.Text>{coalition.name}</St.Text>
      {renderRequestButton()}
    </St.Row>
  );
};

const JoinCoalitions: React.FC<StepProps> = ({ setStep }) => {
  const coalitions = useGetCoalitions();
  return (
    <St.Column height="445px">
      <St.Text
        fontWeight="800"
        fontSize="20px"
        textAlign="center"
        mb="20px"
        mx="25px"
      >
        Join coalitions to stay up to date with their activities.
      </St.Text>
      <Coalitions>
        {coalitions.map((domain) => (
          <CoalitionCard key={domain.id} coalition={domain} />
        ))}
      </Coalitions>
      <St.PrimaryButton
        type="button"
        onClick={() => setStep(UserConfigStep.JoinChurches)}
        mt="auto"
      >
        Continue
      </St.PrimaryButton>
    </St.Column>
  );
};

export default JoinCoalitions;
