import * as St from 'components/Style.styled';
import routes from 'config/routes';
import useUserAccess from 'hooks/useUserAccess';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import EditButtons from '../../components/EditButtons';
import EventCard from '../../components/EventCard';
import ChurchDetailsDTO from '../../dtos/ChurchDetailsDTO';
import EventDetailsDTO from '../../dtos/EventDetailsDTO';

interface CalendarProps {
  church: ChurchDetailsDTO;
  events: EventDetailsDTO[];
}

const ChurchCalendar: React.FC<CalendarProps> = ({ church, events }) => {
  const { isChurchAdminOf } = useUserAccess();
  const isChurchAdmin = isChurchAdminOf(church);

  const navigate = useNavigate();
  const onClickAdd = useCallback(() => navigate(routes.CREATE_EVENT.href), []);

  return (
    <St.Column gap={2} alignItems="flex-start" width="100%">
      <St.Row justifyContent="space-between" width="100%">
        <St.Text
          fontWeight="800"
          fontSize="20px"
          color="var(--color-dark-grey)"
          mt="5px"
        >
          Upcoming Events
        </St.Text>
        {isChurchAdmin && (
          <EditButtons onClickAdd={onClickAdd} onClickEdit={null} />
        )}
      </St.Row>
      <St.Column width="100%" pt="10px" gap="13px">
        {events.length > 0 ? (
          events.map((e) => <EventCard event={e} key={e._id} />)
        ) : (
          <St.Text mr="auto">No upcoming events</St.Text>
        )}
      </St.Column>
    </St.Column>
  );
};

export default ChurchCalendar;
