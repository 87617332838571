import * as St from 'components/Style.styled';
import { sortBy } from 'lodash';
import React from 'react';
import useGetNotifications from 'hooks/useGetNotifications';

const Notifications: React.FC = () => {
  const notifications = useGetNotifications();
  return (
    <St.Div pb="10px">
      <St.Header>
        <St.HeaderText>Notifications</St.HeaderText>
      </St.Header>
      <St.Column alignItems="flex-start" gap="10px" width="50%">
        {notifications.length > 0
          ? sortBy(notifications, (n) => -n.date).map((notification) => (
              <St.Div key={notification.id} width="100%">
                {notification.component}
              </St.Div>
            ))
          : 'No notifications'}
      </St.Column>
    </St.Div>
  );
};

export default Notifications;
