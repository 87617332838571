import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultUserState } from 'types/UserState';
import RelatedObjectDTO from '../dtos/RelatedObjectDTO';
import UserDetailsDTO from '../dtos/UserDetailsDTO';

export const userSlice = createSlice({
  name: 'user',
  initialState: defaultUserState,
  reducers: {
    set_logged_in: (state, action: PayloadAction<UserDetailsDTO>) => {
      Object.assign(state, action.payload);
      state.logged_in = true;
      state.is_admin = action.payload.grants
        ? action.payload?.grants.includes('ADMINISTRATOR')
        : false;
    },
    set_logged_out: (state) => {
      state.logged_in = false;
      state.id = '';
      state.is_admin = false;
      state.is_site_admin = false;
      state.name = '';
      state.username = '';
      state.email = '';
    },
    set_related_church_ids: (
      state,
      action: PayloadAction<RelatedObjectDTO[]>,
    ) => {
      state.related_church_objs = action.payload;
    },
    set_related_coalition_ids: (state, action: PayloadAction<string[]>) => {
      state.related_coalition_objs = action.payload;
    },
  },
});

export const {
  set_logged_in,
  set_logged_out,
  set_related_coalition_ids,
  set_related_church_ids,
} = userSlice.actions;
export default userSlice.reducer;
