import ChurchStatisticDetailsDTO from '../dtos/ChurchStatisticDetailsDTO';

export const inverse = (obj) => {
  const retobj = {};
  for (const key in obj) {
    retobj[obj[key]] = key;
  }
  return retobj;
};

export const isLatLngLiteral = (obj: any): obj is google.maps.LatLngLiteral => {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.lat === 'number' &&
    typeof obj.lng === 'number'
  );
};

export const areChurchStatisticsEqual = (
  a: ChurchStatisticDetailsDTO,
  b: ChurchStatisticDetailsDTO,
) =>
  a._id === b._id &&
  a.description === b.description &&
  a.more_info === b.more_info &&
  a.type_id === b.type_id &&
  a.quantity === b.quantity &&
  a.domain_id === b.domain_id &&
  a.church_id === b.church_id;
