import apis from 'api';
import React, { useState } from 'react';
import * as St from '../../components/Style.styled';
import { LoadingWhite } from '../../config/icons';
import { ModalContext } from '../../contexts/ModalContext';
import { ToastContext } from '../../contexts/ToastContext';
import { set_logged_in } from 'store/user';
import { useAppDispatch } from '../../store/hooks';
import { SignUpStepProps } from './index';
import { encrypt } from 'utils/encryptAndDecrypt';

const SignUpForm: React.FC<SignUpStepProps> = ({ setStep }) => {
  const [confirming, setConfirming] = useState(false);
  const dispatch = useAppDispatch();
  const { toggleSignInOpen } = React.useContext(ModalContext);
  const { show } = React.useContext(ToastContext);
  const submit = (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.name.value === '' || form.name.value === undefined) {
      show('must-enter-name-failure');
      return;
    }

    if (form.username.value === '' || form.username.value === undefined) {
      show('must-enter-username-failure');
      return;
    }

    if (form.email.value === '' || form.email.value === undefined) {
      show('must-enter-email-failure');
      return;
    }

    if (form.password.value === '' || form.password.value === undefined) {
      show('must-enter-password-failure');
      return;
    }

    if (form.password.value !== form.password_confirm.value) {
      show('passwords-do-not-match-failure');
      return;
    }

    setConfirming(true);
    apis.users
      .create({
        username: form.username.value,
        name: form.name.value,
        email: form.email.value,
        password: encrypt(form.password.value),
      })
      .then(async () => {
        setConfirming(false);
        show('sign-up-success');
        const result = await apis.users.login(
          form.username.value,
          form.password.value,
        );
        dispatch(set_logged_in(result));
        setStep(1);
      })
      .catch(() => {
        show('sign-up-failure');
        setConfirming(false);
      });
  };

  return (
    <St.Form onSubmit={submit}>
      <St.FormLabel>Name</St.FormLabel>
      <St.TextInput mb="2" placeholder="Full Name" name="name" />
      <St.FormLabel>Username</St.FormLabel>
      <St.TextInput mb="2" placeholder="Username" name="username" />
      <St.FormLabel>Email</St.FormLabel>
      <St.TextInput mb="2" placeholder="Email" type="email" name="email" />
      <St.FormLabel>Password</St.FormLabel>
      <St.TextInput
        type="password"
        mb="2"
        placeholder="Password"
        name="password"
      />
      <St.FormLabel>Confirm Password</St.FormLabel>
      <St.TextInput
        type="password"
        mb="4"
        placeholder="Confirm Password"
        name="password_confirm"
      />
      <St.PrimaryButton type="submit">
        {confirming ? (
          <St.Icon src={LoadingWhite} size="20px" mx="auto" />
        ) : (
          'Sign up'
        )}
      </St.PrimaryButton>
      <St.Row mt="30px">
        <St.Text
          style={{ whiteSpace: 'nowrap' }}
          fontWeight="800"
          fontSize="12px"
        >
          Already have an account?
        </St.Text>
        <St.Button
          p="0"
          ml="1"
          color="black"
          fontSize="12px"
          type="button"
          onClick={toggleSignInOpen}
        >
          Sign in
        </St.Button>
      </St.Row>
    </St.Form>
  );
};

export default SignUpForm;
