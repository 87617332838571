import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import { defaultChurchDetailsDTO } from 'dtos/ChurchDetailsDTO';

import apis from 'api';

import NotAuthorized from 'components/NotAuthorized';
import ListActionButton from '../components/ListActionButton';
import Page from '../components/Page';

const PendingChurches: React.FC = () => {
  const [reload, setReload] = useState(false);
  const [list, setList] = useState([defaultChurchDetailsDTO]);

  const is_admin = useAppSelector((state) => state.user.is_site_admin);

  useEffect(() => {
    if (!is_admin) return;
    apis.churches.get_pending().then((result) => {
      if (!result) return;
      setList(result);
    });
  }, [is_admin, reload]);

  const approve_church = async (id: string): Promise<void> => {
    if (!is_admin) return;
    await apis.churches.approve(id);
    setReload(!reload);
  };

  const deny_church = async (id: string): Promise<void> => {
    if (!is_admin) return;
    await apis.churches.deny(id);
    setReload(!reload);
  };

  if (!is_admin) return <NotAuthorized />;

  return (
    <Page>
      <div className="text-2xl font-bold">Pending Churches</div>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Name</th>
            <th>Primary Contact</th>
            <th>Primary Phone</th>
            <th>Primary Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {list.map((church) => (
            <tr key={church.id}>
              <td>{church.name}</td>
              <td>{church.primary_contact}</td>
              <td>{church.primary_phone}</td>
              <td>{church.primary_email}</td>
              <td>
                <ListActionButton
                  text="APPROVE"
                  onClick={() => approve_church(church.id)}
                />
                <ListActionButton
                  text="DENY"
                  onClick={() => deny_church(church.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Page>
  );
};

export default PendingChurches;
