import { DemographicType } from '../components/Map/config/types';
import HouseholdDetailsDTO from '../dtos/HouseholdDetailsDTO';

export const defaultMapState: MapState = {
  households: [],
  filterDemographic: DemographicType.INCOME_BAND,
};

export default interface MapState {
  households: HouseholdDetailsDTO[];
  filterDemographic: DemographicType;
}
