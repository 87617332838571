enum DomainId {
  Health,
  Trafficking,
  Immigration,
  Economics,
  Housing,
  DisasterRecovery,
  Education,
  Discipleship,
  FoodSecurity,
  Evangelism,
  CriminalJustice,
  Recovery,
}

export default DomainId;
