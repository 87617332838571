import apis from 'api';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useGetCurrentUser } from 'store/hooks';
import Modal from '../components/Modal';
import * as St from '../components/Style.styled';
import { ToastContext } from '../contexts/ToastContext';
import useModal from '../hooks/useModal';
import { updateEvents } from '../store/helpers';

const RemoveEventModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const { removeEventOpen, closeRemoveEvent } = useModal();
  const { show } = useContext(ToastContext);
  const pathnameArray = window.location.pathname.split('/');
  const guidIndex = pathnameArray.indexOf('calendar') + 1;
  const id = pathnameArray[guidIndex];
  const handleDeleteEvent = () => {
    apis.events.remove(id).then(() => {
      updateEvents(dispatch, currentUser._id);
      closeRemoveEvent();
      show('event-removed-success');
      navigate(-1);
    });
  };

  return (
    <Modal
      height="450px"
      isOpen={removeEventOpen}
      requestClose={closeRemoveEvent}
    >
      <St.Column gap="50px" width="100%" px="10px">
        <St.Text fontWeight="800" fontSize="16px" mt="20px" textAlign="center">
          Are you sure you want to remove this event?
        </St.Text>
        <St.Row gap="10px">
          <St.SecondaryButton onClick={closeRemoveEvent}>
            Back
          </St.SecondaryButton>
          <St.PrimaryButton type="button" onClick={handleDeleteEvent}>
            Remove
          </St.PrimaryButton>
        </St.Row>
      </St.Column>
    </Modal>
  );
};

export default RemoveEventModal;
