import { executor, getAxios } from 'api';
import CoalitionKeyInitiativeDetailsDTO from 'dtos/CoalitionKeyInitiativeDetailsDTO';
import { CoalitionMemberDetailsDTO } from 'dtos/CoalitionMemberDetailsDTO';
import AddKeyInitiativeDTO from '../dtos/AddKeyInitiativeDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import CoalitionUserDetailsDTO from '../dtos/CoalitionUserDetailsDTO';
import RelatedObjectDTO from '../dtos/RelatedObjectDTO';
import UpdateKeyInitiativeDTO from '../dtos/UpdateKeyInitiativeDTO';

export interface CoalitionsApi {
  get_all: () => Promise<CoalitionDetailsDTO[] | undefined>;
  get_user_memberships: (id: string) => Promise<CoalitionMemberDetailsDTO[]>;
  get_by_id: (id: string) => Promise<CoalitionDetailsDTO | undefined>;
  join: (
    coalition_id: string,
    church_id: string,
    user_id: string,
  ) => Promise<void>;
  get_incoming_admin_requests: () => Promise<CoalitionUserDetailsDTO[]>;
  approve_admin_status: (id: string, userId: string) => Promise<void>;
  get_related_ids: () => Promise<RelatedObjectDTO[]>;
  get_key_initiatives: (
    coalition_id: string,
  ) => Promise<CoalitionKeyInitiativeDetailsDTO[]>;

  update_key_initiative: (
    initiativeId: string,
    dto: UpdateKeyInitiativeDTO,
  ) => Promise<void | undefined>;
  add_key_initiative: (dto: AddKeyInitiativeDTO) => Promise<string | undefined>;
  remove_key_initiative: (id: string) => Promise<void | undefined>;
  request_admin_status: (id: string) => Promise<void>;
  deny_admin_status: (id: string, userId: string) => Promise<void>;
}

const get_user_memberships = async (
  user_id: string,
): Promise<CoalitionMemberDetailsDTO[]> => {
  const action = async (): Promise<CoalitionMemberDetailsDTO[]> => {
    const church = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_members',
        query: { _IsActive: { $ne: false }, user_id },
      },
    });
    if (!church?.data?.data) return [];

    const church_id = church.data.data[0].church_id;
    const res = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'coalition_members',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id,
            },
          },
          {
            $addFields: {
              _coalition_id: {
                $toObjectId: '$coalition_id',
              },
            },
          },
          {
            $lookup: {
              from: 'coalitions',
              localField: '_coalition_id',
              foreignField: '_id',
              as: 'coalition',
            },
          },
          {
            $unwind: '$coalition',
          },
          {
            $addFields: {
              _church_id: {
                $toObjectId: '$church_id',
              },
            },
          },
          {
            $lookup: {
              from: 'churches',
              localField: '_church_id',
              foreignField: '_id',
              as: 'church',
            },
          },
          {
            $unwind: '$church',
          },
        ],
      },
    });

    return res?.data?.data ?? [];
  };

  return await executor(action, 'get_user_memberships');
};

const get_all = async (): Promise<CoalitionDetailsDTO[] | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: { collection: 'coalitions', query: { _IsActive: { $ne: false } } },
    });

    return result.data.data;
  };

  return await executor(action, 'coalitions');
};

const get_by_id = async (
  id: string,
): Promise<CoalitionDetailsDTO | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'coalitions',
        query: { _id: id, _IsActive: { $ne: false } },
      },
    });

    return result.data;
  };

  return await executor(action, 'coalition_by_id');
};

const join = async (
  coalition_id: string,
  church_id: string,
  user_id: string,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'churches',
        query: { _id: church_id },
        update: { coalition_id },
      },
    });

    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'coalition_members',
        query: { church_id },
        update: {
          coalition_id,
          church_id,
        },
      },
    });

    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'coalition_users',
        query: { user_id },
        update: {
          coalition_id,
          user_id,
          role: 'ro',
        },
      },
    });
  };

  await executor(action, 'join_coalition');
};

const get_incoming_admin_requests = async (): Promise<
  CoalitionUserDetailsDTO[]
> => {
  const action = async (): Promise<CoalitionUserDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'coalitions/incoming_admin_requests',
    });

    return result.data;
  };

  return await executor(action, 'get_incoming_coalition_admin_requests');
};

const approve_admin_status = async (
  id: string,
  userId: string,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: `coalitions/${id}/approve_admin_status/${userId}`,
    });
  };

  await executor(action, 'approve_coalition_user_admin_status');
};

const get_related_ids = async (): Promise<RelatedObjectDTO[]> => {
  const action = async (): Promise<RelatedObjectDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'coalitions/related_ids',
    });

    return result.data;
  };

  return await executor(action, 'get_related_coalition_ids');
};

const get_key_initiatives = async (
  coalition_id: string,
): Promise<CoalitionKeyInitiativeDetailsDTO[] | undefined> => {
  const action = async (): Promise<CoalitionKeyInitiativeDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'coalition_key_initiatives',
        query: {
          _IsActive: { $ne: false },
          coalition_id: coalition_id,
        },
      },
    });

    return result?.data.data ?? [];
  };

  return await executor(action, 'get_key_initiatives');
};

const update_key_initiative = async (
  initiativeId: string,
  dto: UpdateKeyInitiativeDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'coalition_key_initiatives',
        query: {
          _id: initiativeId,
        },
        update: { ...dto },
      },
    });
  };
  await executor(action, 'update_key_initiative');
};
const add_key_initiative = async (
  dto: AddKeyInitiativeDTO,
): Promise<string> => {
  const action = async (): Promise<void> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'coalition_key_initiatives',
        update: { ...dto },
      },
    });
    return result.data.data;
  };
  return await executor(action, 'add_key_initiative');
};
const remove_key_initiative = async (id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'coalition_key_initiatives',
        query: {
          _id: id,
        },
        update: { _IsActive: false },
      },
    });
  };

  await executor(action, 'remove_key_initiative');
};

const request_admin_status = async (id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'coalitions/' + id + '/request_admin_status',
    });
  };

  await executor(action, 'request_admin_status');
};

const deny_admin_status = async (id: string, userId: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'coalitions/' + id + '/deny_admin_status/' + userId,
    });
  };

  await executor(action, 'deny_admin_status');
};

const coalitions_api_set: CoalitionsApi = {
  get_all,
  get_user_memberships,
  get_by_id,
  join,
  approve_admin_status,
  get_related_ids,
  update_key_initiative,
  add_key_initiative,
  remove_key_initiative,
  request_admin_status,
  get_incoming_admin_requests,
  deny_admin_status,
  get_key_initiatives,
};
export default coalitions_api_set;
