import apis from 'api';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as St from '../components/Style.styled';
import useToast from '../hooks/useToast';
import { updateCurrentUser } from '../store/helpers';
import { useAppDispatch, useGetCurrentUser } from '../store/hooks';
import { encrypt } from 'utils/encryptAndDecrypt';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 124px;
`;

const SmallInput = styled(St.TextInput)`
  flex: 0.5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  //border: black solid 4px;
`;

const EditUserProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const user = useGetCurrentUser();
  const { show } = useToast();

  const submitUpdateDetails = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    try {
      await apis.users.update_user({
        _id: id,
        name: form.name.value,
        username: form.username.value,
        email: form.email.value,
      });
      show('update-user-details-success');
      updateCurrentUser(dispatch);
    } catch (e) {
      show('update-user-details-failure');
    }
  };

  const submitChangePassword = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    try {
      await apis.users.update_password({
        _id: id,
        password: encrypt(form.new_password.value),
      });
      form.reset();
      show('change-password-success');
    } catch (e) {
      show('update-user-details-failure');
    }
  };

  if (id !== user.id) {
    return <div>You do not have access to this page.</div>;
  }

  return (
    <Container>
      <St.Header ml="0">
        <St.Text fontSize="20px" fontWeight="800" mr="auto">
          Edit Profile
        </St.Text>
        <St.TertiaryButtonLink to={`/users/${id}`}>Back</St.TertiaryButtonLink>
      </St.Header>

      <St.Row alignItems="flex-start" gap="10px">
        <Form onSubmit={submitUpdateDetails}>
          <St.Row justifyContent="start" width="100%">
            <St.Text fontWeight="800">Profile Details</St.Text>
          </St.Row>
          <St.TextInput
            type="text"
            name="name"
            placeholder="Name"
            defaultValue={user.name}
          />
          <St.TextInput
            type="text"
            name="username"
            placeholder="Username"
            required
            defaultValue={user.username}
          />
          <St.TextInput
            type="email"
            name="email"
            placeholder="Email"
            required
            defaultValue={user.email}
          />
          <St.PrimaryButton type="submit" width="150px">
            Update profile
          </St.PrimaryButton>
        </Form>
        <Form onSubmit={submitChangePassword}>
          <St.Text fontWeight="800">Change password</St.Text>
          <St.Row
            flexWrap="wrap"
            width="100%"
            justifyContent="flex-start"
            gap="10px"
          >
            <St.TextInput
              type="password"
              name="current_password"
              placeholder="Current password"
            />
            <SmallInput
              type="password"
              name="new_password"
              placeholder="New password"
              required
            />

            <SmallInput
              type="password"
              name="confirm_new_password"
              placeholder="Confirm new password"
              required
            />
          </St.Row>

          <St.PrimaryButton type="submit" width="175px">
            Change password
          </St.PrimaryButton>
        </Form>
      </St.Row>
    </Container>
  );
};

export default EditUserProfile;
