import { executor, getAxios } from 'api';
import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';

import CreateChurchDTO from 'dtos/CreateChurchDTO';
import AddAssetDTO from '../dtos/AddAssetDTO';
import AddChurchMemberDTO from '../dtos/AddChurchMemberDTO';
import AddKeyInitiativeDTO from '../dtos/AddKeyInitiativeDTO';
import AddProgramDTO from '../dtos/AddProgramDTO';
import ChurchMemberDetailsDTO from '../dtos/ChurchMemberDetailsDTO';
import ChurchRelatedDomainDTO from '../dtos/ChurchRelatedDomainDTO';
import ChurchStatisticDetailsDTO from '../dtos/ChurchStatisticDetailsDTO';
import RelatedObjectDTO from '../dtos/RelatedObjectDTO';
import UpdateChurchDTO from '../dtos/UpdateChurchDTO';
import UpdateKeyInitiativeDTO from '../dtos/UpdateKeyInitiativeDTO';
import UpdateProgramDTO from '../dtos/UpdateProgramDTO';
import UpdateStatisticDTO from '../dtos/UpdateStatisticDTO';

export interface ChurchesApi {
  get_all: () => Promise<ChurchDetailsDTO[] | undefined>;
  get_user_memberships: (id: string) => Promise<ChurchMemberDetailsDTO[]>;
  get_all_with_relations: () => Promise<ChurchDetailsDTO[] | undefined>;
  create: (dto: CreateChurchDTO) => Promise<string | undefined>;
  update: (id: string, dto: UpdateChurchDTO) => Promise<boolean>;
  get_by_id: (id: string) => Promise<ChurchDetailsDTO | undefined>;
  get_admin_members: (
    id: string,
  ) => Promise<ChurchMemberDetailsDTO[] | undefined>;
  add_key_initiative: (
    id: string,
    dto: AddKeyInitiativeDTO,
  ) => Promise<void | undefined>;
  remove_key_initiative: (
    id: string,
    initiativeId: string,
  ) => Promise<void | undefined>;
  update_key_initiative: (
    churchId: string,
    initiativeId: string,
    dto: UpdateKeyInitiativeDTO,
  ) => Promise<void | undefined>;
  get_pending: () => Promise<ChurchDetailsDTO[] | undefined>;
  get_approved: () => Promise<ChurchDetailsDTO[] | undefined>;
  approve: (id: string) => Promise<void | undefined>;
  deny: (id: string) => Promise<void | undefined>;
  add_program: (dto: AddProgramDTO) => Promise<string | undefined>;
  delete_program: (id: string, programId: string) => Promise<void>;
  get_related_domains: (
    id: string,
  ) => Promise<ChurchRelatedDomainDTO[] | undefined>;
  get_church_statistics: (
    id: string,
  ) => Promise<ChurchStatisticDetailsDTO[] | undefined>;
  add_church_statistic: (
    id: string,
    dto: AddAssetDTO,
  ) => Promise<void | undefined>;
  update_church_statistic: (
    id: string,
    dto: UpdateStatisticDTO,
  ) => Promise<void>;
  add_member: (
    id: string,
    dto: AddChurchMemberDTO,
  ) => Promise<void | undefined>;
  delete_church_statistic: (id: string) => Promise<void | undefined>;
  update_program: (
    id: string,
    dto: UpdateProgramDTO,
  ) => Promise<void | undefined>;
  join: (user_id: string, church_id: string) => Promise<void>;
  get_members: (id: string) => Promise<ChurchMemberDetailsDTO[]>;
  ingest_test_data: () => Promise<number | undefined>;
  get_household_count: () => Promise<number | undefined>;
}

const get_all = async (): Promise<ChurchDetailsDTO[] | undefined> => {
  const action = async (): Promise<ChurchDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'churches',
        query: { _IsActive: { $ne: false } },
      },
    });

    return result.data.data;
  };

  return await executor(action, 'churches');
};

const get_user_memberships = async (
  id: string,
): Promise<ChurchMemberDetailsDTO[]> => {
  const action = async (): Promise<ChurchMemberDetailsDTO[]> => {
    const res = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_members',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              user_id: id,
            },
          },
          {
            $addFields: {
              _church_id: {
                $toObjectId: '$church_id',
              },
            },
          },
          {
            $lookup: {
              from: 'churches',
              localField: '_church_id',
              foreignField: '_id',
              as: 'church',
            },
          },
          {
            $unwind: '$church',
          },
        ],
      },
    });

    return res?.data?.data ?? [];
  };

  return await executor(action, 'get_user_memberships');
};

const get_all_with_relations = async (): Promise<
  ChurchDetailsDTO[] | undefined
> => {
  const action = async (): Promise<ChurchDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'churches/with_relations',
    });

    return result.data;
  };

  return await executor(action, 'get_churches_with_relations');
};

const get_by_id = async (id: string): Promise<ChurchDetailsDTO | undefined> => {
  const action = async (): Promise<ChurchDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: { collection: 'churches', query: { _id: id } },
    });

    return result?.data?.data?.[0] ?? undefined;
  };

  return await executor(action, 'get_church_by_id');
};

const get_admin_members = async (
  id: string,
): Promise<ChurchMemberDetailsDTO[] | undefined> => {
  const action = async (): Promise<string[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'churches/' + id + '/get_admin_members',
    });

    return result.data;
  };

  return await executor(action, 'get_church_by_id');
};

const add_key_initiative = async (
  id: string,
  dto: AddKeyInitiativeDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'churches/' + id + '/add_key_initiative',
      data: dto,
    });
  };

  await executor(action, 'add_key_initiative');
};

const remove_key_initiative = async (
  id: string,
  initiativeId: string,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'churches/' + id + '/remove_key_initiative/' + initiativeId,
    });
  };

  await executor(action, 'remove_key_initiative');
};

const update_key_initiative = async (
  churchId: string,
  initiativeId: string,
  dto: UpdateKeyInitiativeDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'churches/' + churchId + '/update_key_initiative/' + initiativeId,
      data: dto,
    });
  };

  await executor(action, 'update_key_initiative');
};

const create = async (dto: CreateChurchDTO): Promise<string | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'churches/create',
      data: dto,
    });

    return result.data;
  };

  return await executor(action, 'create_church');
};

const add_member = async (
  id: string,
  dto: AddChurchMemberDTO,
): Promise<void | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: `churches/${id}/add_member`,
      data: dto,
    });

    return result.data;
  };

  return await executor(action, 'add_church_member');
};

const update = async (id: string, dto: UpdateChurchDTO): Promise<boolean> => {
  const action = async (): Promise<boolean> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'churches',
        query: { _id: id },
        update: dto,
      },
    });

    return result.status === 200;
  };

  return await executor(action, 'update_church');
};

const get_pending = async (): Promise<ChurchDetailsDTO[] | undefined> => {
  const action = async (): Promise<ChurchDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'churches/pending',
    });

    return result.data;
  };

  return await executor(action, 'get_pending_churches');
};

const get_approved = async (): Promise<ChurchDetailsDTO[] | undefined> => {
  const action = async (): Promise<ChurchDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'churches/approved',
    });

    return result.data;
  };

  return await executor(action, 'get_pending_churches');
};

const approve = async (id: string): Promise<void | undefined> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'churches/' + id + '/approve',
    });
  };

  return await executor(action, 'approve_church');
};

const deny = async (id: string): Promise<void | undefined> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'churches/' + id + '/deny',
    });
  };

  return await executor(action, 'deny_church');
};

const add_program = async (
  dto: AddProgramDTO,
): Promise<string | undefined> => {
  const action = async (): Promise<string | undefined> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'programs',
        update: dto,
      },
    });
    return result.data.data;
  };

  return await executor(action, 'add_program');
};

const delete_program = async (id: string, programId: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'churches/' + id + '/delete_program/' + programId,
    });
  };

  return await executor(action, 'delete_program');
};

const get_related_domains = async (
  id: string,
): Promise<ChurchRelatedDomainDTO[] | undefined> => {
  const action = async (): Promise<ChurchRelatedDomainDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_statistics',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id: id,
            },
          },
          {
            $addFields: {
              _domain_id: {
                $toObjectId: '$domain_id',
              },
            },
          },
          {
            $lookup: {
              from: 'domains',
              localField: '_domain_id',
              foreignField: '_id',
              as: 'domain',
            },
          },
          {
            $unwind: '$domain',
          },
        ],
        proj: { domain: 1 },
      },
    });
    // Extract domain field from the result and convert it to an array
    const domains = result?.data?.data.map((item) => item.domain);
    return domains;
  };

  return await executor(action, 'get_related_domains');
};

const get_church_statistics = async (
  id: string,
): Promise<ChurchStatisticDetailsDTO[] | undefined> => {
  const action = async (): Promise<ChurchStatisticDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_statistics',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id: id,
            },
          },
          {
            $addFields: {
              _domain_id: {
                $toObjectId: '$domain_id',
              },
            },
          },
          {
            $lookup: {
              from: 'domains',
              localField: '_domain_id',
              foreignField: '_id',
              as: 'domain',
            },
          },
          { $unwind: '$domain' },
          {
            $addFields: {
              type_id: {
                $toObjectId: '$asset_type_id',
              },
            },
          },
          {
            $addFields: {
              _asset_type_id: {
                $toObjectId: '$asset_type_id',
              },
            },
          },
          {
            $lookup: {
              from: 'church_statistic_types',
              localField: '_asset_type_id',
              foreignField: '_id',
              as: 'type',
            },
          },
          { $unwind: '$type' },
          {
            $addFields: {
              _church_id: {
                $toObjectId: '$church_id',
              },
            },
          },
          {
            $lookup: {
              from: 'churches',
              localField: '_church_id',
              foreignField: '_id',
              as: 'church',
            },
          },
          { $unwind: '$church' },
        ],
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_church_statistics');
};

const add_church_statistic = async (
  id: string,
  dto: AddAssetDTO,
): Promise<void | undefined> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'write',
      data: {
        collection: 'church_statistics',
        update: {
          ...dto,
          church_id: id,
        },
      },
    });
  };

  return await executor(action, 'add_church_statistic');
};

const update_church_statistic = async (
  id: string,
  dto: UpdateStatisticDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'write',
      data: {
        collection: 'church_statistics',
        query: { _id: dto._id },
        update: {
          ...dto,
          church_id: id,
        },
      },
    });
  };

  return await executor(action, 'add_church_statistic');
};
const delete_church_statistic = async (id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'write',
      data: {
        collection: 'church_statistics',
        query: { _id: id },
        update: {
          _IsActive: false,
        },
      },
    });
  };

  return await executor(action, 'add_church_statistic');
};

const update_program = async (
  id: string,
  dto: UpdateProgramDTO,
): Promise<void | undefined> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'churches/' + id + '/update_program',
      data: dto,
    });
  };

  return await executor(action, 'update_program');
};

const join = async (user_id: string, church_id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'church_members',
        query: { user_id },
        update: {
          church_id,
          user_id,
          role: 'ro',
        },
      },
    });
  };

  return await executor(action, 'join_church');
};

const ingest_test_data = async (): Promise<number | undefined> => {
  const action = async (): Promise<number> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'ingest_test_data',
    });

    return result.data;
  };

  return await executor(action, 'ingest_test_data');
};

const get_household_count = async (): Promise<number | undefined> => {
  const action = async (): Promise<number> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'get_household_count',
    });

    return result.data;
  };

  return await executor(action, 'get_household_count');
};

const get_members = async (
  church_id: string,
): Promise<ChurchMemberDetailsDTO[]> => {
  const action = async (): Promise<RelatedObjectDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_members',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id,
            },
          },
          {
            $addFields: {
              _user_id: {
                $toObjectId: '$user_id',
              },
            },
          },
          {
            $lookup: {
              from: 'users',
              localField: '_user_id',
              foreignField: '_id',
              as: 'user',
            },
          },
          {
            $unwind: '$user',
          },
        ],
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_members');
};

const churches_api_set: ChurchesApi = {
  get_all,
  get_user_memberships,
  get_all_with_relations,
  create,
  update,
  get_by_id,
  get_admin_members,
  add_key_initiative,
  remove_key_initiative,
  update_key_initiative,
  get_pending,
  get_approved,
  approve,
  deny,
  add_program,
  delete_program,
  get_related_domains,
  get_church_statistics,
  add_church_statistic,
  update_church_statistic,
  add_member,
  delete_church_statistic,
  update_program,
  join,
  get_members,
  ingest_test_data,
  get_household_count,
};
export default churches_api_set;
