import * as St from 'components/Style.styled';
// import { motion } from 'framer-motion';
import React from 'react';
// import { Comment } from '../../config/icons';
// import { ToastContext } from '../../contexts/ToastContext';
// import PostReplyDetailsDTO from '../../dtos/PostReplyDetailsDTO';
// import classNames from '../../utils/classNames';
import PostRepliesDTO from 'dtos/PostRepliesDTO';
// import apis from '../../api';
// import { useGetCurrentUser } from 'store/hooks';

interface ReplyProps {
  reply: PostRepliesDTO;
}

const Reply: React.FC<ReplyProps> = ({ reply }: ReplyProps) => {
  // const { show } = useContext(ToastContext);
  // const [reply, setReply] = useState(defaultReply);
  // const [isReplying, setIsReplying] = useState(false);
  // const [newReplies, setNewReplies] = useState<PostReplyDetailsDTO[]>([]);
  // const replyCount = reply.reply_count + newReplies.length;
  // const replies = useMemo(
  //   () => newReplies.concat(reply.replies),
  //   [newReplies, reply.replies],
  // );
  // const [showReplies, setShowReplies] = useState(replies.length > 0);
  // const toggleIsReplying = () => setIsReplying(!isReplying);
  // const toggleShowingReplies = () => setShowReplies(!showReplies);
  // const currentUser = useGetCurrentUser();

  // useEffect(() => {
  //   if (showReplies && replyCount !== replies.length) {
  //     // apis.posts.get_post_reply(reply.id).then((replyWithReplies) => {
  //     //   if (replyWithReplies) {
  //     //     setReply(replyWithReplies);
  //     //     setNewReplies([]);
  //     //   }
  //     // });
  //   }
  // }, [showReplies]);

  // const submit = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   const form = e.currentTarget;

  //   apis.posts
  //     .create_reply({
  //       user_id: currentUser?._id,
  //       reply_id: reply._id,
  //       content: form.content.value,
  //     })
  //     .then((newReply) => {
  //       if (newReply) {
  //         show('reply-created-success');
  //         setNewReplies([newReply, ...newReplies]);
  //         setIsReplying(false);
  //       }
  //     });
  // };

  return (
    <St.Div mt="10px" width="100%">
      <St.Row justifyContent="flex-start" gap="10px" display={'flex'}>
        <St.Column alignItems={'flex-start'} flex={1}>
          <St.Row alignItems={'flex-start'} gap={'10px'}>
            <St.Icon src="/images/default-user.webp" size="20px" round />
            <St.Text fontWeight={800}>{reply.user?.name}</St.Text>
          </St.Row>
        </St.Column>
        <St.Text>{new Date(reply._CreatedOn).toLocaleDateString()}</St.Text>
      </St.Row>
      <St.Column alignItems="flex-start" pl="30px" pt={'10px'} width="100%">
        <St.Text>{reply.content}</St.Text>
        <St.Row mb="5px" gap="10px">
          {/* <motion.div
            onClick={toggleIsReplying}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className="cursor-pointer"
            style={{
              display: 'flex',
              gap: '10px',
              marginRight: 'auto',
              alignItems: 'center',
            }}
          >
            <St.Icon src={Comment} height="18px" />
            Reply
          </motion.div>
          {replyCount > 0 && (
            <St.Text
              fontWeight={800}
              opacity={0.5}
              className={classNames('hover:underline', 'hover:cursor-pointer')}
              onClick={toggleShowingReplies}
            >
              {showReplies ? 'Hide replies' : `Show replies (${replyCount})`}
            </St.Text>
          )} */}
        </St.Row>
        {/* {isReplying && (
          <St.Form onSubmit={submit} width="100%" gap="10px">
            <St.LargeInput
              name="content"
              placeholder="What are your thoughts?"
              minHeight="100px"
              width="100%"
              required
              pr="auto"
            />
            <St.Row justifyContent="flex-start">
              <St.PrimaryButton type="submit">Reply</St.PrimaryButton>
            </St.Row>
          </St.Form>
        )} */}
        {/* <St.Column width="100%">
          {showReplies && replies.map((r) => <Reply reply={r} key={r.id} />)}
        </St.Column> */}
      </St.Column>
    </St.Div>
  );
};

export default Reply;
