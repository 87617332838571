import { useEffect, useState } from 'react';

const usePlacesService = (): google.maps.places.PlacesService => {
  const [service, setService] =
    useState<google.maps.places.PlacesService>(null);
  useEffect(() => {
    try {
      const map = new google.maps.Map(document.createElement('div'));
      setService(new google.maps.places.PlacesService(map));
    } catch (e) {
      console.warn(e);
    }
  }, []);

  return service;
};

export default usePlacesService;
