import { flatten, groupBy, sumBy, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { ChartDatum } from '../components/CircleChart';
import { demographicColor } from '../components/Map/config/config';
import CoalitionDollarContributionsDTO from '../dtos/CoalitionDollarContributionsDTO';
import { useGetCurrentUser } from '../store/hooks';
import useOnGetChurchById from './useOnGetChurchById';

export const useDollarContributionsChartData = (
  filteredCoalitions: string[],
  filteredDomains: string[],
): ChartDatum[] => {
  const { onGetChurchById } = useOnGetChurchById();
  const currentUser = useGetCurrentUser();
  const [dollarContributions, setDollarContributions] = useState<
    CoalitionDollarContributionsDTO[]
  >([]);
  const filteredDollarContributions =
    filteredCoalitions.length > 0
      ? dollarContributions.filter((dc) =>
          filteredCoalitions.includes(dc.coalition?.id),
        )
      : dollarContributions;

  useEffect(() => {
    if (!currentUser.logged_in) return;
    // TODO :: Reintegrate
    // apis.church_statistics
    //   .get_dollar_contributions_summarized_by_coalition()
    //   .then((res) => res && setDollarContributions(res));
  }, [currentUser.logged_in]);

  const flattenedStatistics = flatten(
    filteredDollarContributions.map((c) => c.dollar_statistics),
  ).filter((c) =>
    filteredDomains.length > 0 ? filteredDomains.includes(c.domain_id) : true,
  );

  const statistics = uniqBy(flattenedStatistics, (stat) => stat.id);

  const groupedByChurch = groupBy(statistics, (stat) => stat.church_id);

  return Object.keys(groupedByChurch).map((key, index) => {
    return {
      name: onGetChurchById(key)?.name,
      value: sumBy(groupedByChurch[key], (stat) => stat?.quantity ?? 0),
      color: demographicColor(index),
    };
  });
};
