import DomainId from '../types/DomainId';

export enum ChurchRelatedDomainType {
  CHURCH_STATISTIC,
  PROGRAM,
}
export default interface ChurchRelatedDomainDTO {
  _id: string;
  id: DomainId;
  target_id: string;
  target_type: ChurchRelatedDomainType;
  target_name: string;
  target_additional_details: string;
}
