import { Menu, Transition } from '@headlessui/react';
import * as St from 'components/Style.styled';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DROPDOWN_NO_COALITIONS_2 } from '../config/constants';
import { DropdownArrow } from '../config/icons';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import { useGetCoalitionsState, useGetMembershipsState } from '../store/hooks';
import { stringNullOrEmpty } from '../utils/strings';

const MenuItem = styled(Menu.Item)`
  :hover {
    cursor: pointer;
  }
`;

export interface DropdownItem {
  id: any;
  name: string;
  checked: boolean;
}

interface Props {
  onClickItem: (coalitions: CoalitionDetailsDTO[]) => void;
  width: string;
  defaultCoalitions: CoalitionDetailsDTO[];
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuButton = styled(Menu.Button)<{ open: boolean }>`
  display: flex;
  border: 1px solid #dddddd;
  min-height: 38px;
  align-items: center;
`;

const MenuItems = styled(Menu.Items)<{ width: string }>`
  position: absolute;
  top: 100%;
  background: white;
  min-width: ${({ width }) => width};
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
  border: 1px solid #e5e7eb;
`;

const arrowVariants = {
  up: { rotate: 0 },
  down: { rotate: 180 },
};

const CoalitionDropdown: React.FC<Props> = ({
  onClickItem,
  width,
  defaultCoalitions,
}) => {
  const coalitionsState = useGetCoalitionsState();

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedCoalitions, setSelectedCoalitions] = useState<
    CoalitionDetailsDTO[]
  >([]);

  const menuButtonRef = useRef(null);

  useEffect(() => {
    if (defaultCoalitions.length === 0) return;
    setSelectedCoalitions([...defaultCoalitions]);
  }, [defaultCoalitions.length]);

  // const toggleMenuOpen = () => {
  //   if (menuButtonRef.current) {
  //     menuButtonRef.current.click();
  //   }
  // };

  const onClickItemWrapper = (e, coalition) => {
    if (e) {
      e.preventDefault();
      // toggleMenuOpen();
    }
    if (stringNullOrEmpty(coalition._id)) return;
    const newCoalitions = selectedCoalitions.includes(coalition)
      ? selectedCoalitions.filter((c) => c._id !== coalition._id)
      : selectedCoalitions.concat([coalition]);

    setSelectedCoalitions(newCoalitions);

    onClickItem(newCoalitions);
  };

  const header = () => {
    if (selectedCoalitions.length == 0) return 'Coalitions';
    if (selectedCoalitions.length === 1) return selectedCoalitions[0].name;
    return 'Multiple coalitions';
  };

  return (
    <Menu as="div" style={{ position: 'relative', zIndex: 1 }}>
      {({ open }) => {
        if (open !== menuOpen) {
          setMenuOpen(open);
        }
        return (
          <>
            <MenuButton open={open} ref={menuButtonRef}>
              <St.Row justifyContent="space-between" width={width}>
                <St.Text
                  fontWeight="800"
                  fontSize="12px"
                  textOverflow="ellipsis"
                  nowrap
                  overflow="hidden"
                  maxWidth="65%"
                  ml="20px"
                >
                  {header()}
                </St.Text>
                <St.AnimatedIcon
                  src={DropdownArrow}
                  animate={open ? 'down' : 'up'}
                  variants={arrowVariants}
                  height="8px"
                  mr="10px"
                />
              </St.Row>
            </MenuButton>
            <Transition
              as={St.Column}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems width="100%">
                {(coalitionsState?.data ?? []).map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={(e) => onClickItemWrapper(e, item)}
                  >
                    {({ active }) => (
                      <St.Row
                        justifyContent="start"
                        flexWrap="nowrap"
                        px="5px"
                        className={classNames(
                          active && !!item.id
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'py-2 text-sm',
                        )}
                      >
                        {selectedCoalitions
                          .map((c) => c.id)
                          .includes(item.id) ? (
                          <St.Icon
                            width="10px"
                            mr="5px"
                            src="/images/check.svg"
                          />
                        ) : (
                          <St.Div width="15px" />
                        )}
                        <St.Text fontStyle={!item.id ? 'italic' : undefined}>
                          {item.name}
                        </St.Text>
                      </St.Row>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default CoalitionDropdown;
