import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
// import apis from '../api';
import routes from '../config/routes';
import { ToastContext } from '../contexts/ToastContext';
import { useAppDispatch, useGetCurrentUser } from '../store/hooks';
import { set_logged_out } from '../store/user';
import { set_points_of_interest } from 'store/pointsOfInterest';

const TooltipContainer = styled.ul<{ show: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  min-height: 50px;
  width: 90%;
  color: white;
  background-color: var(--color-hr-red-dark);
  border-radius: 6px;
  transition: 0.2s;
  z-index: 1;
`;

const TooltipLi = styled.li`
  font-weight: 800;
  padding: 20px;
  width: 100%;
  transition: 0.2s;
  background-color: var(--color-hr-red-dark);

  :hover {
    background-color: var(--color-hr-red-darker);
    cursor: pointer;
  }
`;

interface TooltipProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const NavProfileTooltip: React.FC<TooltipProps & SpaceProps> = ({
  visible,
  setVisible,
  ...props
}) => {
  const { show } = useContext(ToastContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useGetCurrentUser();

  const handle_logout = async (): Promise<void> => {
    // await apis.users.logout();
    show('sign-out-success');
    dispatch(set_points_of_interest([]));
    dispatch(set_logged_out());
    navigate(routes.HOME.href);
    setVisible(false);
  };

  const handle_to_profile = () => {
    navigate(`users/${user.id}`);
    setVisible(false);
  };

  return (
    <TooltipContainer {...props} show={visible}>
      <TooltipLi
        style={{ borderRadius: '6px 6px 0 0' }}
        onClick={handle_logout}
      >
        Log out @{user.username}
      </TooltipLi>
      <TooltipLi
        style={{ borderRadius: '0 0 6px 6px' }}
        onClick={handle_to_profile}
      >
        Go to profile
      </TooltipLi>
    </TooltipContainer>
  );
};

export default NavProfileTooltip;
