import * as St from 'components/Style.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import apis from '../../api';
import EditButtons from '../../components/EditButtons';
import { TrashRedIcon } from '../../config/icons';
import { ToastContext } from '../../contexts/ToastContext';
import { updateDomainById } from '../../store/helpers';
import { useAppDispatch } from '../../store/hooks';
import { formatUrl } from '../../utils/urlUtils';
import DomainSupportResourceDetailsDTO from 'dtos/DomainSupportResourceDetailsDTO';
import api from '../../api';

const InitiativesInput = styled.input`
  ${St.textInputStyles}
  width: 100%;
  height: 22px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const SupportResources: React.FC<{ canEdit: boolean }> = ({ canEdit}) => {
  const dispatch = useAppDispatch();
  const { id: domain_id } = useParams();
  const canEditDomain = canEdit;
  const [resources, setResources] = useState<DomainSupportResourceDetailsDTO[]>(
    [],
  );
  const [addingResource, setAddingResource] = useState(false);
  const [editId, setEditId] = useState('');
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const modifying = addingResource || editId;
  const { show } = useContext(ToastContext);

  useEffect(() => {
    api.domains.get_support_resource(domain_id).then((data) => {
      setResources(data);
    });
  }, [domain_id]);

  const cancelInitiatives = () => {
    setAddingResource(false);
    setEditId('');
  };

  function onClickTrash(resourceId: string) {
    apis.domains.remove_support_resource(resourceId).then(() => {
      show('set-support-resources-success');
      setEditId('');
      updateDomainById(dispatch, domain_id);
      const updatedResources = resources.filter((r) => r._id !== resourceId);
      setResources(updatedResources);
    });
  }

  function onClickAdd() {
    setEditId('');
    setAddingResource(!addingResource);
  }

  const addInitiative = async (e: any) => {
    const form = e.currentTarget;
    const json = {
      resource: form.add_resource_name.value,
      links: [form.add_resource_link.value],
    };
    apis.domains.add_support_resource(domain_id, json).then((res) => {
      const newResource: DomainSupportResourceDetailsDTO = {
        ...json,
        _id: res,
        domain_id,
      };

      setResources([...resources, newResource]);
      updateDomainById(dispatch, domain_id);
      show('set-support-resources-success');
      setAddingResource(false);
    });
  };

  const editInitiative = async (e: any) => {
    const form = e.currentTarget;
    await apis.domains.update_support_resource(domain_id, editId, {
      resource: form.edit_resource_name.value,
      links: [form.edit_resource_link.value],
    });
    updateDomainById(dispatch, domain_id);
    show('set-support-resources-success');
    setEditId('');
  };

  const submit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (addingResource) {
      await addInitiative(e);
    } else if (editId) {
      await editInitiative(e);
    }
  };

  const submitButtonText = () => {
    if (addingResource) return 'Add';
    return 'Save';
  };

  const onMouseEnterResource = (id: string) => {
    if (!canEditDomain) return;
    setIsHovered((prev) => ({ ...prev, [id]: true }));
  };

  const onMouseLeaveResource = (id: string) => {
    if (!canEditDomain) return;
    setIsHovered((prev) => ({ ...prev, [id]: false }));
  };

  const onEditInitiative = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setEditId(id);
    setAddingResource(false);
    setIsHovered({}); // reset hover state
  };

  const renderResource = (resource: DomainSupportResourceDetailsDTO) => {
    const hasLink = resource.links.filter((l) => l).length > 0;

    if (editId === resource._id) {
      return (
        <St.Row gap="10px" mt="10px" width={'100%'}>
          <St.Icon src="/images/check.svg" size="14px" />
          <InitiativesInput
            type="text"
            name="edit_resource_name"
            required
            placeholder="New title"
            defaultValue={resource.resource}
          />
          <InitiativesInput
            type="url"
            name="edit_resource_link"
            placeholder="Link (optional)"
            defaultValue={hasLink ? resource.links[0] : ''}
          />
          <St.AnimatedIcon
            src={TrashRedIcon}
            onClick={() => onClickTrash(resource._id)}
            size="16px"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            mr="10px"
          />
        </St.Row>
      );
    }

    const content = (
      <St.Row
        key={resource._id}
        justifyContent="start"
        mt="10px"
        gap="10px"
        minWidth="50%"
        onMouseEnter={() => onMouseEnterResource(resource._id)}
        onMouseLeave={() => onMouseLeaveResource(resource._id)}
      >
        <St.Icon src="/images/check.svg" size="14px" />
        {resource.resource}
        {isHovered[resource._id] && (
          <St.AnimatedIcon
            src="/images/edit.svg"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            size="14px"
            ml="auto"
            mr="10px"
            onClick={(e) => onEditInitiative(e, resource._id)}
          />
        )}
      </St.Row>
    );

    return hasLink ? (
      <St.Link target="_blank" to={formatUrl(resource.links[0])}>
        {content}
      </St.Link>
    ) : (
      content
    );
  };

  return (
    <Form onSubmit={submit}>
      <St.Row justifyContent="space-between" width="100%">
        <St.HighlightsBanner justifyContent="space-between">
          Support Resources
          {canEditDomain && (
            <EditButtons onClickEdit={null} onClickAdd={onClickAdd} white />
          )}
        </St.HighlightsBanner>
      </St.Row>
      <St.Row justifyContent="start" width="100%" flexWrap="wrap">
        {resources.length > 0 || modifying
          ? resources.map((resource) => (
              <St.Div
                key={resource._id}
                id={resource._id}
                width={editId === resource._id ? '100%' : '50%'}
              >
                {renderResource(resource)}
              </St.Div>
            ))
          : 'No support resources'}
        {addingResource && (
          <St.Row minWidth="50%" mt="10px" gap="10px">
            <St.AnimatedIcon src="/images/check.svg" size="14px" />
            <InitiativesInput
              type="text"
              name="add_resource_name"
              required
              placeholder="New resource"
            />
            <InitiativesInput
              type="url"
              name="add_resource_link"
              placeholder="Link (optional)"
            />
          </St.Row>
        )}
      </St.Row>
      {modifying && (
        <St.Row mt="10px" justifyContent="flex-start">
          <St.TertiaryButton onClick={cancelInitiatives} mr="10px">
            Cancel
          </St.TertiaryButton>
          <St.PrimaryButton type="submit">
            {submitButtonText()}
          </St.PrimaryButton>
        </St.Row>
      )}
    </Form>
  );
};

export default SupportResources;
