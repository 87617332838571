import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { AnimatePresence, motion } from 'framer-motion';

interface ModalProps {
  height: string;
  width?: string;
  isOpen: boolean;
  requestClose: () => void;
  children: ReactNode;
}

const Header = styled.div`
  background: var(--color-hr-red-dark);
  height: 123px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  border-radius: 4px 4px 0 0;
`;

const Logo = styled.img`
  width: 220px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledModal = styled.div<{ height: string; width: string }>`
  overflow: scroll;
  position: absolute;
  background: #f3f3f3;
  padding: 0;
  border-radius: 4px;
  transform: translate(-50%, 0);
  z-index: 1;
  ${layout}
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: -10px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(255, 255, 255, 0.5);
`;

const Modal: React.FC<ModalProps> = ({
  height,
  width = '354px',
  isOpen,
  requestClose,
  children,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 99999,
          }}
        >
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Overlay onClick={requestClose} />
          </motion.div>
          <motion.div
            layout
            style={{ paddingTop: '5vh' }}
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.5 }}
          >
            <StyledModal width={width} height={height}>
              <Header>
                <Logo src="/images/logo white.png" />
              </Header>
              <Container>{children}</Container>
            </StyledModal>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
