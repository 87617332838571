import * as St from 'components/Style.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { RightArrowIcon } from '../../../config/icons';
import CommunityDemographicsDTO from '../../../dtos/CommunityDemographicsDTO';
import MapMarkerDTO from '../../../dtos/MapMarkerDTO';
import Demographic, {
  EducationLevel,
  EthnicGroup,
  IncomeBand,
} from '../../../types/Demographic';
import CircleChart, { ChartDatum } from '../../CircleChart';
import Circle from '../Circle';
import {
  demographicColor,
  DemographicItem,
  DemographicItemWithValue,
  demographicTypeInfo,
} from '../config/config';
import { DemographicType } from '../config/types';

const Container = styled(St.Column)`
  min-width: 300px;
  height: 195px;
`;

const Title = styled(St.Text)`
  color: #454545;
  font-weight: 800;
  font-size: 20px;
  white-space: nowrap;
  line-height: 22px;
`;

function demographicDataToItemAndSort<TDemographic extends Demographic>(
  demographicData: Record<TDemographic, number>,
  demographicType: DemographicType,
): DemographicItemWithValue[] {
  const data: [string, number][] = Object.entries(demographicData);

  return sortBy(data, ([, value]) => -value)
    .map(([name, value]) => ({
      ...getDemographicItem(name as unknown as TDemographic, demographicType),
      value,
    }))
    .filter((item) => item !== undefined);
}

function demographicItemsToChartItems(
  items: DemographicItemWithValue[],
): ChartDatum[] {
  return items.map((item) => ({
    color: demographicColor(item.demographic),
    name: item.name,
    value: item.value,
  }));
}

function getDemographicItem(
  demographic: Demographic,
  demographicType: DemographicType,
): DemographicItem {
  const strDemographic = demographic as unknown as string;
  const demographicInfo = demographicTypeInfo[demographicType];

  return demographicInfo.fields.find(
    (di) => parseInt(strDemographic) === di.demographic,
  );
}

interface OverlayWindowProps {
  community: MapMarkerDTO;
  demographics: any;
}

const CommunityOverlayWindow: React.FC<OverlayWindowProps> = ({
  community,
  demographics,
}) => {
  const ethnic_groups = [];
  const income_bands = [];
  const education_levels = [];
  if (Array.isArray(demographics)) {
    demographics.forEach((demographic) => {
      ethnic_groups[demographic.ethnic_group] = ethnic_groups[
        demographic.ethnic_group
      ]
        ? ethnic_groups[demographic.ethnic_group] + 1
        : 1;
      income_bands[demographic.income] = income_bands[demographic.income]
        ? income_bands[demographic.income] + 1
        : 1;
      education_levels[demographic.education_level] = education_levels[
        demographic.education_level
      ]
        ? education_levels[demographic.ethnic_group] + 1
        : 1;
    });
  }
  const tmpDemographics = { ethnic_groups, income_bands, education_levels };
  const [demographicType, setDemographicType] = useState(
    DemographicType.INCOME_BAND,
  );
  const currentDemographicInfo = demographicTypeInfo[demographicType];
  const formattedAndSortedData = demographicDataToItemAndSort(
    tmpDemographics[currentDemographicInfo.key],
    demographicType,
  );

  const nextDemoGraphic = () => {
    const numDemoGraphicTypes = Object.keys(DemographicType).length / 2;
    setDemographicType((demographicType + 1) % numDemoGraphicTypes);
  };

  const renderChartColumn = () => {
    const numVisible = 5;
    return (
      <St.Column width="50%" overflowX="hidden">
        {formattedAndSortedData
          .slice(0, numVisible)
          .map(({ demographic, name }) => {
            return (
              <St.Row justifyContent="start" alignItems="start" width="100%">
                <div style={{ width: 'auto', height: 'auto' }}>
                  <Circle color={demographicColor(demographic)} size={8} />
                </div>
                <St.Text nowrap ml="5px">
                  {name}
                </St.Text>
              </St.Row>
            );
          })}
        {formattedAndSortedData.length > numVisible && (
          <St.Row justifyContent="start" width="100%">
            <St.Text>OTHER</St.Text>
          </St.Row>
        )}
      </St.Column>
    );
  };

  return (
    <Container justifyContent="start">
      <St.Row height="auto" width="100%" justifyContent="start">
        <St.Column alignItems="start" width="100%">
          <Title>Community in Houston</Title>
          <St.Row justifyContent="space-between" width="100%">
            <St.Text
              fontWeight="800"
              lineHeight="22px"
              mt="5px"
              color="#919191"
            >
              {demographics.length || ''} households
            </St.Text>
            <motion.button
              onClick={nextDemoGraphic}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '1.5px',
              }}
            >
              <St.Text color="var(--color-hr-red)" mr="5px">
                Next Stat
              </St.Text>
              <St.Icon src={RightArrowIcon} height="10px" />
            </motion.button>
          </St.Row>
        </St.Column>
      </St.Row>
      <div style={{ border: '1px solid #DDDDDD', width: '100%' }} />
      <>
        <AnimatePresence>
          <motion.div
            key={currentDemographicInfo.key}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              // position: 'absolute',
              // bottom: 0,
              height: '70%',
              width: '100%',
              padding: '0 10px',
            }}
          >
            <St.Row height="100%" width="100%">
              <St.Column height="100%" width="50%">
                <CircleChart
                  data={demographicItemsToChartItems(formattedAndSortedData)}
                  cornerRadius={0}
                  height="80%"
                  donut
                />
                <St.Text fontWeight="800" mt="4px">
                  {currentDemographicInfo.label}
                </St.Text>
              </St.Column>
              {renderChartColumn()}
            </St.Row>
          </motion.div>
        </AnimatePresence>
      </>
    </Container>
  );
};

export default CommunityOverlayWindow;
