import routes from 'config/routes';
import useUserAccess from 'hooks/useUserAccess';
import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import styled from 'styled-components';
import DomainCard from '../components/DomainCard';
import Tabs from '../components/Tabs';
import { useGetCurrentUser, useGetDomains } from '../store/hooks';
import { FilterIcon } from 'components/Map/Icons';

const TABS = ['All Domains', 'Your Domains'];

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Domains: React.FC = () => {
  const { isDomainMemberOf, isDomainAdminOf } = useUserAccess();
  const domains = useGetDomains();
  const currentUser = useGetCurrentUser();
  const [searchTerm, setSearchTerm] = useState('');
  const tabState = useState(0);
  const [_, setSelectedIndex] = tabState;

  const filteredDomains =
    tabState[0] === 1
      ? domains
          .filter((domain) => !!isDomainMemberOf(domain))
          .filter(
            (e) =>
              e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              !searchTerm,
          )
      : domains.filter(
          (e) =>
            e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            !searchTerm,
        );

  const emptyMessage = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <St.Text ml="20px" mb={'8px'}>
          {tabState[0] === 1
            ? 'No domains available. Join a domain for it to show up here.'
            : 'No domain found.'}
        </St.Text>
        <St.SecondaryButton onClick={() => setSelectedIndex(0)}>
          <St.Text>Find a domain</St.Text>
        </St.SecondaryButton>
      </div>
    );
  };

  return (
    <>
      <St.Header>
        <St.HeaderText ml="10px">Domains</St.HeaderText>
        <St.Row
          justifyContent="start"
          width="100%"
          maxWidth={325}
          marginRight={12}
        >
          <St.Icon src={FilterIcon} style={{ paddingRight: 12 }} />
          <St.TextInput
            placeholder="Search for a domain"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
        </St.Row>

        {currentUser.is_site_admin && (
          <St.PrimaryButtonLink
            to={routes.RETOOL.href}
            p="10px"
            target="_blank"
          >
            <St.Row>
              <St.Icon src="/images/edit-white.svg" height="18px" mr="10px" />
              Manage Domains
            </St.Row>
          </St.PrimaryButtonLink>
        )}
      </St.Header>
      <Tabs tabs={TABS} parentTabState={tabState} />
      <Container>
        {filteredDomains.length > 0
          ? filteredDomains.map((domain) => (
              <DomainCard
                domain={domain}
                key={domain._id}
                tabIndex={tabState[0]}
                isMember={isDomainMemberOf(domain)}
                isAdmin={isDomainAdminOf(domain)}
              />
            ))
          : emptyMessage()}
      </Container>
    </>
  );
};

export default Domains;
