import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChurchStatisticTypeDetailsDTO from '../dtos/ChurchStatisticTypeDetailsDTO';
import { defaultArrayDataState } from '../types/ArrayDataState';

export const churchStatisticTypeSlice = createSlice({
  name: 'churchStatisticTypes',
  initialState: defaultArrayDataState<ChurchStatisticTypeDetailsDTO>(),
  reducers: {
    set_church_statistic_types: (
      state,
      action: PayloadAction<ChurchStatisticTypeDetailsDTO[]>,
    ) => {
      state.data = action.payload;
      state.loaded = true;
    },
  },
});

export const { set_church_statistic_types } = churchStatisticTypeSlice.actions;
export default churchStatisticTypeSlice.reducer;
