import React from 'react';
import Map from 'components/Map';

const CommunityMap: React.FC = () => {
  const loaded = typeof google === 'object' && typeof google.maps === 'object';
  if (!loaded) return <div>Loading credentials...</div>;
  return <Map />;
};

export default CommunityMap;
