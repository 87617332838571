import { LoadScript } from '@react-google-maps/api';
import Footer from 'components/Footer';
import NavMenu from 'components/NavMenu';
import env from 'config/env';
import routes from 'config/routes';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo } from 'react';
import { Provider, batch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ChurchProfile from 'views/ChurchProfile';
import CreateChurch from 'views/CreateChurch';
import Home from 'views/Home';
import PendingChurches from 'views/PendingChurches';
import RegisterUserComplete from 'views/RegisterUserComplete';
import Auth from './components/Auth';
import Page from './components/Page';
import ToastPortal from './components/Toast/Portal';
import {
  updateChurchStatisticTypes,
  updateChurches,
  updateCoalitions,
  updateDomains,
  updateEvents,
  updatePointsOfInterest,
  updatePosts,
  updatePrograms,
} from './store/helpers';
import './style/app.css';

import PageNotFound from 'views/PageNotFound';
import GlobalStyle from './style/GlobalStyle';
import AddDomain from './views/AddDomain';
import Admin from './views/Admin';
import Calendar from './views/Calendar';
import CreateEvent from './views/Calendar/CreateEvent';
import EditEvent from './views/Calendar/EditEvent';
import AddChurchStatistics from './views/ChurchProfile/AddChurchStatistics';
import EditChurchStatistics from './views/ChurchProfile/EditChurchStatistics';
import Churches from './views/Churches';
import CoalitionPage from './views/CoalitionProfile';
import Coalitions from './views/Coalitions';
import CommunityMap from './views/CommunityMap';
import CreatePost from './views/CreatePost';
import DomainProfile from './views/DomainProfile';
import Domains from './views/Domains';
import EditUserProfile from './views/EditUserProfile';
import Notifications from './views/Notifications';
import Post from './views/Post';
import RemoveEventModal from './views/RemoveEventModal';
import SignInModal from './views/SignInModal';
import SignUpModal from './views/SignUpModal';
import UpdateChurch from './views/UpdateChurch';
import UserProfile from './views/UserProfile';

import { useGetUserMemberships } from 'hooks/updateHooks';
import { persistStore } from 'reduxjs-toolkit-persist';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { set_logged_out } from 'store/user';
import { store } from './store/index';
import ErrorBoundary from 'views/ErrorBoundary';
// import apis from 'api';

const persistor = persistStore(store);

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { onGetMemberships } = useGetUserMemberships();

  useEffect(() => {
    batch(() => {
      // updateCurrentUser(dispatch);
      if (!user.logged_in) return;
      updateChurches(dispatch);
      updatePosts(dispatch);
      updateEvents(dispatch, user._id);
      updateCoalitions(dispatch);
      updateDomains(dispatch);
      onGetMemberships(user._id);
      updateChurchStatisticTypes(dispatch);
      // TODO :: Reintegreate
      updatePrograms(dispatch);
    });
  }, [user.logged_in]);

  useEffect(() => {
    const handle_logout = async (): Promise<void> => {
      dispatch(set_logged_out());
      Cookies.remove('username');
      Cookies.remove('uid');
    };

    if (!Cookies.get('username')) {
      handle_logout();
    }
  }, []);

  return (
    <BrowserRouter>
      <GlobalStyle />
      <ToastPortal />
      <SignInModal />
      <SignUpModal />
      <RemoveEventModal />
      <NavMenu />
      <LoadScript
        googleMapsApiKey={env.googleMapsApiKey}
        libraries={['places', 'geometry']}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
              <Auth loggedIn={user.logged_in}>
                <Page>
                  <Routes>
                    <Route path={routes.HOME.href} element={<Home />} />
                    <Route
                      path={routes.REGISTER_USER_COMPLETE.href}
                      element={<RegisterUserComplete />}
                    />
                    <Route
                      path={routes.COALITIONS.href}
                      element={<Coalitions />}
                    />
                    <Route
                      path={routes.COALITION_PROFILE.href}
                      element={<CoalitionPage />}
                    />
                    <Route path={routes.CHURCHES.href} element={<Churches />} />
                    <Route
                      path={routes.ADD_CHURCH.href}
                      element={<CreateChurch />}
                    />
                    <Route
                      path={routes.UPDATE_CHURCH.href}
                      element={<UpdateChurch />}
                    />
                    <Route
                      path={routes.PENDING_CHURCHES.href}
                      element={<PendingChurches />}
                    />
                    <Route
                      path={routes.CHURCH_PROFILE.href}
                      element={<ChurchProfile />}
                    />
                    <Route path={routes.ADMIN.href} element={<Admin />} />
                    <Route
                      path={routes.COMMUNITY_MAP.href}
                      element={<CommunityMap />}
                    />
                    <Route path={routes.DOMAINS.href} element={<Domains />} />
                    <Route
                      path={routes.ADD_DOMAIN.href}
                      element={<AddDomain />}
                    />
                    <Route
                      path={routes.DOMAIN_PROFILE.href}
                      element={<DomainProfile />}
                    />
                    <Route
                      path={routes.USER_PROFILE.href}
                      element={<UserProfile />}
                    />
                    <Route
                      path={routes.CREATE_POST.href}
                      element={<CreatePost />}
                    />
                    <Route
                      path={routes.EDIT_POST.href}
                      element={<CreatePost />}
                    />
                    <Route path={routes.POST.href} element={<Post />} />
                    <Route path={routes.CALENDAR.href} element={<Calendar />} />
                    <Route
                      path={routes.CREATE_EVENT.href}
                      element={<CreateEvent />}
                    />
                    <Route
                      path={routes.EDIT_EVENT.href}
                      element={<EditEvent />}
                    />
                    <Route
                      path={routes.NOTIFICATIONS.href}
                      element={<Notifications />}
                    />
                    <Route
                      path={routes.EDIT_CHURCH_STATISTICS.href}
                      element={<EditChurchStatistics />}
                    />
                    <Route
                      path={routes.ADD_CHURCH_STATISTICS.href}
                      element={<AddChurchStatistics />}
                    />
                    <Route
                      path={routes.EDIT_USER_PROFILE.href}
                      element={<EditUserProfile />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Page>
                <Footer />
              </Auth>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </LoadScript>
    </BrowserRouter>
  );
};

export default App;
