import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { DropdownArrow } from '../config/icons';
import * as St from './Style.styled';

const arrowVariants = {
  up: { rotate: 0 },
  down: { rotate: 180 },
};

interface ExpandableProps {
  expandedComponent: ReactNode;
}

const Expandable: React.FC<PropsWithChildren<ExpandableProps>> = ({
  children,
  expandedComponent,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <St.Column
      onClick={() => setOpen(!open)}
      width="100%"
      className="hover:cursor-pointer"
    >
      <St.Row justifyContent="space-between" width="100%">
        {children}
        <St.AnimatedIcon
          src={DropdownArrow}
          animate={open ? 'down' : 'up'}
          variants={arrowVariants}
          height="8px"
          mr="10px"
        />
      </St.Row>
      {open && expandedComponent}
    </St.Column>
  );
};

export default Expandable;
