import { motion } from 'framer-motion';
import React, { MouseEventHandler, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { StyledComponent, css } from 'styled-components';
import {
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  color,
  compose,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';

type DivProps = TypographyProps & SpaceProps & ColorProps & LayoutProps;

type ExtendedFlexboxProps = FlexboxProps & {
  gap?: string | number;
};

type FlexProps = DivProps & ExtendedFlexboxProps;

export const Div: StyledComponent<'div', any, DivProps> = styled.div<DivProps>`
  ${compose(typography, space, layout, color)};
`;

export const Flex: StyledComponent<'div', any, FlexProps> = styled(
  Div,
)<ExtendedFlexboxProps>`
  gap: ${({ gap }) => gap || '0'};
  display: flex;
  ${flexbox};
`;

interface LinkProps {
  to: string;
}

type CustomLinkProps = LinkProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  TypographyProps;

// Links
const linkStyles = css`
  color: rgba(69, 69, 69, 1);
  font-weight: 800;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  display: block;
  ${compose(space, layout, color, typography)};
`;

export const Link = styled(ReactRouterLink)`
  ${linkStyles};
` as StyledComponent<typeof ReactRouterLink, any, CustomLinkProps>;

export const ExternalLink = styled.a`
  ${linkStyles};
`;

const ButtonLink = styled(ReactRouterLink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: 500;
` as StyledComponent<typeof ReactRouterLink, any, LinkProps & SpaceProps>;

export const PrimaryButtonLink = styled(ButtonLink)`
  background: var(--color-hr-red-dark);
  color: white;
  ${space};
` as StyledComponent<typeof ButtonLink, any>;

export const SecondaryButtonLink = styled(ButtonLink)`
  color: var(--color-hr-red-dark);
  border: 1px solid var(--color-hr-red);
  ${space}
` as StyledComponent<typeof ButtonLink, any>;

export const TertiaryButtonLink = styled(ButtonLink)`
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
  ${space};
` as StyledComponent<typeof ButtonLink, any>;

// Buttons
export const Button: StyledComponent<
  typeof motion.button,
  any,
  SpaceProps & TypographyProps
> = styled(motion.button)`
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 500;

  ${compose(space, typography, layout)}
  :hover {
    background: rgba(217, 217, 217, 0.3);
  }
`;

export const PrimaryButton: React.FC<
  SpaceProps &
    TypographyProps &
    LayoutProps & {
      disabled?: boolean;
      type: 'button' | 'submit' | 'reset';
      onClick?: MouseEventHandler<HTMLButtonElement>;
      children: ReactNode;
    }
> = ({ disabled, type, children, ...props }) => {
  const variants = {
    enabled: {
      backgroundColor: 'var(--color-hr-red-dark)',
      color: 'white',
      cursor: 'pointer',
    },
    disabled: {
      backgroundColor: 'var(--color-failure-bg)',
      color: 'var(--color-hr-red)',
      cursor: 'not-allowed',
    },
  };

  return (
    <Button
      type={type}
      disabled={disabled}
      variants={variants}
      initial={disabled ? 'disabled' : 'enabled'}
      animate={disabled ? 'disabled' : 'enabled'}
      whileHover={!disabled && { backgroundColor: 'var(--color-hr-red)' }}
      whileTap={!disabled && { scale: 0.95 }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton = styled(Button)`
  color: var(--color-hr-red-dark);
  border: 1px solid var(--color-hr-red);
  :hover {
    opacity: 0.85;
  }
` as StyledComponent<typeof Button, any>;

export const DisabledButton = styled(Button)`
  background: var(--color-failure-bg);
  color: var(--color-hr-red);
  border: 1px solid var(--color-failure-border);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  cursor: not-allowed;
` as StyledComponent<typeof Button, any>;

export const TertiaryButton = styled(SecondaryButton)`
  border-color: #bcbcbc;
  color: #bcbcbc;
` as StyledComponent<typeof SecondaryButton, any>;

// Inputs
export const textInputStyles = css`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  padding: 12px;

  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
`;

type CustomInputType = SpaceProps & LayoutProps;

export const Input = styled.input<CustomInputType>`
  ${compose(space, layout)};
` as StyledComponent<'input', any, CustomInputType>;

export const TextInput = styled(Input)<LayoutProps>`
  ${textInputStyles}
` as StyledComponent<typeof Input, any>;

export const DateInput: StyledComponent<typeof DatePicker, any> = styled(
  DatePicker,
)`
  z-index: -1;
  ${textInputStyles};
`;

export const Form = styled.form<ExtendedFlexboxProps>`
  display: flex;
  width: 220px;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0'};
  ${compose(layout, flexbox)};
` as StyledComponent<'form', any, LayoutProps & ExtendedFlexboxProps>;

export const LargeInput = styled.textarea<CustomInputType>`
  ${textInputStyles};
  height: auto;
  ${compose(space, layout)};
` as StyledComponent<
  'textarea',
  any,
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & CustomInputType
>;

export const FormLabel = styled.label`
  font-size: 10px;
  line-height: 14px;
` as StyledComponent<'label', any>;

export const Row: StyledComponent<'div', any, FlexProps> = styled(Flex)``;
Row.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

export const Column: StyledComponent<'div', any, FlexProps> = styled(Flex)`
  flex-direction: column;
`;
Column.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

export const InfoColumn = styled(Column)<{ hasBorder?: boolean }>`
  padding: 10px;
  height: 100%;
  justify-content: start;
  align-items: start;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #DDDDDD' : 'none')};
`;

export const Dash = styled.div<{ background?: string }>`
  width: 6px;
  height: 3px;
  background: ${({ background }) => background};
  border-radius: 8px;
  margin-right: 8px;
`;

Dash.defaultProps = {
  background: '#c2c2c2',
};

export const Text = styled.p<
  TypographyProps &
    ColorProps &
    LayoutProps &
    SpaceProps & {
      nowrap?: boolean;
      textOverflow?: string;
      textDecorationLine?: string;
    }
>`
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
  text-overflow: ${({ textOverflow }) => textOverflow || 'clip'};
  text-decoration-line: ${({ textDecorationLine }) =>
    textDecorationLine || 'none'};
  ${compose(typography, space, layout, color)};
`;

export const HeaderText = styled(Text)<ExtendedFlexboxProps>`
  color: var(--color-dark-grey);
  font-size: 20px;
  font-weight: 800;
  margin-right: auto;
  ${flexbox}
`;

export const Header = styled(Row)`
  height: 40px;
  margin-top: 10px;
  justify-content: flex-end;
`;
Header.defaultProps = {
  mr: '5px',
  ml: '5px',
  mb: '10px',
};

export const Icon = styled.img<
  {
    size?: string;
    height?: string;
    width?: string;
    round?: boolean;
  } & SpaceProps
>`
  width: ${({ size, width }) => (size ? size : width ? width : 'auto')};
  height: ${({ size, height }) => (size ? size : height ? height : 'auto')};
  border-radius: ${({ round }) => (round ? '50%' : '0')};
  ${space};
`;

interface IconProps {
  size?: string;
  height?: string;
  width?: string;
}

export const AnimatedIcon = styled(motion.img)<IconProps>`
  width: ${({ size, width }) => (size ? size : width ? width : 'auto')};
  height: ${({ size, height }) => (size ? size : height ? height : 'auto')};
  ${space};

  :hover {
    cursor: pointer;
  }
` as StyledComponent<typeof motion.img, any, IconProps & SpaceProps>;

export const AnimatedButton = styled(motion.button)<ExtendedFlexboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap || '0'};
  ${compose(typography, space, layout, color)};
`;

export const HighlightsBanner = styled.div`
  width: 100%;
  height: 35px;
  padding-left: 16px;
  display: flex;
  align-items: center;

  background: var(--color-hr-red);
  color: white;

  font-size: 16px;
  font-weight: 800;

  ${space}
  ${flexbox}
` as StyledComponent<'div', any, SpaceProps & ExtendedFlexboxProps>;

export const Table = styled.table`
  width: 100%;
  border: 1px solid;
`;

const tableStyles = css`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const Td = styled.td`
  text-align: center;
  ${tableStyles}
`;

export const Tr = styled.tr`
  ${tableStyles};

  :nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const THeaderRow = styled.tr`
  ${tableStyles};
  width: 100%;
  height: 35px;
  padding-left: 16px;
  align-items: center;

  background: var(--color-hr-red);
  color: white;

  font-size: 16px;
  font-weight: 800;
`;

export const TdLink = styled(Td)`
  background: rgba(0, 0, 0, 0);
  transition: 0.3s;

  :hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const Description = styled.p`
  font-size: 1.1rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

export const Section = styled.div`
  width: 95%;
  margin-bottom: 1.5rem;
`;

export const Subtitle = styled.h3`
  font-weight: 800;
  font-size: 1.4rem;
  color: #333;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
`;

export const ListItem = styled.li`
  font-size: 18px;
  border-bottom: none;
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;

export const ProfileSectionContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

export const NotApplicableContainer = styled.div`
  background-color: var(--color-light-grey);
  color: var(--color-hr-red);
  border-radius: 4px;
  font-style: italic;
  padding: 25px 16px;
  width: 100%;
`;

export const Card: StyledComponent<'div', any, FlexProps> = styled(Flex)`
  background-color: var(--color-light-grey);
  border-radius: 4px;
  padding: 10px;
`;

export const NotificationCard = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 100%;
  transition: 0.2s;
  justify-content: space-between;
  :hover {
    background-color: var(--color-light-grey);
  }
`;

export const RelatedDomainContainer = styled.div`
  background-color: var(--color-light-grey);
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  margin: 5px 0;
`;

export const TwoColumnLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
