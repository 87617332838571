import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CoalitionKeyInitiativeDetailsDTO from 'dtos/CoalitionKeyInitiativeDetailsDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import { defaultCoalitionState } from '../types/CoalitionState';
type AddKeyInitiativesPayload = {
  id: string;
  keyInitiatives: CoalitionKeyInitiativeDetailsDTO[];
};
type AddNewKeyInitiativesPayload = {
  initiative: CoalitionKeyInitiativeDetailsDTO;
};
type UpdateKeyInitiativesPayload = {
  coalitionId: string;
  keyInitiative: CoalitionKeyInitiativeDetailsDTO;
  initiativeId: string;
};
type RemoveKeyInitiativePayload = {
  coalitionId: string;
  initiativeId: string;
};
export const coalitionSlice = createSlice({
  name: 'coalitions',
  initialState: defaultCoalitionState,
  reducers: {
    set_coalitions: (state, action: PayloadAction<CoalitionDetailsDTO[]>) => {
      state.data = action.payload;
      state.loaded = true;
    },
    add_key_initiatives: (
      state,
      action: PayloadAction<AddKeyInitiativesPayload>,
    ) => {
      const { id, keyInitiatives } = action.payload;
      const newColition = state.data.map((coalition) =>
        coalition._id === id
          ? { ...coalition, key_initiatives: keyInitiatives }
          : coalition,
      );
      state.data = newColition;
    },
    add_new_key_initiatives: (
      state,
      action: PayloadAction<AddNewKeyInitiativesPayload>,
    ) => {
      // const { initiative } = action.payload;
      const newCoalitions = state.data.map((coalition) => {
        return coalition;
        // coalition._id === initiative.coalition_id
        //   ? {
        //       ...coalition,
        //       key_initiatives: [...coalition.key_initiatives, initiative],
        //     }
        //   : coalition,
      });

      state.data = newCoalitions;
    },
    update_key_initiatives: (
      state,
      action: PayloadAction<UpdateKeyInitiativesPayload>,
    ) => {
      const { coalitionId, keyInitiative, initiativeId } = action.payload;
      const newCoalition = state.data.map((coalition) => {
        if (coalition._id === coalitionId) {
          const updatedKeyInitiatives = coalition.key_initiatives.map(
            (initiative) =>
              initiative._id === initiativeId
                ? {
                    ...initiative,
                    initiative: keyInitiative.initiative,
                    links: keyInitiative.links,
                  }
                : initiative,
          );
          return {
            ...coalition,
            key_initiatives: updatedKeyInitiatives,
          };
        }

        return coalition;
      });
      state.data = newCoalition;
    },
    remove_key_initiative: (
      state,
      action: PayloadAction<RemoveKeyInitiativePayload>,
    ) => {
      // const { coalitionId, initiativeId } = action.payload;
      const newCoalition = state.data.map((coalition) => {
        // if (coalition._id === coalitionId) {
        //   coalition.key_initiatives = coalition.key_initiatives.filter(
        //     (initiative) => initiative._id !== initiativeId,
        //   );
        // }
        return coalition;
      });

      state.data = newCoalition;
    },
  },
});

export const {
  set_coalitions,
  add_key_initiatives,
  update_key_initiatives,
  add_new_key_initiatives,
  remove_key_initiative,
} = coalitionSlice.actions;
export default coalitionSlice.reducer;
