import React from 'react';
import * as St from '../../../components/Style.styled';
import UserConfigStep from '../../../types/UserConfigStep';
import { StepProps } from './index';

const AreYouDomainLeader: React.FC<StepProps> = ({ setStep }) => {
  return (
    <St.Column height="445px" width="220px">
      <St.Text fontWeight="800" fontSize="20px" textAlign="center" mb="20px">
        Are you a domain partner?
      </St.Text>

      <St.NotApplicableContainer>
        Domain partners help manage all details surrounding a domain.
        <br />
        <br />
        Please select "no" if you are unsure.
      </St.NotApplicableContainer>
      <St.Row justifyContent="space-between" mt="auto" gap="10px" width="100%">
        <St.PrimaryButton
          type="button"
          onClick={() => setStep(UserConfigStep.JoinDomains)}
          width="50%"
        >
          No
        </St.PrimaryButton>
        <St.SecondaryButton
          type="button"
          onClick={() => setStep(UserConfigStep.RequestPartnerDomains)}
          width="50%"
        >
          Yes
        </St.SecondaryButton>
      </St.Row>
    </St.Column>
  );
};

export default AreYouDomainLeader;
