import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DemographicType } from '../components/Map/config/types';
import HouseholdDetailsDTO from '../dtos/HouseholdDetailsDTO';
import { defaultMapState } from '../types/MapState';

export const mapSlice = createSlice({
  name: 'map',
  initialState: defaultMapState,
  reducers: {
    setHouseholds: (state, action: PayloadAction<HouseholdDetailsDTO[]>) => {
      state.households = action.payload;
    },
    setFilterDemographic: (state, action: PayloadAction<DemographicType>) => {
      state.filterDemographic = action.payload;
    },
  },
});

export const { setFilterDemographic } = mapSlice.actions;
export default mapSlice.reducer;
