import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PostDetailsDTO from '../dtos/PostDetailsDTO';
import { defaultObjectDataState } from '../types/ObjectDataState';

export const postState = createSlice({
  name: 'posts',
  initialState: defaultObjectDataState<PostDetailsDTO>(),
  reducers: {
    set_posts: (state, action: PayloadAction<PostDetailsDTO[]>) => {
      state.data = action.payload.reduce(
        (acc, post) => {
          const oldPost = state.data[post._id];
          acc[post._id] = oldPost ? oldPost : post;
          return acc;
        },
        {}, // { ...state.data },
      );
      state.loaded = true;
    },
    set_post: (state, action: PayloadAction<PostDetailsDTO>) => {
      state.data[action.payload._id] = action.payload;
    },
    remove_post: (state, action: PayloadAction<string>) => {
      const postIdToRemove = action.payload;
      const newData = { ...state.data };
      delete newData[postIdToRemove];
      return {
        ...state,
        data: newData,
      };
    },
    edit_post: (state, action: PayloadAction<PostDetailsDTO>) => {
      const updatedPost = action.payload;
      if (state.data[updatedPost._id]) {
        state.data[updatedPost._id] = updatedPost;
      }
    },
  },
});

export const { set_posts, set_post, remove_post, edit_post } =
  postState.actions;
export default postState.reducer;
