import { DemographicType } from 'components/Map/config/types';
import React, { createContext, useState } from 'react';

const MapContext = createContext({
  filterDemographic: DemographicType.INCOME_BAND,
  setFilterDemographic: (demographic: DemographicType) => null,
});

const MapContextProvider = ({ children }) => {
  const [filterDemographic, setFilterDemographic] = useState(
    DemographicType.INCOME_BAND,
  );

  const _setFilterDemographic = (demographic: DemographicType) => {
    setFilterDemographic(demographic);
  };

  return (
    <MapContext.Provider
      value={{ filterDemographic, setFilterDemographic: _setFilterDemographic }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider };
