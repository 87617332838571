import CoalitionUserDetailsDTO from 'dtos/CoalitionUserDetailsDTO';
import DomainMemberDetailsDTO from 'dtos/DomainMemberDetailsDTO';
import { sortBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import apis from '../api';
import CoalitionUserRole from '../types/CoalitionUserRole';
import CoalitionRequestNotificationCard from '../views/Notifications/CoalitionRequestNotificationCard';
import DomainRequestNotificationCard from '../views/Notifications/DomainRequestNotificationCard';

export interface NotificationItem {
  component: JSX.Element;
  date: Date;
  id: string;
}

const useGetNotifications = (): NotificationItem[] => {
  const [incomingCoalitionRequests, setIncomingCoalitionRequests] = useState<
    CoalitionUserDetailsDTO[]
  >([]);
  const [incomingDomainRequests, setIncomingDomainRequests] = useState<
    DomainMemberDetailsDTO[]
  >([]);
  const sortCoalitionRequests = (requests) => {
    return uniqBy(
      [
        ...sortBy(
          requests.filter((a) => a.role === CoalitionUserRole.PENDING_ADMIN),
          (a) => a.updated_at,
        ),
        ...sortBy(
          requests.filter((a) => a.role === CoalitionUserRole.ADMIN),
          (a) => a.updated_at,
        ),
      ],
      (cm) => cm.id,
    );
  };

  const refreshDomainRequests = useCallback(() => {
    apis.domains
      .get_incoming_expert_requests()
      .then((res) => res && setIncomingDomainRequests(res));
  }, []);
  const refreshCoalitionRequests = useCallback(() => {
    apis.coalitions.get_incoming_admin_requests().then((adminRequests) => {
      setIncomingCoalitionRequests(sortCoalitionRequests(adminRequests));
    });
  }, []);

  useEffect(() => {
    refreshDomainRequests();
    refreshCoalitionRequests();
  }, []);

  return useMemo(
    () => [
      ...incomingDomainRequests.map((dm) => ({
        id: dm.id,
        date: new Date(dm.created_at),
        component: (
          <DomainRequestNotificationCard
            domainMember={dm}
            refreshDomainRequests={refreshDomainRequests}
          />
        ),
      })),
      ...incomingCoalitionRequests.map((cu) => ({
        id: cu.id,
        date: new Date(cu.updated_at),
        component: (
          <CoalitionRequestNotificationCard
            coalitionUser={cu}
            refreshCoalitionUserRequests={refreshCoalitionRequests}
          />
        ),
      })),
    ],
    [incomingDomainRequests, incomingCoalitionRequests],
  );
};

export default useGetNotifications;
