import apis from 'api';
import routes from 'config/routes';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { set_logged_in } from 'store/user';
import Modal from '../components/Modal';
import * as St from '../components/Style.styled';
import { ModalContext } from '../contexts/ModalContext';
import { ToastContext } from '../contexts/ToastContext';

const SignInModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signInOpen, toggleSignInOpen, toggleSignUpOpen } =
    React.useContext(ModalContext);
  const { show } = useContext(ToastContext);
  const submit = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    const result = await apis.users.login(
      form.username.value,
      form.password.value,
    );

    if (!result) {
      show('login-failure');
      return;
    }
    show('sign-in-success');
    toggleSignInOpen();
    dispatch(set_logged_in(result));
    navigate(routes.HOME.href);
  };

  return (
    <Modal height="450px" isOpen={signInOpen} requestClose={toggleSignInOpen}>
      <St.Form onSubmit={submit}>
        <St.FormLabel>Username</St.FormLabel>
        <St.TextInput mb="2" name="username" />
        <St.FormLabel>Password</St.FormLabel>
        <St.TextInput type="password" name="password" />
        <St.Row justifyContent="space-between" fontSize="10px" mt="2" mb="3">
          <St.Row>
            <St.Input type="checkbox" mr="2" />
            Remember me
          </St.Row>
          {/*<St.Link to="">Forgot password?</St.Link>*/}
        </St.Row>
        <St.PrimaryButton type="submit">Sign in</St.PrimaryButton>
        <St.Row mt="30px">
          <St.Text
            style={{ whiteSpace: 'nowrap' }}
            fontWeight="800"
            fontSize="12px"
          >
            Don't have an account?
          </St.Text>
          <St.Button
            ml="1"
            p="0"
            color="black"
            fontSize="12px"
            onClick={toggleSignUpOpen}
            type="button"
          >
            Sign Up
          </St.Button>
        </St.Row>
      </St.Form>
    </Modal>
  );
};

export default SignInModal;
