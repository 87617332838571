import apis from 'api';
import * as St from 'components/Style.styled';
import Tooltip from 'components/Tooltip';
import { QuestionMarkRed } from 'config/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { getEventsForDomain, updateDomainById } from 'store/helpers';
import styled from 'styled-components';
import { ToastContext } from '../../contexts/ToastContext';
import { useJoinDomain } from '../../hooks/updateHooks';
import useUserAccess from '../../hooks/useUserAccess';
import {
  useAppDispatch,
  useGetCurrentUser,
  useGetDomainById,
  useGetProgramState,
} from '../../store/hooks';
import { ChurchProfileParams } from '../ChurchProfile';
import DomainCalendar from './DomainCalendar';
import DomainMemberDetailsDTO from 'dtos/DomainMemberDetailsDTO';
import api from 'api';
import SupportResources from './SupportResources';
import ProgramDetailsDTO from 'dtos/ProgramDetailsDTO';

const MainImage = styled(Box)<{ src: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background: url('/images/home-image-filter.png') no-repeat,
    url(${(props) => props.src}) no-repeat;
  background-position: center;
  background-size: cover;

  margin-bottom: 12.5px;
`;

const DomainProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { show } = useContext(ToastContext);
  const { id } = useParams<ChurchProfileParams>();
  const domain = useGetDomainById(id);
  const [editingDescription, setEditingDescription] = useState(false);
  const [members, setMembers] = useState<DomainMemberDetailsDTO[]>([]);
  const [programs, setPrograms] = useState<ProgramDetailsDTO[]>([]);
  const experts = members
    .filter((member) => member.role === 'rw')
    .map((member) => member.user);
  const { isDomainMemberOf, churchIdIfAdmin } = useUserAccess();

  const programState = useGetProgramState();

  const refreshProfile = () => {
    updateDomainById(dispatch, id);
    setPrograms(programState.data.filter((p) => p.domain_id === id));
  };
  const currentUser = useGetCurrentUser();
  const churchId = churchIdIfAdmin();
  const member = isDomainMemberOf(domain);
  const { onJoinDomain } = useJoinDomain();

  useEffect(refreshProfile, [id]);
  useEffect(() => {
    api.domains.get_members(id).then((data) => {
      setMembers(data);
    });

    getEventsForDomain(dispatch, id);
  }, [id]);

  const isDomainAdmin = experts.map((e) => e._id).includes(currentUser._id);

  const handleJoinDomain = () => {
    onJoinDomain(id, churchId, currentUser._id).then(() => {
      show('joined-domain-success');
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    const dto = { description: form.description.value };
    apis.domains.update(id, dto).then(() => {
      refreshProfile();
      setEditingDescription(false);
    });
  };

  const renderMembershipButton = () => {
    if (!churchIdIfAdmin()) return null;
    else if (!member)
      return (
        <St.SecondaryButton onClick={handleJoinDomain}>
          Join as member
        </St.SecondaryButton>
      );
    else
      return (
        <St.PrimaryButton type="button" disabled>
          {isDomainAdmin
            ? 'You are an admin of this domain'
            : 'You are a member of this domain'}
        </St.PrimaryButton>
      );
  };

  return (
    <>
      <MainImage src="/images/domain-header.png">
        <St.Text
          fontWeight="900"
          fontSize="60px"
          lineHeight="24px"
          color="white"
        >
          Domain: {domain.name}
        </St.Text>
      </MainImage>
      <St.Row alignItems="flex-start" pb={'40px'}>
        <St.Column alignItems="flex-start" mt="13px" mr="5px" width="50%">
          <St.Row
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
            pr="13px"
            pb="5px"
          >
            <St.Subtitle>Description</St.Subtitle>
            {isDomainAdmin && (
              <St.AnimatedIcon
                src="/images/edit.svg"
                size="16px"
                onClick={() => setEditingDescription(!editingDescription)}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: 'tween', duration: 0.1 }}
                ml="auto"
                mr="10px"
              />
            )}
            {renderMembershipButton()}
          </St.Row>
          <St.Section>
            {editingDescription ? (
              <St.Form onSubmit={submit} width="100%">
                <St.LargeInput placeholder="Description" name="description" />
                <St.Row mt="10px" justifyContent="flex-start">
                  <St.TertiaryButton
                    onClick={() => setEditingDescription(false)}
                    mr="13px"
                  >
                    Cancel
                  </St.TertiaryButton>
                  <St.PrimaryButton type="submit">Save</St.PrimaryButton>
                </St.Row>
              </St.Form>
            ) : (
              <St.Text>
                {domain.description
                  ? domain.description
                  : 'Not specified. Contact a domain partner to provide more information about this domain.'}
              </St.Text>
            )}
          </St.Section>
          <St.Row justifyContent="space-between" width="100%" pr="13px">
            <St.Subtitle>Domain Partners</St.Subtitle>
            <Tooltip
              src={QuestionMarkRed}
              text="Domain partners manage a domain's details. Contact them for domain-related queries."
            />
          </St.Row>
          <St.Section>
            <St.Column alignItems="flex-start" gap="10px">
              {experts.map((partner, index) => (
                <St.Row key={`partner-${index}`} gap="5px">
                  <St.Text
                    fontSize={'16px'}
                    onClick={() => navigate(`/users/${partner._id}`)}
                    textDecorationLine="underline"
                    className="hover:cursor-pointer"
                    key={index}
                  >
                    {partner.name}
                  </St.Text>
                </St.Row>
              ))}
            </St.Column>
            {experts.length === 0 && (
              <St.Div pt="10px">
                <St.Text>No domain partners</St.Text>
              </St.Div>
            )}
          </St.Section>
          <DomainCalendar />
        </St.Column>
        <St.Column alignItems="start" mt="13px" ml="5px" width="50%">
          <St.ProfileSectionContainer style={{ paddingBottom: '30px' }}>
            <SupportResources canEdit={isDomainAdmin} />
          </St.ProfileSectionContainer>

          <St.Table>
            <St.THeaderRow>
              <th align="left" style={{ paddingLeft: 8 }}>
                Domain Programs
              </th>
              <th align="center">More Info</th>
              <th align="center">Church</th>
            </St.THeaderRow>
            {(programs ?? []).map((program) => (
              <St.Tr>
                <St.TdLink>{program.description}</St.TdLink>
                <St.Td>{program.more_info}</St.Td>
                <St.Td>{program.church?.name}</St.Td>
              </St.Tr>
            ))}
          </St.Table>
          {(programs ?? []).length === 0 && (
            <St.NotApplicableContainer style={{ marginTop: '10px' }}>
              <St.Text>No domain programs</St.Text>
            </St.NotApplicableContainer>
          )}
        </St.Column>
      </St.Row>
    </>
  );
};

export default DomainProfile;
