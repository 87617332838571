import * as St from 'components/Style.styled';
import CoalitionKeyInitiativeDetailsDTO from 'dtos/CoalitionKeyInitiativeDetailsDTO';
import useUserAccess from 'hooks/useUserAccess';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import EditButtons from '../../components/EditButtons';
import { TrashRedIcon } from '../../config/icons';
import { ToastContext } from '../../contexts/ToastContext';
import {
  addKeyInitiative,
  removeCoalitionKeyInitiatives,
  UpdateChurchKeyInitiative,
  UpdateKeyInitiative,
} from '../../store/helpers';
import {
  useAppDispatch,
  useGetChurchById,
  useGetCurrentUser,
} from '../../store/hooks';
import { formatUrl } from '../../utils/urlUtils';
import { ChurchProfileParams } from './index';
import apis from 'api';

const InitiativesInput = styled.input`
  ${St.textInputStyles}
  width: 100%;
  height: 22px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const KeyInitiatives: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<ChurchProfileParams>();
  const church = useGetChurchById(id);
  const { isChurchAdminOf, userCoalitionAffiliation } = useUserAccess();
  const isChurchAdmin = isChurchAdminOf(church);
  const [addingInitiative, setAddingInitiative] = useState(false);
  const [editId, setEditId] = useState('');
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const [keyInitiatives, setKeyInitiatives] = useState<
    CoalitionKeyInitiativeDetailsDTO[]
  >([]);
  const modifying = addingInitiative || editId;
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();

  const coalition = userCoalitionAffiliation();

  const refreshKeyInitiatives = () => {
    apis.coalitions
      .get_key_initiatives(coalition?.coalition_id)
      .then((initiatives) => {
        setKeyInitiatives(initiatives);
      });
  };

  const cancelInitiatives = () => {
    setAddingInitiative(false);
    setEditId('');
  };

  const onClickTrash = (id: string) => {
    removeCoalitionKeyInitiatives(dispatch, coalition.coalition_id, id, show);
    const newInitiatives = keyInitiatives.filter((initiative) => {
      return initiative._id !== id;
    });
    setKeyInitiatives(newInitiatives);
    cancelInitiatives();
  };

  function onClickAdd() {
    setEditId('');
    setAddingInitiative(!addingInitiative);
  }

  const addInitiative = async (e: any) => {
    if (!coalition)
      return alert('You must be a coalition member to add initiatives');

    const form = e.currentTarget;
    const json = {
      coalition_id: coalition.coalition_id,
      user_id: currentUser._id,
      initiative: form.add_initiative_name.value,
      links: [form.add_initiative_link.value],
    };

    addKeyInitiative(dispatch, json, show).then((res) => {
      show('set-key-initiatives-success');
      setAddingInitiative(false);
      setEditId('');
      const newInitiative: CoalitionKeyInitiativeDetailsDTO = {
        ...json,
        _id: res,
      };
      setKeyInitiatives([...keyInitiatives, newInitiative]);
    });
  };

  const editInitiative = async (id: string, e: any) => {
    const form = e.currentTarget;
    UpdateKeyInitiative(
      dispatch,
      editId,
      {
        initiative: form.edit_initiative_name.value,
        links: [form.edit_initiative_link.value],
        _id: editId,
      } as CoalitionKeyInitiativeDetailsDTO,
      show,
    );
    UpdateChurchKeyInitiative(dispatch, church, {
      initiative: form.edit_initiative_name.value,
      links: [form.edit_initiative_link.value],
      _id: editId,
      church_id: church._id,
      user_id: currentUser._id,
    });
    show('set-key-initiatives-success');
    setEditId('');
  };

  const submit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (addingInitiative) {
      await addInitiative(e);
    } else if (editId) {
      await editInitiative(editId, e);
    }
  };

  const submitButtonText = () => {
    if (addingInitiative) return 'Add';
    return 'Save';
  };

  const onMouseEnterInitiative = (id: string) => {
    if (!isChurchAdmin) return;
    setIsHovered((prev) => ({ ...prev, [id]: true }));
  };

  const onMouseLeaveInitiative = (id: string) => {
    if (!isChurchAdmin) return;
    setIsHovered((prev) => ({ ...prev, [id]: false }));
  };

  const onEditInitiative = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setEditId(id);
    setAddingInitiative(false);
    setIsHovered({}); // reset hover state
  };

  useEffect(() => {
    refreshKeyInitiatives();
    return;
  }, [coalition]);

  const renderInitiative = (initiative: CoalitionKeyInitiativeDetailsDTO) => {
    const hasLink = initiative.links.filter((l) => l).length > 0;

    if (editId === initiative._id) {
      return (
        <St.Row gap="10px" mt="10px">
          <St.Icon src="/images/check.svg" size="14px" />
          <InitiativesInput
            type="text"
            name="edit_initiative_name"
            required
            placeholder="New title"
            defaultValue={initiative.initiative}
          />
          <InitiativesInput
            type="url"
            name="edit_initiative_link"
            placeholder="Link (optional)"
            defaultValue={hasLink ? initiative.links[0] : ''}
          />
          <St.AnimatedIcon
            src={TrashRedIcon}
            onClick={() => onClickTrash(initiative._id)}
            size="16px"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            mr="10px"
          />
        </St.Row>
      );
    }

    const content = (
      <St.Row
        key={initiative._id}
        justifyContent="start"
        mt="10px"
        gap="10px"
        minWidth="50%"
        onMouseEnter={() => onMouseEnterInitiative(initiative._id)}
        onMouseLeave={() => onMouseLeaveInitiative(initiative._id)}
      >
        <St.Icon src="/images/check.svg" size="14px" />
        {initiative.initiative}
        {isHovered[initiative._id] && (
          <St.AnimatedIcon
            src="/images/edit.svg"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            size="14px"
            ml="auto"
            mr="10px"
            onClick={(e) => onEditInitiative(e, initiative._id)}
          />
        )}
      </St.Row>
    );

    return hasLink ? (
      <St.Link target="_blank" to={formatUrl(initiative.links[0])}>
        {content}
      </St.Link>
    ) : (
      content
    );
  };

  return (
    <Form onSubmit={submit}>
      <St.Row justifyContent="space-between" width="100%">
        <St.Text
          fontWeight="800"
          fontSize="20px"
          color="var(--color-dark-grey)"
        >
          Key Initiatives / Accomplishments
        </St.Text>
        {isChurchAdmin && (
          <EditButtons onClickEdit={null} onClickAdd={onClickAdd} />
        )}
      </St.Row>
      <St.Row justifyContent="start" width="100%" flexWrap="wrap">
        {keyInitiatives.length > 0 || modifying
          ? keyInitiatives.map((initiative) => (
              <St.Div
                key={initiative._id}
                id={initiative._id}
                width={editId === initiative._id ? '100%' : '50%'}
              >
                {renderInitiative(initiative)}
              </St.Div>
            ))
          : 'No key initiatives'}
        {addingInitiative && (
          <St.Row minWidth="100%" mt="10px" gap="10px">
            <St.AnimatedIcon src="/images/check.svg" size="14px" />
            <InitiativesInput
              type="text"
              name="add_initiative_name"
              required
              placeholder="New initiative"
            />
            <InitiativesInput
              type="url"
              name="add_initiative_link"
              placeholder="Link (optional)"
            />
          </St.Row>
        )}
      </St.Row>
      {modifying && (
        <St.Row mt="10px" justifyContent="flex-start">
          <St.TertiaryButton onClick={cancelInitiatives} mr="10px">
            Cancel
          </St.TertiaryButton>
          <St.PrimaryButton type="submit">
            {submitButtonText()}
          </St.PrimaryButton>
        </St.Row>
      )}
    </Form>
  );
};

export default KeyInitiatives;
