import { motion } from 'framer-motion';
import React, { useState } from 'react';
import * as St from '../../Style.styled';

const MotionAnimatedButton = motion(St.AnimatedButton);
const filterButtonVariants = {
  rest: { scale: 1 },
  hover: { scale: 1.05 },
  tap: { scale: 1 },
};

const filterButtonTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 20,
};

interface PoiToggleButtonProps {
  children: React.ReactNode;
  src: string;
  setParentClicked: (clicked: boolean) => void;
}

const POIVisibilityButton: React.FC<PoiToggleButtonProps> = ({
  children,
  src,
  setParentClicked,
}) => {
  const [clicked, setClicked] = useState(true);

  const handleClick = () => {
    setParentClicked(!clicked);
    setClicked(!clicked);
  };

  return (
    <MotionAnimatedButton
      gap="5px"
      whileHover="hover"
      whileTap="tap"
      initial="rest"
      variants={filterButtonVariants}
      transition={filterButtonTransition}
      onClick={handleClick}
      style={{ position: 'relative' }}
    >
      <St.Icon
        src={src}
        size="15px"
        style={{
          opacity: clicked ? 1 : 0.5,
          transition: 'opacity 0.5s',
        }}
      />
      <span
        style={{
          opacity: clicked ? 1 : 0.5,
          transition: 'opacity 0.5s',
        }}
      >
        {children}
      </span>
    </MotionAnimatedButton>
  );
};

export default POIVisibilityButton;
