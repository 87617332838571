import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChurchMemberDetailsDTO from 'dtos/ChurchMemberDetailsDTO';
import { CoalitionMemberDetailsDTO } from 'dtos/CoalitionMemberDetailsDTO';
import DomainMemberDetailsDTO from 'dtos/DomainMemberDetailsDTO';
import MembershipsDTO from 'dtos/MembershipsDTO';

export const membershipSlice = createSlice({
  name: 'memberships',
  initialState: {
    data: {
      churches: [],
      coalitions: [],
      domains: [],
    } as MembershipsDTO,
    loaded: false,
  },
  reducers: {
    set_user_church_memberships: (
      state,
      action: PayloadAction<ChurchMemberDetailsDTO[]>,
    ) => {
      state.data = { ...state.data, churches: action.payload };
      state.loaded = true;
    },
    set_user_coalition_memberships: (
      state,
      action: PayloadAction<CoalitionMemberDetailsDTO[]>,
    ) => {
      state.data = { ...state.data, coalitions: action.payload };
      state.loaded = true;
    },
    set_user_domain_memberships: (
      state,
      action: PayloadAction<DomainMemberDetailsDTO[]>,
    ) => {
      state.data = { ...state.data, domains: action.payload };
      state.loaded = true;
    },
  },
});

export const {
  set_user_church_memberships,
  set_user_coalition_memberships,
  set_user_domain_memberships,
} = membershipSlice.actions;
export default membershipSlice.reducer;
