import * as St from 'components/Style.styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButtons from '../../components/EditButtons';
import EventCard from '../../components/EventCard';
import useUserAccess from '../../hooks/useUserAccess';
import { useGetDomainById, useGetEventState } from '../../store/hooks';
import { ChurchProfileParams } from '../ChurchProfile';
import EventDetailsDTO from 'dtos/EventDetailsDTO';

const DomainCalendar: React.FC = () => {
  const [domainEvents, setDomainEvents] = useState<EventDetailsDTO[]>([]);
  const { id } = useParams<ChurchProfileParams>();
  const domain = useGetDomainById(id);
  const navigate = useNavigate();
  const { isDomainAdminOf } = useUserAccess();
  const onClickAdd = useCallback(
    () => navigate(`/events/create?domain_id=${id}`),
    [],
  );
  const events = useGetEventState();

  useEffect(() => {
    if (events) {
      const domainEvents = events.data.filter((e) => e.domain_id === id);
      setDomainEvents(domainEvents);
    }
  }, [events, id]);

  return (
    <St.Column alignItems="flex-start" width="100%">
      <St.Row justifyContent="space-between" width="100%">
        <St.Text
          fontWeight="800"
          fontSize="20px"
          color="var(--color-dark-grey)"
          mt="5px"
        >
          Upcoming Events
        </St.Text>
        {isDomainAdminOf(domain) && (
          <EditButtons
            onClickEdit={domain.events.length > 0 ? onClickAdd : null}
            onClickAdd={onClickAdd}
          />
        )}
      </St.Row>
      <St.Column width="100%" pt="10px" gap="13px">
        {domainEvents.length > 0 ? (
          domainEvents.map((e) => <EventCard event={e} key={e._id} />)
        ) : (
          <St.Text mr="auto">No upcoming events</St.Text>
        )}
      </St.Column>
    </St.Column>
  );
};

export default DomainCalendar;
