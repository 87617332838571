import React from 'react';
import styled from 'styled-components';
import Toast from '../Toast';

const Container = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
`;

const ToastPortal: React.FC = () => {
  return (
    <Container>
      <Toast toastId="sign-in-success">
        Welcome back. You are now logged in.
      </Toast>
      <Toast toastId="sign-out-success">You are now signed out.</Toast>
      <Toast toastId="sign-up-success">
        Account created. You are now logged in.
      </Toast>
      <Toast toastId="sign-up-failure" secondary>
        Username or email already in use.
      </Toast>
      <Toast toastId="configure-account-success">Account configured.</Toast>
      <Toast toastId="set-key-initiatives-success">
        Updated Key Initiatives.
      </Toast>
      <Toast toastId="set-support-resources-success">
        Updated Support Resources.
      </Toast>
      <Toast toastId="update-coalitions-success">Updated Coalitions.</Toast>
      <Toast toastId="update-programs-success">Updated Programs.</Toast>
      <Toast toastId="update-church-statistics-success">
        Updated church statistics.
      </Toast>
      <Toast toastId="update-partner-domains-success">
        Updated partner domains.
      </Toast>
      <Toast toastId="passwords-do-not-match-failure" secondary>
        Passwords do not match.
      </Toast>
      <Toast toastId="must-enter-password-failure" secondary>
        Must enter a password.
      </Toast>
      <Toast toastId="must-enter-name-failure" secondary>
        Must enter a name.
      </Toast>
      <Toast toastId="must-enter-username-failure" secondary>
        Must enter a username.
      </Toast>
      <Toast toastId="must-enter-email-failure" secondary>
        Must enter an email.
      </Toast>
      <Toast toastId="login-failure" secondary>
        Incorrect username or password.
      </Toast>
      <Toast toastId="domain-added-success">New domain added.</Toast>
      <Toast toastId="event-created-success">New event created.</Toast>
      <Toast toastId="event-removed-success">Event removed.</Toast>
      <Toast toastId="event-updated-success">Event updated.</Toast>
      <Toast toastId="post-created-success">Post created.</Toast>
      <Toast toastId="post-updated-success">Post updated.</Toast>

      <Toast toastId="reply-created-success">Reply created.</Toast>
      <Toast toastId="joined-domain-success">Domain joined as member.</Toast>
      <Toast toastId="joined-coalition-success">
        Coalition joined as member.
      </Toast>
      <Toast toastId="joined-church-success">Church joined as member.</Toast>
      <Toast toastId="requested-domain-expert-status-success">
        Requested domain expert status.
      </Toast>
      <Toast toastId="must-enter-title-failure" secondary>
        Must enter a title.
      </Toast>
      <Toast toastId="must-enter-date-failure" secondary>
        Must enter a date.
      </Toast>
      <Toast toastId="update-user-details-success">User details updated.</Toast>
      <Toast toastId="change-password-success">Password changed.</Toast>
      <Toast toastId="update-user-details-failure" secondary>
        Failed to update details.
      </Toast>
      <Toast toastId="requested-coalition-admin">
        Requested partner status.
      </Toast>
      <Toast toastId="requested-coalition-admin-failed" secondary>
        Failed to request partner status.
      </Toast>
    </Container>
  );
};

export default ToastPortal;
