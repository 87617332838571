import { useMemo } from 'react';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import useGetFilterTitle from './useGetFilterTitle';

const useFilteredTabs = (
  tabs: string[],
  selectedCoalitions: CoalitionDetailsDTO[],
  selectedDomainIds: string[],
) => {
  const filterTitle = useGetFilterTitle(selectedCoalitions, selectedDomainIds);

  return useMemo(
    () => tabs.map((tab) => `${tab} (${filterTitle})`),
    [filterTitle, tabs],
  );
};

export default useFilteredTabs;
