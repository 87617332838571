import { executor, getAxios } from 'api';
import Cookies from 'js-cookie';
import CreateUserDTO from 'dtos/CreateUserDTO';
import UserDetailsDTO from 'dtos/UserDetailsDTO';
import UpdatePasswordDTO from '../dtos/UpdatePasswordDTO';
import UpdateUserDTO from '../dtos/UpdateUserDTO';
import { decrypt } from 'utils/encryptAndDecrypt';

export interface UserApi {
  users: () => Promise<UserDetailsDTO[] | undefined>;
  get_by_id: (id: string) => Promise<UserDetailsDTO | undefined>;
  whoami: () => Promise<UserDetailsDTO | undefined>;
  create: (dto: CreateUserDTO) => Promise<string | undefined>;
  login: (
    username: string,
    password: string,
  ) => Promise<UserDetailsDTO | undefined>;
  logout: () => Promise<void>;
  update_user: (dto: UpdateUserDTO) => Promise<void>;
  update_password: (dto: UpdatePasswordDTO) => Promise<void>;
}

const users = async (): Promise<UserDetailsDTO[] | undefined> => {
  const action = async (): Promise<UserDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'users',
        query: { _IsActive: { $ne: false } },
      },
    });

    return result.data;
  };

  return await executor(action, 'users');
};

const whoami = async (): Promise<UserDetailsDTO | undefined> => {
  const action = async (): Promise<UserDetailsDTO> => {
    const uid = Cookies.get('uid');
    if (!uid) return undefined;

    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'users',
        query: { _id: uid, _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data[0] ?? undefined;
  };

  return await executor(action, 'whoami');
};

const login = async (
  username: string,
  password: string,
): Promise<UserDetailsDTO | undefined> => {
  const action = (): (() => Promise<UserDetailsDTO>) => {
    const axiosInstance = getAxios();
    return async () => {
      const result = await axiosInstance.request({
        method: 'POST',
        url: '/read',
        data: {
          collection: 'users',
          query: { username, _IsActive: { $ne: false } },
        },
      });

      if (!!result?.data && !!result?.data?.data[0]) {
        if (decrypt(result.data.data[0]?.password) === password) {
          Cookies.set('username', result.data.data[0].username, {
            expires: 1,
          });
          Cookies.set('uid', result.data.data[0]._id, {
            expires: 1,
          });
          return { ...result.data.data[0], id: result.data.data[0]._id };
        }
      }
      return null;
    };
  };

  return await executor(action(), 'login');
};

const logout = async (): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/read',
    });
  };

  await executor(action, 'logout');
};

const create = async (dto: CreateUserDTO): Promise<string | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'users',
        update: { ...dto },
      },
    });

    return result.data;
  };

  return await executor(action, 'create_user');
};

const get_by_id = async (_id: string): Promise<UserDetailsDTO | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: { collection: 'users', query: { _id } },
    });
    return result.data;
  };

  return await executor(action, 'get_user_by_id');
};

const update_user = async (dto: UpdateUserDTO): Promise<void> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: { collection: 'users', update: dto },
    });

    return result.data;
  };

  return await executor(action, 'update_user');
};

const update_password = async (dto: UpdatePasswordDTO): Promise<void> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: { collection: 'users', update: dto },
    });

    return result.data;
  };

  return await executor(action, 'update_password');
};

const user_api_set: UserApi = {
  users,
  whoami,
  create,
  login,
  logout,
  get_by_id,
  update_user,
  update_password,
};
export default user_api_set;
