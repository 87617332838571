import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import styled from 'styled-components';
import CoalitionCard from '../components/CoalitionCard';
import Tabs from '../components/Tabs';
import { useGetCoalitions, useGetCurrentUser } from '../store/hooks';
import routes from 'config/routes';
import useUserAccess from 'hooks/useUserAccess';
import { FilterIcon } from 'components/Map/Icons';

const TABS = ['All Coalitions', 'Your Coalition'];

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Coalitions: React.FC = () => {
  const { isCoalitionMemberOf } = useUserAccess();
  const coalitions = useGetCoalitions();
  const currentUser = useGetCurrentUser();
  const [searchTerm, setSearchTerm] = useState('');
  const tabState = useState(0);
  const [_, setSelectedIndex] = tabState;

  const filteredCoalitions =
    tabState[0] === 1
      ? coalitions
          .filter((coalition) => !!isCoalitionMemberOf(coalition))
          .filter(
            (e) =>
              e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              !searchTerm,
          )
          .map((coalition) => ({ coalition, message: undefined }))
      : coalitions
          .filter(
            (e) =>
              e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              !searchTerm,
          )
          .map((coalition) => ({ coalition, message: undefined }));

  const emptyMessage = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <St.Text ml="20px" mb={'8px'}>
          {tabState[0] === 1
            ? 'No coalition available. Join a coalition for it to show up here.'
            : 'No coalition found.'}
        </St.Text>
        <St.SecondaryButton onClick={() => setSelectedIndex(1)}>
          <St.Text>Find a coalition</St.Text>
        </St.SecondaryButton>
      </div>
    );
  };

  return (
    <>
      <St.Header>
        <St.HeaderText ml="10px">Coalitions</St.HeaderText>
        {tabState[0] === 0 && (
          <St.Row
            justifyContent="start"
            width="100%"
            maxWidth={325}
            marginRight={12}
          >
            <St.Icon src={FilterIcon} style={{ paddingRight: 12 }} />
            <St.TextInput
              placeholder="Search for a coalition"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
          </St.Row>
        )}

        {currentUser.is_site_admin && (
          <St.PrimaryButtonLink
            to={routes.RETOOL.href}
            p="10px"
            target="_blank"
          >
            <St.Row>
              <St.Icon src="/images/edit-white.svg" height="18px" mr="10px" />
              Manage Coalitions
            </St.Row>
          </St.PrimaryButtonLink>
        )}
      </St.Header>
      <Tabs tabs={TABS} parentTabState={tabState} />
      <Container>
        {filteredCoalitions.length > 0
          ? filteredCoalitions.map(
              (obj) =>
                obj.coalition && (
                  <CoalitionCard
                    key={obj.coalition._id}
                    coalition={obj.coalition}
                    tooltipMessage={obj.message}
                    isMember={isCoalitionMemberOf(obj.coalition)}
                    isAdmin={false}
                    // isAdmin={isCoalitionAdminOf(obj.coalition)}
                  />
                ),
            )
          : emptyMessage()}
      </Container>
    </>
  );
};

export default Coalitions;
