export const defaultArrayDataState = <
  TDataDTO,
>(): ArrayDataState<TDataDTO> => ({
  data: [],
  loaded: false,
});

export default interface ArrayDataState<TDataDTO> {
  data: TDataDTO[];
  loaded: boolean;
}
