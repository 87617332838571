import * as St from 'components/Style.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePost } from 'store/helpers';
import styled from 'styled-components';
import apis from '../../api';
import CoalitionDropdown from '../../components/CoalitionDropdown';
import { Comment } from '../../config/icons';
import { ToastContext } from '../../contexts/ToastContext';
import {
  useGetCoalitionById,
  useGetCurrentUser,
  useGetDomainById,
  useGetPostById,
} from '../../store/hooks';
import Reply from './Reply';
import PostRepliesDTO from 'dtos/PostRepliesDTO';

const InfoTag = styled.div`
  border: 2px solid black;
  padding: 0 5px;
  border-radius: 12px;
`;

const Post: React.FC = () => {
  const { id: post_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultPost = useGetPostById(post_id);
  const [post, setPost] = useState(defaultPost);
  const [replies, setReplies] = useState<PostRepliesDTO[]>([]);
  const coalition = useGetCoalitionById(post?.coalition_id);
  const domain = useGetDomainById(post?.domain_id);
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();
  const tags: string[] = [];
  if (coalition) tags.push(coalition.name);
  if (domain) tags.push(domain.name);

  const getReplies = () => {
    apis.posts.get_post_by_id(post_id).then((res) => res && setPost(res));
  };

  useEffect(() => {
    if (!!post_id) getReplies();
  }, [post_id]);

  const submit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const json = {
      user_id: currentUser?._id,
      content: form.content.value,
      post_id,
    };

    apis.posts.create_reply(json).then((res) => {
      form.reset();
      getReplies();
      show('reply-created-success');

      const newReply: PostRepliesDTO = {
        ...json,
        _id: res,
        _CreatedOn: new Date(),
        _UpdatedOn: new Date(),
        user: currentUser,
      };
      setReplies([...replies, newReply]);
    });
  };

  const handleEditPost = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    navigate(`/edit/${post_id}/post`);
  };
  const handleDeletePost = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    deletePost(post_id, dispatch, navigate, show);
  };

  useEffect(() => {
    if (!!post_id) {
      apis.posts.get_post_replies(post_id).then((replyWithReplies) => {
        if (replyWithReplies) {
          setReplies(replyWithReplies);
        }
      });
    }
  }, []);

  return (
    <>
      <St.Header>
        <St.Row justifyContent="flex-start" gap="20px" width={'100%'}>
          <St.Column alignItems={'flex-start'}>
            <St.Text
              textOverflow="ellipsis"
              overflow="hidden"
              fontWeight={800}
              fontSize="20px"
            >
              {post?.title}
            </St.Text>

            {!!post?.user && (
              <St.Row justifyContent={'flex-start'} style={{ gap: 4 }}>
                <St.Text
                  fontSize={14}
                  fontWeight={300}
                  color="var(--color-text-light)"
                >
                  By:
                </St.Text>
                <St.Link to={`/users/${post.user._id}`} fontSize={14}>
                  {post.user.name}
                </St.Link>
              </St.Row>
            )}
          </St.Column>

          <St.Column
            style={{
              // flexWrap: 'nowrap',
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            {tags.map((tag, i) => (
              <InfoTag key={`reply-${i}`}>
                <St.Text
                  textAlign="center"
                  lineHeight="20px"
                  verticalAlign="center"
                >
                  {tag}
                </St.Text>
              </InfoTag>
            ))}
          </St.Column>
        </St.Row>
      </St.Header>
      <St.Card
        mx="auto"
        flexDirection="column"
        width="100%"
        style={{ padding: '12px' }}
      >
        {(post?.content ?? '').split('\n').map((paragraph, i) => (
          <St.Text
            key={'paragraph-' + i}
            textOverflow="ellipsis"
            overflow="hidden"
            fontSize={'14px'}
            mt={i === 0 ? '0px' : '12px'}
          >
            {paragraph}
          </St.Text>
        ))}
      </St.Card>

      {post?.user_id === currentUser?._id && (
        <St.Row
          gap="10px"
          mr="auto"
          mt="10px"
          pb={'12px'}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          width={'100%'}
        >
          <St.SecondaryButton onClick={handleEditPost} type="submit">
            Edit
          </St.SecondaryButton>
          <St.PrimaryButton onClick={handleDeletePost} type="submit">
            Delete
          </St.PrimaryButton>
        </St.Row>
      )}

      <St.Card flexDirection="column" mx="auto" my="10px" width="100%">
        <St.Form
          onSubmit={submit}
          width="100%"
          gap="10px"
          justifyContent="flex-start"
        >
          <St.LargeInput
            placeholder="What are your thoughts?"
            minHeight="100px"
            name="content"
            required
          />

          <St.Row pl={'12px'} alignItems="flex-end" gap={'7px'}>
            <St.Column>
              <St.Row gap="10px">
                <St.Icon src={Comment} height="18px" />
                {replies.length}
                {replies.length === 1 ? ' reply' : ' replies'}
              </St.Row>
            </St.Column>

            <St.Column alignItems={'flex-end'} flex={1} display={'flex'}>
              <St.PrimaryButton type="submit">Comment</St.PrimaryButton>
            </St.Column>
          </St.Row>
        </St.Form>
        <div
          style={{
            border: '1px solid var(--color-hr-red)',
            width: '100%',
            margin: '10px 0',
          }}
        />
        {replies?.map((reply) => (
          <Reply reply={reply} />
        ))}
      </St.Card>
    </>
  );
};

export default Post;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
