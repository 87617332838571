import PointOfInterestDetailsDTO, {
  PointOfInterestType,
} from '../dtos/PointOfInterestDetailsDTO';

export const defaultPointOfInterestState: PointOfInterestState = {
  data: {
    [PointOfInterestType.PRIMARY_SCHOOL]: [],
    [PointOfInterestType.SECONDARY_SCHOOL]: [],
    [PointOfInterestType.UNIVERSITY]: [],
    [PointOfInterestType.LIBRARY]: [],
    [PointOfInterestType.SUPERMARKET]: [],
    [PointOfInterestType.DOCTOR]: [],
    [PointOfInterestType.DRUGSTORE]: [],
    [PointOfInterestType.HOSPITAL]: [],
    [PointOfInterestType.GYM]: [],
    [PointOfInterestType.PHARMACY]: [],
    [PointOfInterestType.CITY_HALL]: [],
    [PointOfInterestType.COURTHOUSE]: [],
    [PointOfInterestType.LAWYER]: [],
    [PointOfInterestType.POLICE]: [],
    [PointOfInterestType.CHURCH]: [],
  },
  loaded: false,
};

export type PointOfInterestData = {
  [key in PointOfInterestType]: PointOfInterestDetailsDTO[];
};

export default interface PointOfInterestState {
  data: PointOfInterestData;
  loaded: boolean;
}
