import * as St from 'components/Style.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import assetTypeIcons from 'config/assetTypeIcons';
import apis from '../../api';
import StatisticHighlight from '../../components/StatisticHighlight';
// import assetTypeIcons from '../../config/assetTypeIcons';
import routes from '../../config/routes';
import { ModalContext } from '../../contexts/ModalContext';
import { ToastContext } from '../../contexts/ToastContext';
import ChurchStatisticDetailsDTO from '../../dtos/ChurchStatisticDetailsDTO';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { set_logged_out } from '../../store/user';

const Slideshow = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 500px;
  background: url('/images/home-image-filter.png') no-repeat,
    url(${(props) => props.src}) no-repeat;
  background-position: 0;
  background-size: cover;

  margin-bottom: 12.5px;
`;

const HeroText = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 0.95;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
`;

const HighlightsSection = styled.div`
  flex: 1;
  min-width: 350px;
`;

const ParagraphSection = styled.p`
  flex: 1;
  min-width: 350px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 32px;
`;

interface Highlight {
  icon: string;
  value: string;
  name: string;
}

const highlights: Highlight[] = [
  {
    name: 'New Coalitions Launched',
    icon: '/images/coalitions-dark.png',
    value: '3',
  },
  {
    name: 'Participating Churches',
    icon: '/images/churches-dark.png',
    value: '30',
  },
  {
    name: 'Volunteer Hours',
    icon: '/images/clock-dark.png',
    value: '53,177',
  },
  {
    name: 'Homes Restored',
    icon: '/images/homes-dark.png',
    value: '53,177',
  },
  {
    name: 'Families Served',
    icon: '/images/families-dark.png',
    value: '8,733',
  },
  {
    name: 'Pounds of food Distributed',
    icon: '/images/food-dark.png',
    value: '1,542,235',
  },
  {
    name: 'Value Invested in The Community',
    icon: '/images/money-dark.png',
    value: '$1,223,068',
  },
  {
    name: 'Gallons of water distributed',
    icon: '/images/water-dark.png',
    value: '218,700',
  },
];

const Highlights = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;


const HomeView: React.FC = () => {
  const { toggleSignUpOpen } = React.useContext(ModalContext);
  const [statistics, setStatistics] = useState<
    ChurchStatisticDetailsDTO[] | any
  >([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logged_in = useAppSelector((state) => state.user.logged_in);
  const { show } = useContext(ToastContext);

  const handle_logout = async (): Promise<void> => {
    await apis.users.logout();
    show('sign-out-success');
    dispatch(set_logged_out());
    navigate(routes.HOME.href);
  };

  useEffect(() => {
    apis.general
      .get_all_statistics_summary()
      .then((res) => {
        console.log(res);
        setStatistics(res);
      })
      .catch((error) => console.log(error));
  }, []);

  console.log();

  return (
    <>
      <Slideshow src="/images/home-panel-1.png">
        <HeroText>Addressing Community Needs</HeroText>
      </Slideshow>
      <St.TwoColumnLayout>
        <ParagraphSection>
          2022 was a year of expanding the mission beyond responding to
          disasters to more broadly addressing community needs. Churches in
          coalitions across Greater Houston and Southern Louisiana made a
          significant impact on critical needs, from home repair and food
          distribution to racial reconciliation and financial literacy, to name
          a few.
          <br />
          We have prepared annual updates for local coalitions and for Houston
          Responds to report data, share stories of the accomplishments in 2021,
          and cast our new "interconnected vision" for 2022 and beyond. Download
          the "2022 HR Overview Update" for an overview of the collective
          efforts of all coalitions. Download the "2022 Coalition Updates
          (Combined)" for a pdf of all the coalition's individual annual updates
          or select a coalition from the list to access each coalition's update.
          <St.Link
            to="https://www.houstonresponds.org/_files/ugd/10a4a8_e29966ed9b614fc0a43690db671ed220.pdf"
            mb="3"
            mt="3"
          >
            2021 HR Overview Update Download
          </St.Link>
          <St.Link
            to="https://www.houstonresponds.org/_files/ugd/10a4a8_0567e2fc20df46908bc640f9533198f3.pdf"
            mb="4"
          >
            2021 Coalition Updates (Combined) Download
          </St.Link>
          {logged_in ? (
            <St.PrimaryButton type="button" onClick={handle_logout} mr="3">
              Sign out
            </St.PrimaryButton>
          ) : (
            <St.PrimaryButtonLink mr="3" mb="2" onClick={toggleSignUpOpen}>
              Sign up to start making a difference
            </St.PrimaryButtonLink>
          )}
          <St.SecondaryButtonLink onClick={() => show('toast-first')}>
            Find a coalition
          </St.SecondaryButtonLink>
        </ParagraphSection>

        <HighlightsSection>
          <St.HighlightsBanner mb="16px">
            Platform Highlights
          </St.HighlightsBanner>
          <Highlights>
            {highlights.map((h) => (
              <StatisticHighlight
                key={h.name}
                name={h.name}
                icon={h.icon}
                value={h.value}
              />
            ))}
          </Highlights>
          {!statistics.includes('offline') && statistics && (
            <Highlights>
              {statistics.map((h) => (
                <StatisticHighlight
                  key={h.id}
                  name={h.type.name}
                  icon={assetTypeIcons[h.type.name]}
                  value={h.quantity.toString()}
                />
              ))}
            </Highlights>
          )}
        </HighlightsSection>
      </St.TwoColumnLayout>
    </>
  );
};

export default HomeView;
