import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #454545;
  color: white;
  width: 100%;
  height: 34px;
`;

const FooterText = styled.span`
  color: white;
  font-size: 12px;
`;

const FooterLink = styled(FooterText)`
  text-decoration: underline;
  cursor: pointer;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>
        © Copyright {new Date().getFullYear()} | Houston Responds is a 501
        (c)(3) |&nbsp;
      </FooterText>
      <FooterLink>Privacy Policy</FooterLink>
    </FooterContainer>
  );
};

export default Footer;
