import { executor, getAxios } from 'api';
import CoalitionDetailsDTO from 'dtos/CoalitionDetailsDTO';
import ChurchStatisticDetailsDTO from '../dtos/ChurchStatisticDetailsDTO';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';

export interface GeneralApi {
  get_all_programs: () => Promise<ProgramDetailsDTO[] | undefined>;
  get_church_programs: (
    churchId: string,
  ) => Promise<ProgramDetailsDTO[] | undefined>;
  update_church_program: (
    programId: string,
    dto: ProgramDetailsDTO,
  ) => Promise<void | undefined>;
  delete_church_program: (
    churchId: string,
    programId: string,
  ) => Promise<void | undefined>;
  get_coalition_statistics: (
    coalition_id: string,
  ) => Promise<ChurchStatisticDetailsDTO[]>;
  get_all_statistics_summary: () => Promise<ChurchStatisticDetailsDTO[]>;
}

const get_all_programs = async (): Promise<ProgramDetailsDTO[] | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'programs',
        query: [
          {
            $match: { _IsActive: { $ne: false } },
          },
          {
            $addFields: {
              _church_id: {
                $toObjectId: '$church_id',
              },
            },
          },
          {
            $lookup: {
              from: 'churches',
              localField: '_church_id',
              foreignField: '_id',
              as: 'church',
            },
          },
          {
            $unwind: '$church',
          },
          {
            $addFields: {
              _domain_id: {
                $toObjectId: '$domain_id',
              },
            },
          },
          {
            $lookup: {
              from: 'domains',
              localField: '_domain_id',
              foreignField: '_id',
              as: 'domain',
            },
          },
          {
            $unwind: '$domain',
          },
        ],
      },
    });

    return result.data?.data ?? [];
  };
  return await executor(action, 'get_all_programs');
};
const get_church_programs = async (
  church_id: string,
): Promise<ProgramDetailsDTO[] | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'programs',
        query: { church_id, _IsActive: { $ne: false } },
      },
    });
    return result.data.data;
  };
  return await executor(action, 'get_all_programs');
};
const update_church_program = async (
  programId: string,
  dto: ProgramDetailsDTO,
): Promise<void | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'programs',
        query: { _id: programId },
        update: { ...dto },
      },
    });
    return result.data.data;
  };
  return await executor(action, 'get_all_programs');
};
const delete_church_program = async (
  churchId: string,
  programId: string,
): Promise<void | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'programs',
        query: {
          church_id: churchId,
          _id: programId,
        },
        update: { _IsActive: false },
      },
    });
    return result.data.data;
  };
  return await executor(action, 'get_all_programs');
};
const get_coalition_statistics = async (
  coalition_id: string,
): Promise<ChurchStatisticDetailsDTO[]> => {
  const action = async (): Promise<ChurchStatisticDetailsDTO> => {
    const members = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'coalition_members',
        query: { coalition_id, _IsActive: { $ne: false } },
      },
    });

    const church_ids = (members?.data?.data ?? []).map((m) => m.church_id);

    const stats = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_statistics',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id: { $in: church_ids },
            },
          },
          {
            $addFields: {
              _asset_type_id: {
                $toObjectId: '$asset_type_id',
              },
            },
          },
          {
            $lookup: {
              from: 'church_statistic_types',
              localField: '_asset_type_id',
              foreignField: '_id',
              as: 'type',
            },
          },
          {
            $unwind: '$type',
          },
        ],
      },
    });

    return stats?.data?.data ?? [];
  };

  return await executor(action, 'get_all_statistics_summary');
};
const get_all_statistics_summary = async (): Promise<
  ChurchStatisticDetailsDTO[]
> => {
  const action = async (): Promise<ChurchStatisticDetailsDTO> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'all_statistics_summary',
    });

    return result.data;
  };

  return await executor(action, 'get_all_statistics_summary');
};

const admin_api_set: GeneralApi = {
  get_all_programs,
  get_coalition_statistics,
  get_all_statistics_summary,
  get_church_programs,
  update_church_program,
  delete_church_program,
};
export default admin_api_set;
