import DomainDetailsDTO from 'dtos/DomainDetailsDTO';

export enum DemographicType {
  INCOME_BAND,
  ETHNIC_GROUP,
  EDUCATION_LEVEL,
}

export interface FilterItem {
  id: DemographicType;
  label: string;
}

export interface UpdateFilterDomainsDTO {
  filteredDomainIds: string[];
  domains: DomainDetailsDTO[];
}
