import { EducationLevel, EthnicGroup, IncomeBand } from 'types/Demographic';

export interface MapFilters {
  income_bands: IncomeBand[];
  ethnic_groups: EthnicGroup[];
  education_levels: EducationLevel[];
}

export const defaultMapFilters: MapFilters = {
  income_bands: [],
  ethnic_groups: [],
  education_levels: [],
};
