import { Menu, Transition } from '@headlessui/react';
import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { defaultFilterDemographic, filters } from './config/config';
import { FilterItem } from './config/types';
import { FilterIcon } from './Icons';
import { updateFilterDemographic } from './utils';

interface FilterProps {
  width: string;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuButton = styled(Menu.Button)`
  ${St.textInputStyles}
`;

const MenuItem = styled(Menu.Item)`
  :hover {
    cursor: pointer;
  }

  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const TopContainer = styled(St.Row)<{ open: boolean }>`
  float: left;
  background: white;
  border-radius: ${(props) => (props.open ? '4px 4px 0px 0px' : '4px')};
  height: 35px;
` as StyledComponent<typeof St.Row, any, { open: boolean }>;

const Filter: React.FC<FilterProps> = ({ width }) => {
  const [filterDemographic, setFilterDemographic] = useState(
    defaultFilterDemographic,
  );

  const onClickItem = (item: FilterItem) => {
    updateFilterDemographic(item.id);
    setFilterDemographic(item.id);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <MenuButton>
            <TopContainer
              width={width}
              open={open}
              justifyContent="space-around"
            >
              <St.Text
                color="#A6A6A6"
                fontWeight="500"
                fontSize="12px"
                ml="5px"
              >
                Filter by
              </St.Text>
              <St.Icon src={FilterIcon} />
            </TopContainer>
          </MenuButton>
          <Transition
            as={St.Column}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{
                width,
                backgroundColor: 'white',
                borderRadius: '0px 0px 4px 4px',
              }}
            >
              <div>
                {filters.map((item) => (
                  <MenuItem key={item.id} onClick={() => onClickItem(item)}>
                    {({ active }) => (
                      <St.Row
                        justifyContent="start"
                        flexWrap="nowrap"
                        width="100%"
                        px="5px"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'py-2 text-sm',
                        )}
                      >
                        {filterDemographic === item.id ? (
                          <St.Icon
                            width="10px"
                            mr="5px"
                            src="/images/check.svg"
                          />
                        ) : (
                          <St.Div width="15px" />
                        )}
                        <St.Text nowrap>{item.label}</St.Text>
                      </St.Row>
                    )}
                  </MenuItem>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default Filter;
