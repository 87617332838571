import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';
import ProgramDetailsDTO from 'dtos/ProgramDetailsDTO';
import UpdateProgramDTO from 'dtos/UpdateProgramDTO';
import { defaultObjectDataState } from 'types/ObjectDataState';

type AddProgramsDTO = {
  churchId: string;
  programs: ProgramDetailsDTO[];
};

type EditProgramDTO = {
  churchId: string;
  program: UpdateProgramDTO;
};
type DeleteProgramDTO = {
  churchId: string;
  programId: string;
};
export const churchSlice = createSlice({
  name: 'churches',
  initialState: defaultObjectDataState<ChurchDetailsDTO>(),
  reducers: {
    set_churches: (state, action: PayloadAction<ChurchDetailsDTO[]>) => {
      state.data = action.payload.reduce(
        (acc, church) => {
          acc[church._id] = {
            ...church,
            coalitions:
              church.coalitions && church.coalitions.map((c) => ({ _id: c })),
          };
          return acc;
        },
        {}, // { ...state.data },
      );
      state.loaded = true;
    },
    set_church: (state, action: PayloadAction<ChurchDetailsDTO>) => {
      const temp = { ...state.data };
      temp[action.payload._id] = action.payload;
      state.data = temp;
      state.loaded = true;
    },
    add_programs: (state, action: PayloadAction<AddProgramsDTO>) => {
      const { churchId, programs } = action.payload;
      if (state.data[churchId]) {
        state.data = {
          ...state.data,
          [churchId]: {
            ...state.data[churchId],
            programs: programs,
          },
        };
      }
    },
    add_program: (state, action: PayloadAction<ProgramDetailsDTO>) => {
      const { church_id } = action.payload;
      const church = state.data[church_id];
      if (church) {
        const updatedPrograms = [...church.programs, action.payload];
        const updatedChurch: ChurchDetailsDTO = {
          ...church,
          programs: updatedPrograms,
        };
        state.data = {
          ...state.data,
          [church_id]: updatedChurch,
        };
      }
    },
    edit_program: (state, action: PayloadAction<EditProgramDTO>) => {
      const { churchId, program } = action.payload;
      const church = state.data[churchId];

      if (church) {
        const updatedPrograms = church.programs.map((p) => {
          if (p._id === program._id) {
            return { ...p, ...program };
          }
          return p;
        });

        const updatedChurch: ChurchDetailsDTO = {
          ...church,
          programs: updatedPrograms as ProgramDetailsDTO[],
        };

        state.data = {
          ...state.data,
          [churchId]: updatedChurch,
        };
      }
    },
    delete_program: (state, action: PayloadAction<DeleteProgramDTO>) => {
      const { churchId, programId } = action.payload;
      const church = state.data[churchId];
      if (church) {
        const updatedPrograms = church.programs.filter(
          (p) => p._id !== programId,
        );

        const updatedChurch: ChurchDetailsDTO = {
          ...church,
          programs: updatedPrograms,
        };
        state.data = {
          ...state.data,
          [churchId]: updatedChurch,
        };
      }
    },
  },
});

export const {
  set_churches,
  set_church,
  add_programs,
  add_program,
  edit_program,
  delete_program,
} = churchSlice.actions;
export default churchSlice.reducer;
