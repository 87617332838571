import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';

export const defaultCoalitionState: CoalitionState = {
  data: [],
  loaded: false,
};

export default interface CoalitionState {
  data: CoalitionDetailsDTO[];
  loaded: boolean;
}
