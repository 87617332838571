import * as St from 'components/Style.styled';
import useUserAccess from 'hooks/useUserAccess';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useParams } from 'react-router-dom';
import { delete_program } from 'store/churches';
import { addProgram, updatePrograms } from 'store/helpers';
import styled from 'styled-components';
import apis from '../../api';
import CheckboxDropdown, {
  DropdownItem,
} from '../../components/CheckboxDropdown';
import EditButtons from '../../components/EditButtons';
import { HOUSTON_LATLNG_BOUNDS } from '../../config/constants';
import env from '../../config/env';
import { TrashRedIcon } from '../../config/icons';
import { ToastContext } from '../../contexts/ToastContext';
import ProgramDetailsDTO from '../../dtos/ProgramDetailsDTO';
import UpdateProgramDTO from '../../dtos/UpdateProgramDTO';
import {
  useAppDispatch,
  useGetChurchById,
  useGetDomains,
  useGetProgramState,
} from '../../store/hooks';
import { delay } from '../../utils/delay';
import { buildGetAddressComponentFields } from '../../utils/googleUtils';
import toDropdownItems from '../../utils/toDropdownItems';
import { formatUrl } from '../../utils/urlUtils';
import { ChurchProfileParams } from './index';

const Input = styled.input`
  ${St.textInputStyles}
  width: 100%;
  height: 22px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  ${St.textInputStyles}
  width: 400px;
  height: 22px;
`;

const StyledTd = styled(St.Td)`
  min-width: 175px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const getAddressFieldsIfPresent = (program: ProgramDetailsDTO) =>
  !!program.longitude && !!program.latitude
    ? {
        address1: program.address1,
        address2: program.address2,
        city: program.city,
        state: program.state,
        zip_code: program.zip_code,
        longitude: program.longitude,
        latitude: program.latitude,
      }
    : {};

const toUpdateProgramDTO = (program: ProgramDetailsDTO): UpdateProgramDTO => {
  return {
    _id: program._id,
    date: program.date,
    description: program.description,
    more_info: program.more_info,
    domain_id: program.domain_id,
    ...getAddressFieldsIfPresent(program),
  };
};

const options = {
  fields: [
    'place_id',
    'address_components',
    'name',
    'photos',
    'geometry.location',
    'formatted_address',
  ],
  types: ['geocode', 'establishment'],
  bounds: HOUSTON_LATLNG_BOUNDS,
  strictBounds: true,
};

const AssetPrograms: React.FC = () => {
  const { show } = useContext(ToastContext);
  const dispatch = useAppDispatch();
  const { id } = useParams<ChurchProfileParams>();
  const church = useGetChurchById(id);
  const domains = useGetDomains();
  const { isChurchAdminOf } = useUserAccess();
  const isChurchAdmin = isChurchAdminOf(church);
  const [selectedItem, setSelectedItem] = useState<DropdownItem>(null);
  const [addingRow, setAddingRow] = useState(false);
  const [editingRows, setEditingRows] = useState(false);
  const [showAddressField, setShowAddressField] = useState(false);
  const [newProgramAddressFields, setNewProgramAddressFields] = useState({});
  const [programs, setPrograms] = useState<ProgramDetailsDTO[]>([]);
  const [staticPrograms, setStaticPrograms] = useState<ProgramDetailsDTO[]>([]);
  const [deletedRows, setDeletedRows] = useState<string[]>([]);
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );
  const dropdownOpen = Object.values(openDropdowns).includes(true);
  const modifying = addingRow || editingRows;
  const tableContainerRef = useRef(null);
  const domainItems = toDropdownItems(domains);

  const onClickAddressFields = () => setShowAddressField(!showAddressField);

  useEffect(() => {
    if (showAddressField && tableContainerRef) {
      tableContainerRef.current.scrollLeft += 1000;
    }
  }, [showAddressField]);

  const programState = useGetProgramState();
  useEffect(() => {
    setPrograms(programState.data.filter((p) => p.church_id === church._id));
    setStaticPrograms(
      programState.data.filter((p) => p.church_id === church._id),
    );
  }, [church, programState.data]);

  const generateAddressFields = (place: google.maps.places.PlaceResult) => {
    const { getField } = buildGetAddressComponentFields(
      place.address_components,
    );
    return {
      address1: `${getField('street_number')} ${getField('route')}`,
      city: getField('locality'),
      state: getField('administrative_area_level_1'),
      zip_code: getField('postal_code'),
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
    };
  };

  const onDropdownClick = (isOpen: boolean, uniqueKey: string) => {
    setOpenDropdowns({ ...openDropdowns, [uniqueKey]: isOpen });
  };

  const onExistingProgramAutocomplete = (
    place: google.maps.places.PlaceResult,
    i: number,
  ) => {
    const newRows = cloneDeep(programs);
    newRows[i] = {
      ...newRows[i],
      ...generateAddressFields(place),
    };

    setPrograms(newRows);
  };
  const onNewProgramAutocomplete = (place: google.maps.places.PlaceResult) => {
    setNewProgramAddressFields(generateAddressFields(place));
  };

  const clearNewProgramAddressIfEmpty = (event: any) => {
    if (!event.target.value) {
      setNewProgramAddressFields({});
    }
  };

  const clearExistingProgramAddressIfEmpty = (event: any, index: number) => {
    if (!event.target.value) {
      const newRows = cloneDeep(programs);
      newRows[index] = {
        _id: newRows[index]._id,
        id: newRows[index].id,
        date: newRows[index].date,
        description: newRows[index].description,
        more_info: newRows[index].more_info,
        domain_id: newRows[index].domain_id,
      };
      setPrograms(newRows);
    }
  };

  const onTrashClicked = (programId: string) => {
    setDeletedRows([...deletedRows, programId]);
    apis.general.delete_church_program(church._id, programId).then(() => {
      show('update-programs-success');
      dispatch(delete_program({ churchId: church._id, programId: programId }));
    });
  };

  const cancelAssetPrograms = () => {
    setAddingRow(false);
    setEditingRows(false);
    setShowAddressField(false);
    setDeletedRows([]);
  };

  const submitPrograms = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const promises = [];
    const program = {
      church_id: church._id,
      domain_id: selectedItem?.id,
      date: form.date.value,
      description: form.description.value,
      more_info: form.more_info.value,
      ...newProgramAddressFields,
    };
    if (addingRow) {
      addProgram(dispatch, program).then((res) => {
        if (!res) return;
        const newProgram: ProgramDetailsDTO = {
          ...program,
          _id: res,
          domain: domains.find((d) => d._id === program.domain_id),
        };
        setPrograms([...programs, newProgram]);
      });
    }
    if (editingRows) {
      const changedRows = programs.filter((program) => {
        const currentRow = staticPrograms.find((p) => p._id === program._id);
        return (
          currentRow.description !== program.description ||
          currentRow.more_info !== program.more_info ||
          currentRow.date !== program.date ||
          currentRow.domain_id !== program.domain_id ||
          currentRow.address1 !== program.address1 ||
          currentRow.address2 !== program.address2 ||
          currentRow.city !== program.city ||
          currentRow.state !== program.state ||
          currentRow.zip_code !== program.zip_code ||
          currentRow.longitude !== program.longitude ||
          currentRow.latitude !== program.latitude
        );
      });

      promises.push(
        changedRows.map((row) =>
          apis.general.update_church_program(row._id, row),
        ),
      );
    }

    promises.push(
      deletedRows.map((deletedId) =>
        apis.churches.delete_program(id, deletedId),
      ),
    );

    await Promise.all(promises);
    await delay(250); // wait for the server to update (not sure why it's not waiting to complete before responding)
    show('update-programs-success');
    cancelAssetPrograms();
    updatePrograms(dispatch);
  };

  const onEditInput = (e, index) => {
    const newRows = cloneDeep(programs);
    switch (e.target.name) {
      case 'description':
        newRows[index].description = e.target.value;
        break;
      case 'date':
        newRows[index].date = e.target.value;
        break;
      case 'more_info':
        newRows[index].more_info = e.target.value;
        break;
    }

    setPrograms(newRows);
  };

  const onEditDropdown = (name, item: DropdownItem, index) => {
    const newRows = cloneDeep(programs);
    switch (name) {
      case 'domain':
        newRows[index].domain = domains.find((d) => d._id === item.id);
        newRows[index].domain_id = item.id;
        break;
    }
    setPrograms(newRows);
  };

  const programDomainDropdownItems = (
    program: ProgramDetailsDTO,
  ): DropdownItem[] => {
    return domainItems.map((d) =>
      d.id === program.domain_id ? { ...d, checked: true } : d,
    );
  };

  const newProgramDomainDropdownItems = (): DropdownItem[] => {
    return domainItems.map((d) =>
      d.id === selectedItem?.id ? { ...d, checked: true } : d,
    );
  };

  const renderAssetPrograms = (
    domain_id: string,
    description: string,
    date: string,
    more_info: string,
    index: number,
  ) => {
    const domain = domains.find((d) => d._id === domain_id);

    if (editingRows) {
      const modifiedProgram = programs[index];
      const hasAddress =
        !!modifiedProgram.longitude && !!modifiedProgram.latitude;
      return (
        <St.Tr>
          <td style={{ width: '100px' }}>
            <St.AnimatedIcon
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              src={TrashRedIcon}
              mx="auto"
              onClick={() => onTrashClicked(modifiedProgram._id)}
            />
          </td>
          <StyledTd>
            <CheckboxDropdown
              items={programDomainDropdownItems(modifiedProgram)}
              onClickItem={(item) => onEditDropdown('domain', item, index)}
              onClick={(isOpen) => onDropdownClick(isOpen, modifiedProgram.id)}
              width="100%"
            >
              {modifiedProgram.domain?.name ?? 'None'}
            </CheckboxDropdown>
          </StyledTd>
          <StyledTd>
            <Input
              type="text"
              required
              name="description"
              value={modifiedProgram?.description}
              onChange={(e) => onEditInput(e, index)}
            />
          </StyledTd>
          <StyledTd>
            <Input
              type="text"
              required
              name="date"
              value={modifiedProgram?.date}
              onChange={(e) => onEditInput(e, index)}
              placeholder="e.g Weekdays 6-7pm"
            />
          </StyledTd>
          <StyledTd>
            <Input
              type="text"
              name="more_info"
              value={modifiedProgram?.more_info}
              onChange={(e) => onEditInput(e, index)}
              placeholder="e.g https://..."
            />
          </StyledTd>
          {showAddressField && (
            <StyledTd style={{ width: '50%' }}>
              <St.Row gap="5px">
                <StyledAutocomplete
                  apiKey={env.googleMapsApiKey}
                  options={options}
                  onPlaceSelected={(place) =>
                    onExistingProgramAutocomplete(place, index)
                  }
                  defaultValue={
                    hasAddress
                      ? `${modifiedProgram.address1}, ${modifiedProgram.city}, ${modifiedProgram.state}, ${modifiedProgram.zip_code}`
                      : ''
                  }
                  onChange={(e) => clearExistingProgramAddressIfEmpty(e, index)}
                />
                {hasAddress && <St.Icon src="/images/check.svg" size="14px" />}
              </St.Row>
            </StyledTd>
          )}
        </St.Tr>
      );
    }

    return (
      <St.Tr>
        <StyledTd>
          {domain ? (
            <St.Link to={`/domains/${domain.id}`}>{domain.name}</St.Link>
          ) : (
            'None'
          )}
        </StyledTd>
        <StyledTd>{description}</StyledTd>
        <StyledTd>{date}</StyledTd>
        <StyledTd>
          {more_info && more_info !== '' ? (
            <St.ExternalLink
              href={formatUrl(more_info)}
              target="_blank"
              rel="noreferrer"
            >
              Click to view
            </St.ExternalLink>
          ) : (
            ''
          )}
        </StyledTd>
      </St.Tr>
    );
  };

  const table = () => {
    if ((programs ?? []).length === 0 && !modifying)
      return (
        <St.NotApplicableContainer>
          This church has no Programs / Assets
        </St.NotApplicableContainer>
      );

    return (
      <>
        <St.Div
          width="100%"
          overflowX={dropdownOpen ? 'visible' : 'auto'}
          style={{ scrollBehavior: 'smooth' }}
          ref={tableContainerRef}
        >
          <St.Table style={{ overflowX: 'hidden', maxWidth: '100%' }}>
            <St.Tr>
              {editingRows && <th style={{ padding: '0 10px' }}>.</th>}
              <th>Domain</th>
              <th>Description</th>
              <th>Dates and Times</th>
              <th>Additional Info</th>
              {showAddressField && <th>Address</th>}
            </St.Tr>
            {programs?.map(
              ({ id, domain_id, description, date, more_info }, index) =>
                !deletedRows.includes(id) &&
                renderAssetPrograms(
                  domain_id,
                  description,
                  date,
                  more_info,
                  index,
                ),
            )}
            {addingRow && (
              <St.Tr>
                {editingRows && <td />}
                <StyledTd>
                  <CheckboxDropdown
                    items={newProgramDomainDropdownItems()}
                    onClickItem={(item) => setSelectedItem(item)}
                    onClick={(isOpen) => onDropdownClick(isOpen, 'new-program')}
                    width="100%"
                  >
                    {selectedItem?.name ?? 'None'}
                  </CheckboxDropdown>
                </StyledTd>
                <StyledTd>
                  <Input
                    type="text"
                    id="description"
                    name="description"
                    required
                    placeholder="Description"
                  />
                </StyledTd>
                <StyledTd>
                  <Input
                    type="text"
                    id="date"
                    name="date"
                    required
                    placeholder="e.g Weekdays 6-7pm"
                  />
                </StyledTd>
                <StyledTd>
                  <Input
                    type="text"
                    id="more_info"
                    name="more_info"
                    placeholder="e.g https://..."
                  />
                </StyledTd>
                {showAddressField && (
                  <StyledTd style={{ width: '50%' }}>
                    <St.Row gap="5px">
                      <StyledAutocomplete
                        apiKey={env.googleMapsApiKey}
                        options={options}
                        onPlaceSelected={(place) =>
                          onNewProgramAutocomplete(place)
                        }
                        onChange={clearNewProgramAddressIfEmpty}
                      />
                      {!!newProgramAddressFields['longitude'] &&
                        !!newProgramAddressFields['latitude'] && (
                          <St.Icon src="/images/check.svg" size="14px" />
                        )}
                    </St.Row>
                  </StyledTd>
                )}
              </St.Tr>
            )}
          </St.Table>
        </St.Div>
        {modifying && (
          <St.Row mt="10px" gap="10px">
            <St.TertiaryButton onClick={cancelAssetPrograms}>
              Cancel
            </St.TertiaryButton>
            <St.PrimaryButton type="submit">
              {!!addingRow ? 'Add' : 'Save'}
            </St.PrimaryButton>
            <St.SecondaryButton type="button" onClick={onClickAddressFields}>
              {showAddressField ? 'Hide custom address' : 'Add custom address'}
            </St.SecondaryButton>
          </St.Row>
        )}
      </>
    );
  };

  return (
    <>
      <St.HighlightsBanner mb="5px">
        <St.Text width="100%">Programs / Assets</St.Text>
        {isChurchAdmin && (
          <EditButtons
            white
            onClickEdit={
              (programs ?? []).length > 0
                ? () => setEditingRows((prev) => !prev)
                : null
            }
            onClickAdd={() => setAddingRow((prev) => !prev)}
          />
        )}
      </St.HighlightsBanner>
      <Form onSubmit={submitPrograms}>{table()}</Form>
    </>
  );
};

export default AssetPrograms;
