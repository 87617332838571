import { useEffect, useState } from 'react';
import usePlacesService from './usePlacesService';

const useFetchGooglePlacesPhotos = (
  placeId: string,
): {
  loading: boolean;
  error: Error | null;
  photos: google.maps.places.PlacePhoto[];
} => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const scriptsLoaded = window.google && window.google.maps;
  const service = usePlacesService();

  useEffect(() => {
    if (placeId && scriptsLoaded && service) {
      service.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPhotos(place.photos);
          setError(null);
        } else {
          setPhotos([]);
          setError(new Error(`Failed to fetch data: ${status}`));
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [placeId, scriptsLoaded, service]);

  return { loading, error, photos };
};

export default useFetchGooglePlacesPhotos;
