import { executor, getAxios } from 'api';
import MapMarkerDTO from '../dtos/MapMarkerDTO';
import MapViewportStateDTO from '../dtos/MapViewportStateDTO';
import { CommunityClickedEventDTO } from '../dtos/CommunityClickedEventDTO';
import CommunityDemographicsDTO from '../dtos/CommunityDemographicsDTO';
import { filterHouseholds, getHouseholdQuery } from '../utils/householdUtils';

export interface MapApi {
  get_viewport_households: (
    dto: MapViewportStateDTO,
  ) => Promise<MapMarkerDTO[] | undefined>;
  get_community_demographics: (
    dto: CommunityClickedEventDTO,
  ) => Promise<CommunityDemographicsDTO | undefined>;
}

const get_community_demographics = async (
  dto: CommunityClickedEventDTO,
): Promise<CommunityDemographicsDTO | undefined> => {
  const action = async (): Promise<MapMarkerDTO[]> => {
    const query = getHouseholdQuery({}, dto.community_bounds);
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'households',
        query,
      },
    });

    return result.data.data;
  };

  return await executor(action, 'get_community_demographics');
};

const get_viewport_households = async (
  dto: MapViewportStateDTO,
): Promise<MapMarkerDTO[] | undefined> => {
  const action = async (): Promise<MapMarkerDTO[]> => {
    let result;

    let query = {};
    if (dto.zoom < 15) {
      const filters = {
        0: 'income',
        1: 'ethnic_group',
        2: 'education_level',
      };

      query = {
        zoom_level: 13,
        category: filters[dto.current_demographic],
      };

      result = await getAxios().request({
        method: 'POST',
        url: '/read',
        data: {
          collection: 'households_batches',
          query,
        },
      });

      const householdsData = [];

      if (result.data.data) {
        const households = filterHouseholds(result.data.data, dto.filters);

        households.forEach((household) => {
          householdsData.push({
            ...household,
            id: household._id,
            income: household.max_category,
            text: `${household.total}`,
            latitude: household.avg_lat,
            longitude: household.avg_lon,
            position: {
              lat: household.avg_lat,
              lng: household.avg_lon,
            },
          });
        });

        return householdsData;
      }
      return [];
    } else {
      query = getHouseholdQuery(dto.filters, dto.bounds);
      result = await getAxios().request({
        method: 'POST',
        url: '/read',
        data: {
          collection: 'households',
          query,
        },
      });
      const tmpResponse = result.data.data
        ? result.data.data.map((household) => {
            return {
              ...household,
              size: 10,
              position: { lat: household.latitude, lng: household.longitude },
            };
          })
        : [];
      return tmpResponse;
    }
  };

  return await executor(action, 'get_viewport_households');
};

const households_api_set: MapApi = {
  get_viewport_households,
  get_community_demographics,
};
export default households_api_set;
