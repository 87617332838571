import 'index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from 'store';

import App from 'App';
import Providers from './Providers';
import { Provider } from 'react-redux';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Providers>
        <App />
      </Providers>
    </React.StrictMode>
  </Provider>,
);
