import { executor, getAxios } from 'api';
import CreateEventDTO from '../dtos/CreateEventDTO';
import EventDetailsDTO from '../dtos/EventDetailsDTO';
import UpdateEventDTO from '../dtos/UpdateEventDTO';
import DomainId from '../types/DomainId';

export interface EventsApi {
  get_for_user: (user_id: string) => Promise<EventDetailsDTO[] | undefined>;
  create: (dto: CreateEventDTO) => Promise<void>;
  update: (id: string, dto: UpdateEventDTO) => Promise<void>;
  remove: (id: string) => Promise<void>;
  get_for_church: (church_id: string) => Promise<EventDetailsDTO[] | undefined>;
  get_for_domain: (
    domain_id: string,
  ) => Promise<EventDetailsDTO[] | undefined>;
}

const get_for_user = async (
  user_id: string,
): Promise<EventDetailsDTO[] | undefined> => {
  const action = async (): Promise<EventDetailsDTO[] | undefined> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'events',
        query: { user_id, _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_events_for_user');
};

const create = async (dto: CreateEventDTO): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: `/write`,
      data: {
        collection: 'events',
        update: { ...dto },
      },
    });
  };

  await executor(action, 'create_event');
};

const update = async (id: string, dto: UpdateEventDTO): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: `/write`,
      data: {
        collection: 'events',
        query: { _id: id },
        update: { ...dto },
      },
    });
  };

  await executor(action, 'update_event');
};

const remove = async (id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: `/write`,
      data: {
        collection: 'events',
        query: { _id: id },
        update: { _IsActive: false },
      },
    });
  };

  await executor(action, 'remove_event');
};

const get_for_church = async (
  church_id: string,
): Promise<EventDetailsDTO[] | undefined> => {
  const action = async (): Promise<EventDetailsDTO[] | undefined> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'events',
        query: { church_id, _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_events_for_church');
};

const get_for_domain = async (
  domain_id: string,
): Promise<EventDetailsDTO[] | undefined> => {
  const action = async (): Promise<EventDetailsDTO[] | undefined> => {
    const result = await getAxios().request({
      url: '/read',
      data: {
        collection: 'events',
        query: { domain_id, _IsActive: { $ne: false } },
      },
    });

    return result.data;
  };

  return await executor(action, 'get_events_for_domain');
};

const eventsApi: EventsApi = {
  get_for_user,
  create,
  update,
  remove,
  get_for_church,
  get_for_domain,
};

export default eventsApi;
