import {
  useGetChurches,
  useGetCurrentUser,
  useGetEvents,
  useGetPosts,
} from '../store/hooks';
import {
  filterChurchByCoalition,
  filterChurchByDomains,
  filterEventByCoalition,
  filterEventByDomain,
  filterPostByCoalition,
  filterPostByDomains,
} from '../utils/filterUtils';
import useUserAccess from './useUserAccess';

export const useFilteredChurches = (
  filterDomainIds: string[],
  selectedCoalitions: string[],
) => {
  const churches = useGetChurches();
  return churches.filter(
    (church) =>
      filterChurchByDomains(church, filterDomainIds) &&
      filterChurchByCoalition(church, selectedCoalitions),
  );
};

export const useFilteredPosts = (
  domainIds: string[],
  selectedCoalitions: string[],
) => {
  const posts = useGetPosts();
  const user = useGetCurrentUser();
  const { userChurchAffiliation } = useUserAccess();
  const { church_id } = userChurchAffiliation();
  return posts.filter(
    (post) =>
      (filterPostByDomains(post, domainIds) &&
        filterPostByCoalition(post, selectedCoalitions)) ||
      post.church_id === church_id ||
      post.user_id === user._id,
  );
};

export const useFilteredEvents = (
  domainIds: string[],
  coalitionIds: string[],
) => {
  const events = useGetEvents();

  return events.filter(
    (e) =>
      filterEventByDomain(e, domainIds) &&
      filterEventByCoalition(e, coalitionIds),
  );
};
