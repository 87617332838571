import ChurchKeyInitiativeDetailsDTO from './ChurchKeyInitiativeDetailsDTO';
import ChurchStatisticDetailsDTO from './ChurchStatisticDetailsDTO';
import CoalitionDetailsDTO from './CoalitionDetailsDTO';
import ProgramDetailsDTO from './ProgramDetailsDTO';

export default interface ChurchDetailsDTO {
  _id: string;
  id: string;
  name: string;
  description: string;
  address1: string;
  address2?: string;
  google_places_id: string;
  image: string;
  key_initiatives: ChurchKeyInitiativeDetailsDTO[];
  longitude: number;
  latitude: number;
  city: string;
  state: string;
  zip_code: string;
  primary_contact: string;
  primary_phone: string;
  primary_email: string;
  status: number;
  coalitions: CoalitionDetailsDTO[] | string[];
  coalition_id?: string;
  programs: ProgramDetailsDTO[];
  assets: ChurchStatisticDetailsDTO[];
  related_domain_ids: string[];
  location: google.maps.LatLngLiteral;
  position: { lat: number; lng: number };
  iconColor?: string;
}

export const defaultChurchDetailsDTO: ChurchDetailsDTO = {
  _id: '',
  id: '',
  name: '',
  description: '',
  google_places_id: '',
  image: '',
  key_initiatives: [],
  latitude: 0,
  longitude: 0,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  primary_contact: '',
  primary_email: '',
  primary_phone: '',
  status: 0,
  coalitions: [],
  programs: [],
  assets: [],
  related_domain_ids: [],
  location: { lat: 0, lng: 0 },
  position: { lat: 0, lng: 0 },
  iconColor: 'red',
};

export const isChurchDetailsDTO = (obj: any): obj is ChurchDetailsDTO => {
  return (
    typeof obj._id === 'string' &&
    typeof obj.id === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.description === 'string' &&
    typeof obj.address1 === 'string' &&
    (typeof obj.address2 === 'undefined' || typeof obj.address2 === 'string') &&
    typeof obj.google_places_id === 'string' &&
    typeof obj.image === 'string' &&
    Array.isArray(obj.key_initiatives) &&
    typeof obj.longitude === 'number' &&
    typeof obj.latitude === 'number' &&
    typeof obj.city === 'string' &&
    typeof obj.state === 'string' &&
    typeof obj.zip_code === 'string' &&
    typeof obj.primary_contact === 'string' &&
    typeof obj.primary_phone === 'string' &&
    typeof obj.primary_email === 'string' &&
    typeof obj.status === 'number' &&
    Array.isArray(obj.coalitions) &&
    Array.isArray(obj.programs) &&
    Array.isArray(obj.assets) &&
    Array.isArray(obj.related_domain_ids)
  );
};
