import { executor, getAxios } from 'api';
import HouseholdDetailsDTO from '../dtos/HouseholdDetailsDTO';

export interface HouseholdsApi {
  households: () => Promise<HouseholdDetailsDTO[] | undefined>;
}

const households = async (): Promise<HouseholdDetailsDTO[] | undefined> => {
  const action = async (): Promise<HouseholdDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: { collection: 'households', limit: 100 },
    });

    return result.data;
  };

  return await executor(action, 'households');
};

const households_api_set: HouseholdsApi = {
  households,
};
export default households_api_set;
