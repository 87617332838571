import { CoalitionMemberDetailsDTO } from 'dtos/CoalitionMemberDetailsDTO';
import MembershipsDTO from 'dtos/MembershipsDTO';
import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { useDeepCompareMemo } from 'use-deep-compare';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import ChurchStatisticTypeDetailsDTO from '../dtos/ChurchStatisticTypeDetailsDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import DomainDetailsDTO from '../dtos/DomainDetailsDTO';
import EventDetailsDTO from '../dtos/EventDetailsDTO';
import PostDetailsDTO from '../dtos/PostDetailsDTO';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';
import ArrayDataState from '../types/ArrayDataState';
import CoalitionState from '../types/CoalitionState';
import MapState from '../types/MapState';
import ObjectDataState from '../types/ObjectDataState';
import PointOfInterestState, {
  PointOfInterestData,
} from '../types/PointOfInterestState';
import UserState from '../types/UserState';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGetMap = (): MapState => useAppSelector((state) => state.map);

export const useGetCoalitionsState = (): CoalitionState =>
  useAppSelector((state) => state.coalitions);
export const useGetMemberShipsState = (): CoalitionMemberDetailsDTO[] =>
  useAppSelector((state) => state.memberships.data.coalitions);
export const useGetChurchMemberships = (): MembershipsDTO =>
  useAppSelector((state) => state.memberships.data);

export const useGetCoalitionsLoaded = (): boolean =>
  useGetCoalitionsState().loaded;

export const useGetCoalitions = (): CoalitionDetailsDTO[] =>
  useGetCoalitionsState().data;

export const useGetCoalitionById = (id: string): CoalitionDetailsDTO => {
  return useGetCoalitions().find((coalition) => coalition._id === id);
};
export const useGetCoalitionAdmin = (id: string): boolean => {
  const { churches } = useGetChurchMemberships();
  let isAdmin = false;
  churches.forEach((church) => {
    if (church.church.coalition_id === id && church.role === 'rw')
      isAdmin = true;
  });
  return isAdmin;
};

export const useGetUserCoalitionsList = (): string[] => {
  const { churches } = useGetChurchMemberships();
  const coalitionList = [];
  churches.forEach((church) => {
    coalitionList.push(church.church.coalition_id);
  });
  return coalitionList;
};
export const useGetRelatedCoalitionObjs = () => {
  const { churches } = useGetChurchMemberships();
  const coalitionSet = new Set<string>();
  churches.forEach((church) => {
    coalitionSet.add(church.church.coalition_id);
  });
  const coalition_ids = Array.from(coalitionSet);
  return coalition_ids;
};

export const useGetDomainsState = (): ObjectDataState<DomainDetailsDTO> =>
  useAppSelector((state) => state.domains);

export const useGetDomainsLoaded = (): boolean => useGetDomainsState().loaded;

export const useGetDomains = (): DomainDetailsDTO[] => {
  const data = useGetDomainsState().data;
  return useDeepCompareMemo(() => Object.values(data), [data]);
};
export const useGetDomainById = (id: string): DomainDetailsDTO =>
  useGetDomainsState().data[id];

export const useGetChurchState = (): ObjectDataState<ChurchDetailsDTO> =>
  useAppSelector((state) => state.churches);

export const useChurchesLoaded = (): boolean => useGetChurchState().loaded;

export const useGetChurches = (): ChurchDetailsDTO[] => {
  const data = useGetChurchState().data;
  return useMemo(() => Object.values(data), [data]);
};

export const useGetChurchById = (id: string): ChurchDetailsDTO | undefined =>
  useGetChurchState().data[id];

export const useGetCurrentUser = (): UserState =>
  useAppSelector((state) => state.user);

export const useGetPointsOfInterestState = (): PointOfInterestState =>
  useAppSelector((state) => state.points_of_interest);

export const useGetPointsOfInterest = (): PointOfInterestData =>
  useAppSelector((state) => state.points_of_interest.data);

export const useGetProgramState = (): ArrayDataState<ProgramDetailsDTO> =>
  useAppSelector((state) => state.programs);

export const useGetProgramsLoaded = (): boolean => useGetProgramState().loaded;

export const useGetPrograms = (): ProgramDetailsDTO[] =>
  useGetProgramState().data;

export const useGetProgramById = (id: string): ProgramDetailsDTO =>
  useGetPrograms().find((program) => program.id === id);

export const useGetPostState = (): ObjectDataState<PostDetailsDTO> =>
  useAppSelector((state) => state.posts);

export const usePostsLoaded = (): boolean => useGetPostState().loaded;

export const useGetPosts = (): PostDetailsDTO[] => {
  const data = useGetPostState().data;
  return useMemo(() => Object.values(data), [data]);
};

export const useGetPostById = (id: string): PostDetailsDTO =>
  useGetPosts().find((post) => post._id === id);

export const useGetEventState = (): ArrayDataState<EventDetailsDTO> =>
  useAppSelector((state) => state.events);

export const useGetEvents = (): EventDetailsDTO[] => useGetEventState().data;

export const useGetEventById = (id: string): EventDetailsDTO =>
  useGetEvents().find((e) => e._id === id);

export const useEventsLoaded = (): boolean => useGetEventState().loaded;

export const useGetChurchStatisticTypeState =
  (): ArrayDataState<ChurchStatisticTypeDetailsDTO> =>
    useAppSelector((state) => state.church_statistic_types);

export const useGetChurchStatisticTypes = (): ChurchStatisticTypeDetailsDTO[] =>
  useGetChurchStatisticTypeState().data;

export const useChurchStatisticTypesLoaded = (): boolean =>
  useGetEventState().loaded;

export const useGetMembershipsState = (): {
  data: MembershipsDTO;
  loaded: boolean;
} => useAppSelector((state) => state.memberships);
