import React, { useEffect, useState } from 'react';

import apis from 'api';
import Page from '../components/Page';

const Admin: React.FC = () => {
  const [householdCount, setHouseholdCount] = useState(0);
  const [indexing, setIndexing] = useState(false);

  useEffect(() => {
    apis.churches.get_household_count().then((res) => setHouseholdCount(res));
  }, []);

  const onIndex = () => {
    setIndexing(true);
    apis.churches
      .ingest_test_data()
      .then(() => {
        setIndexing(false);
        apis.churches
          .get_household_count()
          .then((res) => setHouseholdCount(res));
      })
      .catch(() => setIndexing(false));
  };

  return (
    <Page>
      <div className="text-2xl font-bold">Admin Dashboard</div>
      <div className="text-lg">
        A platform overview for monitoring purposes.
      </div>
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4 mt-5">
        <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-orange-500 dark:text-orange-100 bg-orange-100 dark:bg-orange-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                Total households
              </p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {householdCount}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={onIndex}
        className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
      >
        {indexing ? 'Indexing...' : 'Index demographic data'}
      </div>
    </Page>
  );
};

export default Admin;
