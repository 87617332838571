import React from 'react';
import styled from 'styled-components';
import {
  useChurchesLoaded,
  useChurchStatisticTypesLoaded,
  useEventsLoaded,
  useGetCoalitionsLoaded,
  useGetDomainsLoaded,
  usePostsLoaded,
} from '../store/hooks';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 270px;
  padding-right: 10px;
  padding-top: 10px;
`;

export const PAGE_HEIGHT = 'calc(100vh - 32px)';

const StyledPage = styled(Container)<{ height: string }>`
  min-height: ${(props) => props.height};
`;

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const coalitionsLoaded = useGetCoalitionsLoaded();
  const postsLoaded = usePostsLoaded();
  const domainsLoaded = useGetDomainsLoaded();
  const churchesLoaded = useChurchesLoaded();
  const eventsLoaded = useEventsLoaded();
  const churchStatisticTypesLoaded = useChurchStatisticTypesLoaded();

  if (
    (!coalitionsLoaded ||
      !postsLoaded ||
      !domainsLoaded ||
      !churchesLoaded ||
      !eventsLoaded ||
      !churchStatisticTypesLoaded) &&
    location.pathname !== '/'
  )
    return <StyledPage height={PAGE_HEIGHT} />;

  return (
    <StyledPage height={PAGE_HEIGHT} {...props}>
      {children}
    </StyledPage>
  );
};

export default Page;
