import * as St from 'components/Style.styled';
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { FilterIcon } from './Map/Icons';

const MenuItem = styled(St.Div)`
  width: 100%;
  padding: 0.5rem 0; /* this corresponds to 'py-2' in Tailwind */
  font-size: 0.875rem; /* this corresponds to 'text-sm' in Tailwind */
  color: #a6a6a6; /* default color */
  background-color: transparent; /* default background */

  &:hover {
    color: gray; /* color on hover */
    background-color: #f7f7f7; /* background on hover */
    cursor: pointer;
  }

  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const DisabledMenuItem = styled(St.Div)`
  width: 100%;
  padding: 0.5rem 0; /* this corresponds to 'py-2' in Tailwind */
  font-size: 0.875rem; /* this corresponds to 'text-sm' in Tailwind */
  color: #a6a6a6; /* default color */
  background-color: transparent; /* default background */
  cursor: pointer;

  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export interface DropdownItem {
  id: any;
  name: string;
  checked: boolean;
}

interface Props {
  items: DropdownItem[];
  onClickItem: (i: DropdownItem) => void;
  onClick?: (open: boolean) => void;
  parentSetOpen?: Dispatch<SetStateAction<boolean>>;
  width: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

const MenuButton = styled(St.Div)<{ open: boolean }>`
  border-radius: ${(props) => (props.open ? '8px 8px 0px 0px' : '8px')};
  display: flex;
  ${St.textInputStyles};
  z-index: -10;
  background-color: white;

  &:hover {
    cursor: pointer;
  }
`;

const MenuItems = styled(St.Column)<{ width: string; isOpen: boolean }>`
  position: absolute;
  top: 100%;
  background: white;
  justify-content: flex-start;
  min-width: ${({ width }) => width};
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
  border: 1px solid #e5e7eb;
  border-radius: 0 0 4px 4px;
  z-index: 100;
  transition: all 0.1s ease-out;
  ${(props) =>
    props.isOpen
      ? css`
          animation: ${scaleUp} 0.1s ease-out forwards;
        `
      : css`
          animation: ${scaleDown} 0.075s ease-in forwards;
        `}
`;

const scaleUp = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleDown = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
`;

const CheckboxDropdown: React.FC<Props> = ({
  items,
  onClick,
  onClickItem,
  parentSetOpen,
  width,
  children,
  style = {},
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggleOpen = () => {
    setOpen(!open);
    parentSetOpen && parentSetOpen(!open);
  };
  const onOutsideClick = () => {
    if (open) toggleOpen();
  };
  useOnClickOutside(ref, onOutsideClick);

  const onClickItemWrapper = (e, item) => {
    e.preventDefault();
    onClickItem(item);
  };

  return (
    <div ref={ref} style={{ ...style, width, position: 'relative' }}>
      <MenuButton
        open={open}
        onClick={() => {
          toggleOpen();
          onClick && onClick(!open);
        }}
      >
        <St.Row justifyContent="space-between" width="100%">
          <St.Text
            color="#A6A6A6"
            fontWeight="500"
            fontSize="12px"
            ml="5px"
            textAlign="left"
          >
            {children}
          </St.Text>
          <St.Icon src={FilterIcon} />
        </St.Row>
      </MenuButton>
      {open && (
        <MenuItems isOpen={open} width={width} className="styled-scrollbar">
          {items.map((item) =>
            !item.id ? (
              <DisabledMenuItem key={item.id}>
                <St.Row justifyContent="flex-start" flexWrap="nowrap" px="5px">
                  <St.Div width="15px" />
                  <St.Text textAlign="start" fontStyle={'italic'}>
                    {item.name}
                  </St.Text>
                </St.Row>
              </DisabledMenuItem>
            ) : (
              <MenuItem
                key={item.id}
                onClick={(e) => onClickItemWrapper(e, item)}
              >
                <St.Row justifyContent="flex-start" flexWrap="nowrap" px="5px">
                  {item.checked ? (
                    <St.Icon width="10px" mr="5px" src="/images/check.svg" />
                  ) : (
                    <St.Div width="15px" />
                  )}
                  <St.Text textAlign="start">{item.name}</St.Text>
                </St.Row>
              </MenuItem>
            ),
          )}
        </MenuItems>
      )}
    </div>
  );
};

export default CheckboxDropdown;
