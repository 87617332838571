import {
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
  SECONDS_PER_YEAR,
} from '../config/constants';

export const removeTimeFromDate = (isoString: string) =>
  isoString.split(/[T ]/i, 1)[0];

export const formatDuration = (
  duration: number,
  withSeconds?: boolean,
  showMinimum?: boolean,
): string => {
  let remaining = duration;
  const years = Math.floor(remaining / SECONDS_PER_YEAR);
  remaining -= years * SECONDS_PER_DAY;
  const days = Math.floor(remaining / SECONDS_PER_DAY);
  remaining -= days * SECONDS_PER_DAY;
  const hours = Math.floor(remaining / SECONDS_PER_HOUR);
  remaining -= hours * SECONDS_PER_HOUR;
  const minutes = Math.floor(remaining / SECONDS_PER_MINUTE);
  if (duration < SECONDS_PER_MINUTE) {
    return `${duration}s`;
  }

  if (showMinimum) {
    if (years > 10) return '10+ years';
    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'}`;
    }
    if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    }
    if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
    if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
  }

  if (withSeconds) {
    remaining -= minutes * SECONDS_PER_MINUTE;
    return `${days}d ${hours}h ${minutes}m ${remaining}s`;
  }

  return `${days}d ${hours}h ${minutes}m`;
};
