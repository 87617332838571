import React, { useContext } from 'react';
import styled from 'styled-components';
import * as St from '../../../components/Style.styled';
import { ToastContext } from '../../../contexts/ToastContext';
import DomainDetailsDTO from '../../../dtos/DomainDetailsDTO';
import { useJoinDomain } from '../../../hooks/updateHooks';
import { useGetCurrentUser, useGetDomains } from '../../../store/hooks';
import DomainMemberRole from '../../../types/DomainMemberRole';
import UserConfigStep from '../../../types/UserConfigStep';
import { StepProps } from './index';

const Domains = styled(St.Column)`
  background-color: var(--color-light-grey);
  border-radius: 4px;
  padding: 25px 16px;
  height: 300px;
  gap: 10px;
  overflow-y: scroll;
  justify-content: flex-start;
`;

const DomainCard: React.FC<{ domain: DomainDetailsDTO }> = ({ domain }) => {
  const { onJoinDomain } = useJoinDomain();
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();

  const handleJoinDomain = async () => {
    await onJoinDomain(domain._id, 'unknown', currentUser._id);
    show('joined-domain-success');
  };

  const renderRequestButton = () => {
    const membership = currentUser.domain_memberships.find(
      (m) => m.domain_id === domain._id,
    );

    const buttonDisabledText =
      membership?.level === DomainMemberRole.PENDING_EXPERT
        ? 'Requested'
        : 'Joined';

    return (
      <St.PrimaryButton
        type="button"
        disabled={!!membership}
        onClick={handleJoinDomain}
      >
        {!membership ? 'Join' : buttonDisabledText}
      </St.PrimaryButton>
    );
  };

  return (
    <St.Row
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      width="100%"
    >
      <St.Text>{domain.name}</St.Text>
      {renderRequestButton()}
    </St.Row>
  );
};

const RequestPartnerDomains: React.FC<StepProps> = ({ setStep }) => {
  const domains = useGetDomains();
  return (
    <St.Column height="445px">
      <St.Text
        fontWeight="800"
        fontSize="20px"
        textAlign="center"
        mb="20px"
        mx="25px"
      >
        Join a domain to stay involved in it's activities.
      </St.Text>
      <Domains>
        {domains.map((domain) => (
          <DomainCard key={domain.domainId} domain={domain} />
        ))}
      </Domains>
      <St.PrimaryButton
        type="button"
        onClick={() => setStep(UserConfigStep.JoinCoalitions)}
        mt="auto"
      >
        Continue
      </St.PrimaryButton>
    </St.Column>
  );
};

export default RequestPartnerDomains;
