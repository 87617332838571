import eventManager from 'utils/eventManager';
import { MapFilters } from '../../dtos/DemographicFiltersDTO';
import DomainId from '../../types/DomainId';
import { DemographicType, UpdateFilterDomainsDTO } from './config/types';

export const updateFilterDemographic = (newState: DemographicType) => {
  eventManager.emit('filterDemographic:update', newState);
};

export const updateFilteredDomainWithDomains = (
  data: UpdateFilterDomainsDTO,
) => {
  eventManager.emit('filteredDomainsWithDomains:update', data);
};

export const updateFilterDomains = (domainIds: DomainId[]) => {
  eventManager.emit('filteredDomains:update', domainIds);
};

export const updateMapFilters = (mapFilters: MapFilters) => {
  eventManager.emit('mapFilters:update', mapFilters);
};
