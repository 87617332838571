import React from 'react';
import Page from './Page';

const NotAuthorized: React.FC = () => {
  return (
    <Page>
      <div className="text-2xl font-bold">Not Authorized</div>
      <div className="text-lg">
        You are not authorized to access this resource.
      </div>
    </Page>
  );
};

export default NotAuthorized;
