import {
  ClockIconDark,
  FamilyIconDark,
  FoodIconDark,
  HomesIconDark,
  MoneyIconDark,
} from './icons';

const assetTypeIcons = {
  ['Volunteer Hours']: ClockIconDark,
  ['Families Served']: FamilyIconDark,
  ['Homes Restored']: HomesIconDark,
  ['Value Invested in The Community']: MoneyIconDark,
  ['Pounds of Food Distributed']: FoodIconDark,
};

export default assetTypeIcons;
