import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistCombineReducers,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';

import thunkMiddleware from 'redux-thunk';
import churchesReducer from 'store/churches';
import coalitionsReducer from 'store/coalitions';
import domainsReducer from 'store/domains';
import eventsReducer from 'store/events';
import mapReducer from 'store/map';
import pointsOfInterestReducer from 'store/pointsOfInterest';
import postsReducer from 'store/posts';
import churchStatisticTypesReducer from 'store/churchStatisticTypes';
import programsReducer from 'store/programs';
import userReducer from 'store/user';
import membershipsReducer from 'store/memberships';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  user: userReducer,
  map: mapReducer,
  coalitions: coalitionsReducer,
  domains: domainsReducer,
  churches: churchesReducer,
  points_of_interest: pointsOfInterestReducer,
  programs: programsReducer,
  posts: postsReducer,
  church_statistic_types: churchStatisticTypesReducer,
  events: eventsReducer,
  memberships: membershipsReducer,
});

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunkMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
