import { now } from 'lodash';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import EventDetailsDTO from '../dtos/EventDetailsDTO';
import PostDetailsDTO from '../dtos/PostDetailsDTO';
import EventFilter from '../types/EventFilter';

export const filterChurchByCoalition = (
  church: ChurchDetailsDTO,
  selectedCoalitions: string[],
) => {
  return selectedCoalitions.length > 0
    ? selectedCoalitions.includes(church.coalition_id)
    : true;
};

export const filterChurchByDomains = (
  church: ChurchDetailsDTO,
  domainIds: string[],
): boolean => {
  return true;
  // TODO :: Redo
  return domainIds.length > 0
    ? domainIds.some((id) => church.coalitions.map((c) => c._id).includes(id))
    : true;
};

export const filterPostByCoalition = (
  post: PostDetailsDTO,
  selectedCoalitions: string[],
) => {
  return selectedCoalitions.length > 0
    ? selectedCoalitions.includes(post.coalition_id)
    : true;
};

export const filterPostByDomains = (
  post: PostDetailsDTO,
  domainIds: string[],
) => {
  return domainIds.length > 0
    ? domainIds.some((domainId) => post.domain_id === domainId)
    : true;
};

export const filterEventByDate = (
  event: EventDetailsDTO,
  filter: EventFilter,
) => {
  const unixNow = now();
  const date = new Date(event.scheduled).getDate();
  switch (filter) {
    case EventFilter.UPCOMING:
      return date >= unixNow;
    case EventFilter.PREVIOUS:
      return date < unixNow;
    case EventFilter.ALL:
      return true;
  }
};

export const filterEventByDomain = (
  event: EventDetailsDTO,
  domainIds: string[],
) => {
  return domainIds.length > 0 ? domainIds.includes(event.domain_id) : true;
};

export const filterEventByCoalition = (
  event: EventDetailsDTO,
  coalitionIds: string[],
) => {
  return coalitionIds.length > 0
    ? coalitionIds.includes(event.coalition_id)
    : true;
};
