import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatUrl } from 'utils/urlUtils';
import * as St from '../../components/Style.styled';
import { TrashRedIcon } from '../../config/icons';

import EditButtons from 'components/EditButtons';
import { ToastContext } from '../../contexts/ToastContext';
import CoalitionDetailsDTO from '../../dtos/CoalitionDetailsDTO';
import CoalitionKeyInitiativeDetailsDTO from '../../dtos/CoalitionKeyInitiativeDetailsDTO';
import {
  UpdateKeyInitiative,
  addKeyInitiative,
  removeCoalitionKeyInitiatives,
} from '../../store/helpers';
import {
  useAppDispatch,
  useGetCoalitionAdmin,
  useGetCoalitionById,
  useGetCurrentUser,
} from '../../store/hooks';
import apis from 'api';

const InitiativesInput = styled.input`
  ${St.textInputStyles}
  width: 100%;
  height: 22px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

interface Props {
  coalition: CoalitionDetailsDTO;
}

const CoalitionKeyInitiatives: React.FC<Props> = ({ coalition: c }) => {
  const dispatch = useAppDispatch();
  const coalition = useGetCoalitionById(c._id);
  const currentUser = useGetCurrentUser();
  const isCoalitionAdmin = useGetCoalitionAdmin(coalition._id);

  const [keyInitiatives, setKeyInitiatives] = useState<
    CoalitionKeyInitiativeDetailsDTO[]
  >([]);
  const [addingInitiative, setAddingInitiative] = useState(false);
  const [editId, setEditId] = useState('');
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const modifying = addingInitiative || editId;
  const { show } = useContext(ToastContext);
  const cancelInitiatives = () => {
    setAddingInitiative(false);
    setEditId('');
  };

  const refreshKeyInitiatives = () => {
    apis.coalitions.get_key_initiatives(c._id).then((initiatives) => {
      setKeyInitiatives(initiatives);
    });
  };

  useEffect(() => {
    refreshKeyInitiatives();
    return;
  }, []);

  const onClickTrash = (id: string) => {
    setEditId('');
    removeCoalitionKeyInitiatives(dispatch, coalition._id, id, show);
    refreshKeyInitiatives();
  };

  function onClickAdd() {
    setEditId('');
    setAddingInitiative(!addingInitiative);
    refreshKeyInitiatives();
  }

  const submit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (addingInitiative) {
      await addInitiative(e);
    } else if (editId) {
      await editInitiative(e);
    }
  };

  const addInitiative = async (e: any) => {
    const form = e.currentTarget;
    addKeyInitiative(
      dispatch,
      {
        coalition_id: coalition._id,
        user_id: currentUser._id,
        initiative: form.add_initiative_name.value,
        links: [form.add_initiative_link.value],
      },
      show,
    );
    setAddingInitiative(false);
    refreshKeyInitiatives();
  };

  const editInitiative = async (e: any) => {
    const form = e.currentTarget;
    UpdateKeyInitiative(
      dispatch,
      editId,
      {
        initiative: form.edit_initiative_name.value,
        links: [form.edit_initiative_link.value],
        _id: editId,
        user_id: currentUser._id,
        coalition_id: coalition._id,
      },
      show,
    );
    const updatedInitiatives = keyInitiatives.map((initiative) => {
      if (initiative._id === editId) {
        return {
          ...initiative,
          initiative: form.edit_initiative_name.value,
          links: [form.edit_initiative_link.value],
        };
      }
      return initiative;
    });
    setKeyInitiatives(updatedInitiatives);
    setEditId('');
    refreshKeyInitiatives();
  };

  const submitButtonText = () => {
    if (addingInitiative) return 'Add';
    return 'Save';
  };

  const onMouseEnterInitiative = (initiative) => {
    if (currentUser._id === initiative.user_id) {
      setIsHovered((prev) => ({ ...prev, [initiative._id]: true }));
    } else {
      return;
    }
  };

  const onMouseLeaveInitiative = (initiative) => {
    if (currentUser._id === initiative.user_id) {
      setIsHovered((prev) => ({ ...prev, [initiative._id]: false }));
    } else {
      return;
    }
  };

  const onEditInitiative = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setEditId(id);
    setAddingInitiative(false);
    setIsHovered({}); // reset hover state
  };

  const renderInitiative = (initiative: CoalitionKeyInitiativeDetailsDTO) => {
    const hasLink = initiative.links.filter((l) => l)?.length > 0;

    if (editId === initiative._id) {
      return (
        <St.Row gap="10px" mt="10px">
          <St.Icon src="/images/check.svg" size="14px" />
          <InitiativesInput
            type="text"
            name="edit_initiative_name"
            required
            placeholder="New title"
            defaultValue={initiative.initiative}
          />
          <InitiativesInput
            type="url"
            name="edit_initiative_link"
            placeholder="Link (optional)"
            defaultValue={hasLink ? initiative.links[0] : ''}
          />
          <St.AnimatedIcon
            src={TrashRedIcon}
            onClick={() => onClickTrash(initiative._id)}
            size="16px"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            mr="10px"
          />
        </St.Row>
      );
    }

    const content = (
      <St.Row
        key={initiative._id}
        justifyContent="start"
        mt="10px"
        gap="10px"
        minWidth="50%"
        onMouseEnter={() => onMouseEnterInitiative(initiative)}
        onMouseLeave={() => onMouseLeaveInitiative(initiative._id)}
      >
        <St.Icon src="/images/check.svg" size="14px" />
        {initiative.initiative}
        {isHovered[initiative._id] && (
          <St.AnimatedIcon
            src="/images/edit.svg"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'tween', duration: 0.1 }}
            size="14px"
            ml="auto"
            mr="10px"
            onClick={(e) => onEditInitiative(e, initiative._id)}
          />
        )}
      </St.Row>
    );

    return hasLink ? (
      <St.Row justifyContent={'flex-start'} gap={2} alignItems={'center'}>
        <St.Link target="_blank" to={formatUrl(initiative.links[0])}>
          {content}
        </St.Link>
      </St.Row>
    ) : (
      content
    );
  };

  return (
    <Form onSubmit={submit}>
      <St.Row justifyContent="space-between" width="100%">
        <St.Text
          fontWeight="800"
          fontSize="16px"
          color="var(--color-dark-grey)"
        >
          {coalition?.name}
        </St.Text>
        {isCoalitionAdmin && (
          <EditButtons onClickEdit={null} onClickAdd={onClickAdd} />
        )}
      </St.Row>
      <St.Row justifyContent="start" width="100%" flexWrap="wrap">
        {keyInitiatives.length > 0 || modifying
          ? keyInitiatives.map((initiative) => (
              <St.Div key={initiative._id} width={modifying ? '100%' : '50%'}>
                {renderInitiative(initiative)}
              </St.Div>
            ))
          : 'No key initiatives'}
        {addingInitiative && (
          <St.Row minWidth="100%" mt="10px" gap="10px">
            <St.AnimatedIcon src="/images/check.svg" size="14px" />
            <InitiativesInput
              type="text"
              name="add_initiative_name"
              required
              placeholder="New initiative"
            />
            <InitiativesInput
              type="url"
              name="add_initiative_link"
              placeholder="Link (optional)"
            />
          </St.Row>
        )}
      </St.Row>
      {modifying && (
        <St.Row mt="10px" justifyContent="flex-start">
          <St.TertiaryButton onClick={cancelInitiatives} mr="10px">
            Cancel
          </St.TertiaryButton>
          <St.PrimaryButton type="submit">
            {submitButtonText()}
          </St.PrimaryButton>
        </St.Row>
      )}
    </Form>
  );
};

export default CoalitionKeyInitiatives;
