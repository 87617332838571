import * as St from 'components/Style.styled';
import { groupBy } from 'lodash';
import React from 'react';
import { createRoot } from 'react-dom/client';
import apis from '../api';
import Expandable from '../components/Expandable';
import {
  getMarkerSize,
  incomeIcons,
  pointOfInterestTypeLabel,
} from '../components/Map/config/config';
import domainPointsOfInterest from '../components/Map/config/domainPointsOfInterest';
import {
  ChurchMarkerGreyIcon,
  ChurchMarkerIcon,
  GymMarkerIcon,
  HealthMarkerIcon,
  PoiMarkerIcon,
  SchoolMarkerIcon,
} from '../components/Map/Icons';
import CommunityOverlayWindow from '../components/Map/OverlayWindow/CommunityOverlayWindow';
import Tooltip from '../components/Tooltip';
import { LoadingRed, QuestionMarkRed } from '../config/icons';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import ChurchRelatedDomainDTO, {
  ChurchRelatedDomainType,
} from '../dtos/ChurchRelatedDomainDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import CommunityDemographicsDTO from '../dtos/CommunityDemographicsDTO';
import DomainDetailsDTO from '../dtos/DomainDetailsDTO';
import MapMarkerDTO from '../dtos/MapMarkerDTO';
import PointOfInterestDetailsDTO, {
  PointOfInterestType,
} from '../dtos/PointOfInterestDetailsDTO';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';
import DomainId from '../types/DomainId';
import { filterChurchByCoalition, filterChurchByDomains } from './filterUtils';

export const clearMarkers = (markers: google.maps.Marker[]) => {
  if (Array.isArray(markers) && markers.length > 0) {
    markers.forEach((marker, index) => {
      if (marker) {
        marker.setMap(null);
        markers[index] = null;
      }
    });
  }
};
export const getFilteredChurches = (
  churches: ChurchDetailsDTO[],
  selectedCoalitions: CoalitionDetailsDTO[],
  selectedDomainIds: string[],
): ChurchDetailsDTO[] => {
  return churches.filter(
    (church) =>
      filterChurchByCoalition(
        church,
        selectedCoalitions.map((a) => a.id),
      ) && filterChurchByDomains(church, selectedDomainIds),
  );
};

export const programDomainFilter = (
  program: ProgramDetailsDTO,
  filteredDomainIds: string[],
): boolean => {
  if (!program.domain_id) return false;
  return filteredDomainIds.length > 0
    ? filteredDomainIds.includes(program.domain_id)
    : true;
};

export const programCoalitionFilter = (
  program: ProgramDetailsDTO,
  selectedCoalitions: CoalitionDetailsDTO[],
) =>
  selectedCoalitions.length > 0
    ? selectedCoalitions.some(
        (c) => c.church_ids && c.church_ids.includes(program.church_id),
      )
    : true;

export const newPointOfInterestMarker = (
  poi: PointOfInterestDetailsDTO,
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
): google.maps.Marker => {
  let icon;
  if (poi.types.includes(PointOfInterestType.CHURCH)) {
    if (poi?.iconColor === 'red') {
      icon = ChurchMarkerIcon;
    } else {
      icon = ChurchMarkerGreyIcon;
    }
  } else if (poi.types.includes(PointOfInterestType.GYM)) {
    icon = GymMarkerIcon;
  } else if (
    poi.types.some((type) =>
      domainPointsOfInterest[DomainId.Health].includes(type),
    )
  ) {
    icon = HealthMarkerIcon;
  } else if (
    poi.types.some((type) =>
      domainPointsOfInterest[DomainId.Education].includes(type),
    )
  ) {
    icon = SchoolMarkerIcon;
  } else {
    icon = { url: PoiMarkerIcon, scaledSize: new google.maps.Size(25, 25) };
  }

  const marker = new google.maps.Marker({
    position: { lat: poi.latitude, lng: poi.longitude },
    zIndex: 0,
    icon,
    map,
  });
  marker['id'] = poi.id;
  marker.addListener('click', () => {
    infoWindow.setContent(getPoiInfoWindowContentString(poi));
    infoWindow.open({
      anchor: marker,
      map,
    });
  });
  return marker;
};

export const newCommunityMarker = (
  dto: MapMarkerDTO,
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
) => {
  // const markerSize = getMarkerSize(dto.size);
  const markerSize = dto.size || 40;
  const icon = {
    url: incomeIcons[dto.income],
    scaledSize: new google.maps.Size(markerSize, markerSize), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(markerSize / 2, 0), // anchor
  };

  const marker = new google.maps.Marker({
    position: dto.position,
    label: dto.text,
    icon,
    map,
  });

  const bounds = dto.max_x
    ? { east: dto.max_x, west: dto.min_x, south: dto.min_y, north: dto.max_y }
    : map?.getBounds().toJSON();

  marker['id'] = dto.id;
  marker.addListener('click', () => {
    infoWindow.setContent(
      getCommunityInfoWindowContentString({
        ...dto,
        bounds,
      }),
    );
    infoWindow.open({
      anchor: marker,
      map,
    });
  });

  return marker;
};

export const newProgramMarker = (
  program: ProgramDetailsDTO,
  onGetDomainById: (DomainId) => DomainDetailsDTO,
  onGetChurchById: (string) => ChurchDetailsDTO,
  isChurchMemberOf: (string) => boolean,
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
) => {
  const marker = new google.maps.Marker({
    position: {
      lng: program.longitude,
      lat: program.latitude,
    },
    icon: { url: PoiMarkerIcon, scaledSize: new google.maps.Size(25, 25) },
    map,
  });
  marker['id'] = program.id;
  marker.addListener('click', () => {
    infoWindow.setContent(
      getProgramInfoWindowContentString(program, onGetDomainById),
    );

    infoWindow.open({
      anchor: marker,
      map,
    });
  });
  return marker;
};

export const newChurchMarker = (
  church: ChurchDetailsDTO,
  isChurchMember: boolean,
  onGetDomainById: (DomainId) => DomainDetailsDTO,
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
) => {
  const marker = new google.maps.Marker({
    position: { lat: church.latitude, lng: church.longitude },
    icon: church.iconColor === 'grey' ? ChurchMarkerGreyIcon : ChurchMarkerIcon,
    map,
  });
  marker['id'] = church._id;
  marker.addListener('mouseover', () => {
    infoWindow.setContent(
      getChurchInfoWindowContentString(church, isChurchMember, onGetDomainById),
    );
    infoWindow.open({
      anchor: marker,
      map,
    });
  });
  return marker;
};

export const getCommunityInfoWindowContentString = (dto: MapMarkerDTO) => {
  const query = { community_bounds: dto.bounds };
  apis.map
    .get_community_demographics(query)
    .then((res) => renderCommunityDemographics(dto, res));

  return `
  <div id="info-window-loaded-content" class="map-info-window">
  <p class="map-info-window-header">Community in Houston</p>
  <p class="map-info-window-subheader">${
    dto.size === 0 ? `1 household (${dto.id})` : `${dto.text || ''} households`
  }</p>
  <div class="separater"></div>
  <div id="info-window-loaded-content">
  <div ><img src="${LoadingRed}" style="width: 100px; margin: auto"/></div>
  </div>
  </div>
  `;
};

export const getPoiInfoWindowContentString = (
  poi: PointOfInterestDetailsDTO,
) => {
  return `
  <div class="map-info-window">
  <p class="map-info-window-header">${poi.name}</p>
  <p class="map-info-window-subheader">${
    pointOfInterestTypeLabel[poi.types[0]]
  } in Houston</p>
  <div class="separater"></div>
  <div id="info-window-loaded-content">
  <p style="margin-top: 10px">No additional data</p>
  </div>
  </div>
  `;
};

export const getProgramInfoWindowContentString = (
  dto: ProgramDetailsDTO,
  onGetDomainById: (DomainId) => DomainDetailsDTO,
) => {
  return `
  <div class="map-info-window">
  <p class="map-info-window-header">${dto.description}</p>
  <p class="map-info-window-subheader">Program in Houston</p>
  <div class="separater"></div>
  <div id="info-window-loaded-content">
  <p>Domain: ${onGetDomainById(dto.domain_id)?.name}</p>
  <p>Date: ${dto.date}</p>
  </div>
  </div>
  `;
};

export const getChurchInfoWindowContentString = (
  church: ChurchDetailsDTO,
  isChurchMember: boolean,
  onGetDomainById: (DomainId) => DomainDetailsDTO,
) => {
  apis.churches
    .get_related_domains(church._id)
    .then((res) => renderChurchRelatedDomains(res, onGetDomainById))
    .catch(() => renderChurchRelatedDomains([], onGetDomainById));

  return `
  <div class="map-info-window">
  <p class="map-info-window-header">${church.name}</p>
  <p class="map-info-window-subheader">Church in Houston - <i>${
    isChurchMember
      ? 'You are a member of this church'
      : 'You are not a member of this church'
  }</i></p>
  <div class="separater"> </div>
  <div id="info-window-loaded-content"><img src="${LoadingRed}" style="width: 100px; margin: auto"/></div>
  </div>
  `;
};

const renderCommunityDemographics = (
  community: MapMarkerDTO,
  demographics: CommunityDemographicsDTO,
) => {
  if (!demographics) return;
  const infoWindowContentDiv = document.getElementById(
    'info-window-loaded-content',
  );
  createRoot(infoWindowContentDiv).render(
    <CommunityOverlayWindow
      community={community}
      demographics={demographics}
    />,
  );
};

const renderChurchRelatedDomains = (
  relatedDomains: ChurchRelatedDomainDTO[],
  onGetDomainById: (DomainId) => DomainDetailsDTO,
) => {
  if (!relatedDomains) return;

  let content;
  if (relatedDomains.length === 0) {
    content = (
      <>
        <St.Text mt="5px" fontWeight={600} fontSize="16px">
          Related domains
        </St.Text>
        <St.RelatedDomainContainer>
          <St.Text fontWeight={800}>This church has no related domains</St.Text>
        </St.RelatedDomainContainer>
      </>
    );
  } else {
    const groupedRelatedDomains: Partial<
      Record<DomainId, ChurchRelatedDomainDTO[]>
    > = groupBy(relatedDomains, (relatedDomain) => relatedDomain._id);
    const groupDomainIds = Object.keys(groupedRelatedDomains).map((_id) => _id);
    content = (
      <>
        <St.Text mt="5px" fontWeight={600} fontSize="16px">
          Related domains
        </St.Text>
        <St.Column width="100%">
          {groupDomainIds.map((domainId) => {
            const domain = onGetDomainById(domainId);
            const relatedTargets = groupedRelatedDomains[domainId];
            const relatedPrograms = relatedTargets.filter(
              (a) => a.target_type === ChurchRelatedDomainType.PROGRAM,
            );
            const relatedStatistics = relatedTargets.filter(
              (a) => a.target_type === ChurchRelatedDomainType.CHURCH_STATISTIC,
            );
            return (
              <St.RelatedDomainContainer>
                <Expandable
                  expandedComponent={
                    <St.Column width="100%" alignItems="flex-start">
                      {relatedStatistics.length > 0 &&
                        relatedStatistics.map((stat) => (
                          <St.Text key={stat.id}>- {stat.target_name}</St.Text>
                        ))}
                      {relatedPrograms.length > 0 &&
                        relatedPrograms.map((program) => (
                          <St.Row
                            width="100%"
                            justifyContent="space-between"
                            key={program.id}
                          >
                            <St.Text mt="5px">- {program.target_name}</St.Text>
                            <Tooltip
                              src={QuestionMarkRed}
                              // ml="auto"
                              mr="5px"
                              text={program.target_additional_details}
                              anchorTopRight
                            />
                          </St.Row>
                        ))}
                    </St.Column>
                  }
                >
                  <St.Row>
                    <St.Text fontWeight={800}>{domain?.name} - </St.Text>
                    <St.Text fontStyle="italic">
                      {relatedPrograms.length > 0 && (
                        <St.Text>
                          {relatedPrograms.length} program
                          {relatedPrograms.length > 1 && 's'} in domain
                        </St.Text>
                      )}
                    </St.Text>

                    {relatedPrograms.length > 0 &&
                      relatedStatistics.length > 0 && <St.Text>, </St.Text>}
                    <St.Text fontStyle="italic">
                      {relatedStatistics.length > 0 && (
                        <St.Text>
                          {relatedStatistics.length} statistic
                          {relatedStatistics.length > 1 && 's'} in domain
                        </St.Text>
                      )}
                    </St.Text>
                  </St.Row>
                </Expandable>
              </St.RelatedDomainContainer>
            );
          })}
        </St.Column>
      </>
    );
  }
  const infoWindowContentDiv = document.getElementById(
    'info-window-loaded-content',
  );
  createRoot(infoWindowContentDiv).render(content);
};
