import { Dispatch, SetStateAction } from 'react';
import { DropdownItem } from '../components/CheckboxDropdown';

export const dropdownSelectOneNoNull = (
  item: DropdownItem,
  state: [DropdownItem[], Dispatch<SetStateAction<DropdownItem[]>>],
) => {
  const [filters, setFilter] = state;
  setFilter(
    filters.map((filter) => {
      if (filter.id === item.id) {
        return {
          ...filter,
          checked: true,
        };
      }
      filter.checked = false;
      return filter;
    }),
  );
};

export const dropdownSelectOne = (
  item: DropdownItem,
  state: [DropdownItem[], Dispatch<SetStateAction<DropdownItem[]>>],
) => {
  const [filters, setFilter] = state;
  setFilter(
    filters.map((filter) => {
      if (filter.id === item.id) {
        return {
          ...filter,
          checked: !filter.checked,
        };
      }
      filter.checked = false;
      return filter;
    }),
  );
};

export const dropdownSelectMany = (
  item: DropdownItem,
  state: [DropdownItem[], Dispatch<SetStateAction<DropdownItem[]>>],
) => {
  const [filters, setFilter] = state;
  setFilter(
    filters.map((filter) => {
      if (filter.id === item.id) {
        return {
          ...filter,
          checked: !filter.checked,
        };
      }
      return filter;
    }),
  );
};

export const dropdownGetSelected = (
  state: [DropdownItem[], Dispatch<SetStateAction<DropdownItem[]>>],
) => state[0].filter((i) => i.checked);
