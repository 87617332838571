import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DomainDetailsDTO from 'dtos/DomainDetailsDTO';
import { defaultObjectDataState } from '../types/ObjectDataState';

export const domainSlice = createSlice({
  name: 'domains',
  initialState: defaultObjectDataState<DomainDetailsDTO>(),
  reducers: {
    set_domains: (state, action: PayloadAction<DomainDetailsDTO[]>) => {
      state.data = action.payload.reduce((acc, domain) => {
        acc[domain._id] = domain;
        return acc;
      }, {});
      state.loaded = true;
    },
    set_domain: (state, action: PayloadAction<DomainDetailsDTO>) => {
      state.data[action.payload._id] = action.payload;
    },
  },
});

export const { set_domains, set_domain } = domainSlice.actions;
export default domainSlice.reducer;
