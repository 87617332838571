import React from 'react';
import styled from 'styled-components';

const HighlightContainer = styled.div`
  min-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 16px;
`;

const HighlightIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
`;

const HighlightValue = styled.p`
  font-weight: 800;
  font-size: 24px;
`;

const HighlightName = styled.div`
  font-size: 14px;
  line-height: 19px;
  width: 110px;
`;

interface StatisticHighlightProps {
  name: string;
  icon: string;
  value: string;
}

const StatisticHighlight: React.FC<StatisticHighlightProps> = ({
  name,
  icon,
  value,
}) => {
  return (
    <HighlightContainer>
      <HighlightIcon src={icon} />
      <HighlightValue>{value}</HighlightValue>
      <HighlightName>{name}</HighlightName>
    </HighlightContainer>
  );
};

export default StatisticHighlight;
