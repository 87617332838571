import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apis from '../api';
import Modal from '../components/Modal';
import * as St from '../components/Style.styled';
import { ModalContext } from '../contexts/ModalContext';
import ChurchMemberDetailsDTO from '../dtos/ChurchMemberDetailsDTO';
import { ChurchProfileParams } from './ChurchProfile';

const ChurchMembersModal: React.FC = () => {
  const { id } = useParams<ChurchProfileParams>();
  const navigate = useNavigate();
  const { churchMembersOpen, closeChurchMembers } =
    React.useContext(ModalContext);
  const [churchMembers, setChurchMembers] = useState<ChurchMemberDetailsDTO[]>(
    [],
  );

  useEffect(() => {
    apis.churches.get_members(id).then((res) => {
      res && setChurchMembers(res);
    });
  }, [id]);

  const role = (cm: ChurchMemberDetailsDTO): string => {
    if (cm.role === 'rw') return 'Admin';
    return 'Member';
  };

  return (
    <Modal
      height="450px"
      isOpen={churchMembersOpen}
      requestClose={closeChurchMembers}
    >
      <St.Column maxHeight="275px" width="80%">
        <St.Text fontWeight="800" fontSize="16px" mb="10px">
          Church Members
        </St.Text>
        <St.Column alignItems="flex-start" overflowY="auto" width="100%">
          {churchMembers.map((cm, i) => (
            <St.Card
              key={`member-${i}`}
              mt="20px"
              gap="10px"
              width="95%"
              mx="auto"
              onClick={() => navigate(`/users/${cm.user?._id}`)}
              className="cursor-pointer"
            >
              <St.Icon src="/images/default-user.webp" size="25px" round />
              <St.Text>{cm.user?.name}</St.Text>
              <St.Text ml="auto" color="var(--color-hr-red)" fontStyle="italic">
                {role(cm)}
              </St.Text>
            </St.Card>
          ))}
        </St.Column>
      </St.Column>
    </Modal>
  );
};
export default ChurchMembersModal;
