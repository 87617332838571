import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PointOfInterestDetailsDTO, {
  PointOfInterestType,
} from '../dtos/PointOfInterestDetailsDTO';
import { defaultPointOfInterestState } from '../types/PointOfInterestState';

export const pointsOfInterestSlice = createSlice({
  name: 'pointsOfInterest',
  initialState: defaultPointOfInterestState,
  reducers: {
    set_points_of_interest: (
      state,
      action: PayloadAction<PointOfInterestDetailsDTO[]>,
    ) => {
      const pois = action.payload;
      state.data = {
        [PointOfInterestType.PRIMARY_SCHOOL]: filterPoi(
          pois,
          PointOfInterestType.PRIMARY_SCHOOL,
        ),
        [PointOfInterestType.SECONDARY_SCHOOL]: filterPoi(
          pois,
          PointOfInterestType.SECONDARY_SCHOOL,
        ),
        [PointOfInterestType.UNIVERSITY]: filterPoi(
          pois,
          PointOfInterestType.UNIVERSITY,
        ),
        [PointOfInterestType.LIBRARY]: filterPoi(
          pois,
          PointOfInterestType.LIBRARY,
        ),
        [PointOfInterestType.SUPERMARKET]: filterPoi(
          pois,
          PointOfInterestType.SUPERMARKET,
        ),
        [PointOfInterestType.DOCTOR]: filterPoi(
          pois,
          PointOfInterestType.DOCTOR,
        ),
        [PointOfInterestType.DRUGSTORE]: filterPoi(
          pois,
          PointOfInterestType.DRUGSTORE,
        ),
        [PointOfInterestType.HOSPITAL]: filterPoi(
          pois,
          PointOfInterestType.HOSPITAL,
        ),
        [PointOfInterestType.GYM]: filterPoi(pois, PointOfInterestType.GYM),
        [PointOfInterestType.PHARMACY]: filterPoi(
          pois,
          PointOfInterestType.PHARMACY,
        ),
        [PointOfInterestType.CITY_HALL]: filterPoi(
          pois,
          PointOfInterestType.CITY_HALL,
        ),
        [PointOfInterestType.COURTHOUSE]: filterPoi(
          pois,
          PointOfInterestType.COURTHOUSE,
        ),
        [PointOfInterestType.LAWYER]: filterPoi(
          pois,
          PointOfInterestType.LAWYER,
        ),
        [PointOfInterestType.POLICE]: filterPoi(
          pois,
          PointOfInterestType.POLICE,
        ),
        [PointOfInterestType.CHURCH]: filterPoi(
          pois,
          PointOfInterestType.CHURCH,
        ),
      };
      state.loaded = true;
    },
  },
});

const filterPoi = (
  pois: PointOfInterestDetailsDTO[],
  type: PointOfInterestType,
) => {
  return pois.filter((poi) => poi.types.includes(type));
};

export const { set_points_of_interest } = pointsOfInterestSlice.actions;
export default pointsOfInterestSlice.reducer;
