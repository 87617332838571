import * as St from 'components/Style.styled';
import PostDetailsDTOData from 'dtos/PostDetailsDTO';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePost } from 'store/helpers';
import toDropdownItems from 'utils/toDropdownItems';
import apis from '../api';
import CheckboxDropdown, { DropdownItem } from '../components/CheckboxDropdown';
import routes from '../config/routes';
import { ToastContext } from '../contexts/ToastContext';
import useUserAccess from '../hooks/useUserAccess';
import {
  useAppDispatch,
  useGetChurches,
  useGetCoalitions,
  useGetCurrentUser,
  useGetDomains,
  useGetPostById,
} from '../store/hooks';
import { dropdownSelectOne } from '../utils/dropdownUtils';

const CreatePost: React.FC = () => {
  const { show } = useContext(ToastContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [post, setPost] = useState<PostDetailsDTOData>();
  const { isDomainMemberOf, isCoalitionMemberOf, isChurchMemberOf } =
    useUserAccess();
  const currentUser = useGetCurrentUser();
  const domainList = useGetDomains();
  const coalitionList = useGetCoalitions();
  const churchList = useGetChurches();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const domainItemState = useState<DropdownItem[]>([]);
  const coalitionItemState = useState<DropdownItem[]>([]);
  const churchItemState = useState<DropdownItem[]>([]);
  const [titleError, setTitleError] = useState<string | null>(null);
  const [contentError, setContentError] = useState<string | null>(null);
  const selectedDomainItem = useMemo(
    () => domainItemState[0].find((i) => i.checked),
    [domainItemState[0]],
  );
  const selectedCoalitionItem = useMemo(
    () => coalitionItemState[0].find((i) => i.checked),
    [coalitionItemState[0]],
  );
  const selectedChurchItem = useMemo(
    () => churchItemState[0].find((i) => i.checked),
    [churchItemState[0]],
  );

  const { id } = useParams();

  useEffect(() => {
    const domains = domainList.filter(isDomainMemberOf);
    domainItemState[1](toDropdownItems(domains));
  }, [domainList]);
  useEffect(() => {
    const coalitions = coalitionList.filter(isCoalitionMemberOf);
    coalitionItemState[1](toDropdownItems(coalitions));
  }, [coalitionList]);
  useEffect(() => {
    const churches = churchList.filter(isChurchMemberOf);
    churchItemState[1](toDropdownItems(churches));
  }, [churchList]);

  const [isEditing, setIsEditing] = useState(false);
  const defaultPost = useGetPostById(id);

  useEffect(() => {
    const isEditMode = window.location.pathname.includes('edit');
    if (id && isEditMode) {
      setIsEditing(true);
      if (defaultPost) {
        setPost(defaultPost);
        const domains = domainList.filter(isDomainMemberOf);
        const domainItems = toDropdownItems(domains);

        const coalitions = coalitionList.filter(isCoalitionMemberOf);
        const coalitionItems = toDropdownItems(coalitions);

        const churches = churchList.filter(isChurchMemberOf);
        const churchItems = toDropdownItems(churches);

        domainItems.forEach((item) => {
          if (item.id === defaultPost.domain_id) item.checked = true;
        });
        coalitionItems.forEach((item) => {
          if (item.id === defaultPost.coalition_id) item.checked = true;
        });
        churchItems.forEach((item) => {
          if (item.id === defaultPost.church_id) item.checked = true;
        });

        domainItemState[1](domainItems);
        coalitionItemState[1](coalitionItems);
        churchItemState[1](churchItems);
      }
    }
  }, [id, defaultPost]);

  const validateForm = (title: string, content: string) => {
    let valid = true;
    if (!title) {
      setTitleError('Title cannot be empty');
      valid = false;
    } else {
      setTitleError(null);
    }
    if (!content) {
      setContentError('Content cannot be empty');
      valid = false;
    } else {
      setContentError(null);
    }
    if (!selectedCoalitionItem) {
      setContentError('Coalition cannot be empty');
      valid = false;
    } else {
      setContentError(null);
    }
    return valid;
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsProcessing(true);
    const form = event.currentTarget;
    const title = (form.elements.namedItem('title') as HTMLInputElement).value;
    const content = (form.elements.namedItem('content') as HTMLInputElement)
      .value;
    const postData = {
      title: title,
      content: content,
      domain_id: selectedDomainItem?.id,
      church_id: selectedChurchItem?.id,
      coalition_id: selectedCoalitionItem?.id,
      user_id: currentUser._id,
    };
    if (!validateForm(title, content)) {
      setIsProcessing(false);
      return;
    }

    if (isEditing) {
      postData['_id'] = id;
      updatePost(postData, dispatch, navigate, show);
    } else {
      apis.posts.create_post(postData).then((res) => {
        if (!!res) {
          show('post-created-success');
          navigate(`/posts/${res}`);
        }
      });
    }

    setIsProcessing(false);
  };

  return (
    <St.Row width="100%">
      <St.Form width="90%" gap="10px" onSubmit={submit}>
        <St.Text
          fontWeight="800"
          fontSize="20px"
          color="var(--color-dark-grey)"
          mb="14px"
        >
          {isEditing ? 'Edit Post' : 'Create a post'}
        </St.Text>
        <St.TextInput
          name="title"
          placeholder="Title"
          width="100%"
          defaultValue={post?.title}
        />
        {titleError && <St.ErrorText>{titleError}</St.ErrorText>}
        <St.LargeInput
          name="content"
          placeholder="Post Content"
          defaultValue={post?.content}
          rows={8}
        />

        <St.Row gap="10px">
          <CheckboxDropdown
            items={coalitionItemState[0]}
            onClickItem={(i) => dropdownSelectOne(i, coalitionItemState)}
            width="100%"
            style={{ flexGrow: 1 }}
          >
            <St.Text nowrap>
              {selectedCoalitionItem?.name ?? <>Choose coalition</>}
            </St.Text>
          </CheckboxDropdown>
          <CheckboxDropdown
            items={domainItemState[0]}
            onClickItem={(i) => dropdownSelectOne(i, domainItemState)}
            width="100%"
            style={{ flexGrow: 1 }}
          >
            <St.Text nowrap>
              {selectedDomainItem?.name ?? (
                <>
                  Choose domain <i>(optional)</i>
                </>
              )}
            </St.Text>
          </CheckboxDropdown>
          <CheckboxDropdown
            items={churchItemState[0]}
            onClickItem={(i) => dropdownSelectOne(i, churchItemState)}
            width="100%"
            style={{ flexGrow: 1 }}
          >
            <St.Text nowrap>
              {selectedChurchItem?.name ?? (
                <>
                  Choose church <i>(optional)</i>
                </>
              )}
            </St.Text>
          </CheckboxDropdown>
        </St.Row>

        {contentError && <St.ErrorText>{contentError}</St.ErrorText>}
        <St.Row className="justify-start" style={{ width: '100%' }} gap="10px">
          <St.TertiaryButtonLink to={routes.HOME.href}>
            Back
          </St.TertiaryButtonLink>
          <St.PrimaryButton disabled={isProcessing} type="submit" width="150px">
            {isEditing ? 'Update Post' : 'Create Post'}
          </St.PrimaryButton>
        </St.Row>
      </St.Form>
    </St.Row>
  );
};

export default CreatePost;
