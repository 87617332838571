import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EventDetailsDTO from 'dtos/EventDetailsDTO';
import { defaultArrayDataState } from 'types/ArrayDataState';

export const eventSlice = createSlice({
  name: 'events',
  initialState: defaultArrayDataState<EventDetailsDTO>(),
  reducers: {
    set_events: (state, action: PayloadAction<EventDetailsDTO[]>) => {
      state.data = action.payload;
      state.loaded = true;
    },
  },
});

export const { set_events } = eventSlice.actions;
export default eventSlice.reducer;
