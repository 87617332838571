import React, { Component, ReactNode } from 'react';
import * as St from '../components/Style.styled';

type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <h1 className="mb-10">
            Something went wrong. Please click on the below button to refresh
            the page.
          </h1>
          <St.PrimaryButton
            type="button"
            onClick={() => window.location.reload()}
          >
            Try again
          </St.PrimaryButton>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
