import * as St from 'components/Style.styled';
import { ToastContext } from 'contexts/ToastContext';
import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';
import { useJoinChurch } from 'hooks/updateHooks';
import useUserAccess from 'hooks/useUserAccess';
import React, { useContext } from 'react';
import { useGetChurches, useGetCurrentUser } from 'store/hooks';
import styled from 'styled-components';
import { ModalContext } from 'contexts/ModalContext';
import { StepProps } from './index';
import { LoadingWhite } from 'config/icons';

const Churches = styled(St.Column)`
  background-color: var(--color-light-grey);
  border-radius: 4px;
  padding: 25px 16px;
  height: 300px;
  gap: 10px;
  overflow-y: scroll;
  justify-content: flex-start;
`;

const ChurchCard: React.FC<{ church: ChurchDetailsDTO }> = ({ church }) => {
  const { onJoinChurch } = useJoinChurch();
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();
  const { isChurchMemberOf } = useUserAccess();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleJoinChurch = async () => {
    setIsProcessing(true);
    await onJoinChurch(currentUser._id, church._id);
    show('joined-church-success');
    setIsProcessing(false);
  };

  const renderJoinButton = () => {
    const joined = isChurchMemberOf(church);

    return (
      <St.PrimaryButton
        type="button"
        disabled={joined || !!isProcessing}
        onClick={handleJoinChurch}
      >
        {!!isProcessing ? (
          <St.Icon src={LoadingWhite} size="20px" mx="auto" />
        ) : !joined ? (
          'Join'
        ) : (
          'Joined'
        )}
      </St.PrimaryButton>
    );
  };

  return (
    <St.Row
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      width="100%"
    >
      <St.Text>{church.name}</St.Text>
      {renderJoinButton()}
    </St.Row>
  );
};

const JoinChurches: React.FC<StepProps> = () => {
  const { toggleSignUpOpen } = useContext(ModalContext);
  const churches = useGetChurches();
  return (
    <St.Column height="445px">
      <St.Text
        fontWeight="800"
        fontSize="20px"
        textAlign="center"
        mb="20px"
        mx="25px"
      >
        Join a church to stay up to date with their activities.
      </St.Text>
      <Churches>
        {churches.map((church) => (
          <ChurchCard key={church._id} church={church} />
        ))}
      </Churches>
      <St.PrimaryButton type="button" onClick={toggleSignUpOpen} mt="auto">
        Complete
      </St.PrimaryButton>
    </St.Column>
  );
};

export default JoinChurches;
