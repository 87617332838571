import * as St from 'components/Style.styled';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { QuestionMarkRed } from '../config/icons';
import { ToastContext } from '../contexts/ToastContext';
import DomainDetailsDTO from '../dtos/DomainDetailsDTO';
import { useJoinDomain } from '../hooks/updateHooks';
import { useGetCurrentUser } from '../store/hooks';
import DomainMemberRole from '../types/DomainMemberRole';
import Tooltip from './Tooltip';
import useUserAccess from 'hooks/useUserAccess';

const MARGIN = '5px';

const Container = styled.div<{ margin: string }>`
  width: calc(50% - (${(props) => props.margin} * 2));
  min-width: 450px;
  background: rgba(217, 217, 217, 0.2);
  margin: ${(props) => props.margin};
  cursor: pointer;
  padding: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  padding: 3px 10px 3px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
`;

interface DomainCardProps {
  domain: DomainDetailsDTO;
  tabIndex: number;
  isMember?: boolean;
  isAdmin?: boolean;
}

const DomainCard: React.FC<DomainCardProps> = ({
  domain,
  tabIndex,
  isMember = false,
  isAdmin = false,
}) => {
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const { onJoinDomain } = useJoinDomain();
  const { show } = useContext(ToastContext);
  const { churchIdIfAdmin } = useUserAccess();
  const churchId = churchIdIfAdmin();

  const buttonText = () => {
    if (!isMember) return 'Join';
    if (!!isAdmin) return 'Partnered';
    return 'Joined';
  };

  const joinDomain = async (e: any) => {
    e.stopPropagation();
    await onJoinDomain(domain._id, churchId, currentUser._id);
    show('joined-domain-success');
  };

  const renderTooltip = () => {
    if (tabIndex !== 0) return null;
    const msg = isMember
      ? 'You are seeing this because you are a member of this domain.'
      : 'You are seeing this because you are a partner of this domain.';
    return <Tooltip text={msg} src={QuestionMarkRed} />;
  };

  return (
    <Container margin={MARGIN} onClick={() => navigate(`${domain._id}`)}>
      <St.Row justifyContent="space-between">
        <St.Text color="var(--color-hr-red)" fontWeight="800">
          {domain.name}
        </St.Text>
        {/* <St.Div ml="auto" mr="5px">{renderTooltip()}</St.Div> */}
        {!!churchIdIfAdmin() && (
          <St.PrimaryButton
            type="button"
            onClick={joinDomain}
            disabled={!!isMember}
          >
            {buttonText()}
          </St.PrimaryButton>
        )}
      </St.Row>
      <Description>{domain.description}</Description>
    </Container>
  );
};

export default DomainCard;
