import { isEmpty } from 'lodash';

export const filterHouseholds = (households, filters) => {
  if (
    isEmpty(filters.income_bands) &&
    isEmpty(filters.ethnic_groups) &&
    isEmpty(filters.education_levels)
  ) {
    return households;
  }

  let filteredHouseholds = [];

  if (filters.income_bands.length > 0) {
    households.forEach((h) => {
      let total = 0;
      let count = 0;
      let max_category, max_count;
      filteredHouseholds.push({
        ...h,
        data: [
          ...h.data
            .map((hh) => {
              if (filters.income_bands.includes(hh._id)) {
                total = total + hh.count;
                if (count < hh.count) {
                  count = hh.count;
                  max_category = hh._id;
                  max_count = count;
                }

                return hh;
              }

              return null;
            })
            .filter((item) => item !== null),
        ],
        total,
        max_category,
        max_count,
      });
    });
  }

  if (filters.ethnic_groups.length > 0) {
    households.forEach((h) => {
      let total = 0;
      let count = 0;
      let max_category, max_count;
      filteredHouseholds.push({
        ...h,
        data: [
          ...h.data
            .map((hh) => {
              if (filters.ethnic_groups.includes(hh._id)) {
                total = total + hh.count;
                if (count < hh.count) {
                  count = hh.count;
                  max_category = hh._id;
                  max_count = count;
                }

                return hh;
              }

              return null;
            })
            .filter((item) => item !== null),
        ],
        total,
        max_category,
        max_count,
      });
    });
  }

  if (filters.education_levels.length > 0) {
    households.forEach((h) => {
      let total = 0;
      let count = 0;
      let max_category, max_count;
      filteredHouseholds.push({
        ...h,
        data: [
          ...h.data
            .map((hh) => {
              if (filters.education_levels.includes(hh._id)) {
                total = total + hh.count;
                if (count < hh.count) {
                  count = hh.count;
                  max_category = hh._id;
                  max_count = count;
                }

                return hh;
              }

              return null;
            })
            .filter((item) => item !== null),
        ],
        total,
        max_category,
        max_count,
      });
    });
  }

  filteredHouseholds = filteredHouseholds.map((h) => h.total > 0 && h);
  return filteredHouseholds;
};

export const getHouseholdQuery = (filters, bounds) => {
  let query = {};
  // Extract the bounds
  const southwest = [bounds.south, bounds.west];
  const northeast = [bounds.north, bounds.east];
  // Construct GeoJSON object for the map bounds
  const mapBoundingBox = {
    type: 'Polygon',
    coordinates: [
      [
        [southwest[1], northeast[0]], // [longitude1, latitude2]
        [northeast[1], northeast[0]], // [longitude2, latitude2]
        [northeast[1], southwest[0]], // [longitude2, latitude1]
        [southwest[1], southwest[0]], // [longitude1, latitude1]
        [southwest[1], northeast[0]], // [longitude1, latitude2]
      ],
    ],
  };

  if (!isEmpty(filters)) {
    if (filters.income_bands.length > 0) {
      query['income'] = { $in: filters.income_bands };
    }

    if (filters.education_levels.length > 0) {
      query['education_level'] = { $in: filters.education_levels };
    }

    if (filters.ethnic_groups.length > 0) {
      query['ethnic_group'] = { $in: filters.ethnic_groups };
    }
  }

  query = {
    ...query,
    location: {
      $geoWithin: {
        $geometry: mapBoundingBox,
      },
    },
  };

  return query;
};
