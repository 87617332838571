import { motion } from 'framer-motion';
import React from 'react';
import classNames from '../../utils/classNames';
import * as St from '../Style.styled';
import './tabStyles.css';

interface TabSelectorProps {
  tabs: string[];
  parentTabState: [number, React.Dispatch<React.SetStateAction<number>>];
}

const Tabs: React.FC<TabSelectorProps> = ({ tabs, parentTabState }) => {
  const [selectedIndex, setSelectedIndex] = parentTabState;
  return (
    <nav className="tab-nav">
      <ul className="tab-ul" style={{ overflowX: 'scroll' }}>
        {tabs.map((item, index) => {
          const selected = index === selectedIndex;
          return (
            <li
              key={item}
              className={classNames('tab-li', selected ? 'selected' : '')}
              onClick={() => setSelectedIndex(index)}
            >
              <St.Text nowrap mx="20px" width="100%">
                {item}
              </St.Text>
              {selected ? (
                <motion.div className="underline" layoutId="underline" />
              ) : null}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Tabs;
