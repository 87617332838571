import { executor, getAxios } from 'api';
import AddChurchStatisticTypeDTO from '../dtos/AddChurchStatisticTypeDTO';
import ChurchStatisticTypeDetailsDTO from '../dtos/ChurchStatisticTypeDetailsDTO';
import ChurchStatisticDetailsDTO from '../dtos/ChurchStatisticDetailsDTO';
import CoalitionDollarContributionsDTO from '../dtos/CoalitionDollarContributionsDTO';

export interface ChurchStatisticsApi {
  get_all_types: () => Promise<ChurchStatisticTypeDetailsDTO[] | undefined>;
  get_quantitative_statistics: () => Promise<ChurchStatisticDetailsDTO[]>;
  get_dollar_contributions_summarized_by_coalition: () => Promise<
    CoalitionDollarContributionsDTO[] | undefined
  >;
  add: (dto: AddChurchStatisticTypeDTO) => Promise<void>;
}

const get_all_types = async (): Promise<
  ChurchStatisticTypeDetailsDTO[] | undefined
> => {
  const action = async (): Promise<ChurchStatisticTypeDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'read',
      data: {
        collection: 'church_statistic_types',
        query: { _IsActive: { $ne: false } },
      },
    });

    return result.data?.data ?? [];
  };

  return await executor(action, 'get_all_church_statistic_types');
};

const get_quantitative_statistics = async (): Promise<
  ChurchStatisticDetailsDTO[]
> => {
  const action = async (): Promise<ChurchStatisticDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'quantitative_statistics',
    });

    return result.data;
  };

  return await executor(action, 'get_quantitative_statistics');
};

const get_dollar_contributions_summarized_by_coalition = async (): Promise<
  CoalitionDollarContributionsDTO[] | undefined
> => {
  const action = async (): Promise<CoalitionDollarContributionsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'dollar_contributions_summarized_by_coalition',
    });

    return result.data;
  };

  return await executor(action, 'dollar_contributions_summarized_by_coalition');
};

const add = async (dto: AddChurchStatisticTypeDTO): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'GET',
      url: 'add_church_statistic_type',
      data: dto,
    });
  };

  return await executor(action, 'add_church_statistic_type');
};

const church_statistic_types_api_set: ChurchStatisticsApi = {
  get_all_types,
  get_quantitative_statistics,
  get_dollar_contributions_summarized_by_coalition,
  add,
};
export default church_statistic_types_api_set;
