import { AES, enc } from 'crypto-js';
const secret = 'test key';

const encrypt = (value) => {
  const cipherText = AES.encrypt(value, secret);
  return cipherText.toString();
};

const decrypt = (value) => {
  let bytes;

  try {
    bytes = AES.decrypt(value, secret);
    const decrypted = bytes.toString(enc.Utf8);
    return decrypted;
  } catch (err) {
    return err;
  }
};

export { encrypt, decrypt };
