import { PointOfInterestType } from '../../../dtos/PointOfInterestDetailsDTO';
import DomainId from 'types/DomainId';

const domainPointsOfInterest: { [key in DomainId]: PointOfInterestType[] } = {
  [DomainId.Health]: [
    PointOfInterestType.DOCTOR,
    PointOfInterestType.DRUGSTORE,
    PointOfInterestType.HOSPITAL,
    PointOfInterestType.GYM,
    PointOfInterestType.PHARMACY,
  ],
  [DomainId.Trafficking]: [],
  [DomainId.Immigration]: [],
  [DomainId.Economics]: [],
  [DomainId.Housing]: [],
  [DomainId.DisasterRecovery]: [],
  [DomainId.Education]: [
    PointOfInterestType.PRIMARY_SCHOOL,
    PointOfInterestType.SECONDARY_SCHOOL,
    PointOfInterestType.UNIVERSITY,
    PointOfInterestType.LIBRARY,
  ],
  [DomainId.Discipleship]: [],
  [DomainId.FoodSecurity]: [PointOfInterestType.SUPERMARKET],
  [DomainId.Evangelism]: [],
  [DomainId.CriminalJustice]: [
    PointOfInterestType.CITY_HALL,
    PointOfInterestType.COURTHOUSE,
    PointOfInterestType.LAWYER,
    PointOfInterestType.POLICE,
  ],
  [DomainId.Recovery]: [],
};

export default domainPointsOfInterest;
