import * as St from 'components/Style.styled';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
import apis from '../api';
import Tabs from '../components/Tabs';
import Tooltip from '../components/Tooltip';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import ChurchMemberDetailsDTO from '../dtos/ChurchMemberDetailsDTO';
import DomainDetailsDTO from '../dtos/DomainDetailsDTO';
import UserDetailsDTO, { defaultUserDetails } from '../dtos/UserDetailsDTO';
import useOnGetChurchById from '../hooks/useOnGetChurchById';
import useOnGetDomainById from '../hooks/useOnGetDomainById';
import { useGetCurrentUser } from '../store/hooks';
import { ChurchProfileParams } from './ChurchProfile';
import DomainMemberDetailsDTO from 'dtos/DomainMemberDetailsDTO';

const domainMissingInfo = (domain: DomainDetailsDTO) => {
  return !domain.description;
};

const churchMissingInfo = (church: ChurchDetailsDTO) => {
  return !church.description;
};

const InfoCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: start;
  border: solid 2px var(--color-dark-grey);
  border-radius: 4px;
  padding: 10px;
  width: 200px;
  margin: 10px;

  :hover {
    cursor: pointer;
  }
` as StyledComponent<typeof Link, any>;

const InfoText = styled.p`
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
`;

const UserProfile: React.FC = () => {
  const { id } = useParams<ChurchProfileParams>();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDetailsDTO>(defaultUserDetails);
  const [churchMemberships, setChurchMemberships] = useState<
    ChurchMemberDetailsDTO[]
  >([]);
  const [domainMemberships, setDomainMemberships] = useState<
    DomainMemberDetailsDTO[]
  >([]);
  const [tabState, setTabState] = useState(0);
  const currentUser = useGetCurrentUser();
  const isCurrentUsersProfile = currentUser._id === id;
  const { onGetDomainById } = useOnGetDomainById();
  const { onGetChurchById } = useOnGetChurchById();

  useEffect(() => {
    if (isCurrentUsersProfile) setUser(currentUser);
    else apis.users.get_by_id(id).then((res) => res && setUser(res));
  }, [currentUser._id, id]);

  useEffect(() => {
    apis.churches
      .get_user_memberships(id)
      .then((res) => res && setChurchMemberships(res));
    apis.domains
      .get_user_memberships(id)
      .then((res) => res && setDomainMemberships(res));
  }, [id]);

  function onClickEdit() {
    navigate('edit');
  }

  function renderTab() {
    if (tabState === 0) {
      return (
        <St.Row flexWrap="wrap" justifyContent="start">
          {churchMemberships.map((membership) => {
            if (!membership.church) return null;
            const church = membership.church;
            return (
              <InfoCard
                key={membership._id}
                id={church._id}
                to={`/churches/${church._id}`}
              >
                <St.Row justifyContent="space-between" width="100%">
                  <InfoText>{church.name}</InfoText>
                  {churchMissingInfo(church) && membership.role === 'rw' && (
                    <Tooltip
                      src="/images/exclamation-mark.svg"
                      text="This church is missing information. Please update it to help others learn more."
                      mr="5px"
                    />
                  )}
                </St.Row>
                <St.Row>
                  Role:
                  <St.Text ml="5px" color="var(--color-hr-red)">
                    {membership.role === 'rw' ? 'Admin' : 'Member'}
                  </St.Text>
                </St.Row>
              </InfoCard>
            );
          })}
        </St.Row>
      );
    } else if (tabState === 1) {
      return (
        <St.Row flexWrap="wrap" justifyContent="start">
          {domainMemberships.map((membership) => {
            if (!membership.domain) return null;
            const domain = membership.domain;
            return (
              <InfoCard
                key={membership._id}
                id={domain._id}
                to={`/domains/${domain._id}`}
              >
                <St.Row justifyContent="space-between" width="100%">
                  <InfoText>{domain.name}</InfoText>
                  {domainMissingInfo(domain) && membership.role === 'rw' && (
                    <Tooltip
                      src="/images/exclamation-mark.svg"
                      text="This domain is missing information. Please update it to help others learn more."
                      mr="5px"
                    />
                  )}
                </St.Row>
                <St.Row>
                  Role:
                  <St.Text ml="5px" color="var(--color-hr-red)">
                    {membership.role === 'rw' ? 'Admin' : 'Member'}
                  </St.Text>
                </St.Row>
              </InfoCard>
            );
          })}
        </St.Row>
      );
    }
  }

  return (
    <div>
      <div className="user-info">
        <img
          src="/images/default-user.webp"
          alt={user.name}
          className="user-image"
        />
        <St.Column alignItems="start">
          <St.Text fontSize="24px" fontWeight="800">
            {user.name}
          </St.Text>
          <St.Row gap="10px">
            <St.Text opacity="0.7" color="var(--color-dark-grey)">
              @{user.username}
            </St.Text>
            <St.AnimatedIcon
              src="/images/edit.svg"
              size="14px"
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: 'tween', duration: 0.1 }}
              onClick={onClickEdit}
            />
          </St.Row>
        </St.Column>
      </div>
      <Tabs
        tabs={[
          `Related Churches (${churchMemberships.length})`,
          `Related Domains (${domainMemberships.length})`,
        ]}
        parentTabState={[tabState, setTabState]}
      />
      {renderTab()}
    </div>
  );
};

export default UserProfile;
