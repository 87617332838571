import React from 'react';
import Page from '../components/Page';

const RegisterUserComplete: React.FC = () => {
  return (
    <Page>
      <div className="text-2xl font-bold">Registration Complete</div>
      <div className="text-lg">
        You have successfully registered for Houston Responds
      </div>
    </Page>
  );
};

export default RegisterUserComplete;
