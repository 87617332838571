import apis from 'api';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CheckboxDropdown, {
  DropdownItem,
} from '../../components/CheckboxDropdown';
import EditButtons from '../../components/EditButtons';
import * as St from '../../components/Style.styled';
import { ToastContext } from '../../contexts/ToastContext';
import ChurchStatisticTypeDetailsDTO from '../../dtos/ChurchStatisticTypeDetailsDTO';
import toDropdownItems from '../../utils/toDropdownItems';
import { ChurchProfileParams } from './index';
import ChurchStatisticDetailsDTO from 'dtos/ChurchStatisticDetailsDTO';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 30px;
`;

const INPUT_MARGIN = '7px';
const Form = styled.form`
  display: flex;
`;

const AddChurchStatistics: React.FC = () => {
  const { id } = useParams<ChurchProfileParams>();
  const { show } = useContext(ToastContext);
  const [assets, setAssets] = useState<ChurchStatisticDetailsDTO[]>([]);
  const [assetTypes, setAssetTypes] = useState<ChurchStatisticTypeDetailsDTO[]>(
    [],
  );
  const [assetTypeItems, setAssetTypeItems] = useState<DropdownItem[]>([]);
  const selectedAssetTypeItem = assetTypeItems.find((item) => item.checked);
  const selectedAssetType = useMemo(
    () =>
      assetTypes.find(
        (assetType) => selectedAssetTypeItem?.id === assetType._id,
      ),
    [selectedAssetTypeItem?.id],
  );
  const [domainItems, setDomainItems] = useState<DropdownItem[]>([]);
  const selectedDomainItem = domainItems.find((domain) => domain.checked);

  const onClickAssetTypeItem = (item: DropdownItem) => {
    setAssetTypeItems(
      assetTypeItems.map((assetType) => ({
        ...assetType,
        checked: item.id === assetType.id,
      })),
    );
  };

  const onClickDomainItem = (item: DropdownItem) => {
    setDomainItems(
      domainItems.map((domain) => ({
        ...domain,
        checked: item.id === domain.id,
      })),
    );
  };

  const refreshProfile = useCallback(() => {
    apis.churches.get_church_statistics(id).then((result) => {
      if (!result) return;
      setAssets(result);
    });
  }, [id]);

  useEffect(() => {
    apis.church_statistics.get_all_types().then((resAssetTypes) => {
      if (!resAssetTypes) return;
      setAssetTypes(resAssetTypes);
      setAssetTypeItems(toDropdownItems(resAssetTypes));
    });

    apis.domains.get_all().then((resDomains) => {
      if (!resDomains) return;
      setDomainItems(toDropdownItems(resDomains));
    });
  }, []);

  useEffect(() => {
    refreshProfile();
  }, [id]);

  const submit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!selectedAssetTypeItem) return;

    const form = event.currentTarget;
    apis.churches
      .add_church_statistic(id, {
        description: form.description.value,
        more_info: form.more_info.value,
        domain_id: selectedDomainItem?.id,
        asset_type_id: selectedAssetTypeItem?.id,
        quantity: form.quantity.value,
      })
      .then(() => {
        show('update-church-statistics-success');
        refreshProfile();
      });
  };

  return (
    <Container>
      <St.Text fontSize="20px" fontWeight="800">
        Add Church Statistics
      </St.Text>
      <Form className="flex-row" onSubmit={submit}>
        <St.Column alignItems="flex-start" mr="10px" mt="20px">
          <St.Row justifyContent="space-between" width="100%">
            <St.Text fontWeight="800" mb="10px">
              Existing assets
            </St.Text>
            <EditButtons onClickEdit={null} />
          </St.Row>
          <St.Table>
            <thead>
              <St.THeaderRow>
                <th>Statistic Type</th>
                <th>Domain</th>
                <th>Quantity</th>
                <th>Description</th>
                <th>Additional info</th>
              </St.THeaderRow>
            </thead>
            <tbody>
              {(assets ?? []).map((asset) => (
                <St.Tr key={asset._id}>
                  <St.Td>
                    <St.Icon src={assetTypes[asset.type_id]} />
                    <St.Text>
                      {asset.type_id
                        ? assetTypes.find(
                            (assetType) => assetType._id === asset.type_id,
                          )?.name
                        : 'None'}
                    </St.Text>
                  </St.Td>
                  <St.Td>{asset.domain?.name || <i>None</i>}</St.Td>
                  <St.Td>{asset.quantity || <i>0</i>}</St.Td>
                  <St.Td>{asset.description || <i>None</i>}</St.Td>
                  <St.Td>{asset.more_info || <i>None</i>}</St.Td>
                </St.Tr>
              ))}
            </tbody>
          </St.Table>

          <St.Text fontWeight="800" my="10px" mt={'48px'}>
            Create new asset
          </St.Text>
          <St.Row
            justifyContent="flex-start"
            width="100%"
            alignItems="flex-end"
            gap="10px"
          >
            <div>
              <label>Asset Type</label>
              <CheckboxDropdown
                items={assetTypeItems}
                onClickItem={onClickAssetTypeItem}
                width="200px"
              >
                {selectedAssetType?.name || 'None'}
              </CheckboxDropdown>
            </div>

            <div>
              <label>Domain</label>
              <CheckboxDropdown
                items={domainItems}
                onClickItem={onClickDomainItem}
                width="200px"
              >
                {selectedDomainItem ? selectedDomainItem.name : 'None'}
              </CheckboxDropdown>
            </div>
            {(!selectedAssetType || selectedAssetType?.has_quantity) && (
              <div>
                <label>Quantity</label>
                <St.TextInput
                  type="number"
                  name="quantity"
                  placeholder="Statistic quantity"
                  required
                />
              </div>
            )}
            <div>
              <label htmlFor="description">
                Description - <i>Optional</i>
              </label>
              <St.TextInput
                type="text"
                name="description"
                placeholder="Statistic description"
              />
            </div>
            <div>
              <label htmlFor="more_info">
                Additional Info - <i>Optional</i>
              </label>
              <St.TextInput
                type="text"
                name="more_info"
                placeholder="e.g https://..."
              />
            </div>
          </St.Row>
          <St.Row className="justify-start" style={{ width: '100%' }}>
            <St.TertiaryButtonLink
              to={`/churches/${id}`}
              mr={INPUT_MARGIN}
              mt={INPUT_MARGIN}
            >
              Back
            </St.TertiaryButtonLink>
            <St.PrimaryButton
              type="submit"
              ml={INPUT_MARGIN}
              mt={INPUT_MARGIN}
              width="150px"
            >
              Add Statistic
            </St.PrimaryButton>
          </St.Row>
        </St.Column>
      </Form>
    </Container>
  );
};

export default AddChurchStatistics;
