import { executor, getAxios } from 'api';
import PointOfInterestDetailsDTO, {
  PointOfInterestType,
} from 'dtos/PointOfInterestDetailsDTO';

export interface PointsOfInterestApi {
  get_all: (
    selectedDomainIds: string[],
  ) => Promise<PointOfInterestDetailsDTO[] | undefined>;
  get_all_church_poi: (
    bounds: google.maps.LatLngBounds,
  ) => Promise<PointOfInterestDetailsDTO[] | undefined>;
}

const get = async (
  selectedDomainIds: string[],
): Promise<PointOfInterestDetailsDTO[] | undefined> => {
  const action = async (): Promise<PointOfInterestDetailsDTO[]> => {
    const domainIds = selectedDomainIds.map((id) => parseInt(id));
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        query: { types: { $in: domainIds } },
        collection: 'points_of_interest',
        limit: 50,
      },
    });
    return result.data.data;
  };

  return await executor(action, 'points_of_interest');
};

const get_all_church_poi = async (
  bounds: google.maps.LatLngBounds,
): Promise<PointOfInterestDetailsDTO[] | undefined> => {
  const action = async (): Promise<PointOfInterestDetailsDTO[]> => {
    const swLat = bounds.getSouthWest().lat();
    const swLng = bounds.getSouthWest().lng();
    const neLat = bounds.getNorthEast().lat();
    const neLng = bounds.getNorthEast().lng();

    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        query: [
          {
            $match: {
              types: { $in: [PointOfInterestType.CHURCH] },
              $and: [
                { latitude: { $gte: swLat, $lte: neLat } },
                { longitude: { $gte: swLng, $lte: neLng } },
              ],
            },
          },
        ],
        collection: 'points_of_interest',
        limit: 50,
      },
    });
    return result.data.data;
  };

  return await executor(action, 'points_of_interest');
};

const pointsOfInterestApi: PointsOfInterestApi = {
  get_all: get,
  get_all_church_poi,
};

export default pointsOfInterestApi;
