import * as St from 'components/Style.styled';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import config from 'tailwindcss/defaultConfig';
import { ToastContext } from '../contexts/ToastContext';

const toastStyles = css`
  min-width: 275px;
  min-height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
`;

const PrimaryToast = styled.div`
  ${toastStyles};
  color: white;
  background: var(--color-hr-red);
  border: 1px solid var(--color-hr-red);
`;

const SecondaryToast = styled.div`
  ${toastStyles};
  background: var(--color-failure-bg);
  color: var(--color-hr-red);
  border: 1px solid var(--color-failure-border);
`;

interface ToastProps {
  toastId: string;
  secondary?: boolean;
  children?: ReactNode;
}

const Toast: React.FC<ToastProps> = ({ toastId, secondary, children }) => {
  const { duration = 3500, role = 'status' } = config;
  const { toastList, close } = useContext(ToastContext);
  const isShown = toastList.has(toastId);
  useEffect(() => {
    if (!duration || !isShown) {
      return;
    }
    const timeoutId = setTimeout(() => {
      close(toastId);
    }, duration);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [toastId, isShown, duration, close]);

  const ToastComponent = secondary ? SecondaryToast : PrimaryToast;
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          key={toastId}
          layout
          initial={{ opacity: 0, y: 50, scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.5 }}
          role={role}
          style={{ zIndex: 100 }}
        >
          <ToastComponent>
            {children}
            <St.Icon
              className="hover:cursor-pointer"
              ml="10px"
              size="15px"
              src={secondary ? '/images/exit-red.svg' : '/images/exit.svg'}
              onClick={() => close(toastId)}
            />
          </ToastComponent>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Toast;
