import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Row } from './Style.styled';

export interface ChartDatum {
  color: string;
  name: string;
  value: number;
}

interface DonutChartProps {
  data: ChartDatum[];
  cornerRadius?: number;
  height?: string | number;
  width?: string | number;
  donut?: boolean;
  outerRadius?: number;
}

const NoDataMessage = () => (
  <div>
    <h4>
      No statistics available. Join a coalition to access it's data or use less
      restrictive filters.
    </h4>
  </div>
);

const CircleChart: React.FC<DonutChartProps> = ({
  data,
  cornerRadius,
  height,
  width,
  donut,
  outerRadius,
}) => {
  const hasData = data && data.length > 0;

  return (
    <>
      {hasData ? (
        <ResponsiveContainer height={height ?? 100} width={width ?? 100}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={donut ? 35 : 0}
              outerRadius={outerRadius ?? 50}
              fill="#8884d8"
              dataKey="value"
              cornerRadius={cornerRadius}
            >
              {data.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={entry.color} />;
              })}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Row>
          <NoDataMessage />
        </Row>
      )}
    </>
  );
};

export default CircleChart;
