import React, { useContext } from 'react';
import Autocomplete from 'react-google-autocomplete';
import styled, { StyledComponent } from 'styled-components';
import * as St from '../components/Style.styled';
import routes from '../config/routes';
import { buildGetAddressComponentFields } from '../utils/googleUtils';
import { ModalContext } from 'contexts/ModalContext';

const ChurchInput = styled(Autocomplete)`
  ${St.textInputStyles}
  box-sizing: border-box;
  padding-right: 1.5rem;
` as StyledComponent<typeof Autocomplete, any, { name: string; value: string }>;

const INPUT_MARGIN = '7px';

const Wrapper = styled.div<{ my: string }>`
  position: relative;
  width: 100%;
  margin-top: ${(props) => props.my};
  margin-bottom: ${(props) => props.my};
`;

const Icon = styled.img`
  height: 2rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
`;

const SmallInput = styled(St.TextInput)<{ mar: string }>`
  width: calc(50% - ${(props) => props.mar});
  margin-top: ${(props) => props.mar};
  margin-bottom: ${(props) => props.mar};
` as StyledComponent<typeof St.TextInput, any, { mar: string }>;

const Form = styled.form`
  display: flex;
`;

interface ChurchFormProps {
  placesInfo: {
    place_id: string;
    name: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    photo: string;
    coords: [number, number];
    primary_contact: string;
    primary_email: string;
    primary_phone: string;
  };
  onFormInput: (e: any) => void;
  setPlacesInfo: (e: any) => void;
  submit: (e: any) => void;
  isEdit?: boolean;
}

const ChurchForm: React.FC<ChurchFormProps> = ({
  onFormInput,
  placesInfo,
  setPlacesInfo,
  submit,
  isEdit = false,
}) => {
  const { toggleEditChurch } = useContext(ModalContext);

  const HOUSTON_LATLNG_BOUNDS = {
    north: 30.110409,
    east: -95.053467,
    south: 29.523056,
    west: -95.788363,
  };

  const options = {
    types: ['church'],
    fields: [
      'place_id',
      'address_components',
      'name',
      'photos',
      'geometry.location',
    ],
    bounds: HOUSTON_LATLNG_BOUNDS,
    strictBounds: true,
  };

  const onAutocomplete = (place: google.maps.places.PlaceResult) => {
    const { getField } = buildGetAddressComponentFields(
      place.address_components,
    );
    setPlacesInfo({
      ...placesInfo,
      place_id: place.place_id,
      name: place.name,
      address1: `${getField('street_number')} ${getField('route')}`,
      city: getField('locality'),
      state: getField('administrative_area_level_1'),
      zip_code: getField('postal_code'),
      coords: [place.geometry.location.lng(), place.geometry.location.lat()],
      photo: place.photos ? place.photos[0].getUrl() : '',
    });
  };

  return (
    <St.Div mx={16} mb={16}>
      <St.Text fontSize="20px" fontWeight="800">
        {isEdit ? `Edit ${placesInfo.name}` : 'Add New Church'}
      </St.Text>
      <Form className="block" onSubmit={submit} onChange={onFormInput}>
        <St.TwoColumnLayout>
          <div style={{ flex: 1, flexGrow: 1 }}>
            <St.Row justifyContent="start" width="100%">
              <St.Text fontWeight="800">Church Details</St.Text>
            </St.Row>
            <Wrapper my={INPUT_MARGIN}>
              <ChurchInput
                options={options}
                onPlaceSelected={onAutocomplete}
                name="name"
                id="name"
                value={placesInfo.name}
              />
              <Icon src="/images/location.png" />
            </Wrapper>
            <St.TextInput
              type="text"
              name="address1"
              id="address1"
              placeholder="Address 1"
              required
              value={placesInfo.address1}
              my={INPUT_MARGIN}
            />
            <St.TextInput
              type="text"
              name="address2"
              id="address2"
              placeholder="Address 2"
              value={placesInfo.address2}
              my={INPUT_MARGIN}
            />
            <St.Row
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'left',
              }}
            >
              <SmallInput
                type="text"
                name="city"
                id="city"
                placeholder="City"
                required
                value={placesInfo.city}
                my={INPUT_MARGIN}
              />
              <SmallInput
                type="text"
                name="state"
                id="state"
                placeholder="State"
                required
                value={placesInfo.state}
                mar={INPUT_MARGIN}
                mr={INPUT_MARGIN}
              />
              <SmallInput
                type="text"
                name="zip_code"
                id="zip_code"
                placeholder="Zip"
                required
                value={placesInfo.zip_code}
                mar={INPUT_MARGIN}
                ml={INPUT_MARGIN}
              />
            </St.Row>
            <St.LargeInput
              name="description"
              placeholder="Description"
              rows={4}
              cols={50}
              mt={INPUT_MARGIN}
              value={placesInfo.description}
            />
          </div>

          <div style={{ flex: 1, flexGrow: 1, minWidth: 300 }}>
            <St.Row justifyContent="start" width="100%">
              <St.Text fontWeight="800">Primary Contact</St.Text>
            </St.Row>
            <St.Row
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'left',
              }}
            >
              <St.TextInput
                type="text"
                name="primary_contact"
                id="primary_contact"
                placeholder="Contact Name"
                required
                my={INPUT_MARGIN}
                value={placesInfo.primary_contact}
              />
              <SmallInput
                type="text"
                name="primary_email"
                id="primary_email"
                placeholder="Email"
                required
                my={INPUT_MARGIN}
                value={placesInfo.primary_email}
              />
              <SmallInput
                type="text"
                name="primary_phone"
                id="primary_phone"
                placeholder="Phone"
                required
                my={INPUT_MARGIN}
                value={placesInfo.primary_phone}
              />
            </St.Row>
          </div>
        </St.TwoColumnLayout>

        <St.Div className="justify-end" mt={16} style={{ width: '100%' }}>
          <St.TertiaryButtonLink
            to={!isEdit ? routes.CHURCHES.href : null}
            mr={INPUT_MARGIN}
            mt={INPUT_MARGIN}
            onClick={() => {
              if (!!isEdit) toggleEditChurch();
            }}
          >
            Cancel
          </St.TertiaryButtonLink>
          <St.PrimaryButton
            type="submit"
            ml={INPUT_MARGIN}
            mt={INPUT_MARGIN}
            width="150px"
          >
            {isEdit ? 'Save Changes' : 'Add Church'}
          </St.PrimaryButton>
        </St.Div>
      </Form>
    </St.Div>
  );
};

export default ChurchForm;
