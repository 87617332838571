const eventManager = {
  _events: {},

  on(event, listener) {
    if (!this._events[event]) {
      this._events[event] = [];
    }
    this._events[event].push(listener);
  },

  off(event, listener) {
    if (this._events[event]) {
      this._events[event] = this._events[event].filter((l) => l !== listener);
    }
  },

  emit(event, data) {
    if (this._events[event]) {
      this._events[event].forEach((listener) => listener(data));
    }
  },
};

export default eventManager;
