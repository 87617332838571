import coalitions_api_set from 'api/coalitions';
import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';
import ChurchKeyInitiativeDetailsDTO from 'dtos/ChurchKeyInitiativeDetailsDTO';
import CoalitionKeyInitiativeDetailsDTO from 'dtos/CoalitionKeyInitiativeDetailsDTO';
import apis from '../api';
import PostDetailsDTO from '../dtos/PostDetailsDTO';
import AddKeyInitiativeDTO from 'dtos/AddKeyInitiativeDTO';
import { set_church_statistic_types } from './churchStatisticTypes';
import { add_program, set_church, set_churches } from './churches';
import {
  add_key_initiatives,
  add_new_key_initiatives,
  remove_key_initiative,
  set_coalitions,
  update_key_initiatives,
} from './coalitions';
import { set_domain, set_domains } from './domains';
import { set_events } from './events';
import {
  set_user_church_memberships,
  set_user_coalition_memberships,
  set_user_domain_memberships,
} from './memberships';
import { set_points_of_interest } from './pointsOfInterest';
import { edit_post, remove_post, set_post, set_posts } from './posts';
import { set_programs } from './programs';
import { set_logged_in, set_related_coalition_ids } from './user';

export const updateCurrentUser = (dispatch) => {
  apis.users
    .whoami()
    .then((result) => {
      if (!result) return;
      dispatch(set_logged_in(result));
    })
    .catch((error) => console.log(error));
};

export const updateCoalitions = (dispatch) => {
  apis.coalitions
    .get_all()
    .then((result) => {
      if (!result) return;
      dispatch(set_coalitions(result));
    })
    .catch((error) => console.log(error));
};
export const UpdateKeyInitiative = async (
  dispatch,
  editId: string,
  keyInitiative: CoalitionKeyInitiativeDetailsDTO,
  show,
) => {
  await apis.coalitions
    .update_key_initiative(editId, keyInitiative)
    .then(() => {
      const initiative = {
        keyInitiative: keyInitiative,
        coalitionId: keyInitiative.coalition_id,
        initiativeId: editId,
      };
      dispatch(update_key_initiatives(initiative));
      show('set-key-initiatives-success');
    });
};
export const UpdateChurchKeyInitiative = async (
  dispatch,
  church: ChurchDetailsDTO,
  updatedKeyInitiatives: ChurchKeyInitiativeDetailsDTO,
) => {
  if (church) {
    const updatedChurch = {
      ...church,
      key_initiatives: church.key_initiatives.map((keyInitiative) => {
        if (keyInitiative._id === updatedKeyInitiatives._id) {
          return updatedKeyInitiatives;
        } else {
          return keyInitiative;
        }
      }),
    };

    dispatch(set_church(updatedChurch));
  }
};

export const addKeyInitiative = async (
  dispatch,
  initiative: AddKeyInitiativeDTO,
  show,
): Promise<string | undefined> => {
  return await apis.coalitions.add_key_initiative(initiative).then((result) => {
    dispatch(
      add_new_key_initiatives({
        initiative: { ...initiative, _id: result },
      }),
    );
    show('set-key-initiatives-success');
    return result;
  });
};

export const addCoalitionKeyInitiatives = (id, dispatch, show) => {
  coalitions_api_set.get_key_initiatives(id).then((keyInitiatives) => {
    dispatch(add_key_initiatives({ id: id, keyInitiatives: keyInitiatives }));
  });
};
export const addNewCoalitionKeyInitiatives = (id, dispatch, show) => {
  coalitions_api_set.get_key_initiatives(id).then((keyInitiatives) => {
    dispatch(add_key_initiatives({ id: id, keyInitiatives: keyInitiatives }));
  });
  show('set-key-initiatives-success');
};
export const removeCoalitionKeyInitiatives = async (
  dispatch,
  coalitionId: string,
  initiativeId: string,
  show,
) => {
  await coalitions_api_set.remove_key_initiative(initiativeId).then(() => {
    dispatch(
      remove_key_initiative({
        coalitionId,
        initiativeId,
      }),
    );
  });
  show('set-key-initiatives-success');
};
export const removeChurchKeyInitiatives = async (
  dispatch,
  church: ChurchDetailsDTO,
  initiativeId: string,
) => {
  if (church) {
    const updatedChurch = {
      ...church,
      key_initiatives: church.key_initiatives.filter(
        (keyInitiative) => keyInitiative._id !== initiativeId,
      ),
    };
    dispatch(set_church(updatedChurch));
  }
};
export const updateDomains = (dispatch) => {
  apis.domains
    .get_all()
    .then((result) => {
      if (!result) return;
      dispatch(set_domains(result));
    })
    .catch((error) => console.log(error));
};

export const updateDomainById = (dispatch, id: string) => {
  apis.domains
    .get_by_id(id)
    .then((domainRes) => {
      if (!domainRes) return;
      dispatch(set_domain(domainRes));
    })
    .catch((error) => console.log(error));
};

export const getUserChurchMemberships = (dispatch, id: string) => {
  apis.churches
    .get_user_memberships(id)
    .then((result) => {
      dispatch(set_user_church_memberships(result));
    })
    .catch((error) => console.log(error));
};

export const getUserCoalitionMemberships = (dispatch, user_id: string) => {
  apis.coalitions
    .get_user_memberships(user_id)
    .then((result) => {
      dispatch(set_user_coalition_memberships(result));
    })
    .catch((error) => console.log(error));
};

export const getUserDomainMemberships = (dispatch, id: string) => {
  apis.domains
    .get_user_memberships(id)
    .then((result) => {
      dispatch(set_user_domain_memberships(result));
    })
    .catch((error) => console.log(error));
};

export const updateChurches = (dispatch) => {
  apis.churches
    .get_all()
    .then((result) => {
      if (!result) return;
      dispatch(set_churches(result));
    })
    .catch((error) => console.log(error));
};

export const updateChurchById = (dispatch, id: string) => {
  apis.churches
    .get_by_id(id)
    .then((result) => {
      if (!result) return;
      const church = { ...result };
      dispatch(set_church(church));
    })
    .catch((error) => console.log(error));
};

export const updatePointsOfInterest = (
  dispatch,
  selectedDomainIds: string[],
) => {
  apis.points_of_interest
    .get_all(selectedDomainIds)
    .then((result) => {
      if (!result) return;
      dispatch(set_points_of_interest(result));
    })
    .catch((error) => console.log('Here error', error));
};

export const updatePrograms = (dispatch) => {
  apis.general
    .get_all_programs()
    .then((result) => dispatch(set_programs(result ?? [])))
    .catch((error) => console.log(error));
};

export const updatePosts = (dispatch) => {
  apis.posts.get_all().then((result) => {
    if (!result) return;
    dispatch(set_posts(result));
  });
};

export const updateEvents = (dispatch, id: string) => {
  apis.events.get_for_user(id).then((result) => {
    if (!result) return;
    dispatch(set_events(result));
  });
};

export const getEventsForChurch = (dispatch, church_id: string) => {
  apis.events.get_for_church(church_id).then((result) => {
    if (!result) return;
    dispatch(set_events(result));
  });
};

export const getEventsForDomain = (dispatch, domain_id: string) => {
  apis.events.get_for_domain(domain_id).then((result) => {
    if (!result) return;
    dispatch(set_events(result));
  });
};

export const addPost = (dispatch, post: PostDetailsDTO) => {
  dispatch(set_post(post));
};

export const updateRelatedCoalitionObjs = (ids, dispatch) => {
  if (!ids) return;
  dispatch(set_related_coalition_ids(ids));
};

export const updatePost = (post, dispatch, navigateCallback, show) => {
  apis.posts.update_post(post._id, post).then(() => {
    const newPost = post;
    dispatch(edit_post(newPost));
    navigateCallback(`/posts/${post._id}`);
    show('post-updated-success');
  });
};
export const deletePost = (id, dispatch, navigateCallback, show) => {
  apis.posts
    .update_post(id, { _IsActive: false })
    .then(() => {
      dispatch(remove_post(id));
      navigateCallback('/');
      show('post-deleted-success');
    })
    .catch((error) => {
      console.error('An error occurred while deleting the post:', error);
    });
};

export const updateChurchStatisticTypes = (dispatch) => {
  apis.church_statistics
    .get_all_types()
    .then((types) => {
      if (!types) return;
      dispatch(set_church_statistic_types(types));
    })
    .catch((error) => console.log(error));
};
export const addProgram = async (dispatch, program): Promise<string> => {
  return await apis.churches.add_program(program).then((response) => {
    dispatch(add_program({ ...program, _id: response }));
    return response;
  });
};
