import * as St from 'components/Style.styled';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { X } from '../../config/icons';
import { defaultMapFilters } from '../../dtos/DemographicFiltersDTO';
import Demographic from '../../types/Demographic';
import eventManager from '../../utils/eventManager';
import { educationLevels, ethnicGroups, incomeBands } from './config/config';
import { UpdateFilterDomainsDTO } from './config/types';
import { updateFilterDomains, updateMapFilters } from './utils';

const ItemContainer = styled.div`
  border-radius: 4px;
  height: 20px;
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 5px;
`;

const FiltersColumn: React.FC = () => {
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [mapFilters, setMapFilters] = useState(defaultMapFilters);
  const onEvent = (data: UpdateFilterDomainsDTO) => {
    setFilteredDomains(data.filteredDomainIds);
    setDomains(data.domains);
  };

  useEffect(() => {
    eventManager.on('filteredDomainsWithDomains:update', onEvent);
    eventManager.on('filteredDomains:update', setFilteredDomains);
    eventManager.on('mapFilters:update', setMapFilters);

    return () => {
      eventManager.off('filteredDomainsWithDomains:update', onEvent);
      eventManager.off('filteredDomains:update', setFilteredDomains);
      eventManager.off('mapFilters:update', setMapFilters);
    };
  }, []);

  const onRemoveDomainFilter = (domainId: string) => {
    updateFilterDomains(filteredDomains.filter((id) => domainId !== id));
  };
  const onRemoveDemographicFilter = (
    demographic: Demographic,
    field: string,
  ) => {
    updateMapFilters({
      ...mapFilters,
      [field]: mapFilters[field].filter((d) => d !== demographic),
    });
  };

  return (
    <St.Column alignItems="flex-start">
      {filteredDomains.map((domainId) => (
        <ItemContainer>
          <St.Text>
            {domains.find((domain) => domainId === domain.domainId)?.name}
          </St.Text>
          <St.AnimatedIcon
            src={X}
            width="10px"
            ml="10px"
            onClick={() => onRemoveDomainFilter(domainId)}
          />
        </ItemContainer>
      ))}
      {mapFilters.income_bands.map((incomeBand) => (
        <ItemContainer>
          {
            incomeBands.find(({ demographic }) => incomeBand === demographic)
              ?.name
          }
          <St.AnimatedIcon
            src={X}
            width="10px"
            ml="10px"
            onClick={() =>
              onRemoveDemographicFilter(incomeBand, 'income_bands')
            }
          />
        </ItemContainer>
      ))}
      {mapFilters.ethnic_groups.map((ethnicGroup) => (
        <ItemContainer>
          {
            ethnicGroups.find(({ demographic }) => ethnicGroup === demographic)
              ?.name
          }
          <St.AnimatedIcon
            src={X}
            width="10px"
            ml="10px"
            onClick={() =>
              onRemoveDemographicFilter(ethnicGroup, 'ethnic_groups')
            }
          />
        </ItemContainer>
      ))}
      {mapFilters.education_levels.map((educationLevel) => (
        <ItemContainer>
          {
            educationLevels.find(
              ({ demographic }) => educationLevel === demographic,
            )?.name
          }
          <St.AnimatedIcon
            src={X}
            width="10px"
            ml="10px"
            onClick={() =>
              onRemoveDemographicFilter(educationLevel, 'education_levels')
            }
          />
        </ItemContainer>
      ))}
    </St.Column>
  );
};

export default FiltersColumn;
