export enum IncomeBand {
  BAND0, // $0 - $14,999
  BAND1, // $15,000 - $19,999
  BAND2, // $20,000 - $29,999
  BAND3, // $30,000 - $39,999
  BAND4, // $40,000 - $49,999
  BAND5, // $50,000 - $74,999
  BAND6, // $75,000 - $99,999
  BAND7, // $100,000 - $124,999
  BAND8, // $125,000 - $149,999
  BAND9, // $150,000 - $174,999
  BAND10, // $175,000 - $199,999
  BAND11, // $200,000 - $249,999
  BAND12, // $250,000 or More
}

export enum EthnicGroup {
  UNKNOWN,
  WESTERN_EUROPEAN,
  EASTERN_EUROPEAN,
  HISPANIC,
  POLYNESIAN,
  MEDITERRANEAN,
  JEWISH,
  AFRICAN_AMERICAN,
  SOUTHEAST_ASIAN,
  CENTRAL_SOUTHWEST_ASIAN,
  SCANDINAVIAN,
  FAR_EASTERN,
  MIDDLE_EASTERN,
  NATIVE_AMERICAN,
  OTHER,
}

export enum EducationLevel {
  UNKNOWN,
  HIGH_SCHOOL,
  SOME_COLLEGE,
  COLLEGE,
  GRADUATE_SCHOOL,
}

type Demographic = IncomeBand | EthnicGroup | EducationLevel;

export default Demographic;
