import apis from 'api';
import { TrashRedIcon } from 'config/icons';
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CheckboxDropdown, {
  DropdownItem,
} from '../../components/CheckboxDropdown';
import EditButtons from '../../components/EditButtons';
import * as St from '../../components/Style.styled';
import { ToastContext } from '../../contexts/ToastContext';
import ChurchStatisticDetailsDTO from '../../dtos/ChurchStatisticDetailsDTO';
import ChurchStatisticTypeDetailsDTO from '../../dtos/ChurchStatisticTypeDetailsDTO';
import { useAppDispatch, useGetDomains } from '../../store/hooks';
import { areChurchStatisticsEqual } from '../../utils';
import { toDropdownItem } from '../../utils/toDropdownItems';
import { ChurchProfileParams } from './index';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
`;

const Input = styled.input`
  ${St.textInputStyles}
  width: 100%;
  height: 22px;
`;

const INPUT_MARGIN = '7px';
const Form = styled.form`
  display: flex;
`;

const EditChurchStatistics: React.FC = () => {
  const { id } = useParams<ChurchProfileParams>();
  const dispatch = useAppDispatch();
  const { show } = useContext(ToastContext);
  const domains = useGetDomains();
  const [statisticTypes, setStatisticTypes] = useState<
    ChurchStatisticTypeDetailsDTO[]
  >([]);
  const [assets, setAssets] = useState<ChurchStatisticDetailsDTO[]>([]);
  const [modifiedRows, setModifiedRows] = useState<ChurchStatisticDetailsDTO[]>(
    [],
  );

  const onClickDomainItem = (
    item: DropdownItem,
    row: ChurchStatisticDetailsDTO,
  ) => {
    setModifiedRows(
      modifiedRows.map((a) =>
        a._id === row._id ? { ...a, domain_id: item.id } : a,
      ),
    );
  };

  const onClickTypeItem = (
    item: DropdownItem,
    row: ChurchStatisticDetailsDTO,
  ) => {
    setModifiedRows(
      modifiedRows.map((a) =>
        a._id === row._id ? { ...a, type_id: item.id } : a,
      ),
    );
  };

  const refreshProfile = useCallback(() => {
    apis.churches.get_church_statistics(id).then((result) => {
      if (!result) return;
      setModifiedRows(result);
      setAssets(result);
    });
  }, [dispatch, id]);

  useEffect(() => {
    apis.church_statistics.get_all_types().then((resAssetTypes) => {
      if (!resAssetTypes) return;
      setStatisticTypes(resAssetTypes);
    });
  }, []);

  useEffect(() => {
    refreshProfile();
  }, [id, refreshProfile]);

  const statisticDomainItems = (
    stat: ChurchStatisticDetailsDTO,
  ): DropdownItem[] => {
    return domains.map((d) =>
      d._id === stat.domain_id
        ? { ...toDropdownItem(d), checked: true }
        : toDropdownItem(d),
    );
  };

  const statisticTypeItems = (
    stat: ChurchStatisticDetailsDTO,
  ): DropdownItem[] => {
    return statisticTypes.map((type) =>
      type._id === stat.type_id
        ? { ...toDropdownItem(type), checked: true }
        : toDropdownItem(type),
    );
  };

  const onInput = (e: FormEvent<HTMLInputElement>, statisticId: string) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;
    setModifiedRows(
      modifiedRows.map((row) =>
        row._id === statisticId
          ? {
              ...row,
              [field]: field === 'quantity' ? parseInt(value) : value,
            }
          : row,
      ),
    );
  };

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const changedRows = modifiedRows.filter(
      (row) =>
        !areChurchStatisticsEqual(row, assets.find((a) => a._id === row._id)!),
    );

    const promises = changedRows.map((stat) =>
      apis.churches.update_church_statistic(id, {
        _id: stat._id,
        description: stat.description,
        asset_type_id: stat.type_id,
        domain_id: stat.domain_id,
        quantity: stat.quantity,
        more_info: stat.more_info,
      }),
    );

    Promise.all(promises).then(() => {
      show('update-church-statistics-success');
      refreshProfile();
    });
  };

  const onClickTrash = async (id: string) => {
    await apis.churches.delete_church_statistic(id).then(() => {
      show('update-church-statistics-success');
      refreshProfile();
    });
  };

  return (
    <Container>
      <St.Text fontSize="20px" fontWeight="800">
        Edit Church Statistics
      </St.Text>
      <Form className="flex-row" onSubmit={submit}>
        <St.Column alignItems="flex-start" mt="20px" width="100%">
          <St.Row justifyContent="space-between">
            <St.Text fontWeight="800" mb="10px">
              Existing statistics
            </St.Text>
            <EditButtons onClickEdit={null} />
          </St.Row>
          <St.Table width="100%">
            <thead>
              <St.THeaderRow>
                <th>Statistic Type</th>
                <th>Domain</th>
                <th>Quantity</th>
                <th>Description</th>
                <th>Additional info</th>
              </St.THeaderRow>
            </thead>
            <tbody>
              {modifiedRows.map((asset) => {
                const type = statisticTypes.find(
                  (stat) => stat._id === asset.type_id,
                );
                const domain = domains.find((d) => d._id === asset.domain_id);
                return (
                  <St.Tr key={asset._id}>
                    <St.Td>
                      <CheckboxDropdown
                        items={statisticTypeItems(asset)}
                        onClickItem={(item) => onClickTypeItem(item, asset)}
                        width="200px"
                      >
                        {type?.name ?? 'Select type'}
                      </CheckboxDropdown>
                    </St.Td>
                    <St.Td>
                      <CheckboxDropdown
                        items={statisticDomainItems(asset)}
                        onClickItem={(item) => onClickDomainItem(item, asset)}
                        width="150px"
                      >
                        {domain?.name ?? 'None'}
                      </CheckboxDropdown>
                    </St.Td>
                    <St.Td>
                      {type?.has_quantity ? (
                        <Input
                          defaultValue={asset.quantity}
                          name="quantity"
                          type="number"
                          placeholder="Statistic quantity"
                          onInput={(e) => onInput(e, asset._id)}
                        />
                      ) : (
                        'N/A'
                      )}
                    </St.Td>
                    <St.Td>
                      <Input
                        defaultValue={asset.description}
                        name="description"
                        placeholder="Statistic Description"
                        onInput={(e) => onInput(e, asset._id)}
                      />
                    </St.Td>
                    <St.Td>
                      <Input
                        defaultValue={asset.more_info}
                        name="more_info"
                        placeholder="e.g https://..."
                        onInput={(e) => onInput(e, asset._id)}
                      />
                    </St.Td>
                    <St.Td>
                      <St.AnimatedIcon
                        src={TrashRedIcon}
                        onClick={() => onClickTrash(asset._id)}
                        size="16px"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: 'tween', duration: 0.1 }}
                        mr="10px"
                      />
                    </St.Td>
                  </St.Tr>
                );
              })}
            </tbody>
          </St.Table>

          <St.Row className="justify-start" style={{ width: '100%' }}>
            <St.TertiaryButtonLink
              to={`/churches/${id}`}
              mr={INPUT_MARGIN}
              mt={INPUT_MARGIN}
            >
              Back
            </St.TertiaryButtonLink>
            <St.PrimaryButton
              type="submit"
              ml={INPUT_MARGIN}
              mt={INPUT_MARGIN}
              width="150px"
            >
              Save changes
            </St.PrimaryButton>
          </St.Row>
        </St.Column>
      </Form>
    </Container>
  );
};

export default EditChurchStatistics;
