import { createContext, useState } from 'react';

const ModalContext = createContext({
  signInOpen: false,
  signUpOpen: false,
  removeEventOpen: false,
  editChurchOpen: false,
  churchMembersOpen: false,
  toggleSignInOpen: () => null,
  toggleSignUpOpen: () => null,
  openSignInModal: () => null,
  closeSignInModal: () => null,
  openRemoveEvent: () => null,
  closeRemoveEvent: () => null,
  openChurchMembers: () => null,
  closeChurchMembers: () => null,
  toggleEditChurch: () => null,
});

const ModalContextProvider = ({ children }) => {
  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [removeEventOpen, setRemoveEvent] = useState(false);
  const [editChurchOpen, setEditChurchOpen] = useState(false);
  const [churchMembersOpen, setChurchMembersOpen] = useState(false);

  const toggleSignInOpen = () => {
    if (signUpOpen) setSignUpOpen(false);
    setSignInOpen(!signInOpen);
  };
  const toggleSignUpOpen = () => {
    if (signInOpen) setSignInOpen(false);
    setSignUpOpen(!signUpOpen);
  };

  const toggleEditChurch = () => {
    if (editChurchOpen) setEditChurchOpen(false);
    setEditChurchOpen(!editChurchOpen);
  };

  const toggleRemoveEventOpen = () => {
    if (removeEventOpen) setRemoveEvent(false);
    setRemoveEvent(!removeEventOpen);
  };

  const toggleChurchMembersOpen = () => {
    if (churchMembersOpen) setChurchMembersOpen(false);
    setChurchMembersOpen(!churchMembersOpen);
  };

  const openSignInModal = () => {
    if (!signInOpen) toggleSignInOpen();
  };
  const closeSignInModal = () => {
    if (signInOpen) toggleSignInOpen();
  };

  const openRemoveEvent = () => {
    if (!removeEventOpen) toggleRemoveEventOpen();
  };

  const closeRemoveEvent = () => {
    if (removeEventOpen) toggleRemoveEventOpen();
  };

  const openChurchMembers = () => {
    if (!churchMembersOpen) toggleChurchMembersOpen();
  };

  const closeChurchMembers = () => {
    if (churchMembersOpen) toggleChurchMembersOpen();
  };

  return (
    <ModalContext.Provider
      value={{
        signInOpen,
        signUpOpen,
        removeEventOpen,
        editChurchOpen,
        churchMembersOpen,
        toggleSignInOpen,
        openSignInModal,
        closeSignInModal,
        toggleSignUpOpen,
        openRemoveEvent,
        closeRemoveEvent,
        openChurchMembers,
        closeChurchMembers,
        toggleEditChurch,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextProvider };
