import { DropdownItem } from '../components/CheckboxDropdown';

interface IDropdownDTO {
  _id?: string;
  id: string | number;
  name: string;
}

const toDropdownItems = (dtos: IDropdownDTO[]): DropdownItem[] => {
  return dtos.map(toDropdownItem);
};

export const toDropdownItem = (dto: IDropdownDTO) => ({
  id: dto._id ?? dto.id,
  name: dto.name,
  checked: false,
});

export default toDropdownItems;
