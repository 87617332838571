export const defaultObjectDataState = <
  TDataDTO,
>(): ObjectDataState<TDataDTO> => ({
  data: {},
  loaded: false,
});

export default interface ObjectDataState<TDataDTO> {
  data: { [id: string | number]: TDataDTO };
  loaded: boolean;
}
