import React, { useCallback } from 'react';
import apis from '../../api';
import * as St from '../../components/Style.styled';
import { DomainsIconBlack } from '../../config/icons';
import DomainMemberDetailsDTO from '../../dtos/DomainMemberDetailsDTO';
import { useGetDomainById } from '../../store/hooks';
import DomainMemberRole from '../../types/DomainMemberRole';
import { formatDuration } from '../../utils/date';

interface Props {
  domainMember: DomainMemberDetailsDTO;
  refreshDomainRequests: () => void;
}

const DomainRequestNotificationCard: React.FC<Props> = ({
  domainMember,
  refreshDomainRequests,
}) => {
  const acknowledged = domainMember.level === DomainMemberRole.EXPERT;
  const domain = useGetDomainById(domainMember.domain_id);
  const onApproveExpertRequest = useCallback(async () => {
    await apis.domains.approve_expert_status(
      domainMember.domain_id,
      domainMember.user_id,
    );
    refreshDomainRequests();
  }, []);
  const timeSinceCreated = formatDuration(
    (Date.now() - Date.parse(domainMember.created_at)) / 1000,
    false,
    true,
  );

  return (
    <St.NotificationCard>
      <St.Icon
        src={DomainsIconBlack}
        size="20px"
        mr="10px"
        style={{ display: 'inline' }}
      />
      <p style={{ marginRight: 'auto' }}>
        <b>{domainMember.user?.name}</b> requested to join the{' '}
        <b>{domain?.name}</b> domain as a partner.
      </p>
      <St.Text fontSize="12px" opacity={0.5} nowrap mx="10px">
        {timeSinceCreated} ago
      </St.Text>
      {acknowledged ? (
        <St.PrimaryButton type="button" disabled={acknowledged}>
          Approve
        </St.PrimaryButton>
      ) : (
        <St.Row gap="10px">
          {/*<St.SecondaryButton type="button">Decline</St.SecondaryButton>*/}
          <St.PrimaryButton type="button" onClick={onApproveExpertRequest}>
            Approve
          </St.PrimaryButton>
        </St.Row>
      )}
    </St.NotificationCard>
  );
};

export default DomainRequestNotificationCard;
