import { Dictionary } from '@reduxjs/toolkit';

export interface Route {
  href: string;
  googleScripts?: boolean;
  noAuth?: boolean;
}

export const routes: Dictionary<Route> = {
  HOME: { href: '/', noAuth: true },
  CHURCHES: { href: '/churches', googleScripts: true },
  ADD_CHURCH: { href: '/churches/add', googleScripts: true },
  UPDATE_CHURCH: { href: '/churches/:id/edit' },
  PENDING_CHURCHES: { href: '/churches/pending' },
  CHURCH_PROFILE: {
    href: '/churches/:id',
    googleScripts: true,
  },
  EDIT_CHURCH_STATISTICS: { href: '/churches/:id/statistics/edit' },
  ADD_CHURCH_STATISTICS: { href: '/churches/:id/statistics/add' },
  REGISTER_USER_COMPLETE: { href: '/users/register_complete' },
  LOGIN: { href: '/users/login' },
  ADMIN: { href: '/admin' },
  COMMUNITY_MAP: { href: '/community-map', googleScripts: true },
  COALITIONS: { href: '/coalitions' },
  COALITION_PROFILE: { href: '/coalitions/:id' },
  CALENDAR: { href: '/calendar' },
  PROFILE: { href: '/profile' },
  DOMAINS: { href: '/domains' },
  ADD_DOMAIN: { href: '/domains/add' },
  DOMAIN_PROFILE: { href: '/domains/:id' },
  USER_PROFILE: { href: '/users/:id' },
  EDIT_USER_PROFILE: { href: '/users/:id/edit' },
  CREATE_POST: { href: '/posts/create' },

  EDIT_POST: { href: '/edit/:id/post' },
  POST: { href: '/posts/:id' },
  CREATE_EVENT: { href: '/events/create' },
  CREATE_CHURCH_EVENT: { href: '/events/:id/create' },
  EDIT_EVENT: { href: '/calendar/:id/edit' },
  NOTIFICATIONS: { href: '/notifications' },
  RETOOL: {
    href: 'https://splinteredglass.retool.com/embedded/public/be4c978b-6093-4613-96e8-057eb6cad5bf',
  },
};

export default routes;
