import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';
import { defaultArrayDataState } from '../types/ArrayDataState';

export const programSlice = createSlice({
  name: 'programs',
  initialState: defaultArrayDataState<ProgramDetailsDTO>(),
  reducers: {
    set_programs: (state, action: PayloadAction<ProgramDetailsDTO[]>) => {
      state.data = action.payload;
      state.loaded = true;
    },
  },
});

export const { set_programs } = programSlice.actions;
export default programSlice.reducer;
