import { useCallback } from 'react';
import DomainId from 'types/DomainId';
import apis from '../api';
import {
  getUserChurchMemberships,
  getUserCoalitionMemberships,
  getUserDomainMemberships,
  updateCurrentUser,
} from '../store/helpers';
import { useAppDispatch } from '../store/hooks';

export const useJoinDomain = () => {
  const dispatch = useAppDispatch();
  const onJoinDomain = useCallback(
    async (domain_id: string, church_id: string, user_id: string) => {
      await apis.domains.join(church_id, domain_id);
      updateCurrentUser(dispatch);
      getUserChurchMemberships(dispatch, user_id);
      getUserCoalitionMemberships(dispatch, user_id);
      getUserDomainMemberships(dispatch, user_id);
    },
    [dispatch],
  );
  return {
    onJoinDomain,
  };
};

export const useRequestDomainExpertStatus = () => {
  const dispatch = useAppDispatch();
  const onRequestDomainExpertStatus = useCallback(
    async (id: DomainId) => {
      await apis.domains.request_expert_status(id);
      updateCurrentUser(dispatch);
    },
    [dispatch],
  );
  return {
    onRequestDomainExpertStatus,
  };
};

export const useJoinCoalition = () => {
  const dispatch = useAppDispatch();
  const onJoinCoalition = useCallback(
    async (church_id: string, coalition_id: string, user_id: string) => {
      await apis.coalitions.join(coalition_id, church_id, user_id);
      updateCurrentUser(dispatch);
      getUserChurchMemberships(dispatch, user_id);
      getUserCoalitionMemberships(dispatch, user_id);
      getUserDomainMemberships(dispatch, user_id);
    },
    [dispatch],
  );
  return {
    onJoinCoalition,
  };
};

export const useJoinChurch = () => {
  const dispatch = useAppDispatch();
  const onJoinChurch = useCallback(
    async (user_id: string, church_id: string) => {
      await apis.churches.join(user_id, church_id);
      updateCurrentUser(dispatch);
      getUserChurchMemberships(dispatch, user_id);
      getUserCoalitionMemberships(dispatch, user_id);
      getUserDomainMemberships(dispatch, user_id);
    },
    [dispatch],
  );
  return {
    onJoinChurch,
  };
};

export const useGetUserMemberships = () => {
  const dispatch = useAppDispatch();
  const onGetMemberships = useCallback(
    async (user_id: string) => {
      getUserChurchMemberships(dispatch, user_id);
      getUserCoalitionMemberships(dispatch, user_id);
      getUserDomainMemberships(dispatch, user_id);
    },
    [dispatch],
  );
  return {
    onGetMemberships,
  };
};
