import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import { ChurchProfileParams } from '../ChurchProfile';
import apis from 'api';
import { DropdownItem } from 'components/CheckboxDropdown';
import ChurchForm from 'views/ChurchForm';
import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';
import { ModalContext } from 'contexts/ModalContext';

interface ChurchMembersModalProps {
  church: ChurchDetailsDTO;
  refreshChurch: () => void;
}

const EditChurchModal: React.FC<ChurchMembersModalProps> = ({
  church,
  refreshChurch,
}) => {
  const { id } = useParams<ChurchProfileParams>();
  const { editChurchOpen, toggleEditChurch } = React.useContext(ModalContext);

  const [placesInfo, setPlacesInfo] = useState({
    place_id: '',
    name: '',
    description: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    photo: '',
    coords: [0, 0] as [number, number],
    primary_contact: '',
    primary_email: '',
    primary_phone: '',
  });
  const [coalitions, setCoalitions] = useState([] as DropdownItem[]);

  useEffect(() => {
    apis.coalitions.get_all().then((res) => {
      if (!res) return;
      setCoalitions(
        res.map((c) => ({
          id: c._id,
          name: c.name,
          checked: false,
        })),
      );
    });
  }, []);

  const submit = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const json = {
      name: form.name.value,
      address1: form.address1.value,
      address2: form.address2.value,
      city: form.city.value,
      state: form.state.value,
      zip_code: form.zip_code.value,
      description: form.description.value,
      image: placesInfo.photo,
      // longitude: placesInfo.coords[0],
      // latitude: placesInfo.coords[1],
      // key_initiatives: [],
      primary_contact: form.primary_contact.value,
      primary_phone: form.primary_phone.value,
      primary_email: form.primary_email.value,
      coalitions: coalitions.filter((c) => c.checked).map((c) => c.id),
    };
    const result = await apis.churches.update(id, json);

    if (!result) return alert('Error updating church details.');
    else {
      toggleEditChurch();
      refreshChurch();
    }
  };

  const onFormInput = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    if (field in placesInfo) {
      setPlacesInfo({ ...placesInfo, [field]: value });
    }
  };

  useEffect(() => {
    if (!!church) {
      setPlacesInfo({
        place_id: church.google_places_id,
        name: church.name,
        description: church.description,
        address1: church.address1,
        address2: church.address2,
        city: church.city,
        state: church.state,
        zip_code: church.zip_code,
        photo: church.image,
        coords: [church.longitude, church.latitude],
        primary_contact: church.primary_contact,
        primary_email: church.primary_email,
        primary_phone: church.primary_phone,
      });
    }
  }, [church]);

  useEffect(() => {
    if (!!church && coalitions.length > 0) {
      setCoalitions(
        coalitions.map((c) => {
          return {
            ...c,
            checked: church.coalition_id && church.coalition_id == c.id,
          };
        }),
      );
    }
  }, [coalitions.length, church]);

  return (
    <Modal
      height="80vh"
      width="65vw"
      isOpen={editChurchOpen}
      requestClose={toggleEditChurch}
    >
      <ChurchForm
        onFormInput={onFormInput}
        placesInfo={placesInfo}
        setPlacesInfo={setPlacesInfo}
        submit={submit}
        isEdit={true}
      />
    </Modal>
  );
};
export default EditChurchModal;
