import { executor, getAxios } from 'api';
import CreateDomainDTO from 'dtos/CreateDomainDTO';
import DomainDetailsDTO from 'dtos/DomainDetailsDTO';
import UpdateDomainDTO from 'dtos/UpdateDomainDTO';
import DomainId from 'types/DomainId';
import AddSupportResourceDTO from '../dtos/AddSupportResourceDTO';
import DomainMemberDetailsDTO from '../dtos/DomainMemberDetailsDTO';
import UpdateSupportResourceDTO from '../dtos/UpdateSupportResourceDTO';
import DomainSupportResourceDetailsDTO from '../dtos/DomainSupportResourceDetailsDTO';

export interface DomainsApi {
  get_all: () => Promise<DomainDetailsDTO[] | undefined>;
  get_members: (domain_id: string) => Promise<DomainMemberDetailsDTO[]>;
  get_user_memberships: (user_id: string) => Promise<DomainMemberDetailsDTO[]>;
  get_all_with_experts: () => Promise<DomainDetailsDTO[] | undefined>;
  create: (dto: CreateDomainDTO) => Promise<DomainDetailsDTO | undefined>;
  update: (id: string, dto: UpdateDomainDTO) => Promise<void>;
  get_by_id: (id: string) => Promise<DomainDetailsDTO | undefined>;
  get_with_relations: (id: DomainId) => Promise<DomainDetailsDTO>;
  join: (church_id: string, domain_id: string) => Promise<void>;
  request_expert_status: (id: DomainId) => Promise<void>;
  approve_expert_status: (id: string, userId: string) => Promise<void>;
  get_incoming_expert_requests: () => Promise<DomainMemberDetailsDTO[]>;
  get_support_resource: (
    id: string,
  ) => Promise<DomainSupportResourceDetailsDTO[]>;
  add_support_resource: (
    id: string,
    dto: AddSupportResourceDTO,
  ) => Promise<string | undefined>;
  remove_support_resource: (resourceId: string) => Promise<void | undefined>;
  update_support_resource: (
    domainId: string,
    resourceId: string,
    dto: UpdateSupportResourceDTO,
  ) => Promise<void | undefined>;
}

const create = async (
  dto: CreateDomainDTO,
): Promise<DomainDetailsDTO | undefined> => {
  const action = async (): Promise<DomainDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'domains',
        update: dto,
      },
    });

    return result.data;
  };

  return await executor(action, 'create_domain');
};
const update = async (id: string, dto: UpdateDomainDTO): Promise<void> => {
  const action = async (): Promise<DomainDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: { collection: 'domains', update: { ...dto, _id: id } },
    });

    return result.data;
  };

  return await executor(action, 'update_domain');
};

const get_all = async (): Promise<DomainDetailsDTO[] | undefined> => {
  const action = async (): Promise<DomainDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: { collection: 'domains', query: { _IsActive: { $ne: false } } },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_all_domains');
};

const get_members = async (domain_id: string): Promise<DomainMemberDetailsDTO[]> => {
  const action = async (): Promise<DomainMemberDetailsDTO[]> => {
    const res = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'domain_users',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              domain_id,
            },
          },
          {
            $addFields: {
              _user_id: { $toObjectId: '$user_id' },
            },
          },
          {
            $lookup: {
              from: 'users',
              localField: '_user_id',
              foreignField: '_id',
              as: 'user',
            },
          },
          {
            $unwind: '$user',
          },
        ],
      },
    });

    return res?.data?.data ?? [];
  };

  return await executor(action, 'get_members');
};

const get_user_memberships = async (
  user_id: string,
): Promise<DomainMemberDetailsDTO[]> => {
  const action = async (): Promise<DomainMemberDetailsDTO[]> => {
    const church = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'church_members',
        query: { _IsActive: { $ne: false }, user_id },
      },
    });
    if (!church?.data?.data) return [];

    const church_id = church.data.data[0].church_id;
    const res = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'domain_members',
        query: [
          {
            $match: {
              _IsActive: { $ne: false },
              church_id,
            },
          },
          {
            $addFields: {
              _domain_id: {
                $toObjectId: '$domain_id',
              },
            },
          },
          {
            $lookup: {
              from: 'domains',
              localField: '_domain_id',
              foreignField: '_id',
              as: 'domain',
            },
          },
          {
            $unwind: '$domain',
          },
        ],
      },
    });

    return res?.data?.data ?? [];
  };

  return await executor(action, 'get_user_memberships');
};

const get_all_with_experts = async (): Promise<
  DomainDetailsDTO[] | undefined
> => {
  const action = async (): Promise<DomainDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'domains/with_experts',
    });

    return result.data;
  };

  return await executor(action, 'get_all_domains_with_experts');
};

const get_by_id = async (id: string): Promise<DomainDetailsDTO | undefined> => {
  const action = async (): Promise<DomainDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'domains',
        query: { _id: id, _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data[0] ?? undefined;
  };

  return await executor(action, 'get_domain_by_id');
};

const get_with_relations = async (id: DomainId): Promise<DomainDetailsDTO> => {
  const action = async (): Promise<DomainDetailsDTO> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'domains/' + id + '/with_relations',
    });

    return result.data;
  };

  return await executor(action, 'get_domain_with_relations');
};

const join = async (church_id: string, domain_id: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'domain_members',
        update: { church_id, domain_id, role: 'ro' },
      },
    });
  };

  return await executor(action, 'join_domain');
};

const request_expert_status = async (id: DomainId): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: 'domains/' + id + '/request_expert_status',
    });
  };

  return await executor(action, 'request_domain_expert_status');
};

const approve_expert_status = async (
  id: string,
  userId: string,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: `domains/${id}/approve_expert_status/${userId}`,
    });
  };

  return await executor(action, 'approve_domain_expert_status');
};

const get_incoming_expert_requests = async (): Promise<
  DomainMemberDetailsDTO[]
> => {
  const action = async (): Promise<DomainMemberDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: 'domains/incoming_expert_requests',
    });

    return result.data;
  };

  return await executor(action, 'get_incoming_domain_expert_requests');
};

const get_support_resource = async (
  id: string,
): Promise<DomainSupportResourceDetailsDTO[]> => {
  const action = async (): Promise<DomainSupportResourceDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'read',
      data: {
        collection: 'support_resources',
        query: { domain_id: id, _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_support_resource');
};

const add_support_resource = async (
  id: string,
  dto: AddSupportResourceDTO,
): Promise<string | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'write',
      data: {
        collection: 'support_resources',
        update: {
          ...dto,
          domain_id: id,
        },
      },
    });

    return result.data.data;
  };

  return await executor(action, 'add_support_resource');
};

const remove_support_resource = async (resourceId: string): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'support_resources',
        query: { _id: resourceId },
        update: { _IsActive: false },
      },
    });
  };

  await executor(action, 'remove_support_resource');
};

const update_support_resource = async (
  id: string,
  resourceId: string,
  dto: UpdateSupportResourceDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'write',
      data: {
        collection: 'support_resources',
        query: { _id: resourceId, domain_id: id },
        update: {
          ...dto,
          domain_id: id,
        },
      },
    });
  };

  await executor(action, 'update_support_resource');
};

const domains_api_set: DomainsApi = {
  get_all,
  get_members,
  get_user_memberships,
  get_all_with_experts,
  create,
  update,
  get_by_id,
  get_with_relations,
  join,
  request_expert_status,
  approve_expert_status,
  get_incoming_expert_requests,
  get_support_resource,
  add_support_resource,
  remove_support_resource,
  update_support_resource,
};
export default domains_api_set;
