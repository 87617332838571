import * as St from 'components/Style.styled';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import useOnGetDomainById from '../hooks/useOnGetDomainById';
import usePlacesService from '../hooks/usePlacesService';
import useUserAccess from '../hooks/useUserAccess';
import { delay } from '../utils/delay';
import { getChurchInfoWindowContentString } from '../utils/mapUtils';
import { POI_PAN_ZOOM } from './Map/config/config';

const Container = styled(St.Row)`
  width: 305px;
  height: 90px;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 4px;

  :hover {
    cursor: pointer;
  }
`;

interface SmallChurchCardProps {
  church: ChurchDetailsDTO;
  churchMarkers?: google.maps.Marker[];
  mapRef?: google.maps.Map;
  infoWindow?: google.maps.InfoWindow;
  linkToChurch?: boolean;
}

const UnstructuredChurchCard: React.FC<SmallChurchCardProps> = ({
  church,
  churchMarkers,
  mapRef,
  infoWindow,
  linkToChurch,
}) => {
  // todo add church coalition under the church name, if there's more than 1 show "coalition name + 2 more"
  const { isChurchMemberOf } = useUserAccess();
  const { onGetDomainById } = useOnGetDomainById();
  const [photo, setPhoto] = useState(null);
  const marker = churchMarkers?.find((m) => m && m['id'] === church._id);
  const service = usePlacesService();
  const navigate = useNavigate();
  useEffect(() => {
    if (!service) return;
    service.getDetails({ placeId: church.google_places_id }, (place) => {
      if (place?.photos && place.photos.length > 0)
        setPhoto(place.photos[0]?.getUrl());
    });
  }, [service]);

  const handleClick = async () => {
    if (linkToChurch) {
      navigate(`/churches/${church._id}`);
      return;
    }
    if (!mapRef) return;
    mapRef.setZoom(POI_PAN_ZOOM);
    // await delay(100);
    // mapRef.panTo({ lat: church.latitude, lng: church.longitude });
    await delay(500);

    infoWindow?.setContent(
      getChurchInfoWindowContentString(
        church,
        isChurchMemberOf(church),
        onGetDomainById,
      ),
    );
    infoWindow?.open({
      anchor: marker,
      map: mapRef,
    });
  };

  return (
    <Container onClick={handleClick}>
      <St.Column ml="8px" alignItems={'flex-start'}>
        <St.Text textDecorationLine="underline" fontWeight="800">
          {church.name}
        </St.Text>
        <St.Text>
          {church.description && church.description.length > 64
            ? church.description.substring(0, 64) + '...'
            : church.description}
        </St.Text>
      </St.Column>

      <img
        src={photo ?? '/images/churches/2.png'}
        style={{
          width: '48px',
          height: '48px',
          borderRadius: '25px',
          objectFit: 'cover',
          marginRight: '15px',
        }}
      />
    </Container>
  );
};

export default UnstructuredChurchCard;
