import { useGetCurrentUser, useGetMembershipsState } from 'store/hooks';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import DomainDetailsDTO from '../dtos/DomainDetailsDTO';
import ChurchMemberDetailsDTO from 'dtos/ChurchMemberDetailsDTO';
import { CoalitionMemberDetailsDTO } from 'dtos/CoalitionMemberDetailsDTO';

const useUserAccess = () => {
  const memberships = useGetMembershipsState();
  const { churches, coalitions, domains } = memberships.data ?? {};

  return {
    isDomainAdminOf: (domain: DomainDetailsDTO) =>
      (domains ?? []).some(
        (membership) =>
          membership.domain_id === domain._id && membership.role === 'rw',
      ),
    isDomainMemberOf: (domain: DomainDetailsDTO) =>
      (domains ?? []).some((membership) => membership.domain_id === domain._id),
    // isCoalitionAdminOf: (coalition: CoalitionDetailsDTO) =>
    //   (coalitions ?? []).some(
    //     (membership) =>
    //       membership.coalition_id === coalition._id && membership.role === 'rw',
    //   ),
    isCoalitionMemberOf: (coalition: CoalitionDetailsDTO) =>
      (coalitions ?? []).some(
        (membership) => membership.coalition_id === coalition._id,
      ),
    userCoalitionAffiliation: (): CoalitionMemberDetailsDTO | undefined =>
      coalitions[0],
    isChurchAdminOf: (church: ChurchDetailsDTO) =>
      (churches ?? []).some(
        (membership) =>
          membership.church_id === church._id && membership.role === 'rw',
      ),
    isChurchMemberOf: (church: ChurchDetailsDTO) =>
      (churches ?? []).some(
        (membership) => membership.church_id === church._id,
      ),
    churchIdIfAdmin: (): string | undefined => {
      const church = (churches ?? []).find(
        (membership) => membership.role === 'rw',
      );
      return church?.church_id;
    },
    userChurchAffiliation: (): ChurchMemberDetailsDTO | undefined =>
      churches[0],
  };
};

export const useCanEditDomain = (domain: DomainDetailsDTO) => {
  const currentUser = useGetCurrentUser();
  const { isDomainAdminOf } = useUserAccess();

  return isDomainAdminOf(domain) || currentUser.is_site_admin;
};

export default useUserAccess;
