import * as St from 'components/Style.styled';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import assetTypeIcons from '../config/assetTypeIcons';
import { QuestionMarkRed } from '../config/icons';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import ChurchStatisticDetailsDTO from '../dtos/ChurchStatisticDetailsDTO';
import ChurchStatisticTypeDetailsDTO from '../dtos/ChurchStatisticTypeDetailsDTO';
import usePlacesService from '../hooks/usePlacesService';
// import { useGetCurrentUser } from '../store/hooks';
import { stringNullOrEmpty } from '../utils/strings';
import Tooltip from './Tooltip';

const MARGIN = '5px';

const Container = styled.div<{ margin: string }>`
  width: calc(50% - (${(props) => props.margin} * 2));
  min-width: 450px;
  aspect-ratio: 1.6;
  background: rgba(217, 217, 217, 0.2);
  margin: ${(props) => props.margin};
  cursor: pointer;
`;

const Image = styled.div<{ src: string }>`
  width: 100%;
  height: 67%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/images/home-image-filter.png') no-repeat,
    url(${(props) => props.src}) no-repeat;
  background-position: bottom;
  background-size: cover;
`;

const ChurchName = styled.p`
  color: var(--color-hr-red);
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  padding: 3px 10px 3px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 5px;
`;

interface ChurchCardProps {
  church: ChurchDetailsDTO;
  tooltipMessage: string;
  placeholder: string;
  churchStatisticTypes: ChurchStatisticTypeDetailsDTO[];
}

const ChurchCard: React.FC<ChurchCardProps> = ({
  church,
  tooltipMessage,
  placeholder,
  churchStatisticTypes,
}) => {
  // const currentUser = useGetCurrentUser();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const placesService = usePlacesService();
  const renderStatistic = (asset: ChurchStatisticDetailsDTO) => {
    const churchStatisticType = churchStatisticTypes.find(
      (assetType) => assetType.id === asset.type_id,
    );

    return (
      <Stats>
        <St.Row className="items-center">
          <St.Icon
            src={assetTypeIcons[churchStatisticType?.name]}
            size="16px"
          />
          <St.Text fontWeight="800" ml="5px">
            {asset.quantity}
          </St.Text>
        </St.Row>
        <St.Text>{churchStatisticType?.name}</St.Text>
      </Stats>
    );
  };

  const renderTooltip = () => {
    if (stringNullOrEmpty(tooltipMessage)) return null;
    return (
      <Tooltip
        text={tooltipMessage}
        src={QuestionMarkRed}
        mr="10px"
        anchorTopRight
      />
    );
  };

  useEffect(() => {
    if (!placesService) return;
    placesService.getDetails({ placeId: church.google_places_id }, (place) => {
      if (place?.photos && place.photos.length > 0)
        setPhoto(place.photos[0]?.getUrl());
    });
  }, [placesService]);

  return (
    <Container margin={MARGIN} onClick={() => navigate(church._id)}>
      <Image src={photo ?? placeholder}>
        <St.Text color="white" fontWeight="900" fontSize="30px">
          {church.name}
        </St.Text>
      </Image>
      <St.Row justifyContent="flex-start">
        <ChurchName>{church.name}</ChurchName>
      </St.Row>
      <St.Row justifyContent="space-between">
        <Description>
          {!stringNullOrEmpty(church.description)
            ? church.description
            : 'No description'}
        </Description>
        {renderTooltip()}
      </St.Row>
      <St.Row className="justify-start" mb="5px">
        {church?.assets && church?.assets.map(renderStatistic)}
      </St.Row>
    </Container>
  );
};

export default ChurchCard;
