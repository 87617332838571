import * as St from 'components/Style.styled';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CalendarBlackIcon, CheckIcon } from '../config/icons';
import EventDetailsDTO from '../dtos/EventDetailsDTO';
import {
  useGetChurchById,
  useGetChurchMemberships,
  useGetCoalitionById,
  useGetCurrentUser,
  useGetDomainById,
} from '../store/hooks';
import { PoiMarkerIcon } from './Map/Icons';

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

const InfoTag = styled.div`
  border: 2px solid black;
  padding: 0 5px;
  border-radius: 12px;
`;

const EventContainer = styled(St.Column)<{ open: boolean }>`
  transition: 0.2s;
  border-radius: 4px;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  margin-right: auto;
  background-color: ${({ open }) =>
    open ? 'var(--color-light-grey)' : 'none'};

  :hover {
    background-color: var(--color-light-grey);
    cursor: pointer;
  }
`;

interface EventRowProps {
  event: EventDetailsDTO;
  hideChurch?: boolean;
}

const EventCard: React.FC<EventRowProps> = ({ event, hideChurch }) => {
  const [open, setOpen] = useState(false);
  const date = Date.parse(event.scheduled);
  const currentUser = useGetCurrentUser();
  const { churches } = useGetChurchMemberships();
  const church_id = churches[0]?.church_id;
  const canEdit =
    event.host_id === currentUser.id ||
    event.church_id === church_id ||
    currentUser.is_admin;

  const navigate = useNavigate();
  const dateString = useMemo(
    () => new Intl.DateTimeFormat('en-US', DATE_OPTIONS).format(date),
    [],
  );
  const coalition = useGetCoalitionById(event?.coalition_id);
  const domain = useGetDomainById(event?.domain_id);
  const church = useGetChurchById(event?.church_id);
  const tags: string[] = [];
  if (!hideChurch && church) tags.push(church.name);
  if (coalition) tags.push(coalition.name);
  if (domain) tags.push(domain.name);
  const toggleOpen = () => setOpen(!open);

  return (
    <EventContainer open={open} onClick={toggleOpen}>
      <St.Row gap="8px" justifyContent="flex-start" alignItems="center">
        <St.Icon src={CheckIcon} size="16px" />
        <St.Text fontWeight="800" nowrap>
          {event.name}
        </St.Text>
        on{' '}
        <St.Text fontWeight="800" nowrap>
          {dateString}
        </St.Text>
        {/*<St.AnimatedIcon*/}
        {/*  src={ShareIcon}*/}
        {/*  size="16px"*/}
        {/*  ml="auto"*/}
        {/*  mr="18px"*/}
        {/*  whileHover={{ scale: 1.2 }}*/}
        {/*  whileTap={{ scale: 0.9 }}*/}
        {/*  transition={{ type: 'spring', stiffness: 400, damping: 17 }}*/}
        {/*/>*/}
        {canEdit && (
          <St.AnimatedIcon
            src="/images/edit.svg"
            size="16px"
            ml="auto"
            mr="18px"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            onClick={() => navigate(`/calendar/${event._id}/edit`)}
          />
        )}
      </St.Row>
      {open && (
        <St.Column gap="10px">
          <St.Row justifyContent="flex-start" gap="10px" width="100%">
            {tags.map((tag) => (
              <InfoTag>
                <St.Text
                  textAlign="center"
                  lineHeight="20px"
                  verticalAlign="center"
                >
                  {tag}
                </St.Text>
              </InfoTag>
            ))}
          </St.Row>
          <St.Row
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
            gap="10px"
          >
            <St.Icon src={CalendarBlackIcon} size="20px" />
            <St.Text>{event.host?.name}</St.Text>
            {event.location && (
              <St.Row gap="5px">
                <St.Icon src={PoiMarkerIcon} size="15px" />
                {event.location}
              </St.Row>
            )}
          </St.Row>
        </St.Column>
      )}
    </EventContainer>
  );
};

export default EventCard;
