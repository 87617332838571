import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { updateRelatedCoalitionObjs } from 'store/helpers';
import styled from 'styled-components';
import CircleChart from '../../components/CircleChart';
import EventCard from '../../components/EventCard';
import StatisticHighlight from '../../components/StatisticHighlight';
import * as St from '../../components/Style.styled';
import Tabs from '../../components/Tabs';
import UnstructuredChurchCard from '../../components/UnstructuredChurchCard';
import UnstructuredPostCard from '../../components/UnstructuredPostCard';
import assetTypeIcons from '../../config/assetTypeIcons';
import routes from '../../config/routes';
import ChurchStatisticDetailsDTO from '../../dtos/ChurchStatisticDetailsDTO';
import CoalitionDetailsDTO from '../../dtos/CoalitionDetailsDTO';
import { useDollarContributionsChartData } from '../../hooks/dashboardHooks';
import {
  useFilteredChurches,
  useFilteredEvents,
} from '../../hooks/filterHooks';
import {
  useAppDispatch,
  useGetChurchStatisticTypes,
  useGetCoalitionsState,
  useGetCurrentUser,
  useGetDomains,
  useGetEvents,
  useGetRelatedCoalitionObjs,
} from '../../store/hooks';
import CoalitionKeyInitiatives from './CoalitionKeyInitiatives';
import DefaultHome from './DefaultHome';
import useUserAccess from 'hooks/useUserAccess';
import apis from 'api';
import PostDetailsDTOData from 'dtos/PostDetailsDTO';
import { filterPostByCoalition, filterPostByDomains } from 'utils/filterUtils';

const DASHBOARD_HEIGHT = '500px';
const DASHBOARD_WIDTH = 'calc(100vw - 700px)';

const StatisticHighlights = styled(St.Row)`
  border-bottom: 1px solid #dddddd;
  min-height: 143px;
`;

const TABS = ['Churches', 'Discussions', 'Key Initiatives', 'Events'];
4;

const Home: React.FC = () => {
  // todo make graph load in nice with the  recharts animation. it does so inconsistently rn
  const dispatch = useAppDispatch();
  const user = useGetCurrentUser();

  const coalitionsState = useGetCoalitionsState();
  const { userCoalitionAffiliation, userChurchAffiliation } = useUserAccess();
  const coalitionAffiliation = userCoalitionAffiliation();
  const queryParameters = new URLSearchParams(window.location.search);
  const coalitionId = queryParameters.get('coalition_id');

  const churchStatisticTypes = useGetChurchStatisticTypes();
  const domains = useGetDomains();
  const events = useGetEvents();
  const coalitionsObj = useGetRelatedCoalitionObjs();

  const [selectedCoalitions, setSelectedCoalitions] = useState<
    CoalitionDetailsDTO[]
  >([]);
  const [selectedDomainIds, setSelectedDomainIds] = useState<string[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<PostDetailsDTOData[]>([]);
  const [statistics, setStatistics] = useState<ChurchStatisticDetailsDTO[]>([]);

  useEffect(() => {
    if (coalitionsState.loaded) {
      if (coalitionId) {
        const coalition = coalitionsState.data.find(
          (c) => c._id === coalitionId,
        );
        setSelectedCoalitions([coalition]);
      } else {
        setSelectedCoalitions(
          coalitionAffiliation ? [coalitionAffiliation.coalition] : [],
        );
      }
    }
  }, [coalitionsState, coalitionAffiliation, coalitionId]);

  const getFilteredPosts = async () => {
    let posts = await apis.posts.get_all();
    posts = posts.filter(
      (post) =>
        filterPostByDomains(post, selectedDomainIds) &&
        filterPostByCoalition(post, [selectedCoalitions[0]?._id]),
      // || post.church_id === userChurchAffiliation().church_id // Include by church affiliation
      // || post.user_id === user._id // Include if user is author
    );
    setFilteredPosts(posts);
  };

  useEffect(() => {
    getFilteredPosts();
    if (!!selectedCoalitions[0]) {
      apis.general
        .get_coalition_statistics(selectedCoalitions[0]._id)
        .then((res) =>
          setStatistics(
            res.map((s) => ({
              ...s,
              coalition_id: selectedCoalitions[0]._id,
              type_id: s.asset_type_id,
            })),
          ),
        );
    }
  }, [selectedCoalitions[0]]);

  const chartData = useDollarContributionsChartData(
    selectedCoalitions.map((c) => c._id),
    selectedDomainIds,
  );
  const filteredChurches = useFilteredChurches(
    selectedDomainIds,
    selectedCoalitions.map((c) => c._id),
  );

  const filteredEvents = useFilteredEvents(
    [],
    selectedCoalitions.map((c) => c._id),
  );
  const userCoalitions = coalitionsState.data.filter((c) =>
    user.related_coalition_objs.some((u) => u === c._id),
  );

  const tabState = useState(0);

  useEffect(() => {
    updateRelatedCoalitionObjs(coalitionsObj, dispatch);
  }, [user.logged_in]);

  const groupedStatisticsByType = (): ChurchStatisticDetailsDTO[] => {
    if (
      !Array.isArray(churchStatisticTypes) ||
      churchStatisticTypes.length === 0
    )
      return [];
    const initialStatistics = churchStatisticTypes.reduce(
      (prev, type) => ({
        ...prev,
        [type._id]: {
          type,
          quantity: 0,
        },
      }),
      {},
    );

    const filteredStatistics = statistics.filter((s) =>
      selectedDomainIds.length > 0
        ? selectedDomainIds.includes(s.domain_id)
        : true,
    );

    filteredStatistics.forEach((s) => {
      initialStatistics[s.type_id].quantity += parseInt(s.quantity.toString());
    });
    return Object.keys(initialStatistics).map((type_id) => {
      const obj = initialStatistics[type_id];
      return {
        _id: '',
        id: type_id,
        type: obj.type,
        quantity: obj.quantity,
        type_id: '',
        church_id: '',
        description: '',
        more_info: '',
      };
    });
  };

  const onClickDomain = (domainId: string) => () => {
    if (selectedDomainIds.includes(domainId)) {
      setSelectedDomainIds(selectedDomainIds.filter((id) => id !== domainId));
    } else {
      setSelectedDomainIds([...selectedDomainIds, domainId]);
    }
  };

  const renderDynamicWindow = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return (
          <St.Row
            justifyContent="flex-start"
            mt="20px"
            gap="17px"
            overflowX="auto"
            maxHeight="200px"
          >
            {filteredChurches.length > 0
              ? filteredChurches.map((church) => (
                  <UnstructuredChurchCard
                    church={church}
                    key={church._id}
                    linkToChurch
                  />
                ))
              : 'No churches'}
          </St.Row>
        );
      case 1:
        return (
          <St.Column gap="16px" alignItems="flex-start">
            <St.Row
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              gap="16px"
              mt="10px"
              overflowX="auto"
              maxHeight="200px"
            >
              {filteredPosts.length > 0 ? (
                filteredPosts.map((post) => (
                  <UnstructuredPostCard post={post} key={post._id} />
                ))
              ) : (
                <St.Text fontSize="16px">No discussions</St.Text>
              )}
            </St.Row>
            <St.PrimaryButtonLink to={routes.CREATE_POST.href}>
              Create post
            </St.PrimaryButtonLink>
          </St.Column>
        );
      case 2:
        return (
          <St.Column
            overflowY="scroll"
            maxHeight="200px"
            justifyContent="flex-start"
          >
            {(selectedCoalitions.length > 0
              ? selectedCoalitions
              : userCoalitions
            ).map((coalition) => (
              <St.ProfileSectionContainer key={coalition._id}>
                <CoalitionKeyInitiatives coalition={coalition} />
              </St.ProfileSectionContainer>
            ))}
          </St.Column>
        );
      case 3:
        const coalitions =
          selectedCoalitions.length > 0 ? selectedCoalitions : userCoalitions;

        const filteredEvents = events.filter((e) =>
          coalitions.some((c) => c._id === e.coalition_id),
        );

        return (
          <St.Column
            overflowY="scroll"
            maxHeight="200px"
            justifyContent="flex-start"
          >
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event) => (
                <St.ProfileSectionContainer key={event._id}>
                  <EventCard event={event} hideChurch />
                </St.ProfileSectionContainer>
              ))
            ) : (
              <St.ProfileSectionContainer>
                <St.Text>No events</St.Text>
              </St.ProfileSectionContainer>
            )}
          </St.Column>
        );
    }
  };

  if (!user.logged_in) return <DefaultHome />;
  const header = () => {
    if (selectedCoalitions.length == 0) return 'Your coalitions';
    if (selectedCoalitions.length === 1) return selectedCoalitions[0].name;
    return `${selectedCoalitions.length} coalitions`;
  };
  // const handleSelectCoalition = (coalition) => {
  //   // if (coalition && coalition.length > 0) {
  //   //   addCoalitionKeyInitiatives(coalition[0]._id, dispatch, show);
  //   // }
  //   setSelectedCoalitions(coalition);
  // };
  return (
    <>
      <St.Header mr="0">
        <St.HeaderText ml="10px" flex={1}>
          {header()}
        </St.HeaderText>
        {/* {coalitionsState.loaded && (
          <CoalitionDropdown
            onClickItem={handleSelectCoalition}
            defaultCoalitions={queryParamCoalition ? [queryParamCoalition] : []}
            width="200px"
          />
        )} */}
      </St.Header>
      <St.Row justifyContent="space-between" alignItems="flex-start">
        <St.InfoColumn hasBorder minWidth="200px" minHeight={DASHBOARD_HEIGHT}>
          <St.Text color="var(--color-hr-red)" fontWeight="800" mb="10px">
            Domains
          </St.Text>
          {domains.map((domain) => (
            <motion.button
              whileHover={{ scale: 1.075 }}
              whileTap={{ scale: 1 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              onClick={onClickDomain(domain._id)}
              key={domain._id}
            >
              <St.Row key={domain._id} mb="15px">
                {selectedDomainIds.includes(domain._id) ? (
                  <St.Dash background="var(--color-hr-red)" />
                ) : (
                  <St.Dash />
                )}
                <span
                  style={{
                    fontWeight: selectedDomainIds.includes(domain._id)
                      ? 700
                      : undefined,
                  }}
                >
                  {domain.name}
                </span>
              </St.Row>
            </motion.button>
          ))}
        </St.InfoColumn>
        <St.Column width="100%" mx="10px" maxWidth={DASHBOARD_WIDTH}>
          <St.InfoColumn hasBorder width="100%" minHeight={DASHBOARD_HEIGHT}>
            <StatisticHighlights justifyContent="center" width="100%" px="50px">
              {groupedStatisticsByType().map((statistic, i) => (
                <StatisticHighlight
                  key={`stat-${i}`}
                  name={statistic.type.name}
                  value={statistic.quantity.toString()}
                  icon={assetTypeIcons[statistic.type.name]}
                />
              ))}
            </StatisticHighlights>
            <St.Div width="100%" height="100%">
              <St.Column>
                <CircleChart
                  data={chartData}
                  width={400}
                  height={300}
                  outerRadius={100}
                />
                {!!groupedStatisticsByType().length && (
                  <St.Text fontWeight={800} fontSize="16px">
                    Dollar contributions by church ($)
                  </St.Text>
                )}
              </St.Column>
            </St.Div>
          </St.InfoColumn>
        </St.Column>
        <St.InfoColumn
          overflowY="auto"
          hasBorder
          minWidth="200px"
          maxWidth="200px"
          minHeight={DASHBOARD_HEIGHT}
          maxHeight={DASHBOARD_HEIGHT}
        >
          <St.Text color="var(--color-hr-red)" fontWeight="800" mb="10px">
            Discussions
          </St.Text>
          {filteredPosts.length > 0
            ? filteredPosts.map((post) => (
                <St.Div mb="10px" key={post._id}>
                  <St.Link to={`posts/${post._id}`}>
                    {post.title}
                  </St.Link>
                  <St.Text ml="10px" className="truncate-lines">
                    {post.content}
                  </St.Text>
                </St.Div>
              ))
            : 'No discussions'}
        </St.InfoColumn>
      </St.Row>

      <St.Div width="100%" paddingTop={32}>
        <Tabs tabs={TABS} parentTabState={tabState} />
        {renderDynamicWindow(tabState[0])}
      </St.Div>
    </>
  );
};

export default Home;
