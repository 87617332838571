import React from 'react';

interface CircleProps {
  size?: number;
  color: string;
}
const Circle: React.FC<CircleProps> = ({ size, color }) => {
  size = size ?? 8;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={color} />
    </svg>
  );
};

export default Circle;
