import * as St from 'components/Style.styled';
import React from 'react';
import styled from 'styled-components';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';
import useOnGetChurchById from '../hooks/useOnGetChurchById';
import useOnGetDomainById from '../hooks/useOnGetDomainById';
import useUserAccess from '../hooks/useUserAccess';
import { delay } from '../utils/delay';
import {
  getChurchInfoWindowContentString,
  getProgramInfoWindowContentString,
} from '../utils/mapUtils';
import { POI_PAN_ZOOM } from './Map/config/config';

const Container = styled(St.Row)`
  width: 305px;
  height: 90px;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 4px;

  :hover {
    cursor: pointer;
  }
`;

interface SmallProgramCardProps {
  program: ProgramDetailsDTO;
  programMarkers: google.maps.Marker[];
  churchMarkers: google.maps.Marker[];
  mapRef: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
}

const SmallProgramCard: React.FC<SmallProgramCardProps> = ({
  program,
  programMarkers,
  churchMarkers,
  mapRef,
  infoWindow,
}) => {
  const { onGetChurchById } = useOnGetChurchById();
  const { onGetDomainById } = useOnGetDomainById();
  const { isChurchMemberOf } = useUserAccess();
  const programChurch = onGetChurchById(program.church_id);
  const hasCustomLocation = !!program.latitude && !!program.latitude;
  const programMarker = programMarkers.find((m) => m['id'] === program.id);
  const churchMarker = churchMarkers.find((m) => m['id'] === program.church_id);
  const handleClick = async () => {
    if (!mapRef) return;
    const marker = hasCustomLocation ? programMarker : churchMarker;

    mapRef.setZoom(POI_PAN_ZOOM);
    await delay(100);
    mapRef.panTo(marker.getPosition());
    infoWindow.setContent(
      hasCustomLocation
        ? getProgramInfoWindowContentString(program, onGetDomainById)
        : getChurchInfoWindowContentString(
            programChurch,
            isChurchMemberOf(programChurch),
            onGetDomainById,
          ),
    );
    infoWindow.open({
      anchor: marker,
      map: mapRef,
    });
  };

  return (
    <Container onClick={handleClick}>
      <St.Column ml="20px">
        <St.Text textDecorationLine="underline" fontWeight="800">
          {program.description}
        </St.Text>
        <St.Text>{program.date}</St.Text>
      </St.Column>
    </Container>
  );
};

export default SmallProgramCard;
