import React, { ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { find } from 'lodash';
import routes, { Route } from '../config/routes';
import { ModalContext } from '../contexts/ModalContext';

interface Props {
  loggedIn: boolean;
  children: ReactNode;
}

const Auth: React.FC<Props> = ({ children, loggedIn }) => {
  const location = useLocation();
  const { openSignInModal, closeSignInModal } = useContext(ModalContext);
  const routeValues: Route[] = Object.values(routes);
  // find route object for current page
  const current: Route = find(routeValues, (route) => {
    const routeComponents = route.href.split('/');
    const currentRouteComponents = location.pathname.split('/');
    if (routeComponents.length !== currentRouteComponents.length) return false;
    routeComponents.forEach((component, i) => {
      if (component.includes(':')) {
        routeComponents[i] = '';
        currentRouteComponents[i] = '';
      }
    });
    return currentRouteComponents.toString() == routeComponents.toString();
  });

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (!current?.noAuth && !loggedIn) {
      openSignInModal();
    } else {
      closeSignInModal();
    }
  }, [location.pathname, loggedIn]);
  if (!current?.noAuth && !loggedIn) return null;

  return <>{children}</>;
};

export default Auth;
