import { useMemo } from 'react';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import useOnGetDomainById from './useOnGetDomainById';

const useGetFilterTitle = (
  selectedCoalitions: CoalitionDetailsDTO[],
  selectedDomainIds: string[],
) => {
  const { onGetDomainById } = useOnGetDomainById();
  return useMemo(() => {
    if (selectedCoalitions.length > 0 && selectedDomainIds.length > 0) {
      const domainTitle =
        selectedDomainIds.length === 1
          ? onGetDomainById(selectedDomainIds[0])?.name
          : `${selectedDomainIds.length} domains`;
      const coalitionTitle =
        selectedCoalitions.length === 1
          ? selectedCoalitions[0].name
          : `${selectedCoalitions.length} coalitions`;
      return coalitionTitle + ', ' + domainTitle;
    }
    if (selectedCoalitions.length > 1) {
      return `${selectedCoalitions.length} coalitions`;
    }

    if (selectedDomainIds.length > 1) {
      return `${selectedDomainIds.length} domains`;
    }

    if (selectedDomainIds.length === 0 && selectedCoalitions.length === 0)
      return 'All';

    return selectedCoalitions
      .map((c) => c.name)
      .concat(selectedDomainIds.map((id) => onGetDomainById(id)?.name))
      .join(', ');
  }, [selectedCoalitions, selectedDomainIds]);
};

export default useGetFilterTitle;
