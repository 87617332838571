import React, { useContext } from 'react';
import styled from 'styled-components';
import apis from '../../../api';
import * as St from '../../../components/Style.styled';
import { ToastContext } from '../../../contexts/ToastContext';
import DomainDetailsDTO from '../../../dtos/DomainDetailsDTO';
import { useRequestDomainExpertStatus } from '../../../hooks/updateHooks';
import { useGetCurrentUser, useGetDomains } from '../../../store/hooks';
import DomainMemberRole from '../../../types/DomainMemberRole';
import UserConfigStep from '../../../types/UserConfigStep';
import { StepProps } from './index';

const Domains = styled(St.Column)`
  background-color: var(--color-light-grey);
  border-radius: 4px;
  padding: 25px 16px;
  height: 300px;
  gap: 10px;
  overflow-y: scroll;
  justify-content: flex-start;
`;

const DomainCard: React.FC<{ domain: DomainDetailsDTO }> = ({ domain }) => {
  const { onRequestDomainExpertStatus } = useRequestDomainExpertStatus();
  const { show } = useContext(ToastContext);
  const currentUser = useGetCurrentUser();

  const handleRequestDomainExpertStatus = async () => {
    await apis.domains.join(currentUser._id, domain._id);
    await onRequestDomainExpertStatus(domain.id);
    show('requested-domain-expert-status-success');
  };

  const renderRequestButton = () => {
    const partnerRequested = currentUser.domain_memberships
      .filter((member) => member.level === DomainMemberRole.PENDING_EXPERT)
      .some((membership) => membership.domain_id === domain._id);

    return (
      <St.PrimaryButton
        type="button"
        disabled={partnerRequested}
        onClick={handleRequestDomainExpertStatus}
      >
        {!partnerRequested ? 'Request partner' : 'Requested'}
      </St.PrimaryButton>
    );
  };

  return (
    <St.Row
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      width="100%"
    >
      <St.Text>{domain.name}</St.Text>
      {renderRequestButton()}
    </St.Row>
  );
};

const RequestPartnerDomains: React.FC<StepProps> = ({ setStep }) => {
  const domains = useGetDomains();
  return (
    <St.Column height="445px">
      <St.Text fontWeight="800" fontSize="20px" textAlign="center" mb="20px">
        Please request partner for the domains you manage.
      </St.Text>

      <Domains>
        {domains.map((domain) => (
          <DomainCard key={domain.domainId} domain={domain} />
        ))}
      </Domains>
      <St.PrimaryButton
        type="button"
        onClick={() => setStep(UserConfigStep.JoinDomains)}
        mt="auto"
      >
        Continue
      </St.PrimaryButton>
    </St.Column>
  );
};

export default RequestPartnerDomains;
