import CoalitionUserDetailsDTO from './CoalitionUserDetailsDTO';
// import DomainDetailsDTO from './DomainDetailsDTO';
import ChurchMemberDetailsDTO from './ChurchMemberDetailsDTO';
import DomainMemberDetailsDTO from './DomainMemberDetailsDTO';

export default interface UserDetailsDTO {
  _id: string;
  id: string;
  username: string;
  name: string;
  email: string;
  grants: string[];
  domain_memberships: DomainMemberDetailsDTO[];
  church_memberships: ChurchMemberDetailsDTO[];
  coalition_memberships: CoalitionUserDetailsDTO[];
}

export const defaultUserDetails: UserDetailsDTO = {
  _id: '',
  id: '',
  name: '',
  username: '',
  email: '',
  grants: [],
  domain_memberships: [],
  church_memberships: [],
  coalition_memberships: [],
};
