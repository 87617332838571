import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import { useAppSelector, useGetMembershipsState } from 'store/hooks';
import * as St from 'components/Style.styled';
import {
  // CalendarIcon,
  CoalitionsIcon,
  DomainsIcon,
  Ellipsis,
  // NotificationIcon,
} from '../config/icons';
import { ModalContext } from '../contexts/ModalContext';
import { AnimatePresence, motion } from 'framer-motion';
import NavProfileTooltip from './NavProfileTooltip';

const Container = styled.div`
  height: 100%;
  width: 260px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--color-hr-red);
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 85%;
  cursor: pointer;
`;

const NavItem = styled(motion.div)`
  display: flex;
  align-items: center;
  margin: auto;
  width: 85%;
`;

const NavItemWrapper = styled(Link)`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: 0.1s;

  :hover {
    background: #a61a2d;
    cursor: pointer;
    font-weight: 800;
  }
`;

const Text = styled.p`
  color: white;
`;

const NavIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const ProfileContainer = styled.div`
  height: 60px;
  width: 90%;
  margin: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  transition: 0.2s;

  :hover {
    background-color: var(--color-hr-red-dark);
    cursor: pointer;
  }
`;

const DefaultProfileImage = styled.div`
  width: 36px;
  height: 36px;
  background: var(--color-light-grey);
  border-radius: 25px;
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
`;

interface NavigationItemProps {
  name: string;
  icon: string;
  href: string;
  noAuth: boolean;
}

const NavMenu: React.FC = () => {
  const { toggleSignInOpen, signUpOpen, signInOpen, toggleSignUpOpen } =
    useContext(ModalContext);
  const [signoutVisible, setSignoutVisible] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const memberships = useGetMembershipsState();
  const [navigation, setNavigation] = useState<{
    resources: NavigationItemProps[];
    user: NavigationItemProps[];
  }>({
    resources: [
      {
        name: 'Home',
        icon: '/images/home.png',
        href: routes.HOME.href,
        noAuth: true,
      },
      {
        name: 'Community Map',
        icon: '/images/demographic-map.png',
        href: routes.COMMUNITY_MAP.href,
        noAuth: false,
      },
      {
        name: 'Churches',
        icon: '/images/churches.png',
        href: routes.CHURCHES.href,
        noAuth: false,
      },
      {
        name: 'Coalitions',
        icon: CoalitionsIcon,
        href: routes.COALITIONS.href,
        noAuth: false,
      },
      {
        name: 'Domains',
        icon: DomainsIcon,
        href: routes.DOMAINS.href,
        noAuth: false,
      },
    ],
    user: [],
  });

  useEffect(() => {
    const userNav: NavigationItemProps[] = [];
    if (memberships.data.churches.length > 0) {
      userNav.push({
        name: memberships.data.churches[0]?.church?.name ?? 'My Church',
        icon: '/images/churches.png',
        href:
          routes.CHURCHES.href + `/${memberships.data.churches[0].church_id}`,
        noAuth: false,
      });
    }
    if (memberships.data.coalitions.length > 0) {
      userNav.push({
        name: memberships.data.coalitions[0]?.coalition?.name ?? 'My Coalition',
        icon: CoalitionsIcon,
        href:
          routes.HOME.href +
          `?coalition_id=${memberships.data.coalitions[0].coalition_id}`,
        noAuth: false,
      });
    }
    if (memberships.data.domains.length > 0) {
      memberships.data.domains.forEach((domain) => {
        userNav.push({
          name: domain?.domain?.name ?? 'My Domain',
          icon: DomainsIcon,
          href: routes.DOMAINS.href + `/${domain.domain_id}`,
          noAuth: false,
        });
      });
    }

    // if (!!user) {
    //   userNav.push({
    //     name: 'Profile',
    //     icon: '/images/profile.png',
    //     href: `/users/${user.id}`,
    //     noAuth: false,
    //   });
    // }
    setNavigation((prev) => ({ ...prev, user: userNav }));
  }, [user, memberships]);

  const disableModals = () => {
    if (signInOpen) toggleSignInOpen();
    if (signUpOpen) toggleSignUpOpen();
  };

  return (
    <AnimatePresence>
      <Container>
        <Logo src="/images/logo white.png" onClick={() => navigate('/')} />
        <div style={{ marginBottom: '24px' }} />
        {navigation.resources.map(
          (info) =>
            (info.noAuth || user.logged_in) && (
              <NavItemWrapper
                key={info.name}
                to={info.href}
                onClick={disableModals}
              >
                <NavItem
                  layout
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                >
                  <NavIcon src={info.icon} />
                  <Text>{info.name}</Text>
                </NavItem>
              </NavItemWrapper>
            ),
        )}
        {!!user.logged_in && !!navigation.user.length && (
          <>
            <div
              style={{
                height: 1,
                width: '100%',
                backgroundColor: '#DDD',
                margin: '16px 0',
              }}
            />
            <St.Text
              color="white"
              fontSize="12px"
              fontWeight="300"
              textAlign={'left'}
              width={'100%'}
              ml={"12px"}
              fontStyle={"italic"}
            >
              My Memberships
            </St.Text>
            {navigation.user.map((info) => (
              <NavItemWrapper
                key={info.name}
                to={info.href}
                onClick={disableModals}
              >
                <NavItem
                  layout
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                >
                  <NavIcon src={info.icon} />
                  <Text>{info.name}</Text>
                </NavItem>
              </NavItemWrapper>
            ))}
          </>
        )}
        <NavProfileTooltip
          visible={signoutVisible}
          setVisible={setSignoutVisible}
        />
        <ProfileContainer
          onClick={() => user.logged_in && setSignoutVisible(!signoutVisible)}
        >
          {user.logged_in ? (
            <>
              <DefaultProfileImage />
              <SignInContainer>
                <St.Text color="white" fontWeight="800">
                  {user.name}
                </St.Text>
                <St.Text color="white">@{user.username}</St.Text>
              </SignInContainer>
            </>
          ) : (
            <>
              <DefaultProfileImage />
              <SignInContainer onClick={toggleSignInOpen}>
                <St.Text color="white">Already a member?</St.Text>
                <St.Text color="white" fontWeight="800">
                  Sign in
                </St.Text>
              </SignInContainer>
            </>
          )}
          <St.Icon src={Ellipsis} width="15px" ml="auto" />
        </ProfileContainer>
      </Container>
    </AnimatePresence>
  );
};

export default NavMenu;
