import { Autocomplete } from '@react-google-maps/api';
import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
import apis from '../../api';
import CheckboxDropdown, {
  DropdownItem,
} from '../../components/CheckboxDropdown';
import {
  DROPDOWN_NO_COALITIONS,
  DROPDOWN_NO_DOMAINS,
  HOUSTON_LATLNG_BOUNDS,
} from '../../config/constants';
import { Loader2 } from '../../config/icons';
import AutocompleteDetails, {
  defaultAutocompleteDetails,
} from '../../dtos/AutocompleteDetails';
import CoalitionDetailsDTO from '../../dtos/CoalitionDetailsDTO';
import DomainDetailsDTO from '../../dtos/DomainDetailsDTO';
import EventDetailsDTO from '../../dtos/EventDetailsDTO';
import useFetchGooglePlacesPhotos from '../../hooks/useFetchGooglePlacesPhotos';
import useModal from '../../hooks/useModal';
import useToast from '../../hooks/useToast';
import useUserAccess from '../../hooks/useUserAccess';
import { updateEvents } from '../../store/helpers';
import {
  useAppDispatch,
  useGetChurchById,
  useGetCoalitions,
  useGetCurrentUser,
  useGetDomains,
  useGetEventById,
} from '../../store/hooks';
import {
  dropdownGetSelected,
  dropdownSelectOne,
} from '../../utils/dropdownUtils';
import firstOrNull from '../../utils/firstOrNull';
import { stringNullOrEmpty } from '../../utils/strings';
import toDropdownItems, { toDropdownItem } from '../../utils/toDropdownItems';

const StyledAutocomplete: StyledComponent<
  typeof Autocomplete,
  any,
  { name: string; value: string }
> = styled(Autocomplete)`
  ${St.textInputStyles};
  box-sizing: border-box;
  padding-right: 1.5rem;
`;

const Container = styled.div`
  margin-top: 26px;
  margin-left: 30px;
  margin-right: 124px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const options = {
  fields: [
    'place_id',
    'address_components',
    'name',
    'photos',
    'geometry.location',
    'formatted_address',
  ],
  types: ['geocode', 'establishment'],
  bounds: HOUSTON_LATLNG_BOUNDS,
  strictBounds: true,
};

const initialDropdownDomains = (
  event: EventDetailsDTO,
  userDomains: DomainDetailsDTO[],
): DropdownItem[] => {
  if (!event?.domain_id) return toDropdownItems(userDomains);
  return userDomains.map((d) =>
    event?.domain_id === d._id
      ? { ...toDropdownItem(d), checked: true }
      : toDropdownItem(d),
  );
};

const initialDropdownCoalitions = (
  event: EventDetailsDTO,
  userCoalitions: CoalitionDetailsDTO[],
): DropdownItem[] => {
  if (!event?.coalition_id) return toDropdownItems(userCoalitions);
  return userCoalitions.map((c) =>
    event?.coalition_id === c.id
      ? { ...toDropdownItem(c), checked: true }
      : toDropdownItem(c),
  );
};

export const initialPlacesInfo = (
  event: EventDetailsDTO,
): AutocompleteDetails => ({
  ...defaultAutocompleteDetails,
  name: event?.location,
  coords: [event?.longitude, event?.latitude],
});

const EditEvent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isDomainMemberOf, isCoalitionMemberOf } = useUserAccess();
  const currentUser = useGetCurrentUser();
  const event = useGetEventById(id);
  const church = useGetChurchById(event?.church_id);
  const [placesInfo, setPlacesInfo] = useState(initialPlacesInfo(event));
  const [selectedDate, setSelectedDate] = useState(new Date(event?.scheduled));
  const { photos, loading, error } = useFetchGooglePlacesPhotos(
    church?.google_places_id,
  );
  const [autocomplete, setAutocomplete] = useState(null);
  const userDomains = useGetDomains().filter(isDomainMemberOf);
  const userCoalitions = useGetCoalitions().filter(isCoalitionMemberOf);
  const coalitionFilterState = useState(
    initialDropdownCoalitions(event, userCoalitions),
  );
  const domainFilterState = useState(
    initialDropdownDomains(event, userDomains),
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const selectedCoalition = firstOrNull(
    dropdownGetSelected(coalitionFilterState),
  );
  const selectedDomain = firstOrNull(dropdownGetSelected(domainFilterState));
  const { show } = useToast();
  const { openRemoveEvent } = useModal();
  function onLoad(autocomplete) {
    setAutocomplete(autocomplete);
  }

  const onLocationInputChange = (e) => {
    setPlacesInfo({
      ...defaultAutocompleteDetails,
      name: e.target.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (stringNullOrEmpty(form.title.value)) {
      show('must-enter-title-failure');
      return;
    }
    if (!selectedDate) {
      show('must-enter-date-failure');
      return;
    }
    apis.events
      .update(id, {
        name: form.title.value,
        description: form.description.value,
        scheduled: selectedDate.toISOString(),
        location: placesInfo.name,
        latitude: placesInfo.coords[1],
        longitude: placesInfo.coords[0],
        coalition_id: selectedCoalition?.id,
        domain_id: selectedDomain?.id,
      })
      .then(() => {
        updateEvents(dispatch, currentUser._id);
        show('event-updated-success');
        navigate(-1);
      });
  };

  function onPlaceChanged() {
    if (!autocomplete) return;
    const place = autocomplete.getPlace();
    setPlacesInfo({
      name: place.formatted_address,
      place_id: place.place_id,
      coords: [place.geometry?.location.lng(), place.geometry?.location.lat()],
    });
  }

  return (
    <>
      <St.Header style={{ marginLeft: '30px' }}>
        <St.HeaderText>Edit event</St.HeaderText>
      </St.Header>
      <Container>
        <St.Column alignItems="flex-start">
          <St.Text fontWeight="800" mb="10px">
            Event Details
          </St.Text>
          <St.Form gap="14px" width="100%" onSubmit={submit}>
            <St.Row justifyContent="flex-start" gap="10px">
              <CheckboxDropdown
                items={
                  domainFilterState[0].length > 0
                    ? domainFilterState[0]
                    : DROPDOWN_NO_DOMAINS
                }
                parentSetOpen={setDropdownOpen}
                width="100%"
                onClickItem={(i) => dropdownSelectOne(i, domainFilterState)}
              >
                {selectedDomain?.name ?? 'No domain'}
              </CheckboxDropdown>
              {/* <CheckboxDropdown
                items={
                  coalitionFilterState[0].length > 0
                    ? coalitionFilterState[0]
                    : DROPDOWN_NO_COALITIONS
                }
                width="230px"
                onClickItem={(i) => dropdownSelectOne(i, coalitionFilterState)}
              >
                {selectedCoalition?.name ?? 'No Coalition'}
              </CheckboxDropdown> */}
            </St.Row>
            <St.TextInput
              placeholder="Title"
              name="title"
              defaultValue={event?.name}
            />
              <div
                style={{
                  zIndex: dropdownOpen ? '-1' : 100,
                  width: '100%',
                }}
              >
                <St.DateInput
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date as Date)}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  isClearable
                  showTimeSelect
                  placeholderText="Select a date"
                />
              </div>
              <div style={{ width: '100%' }}>
                <Autocomplete
                  options={options}
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    name="name"
                    id="name"
                    value={placesInfo.name}
                    onChange={onLocationInputChange}
                    style={{
                      height: '40px',
                      width: '100%',
                      borderRadius: '4px',
                      border: '1px solid #e2e2e2',
                      padding: '12px',
                      fontWeight: '800',
                      fontSize: '12px',
                      lineHeight: '16px',
                    }}
                  />
                </Autocomplete>
              </div>
            <St.LargeInput
              name="description"
              placeholder="Description..."
              defaultValue={event?.description}
            />
            <St.Row gap="14px" justifyContent="flex-start">
              <St.TertiaryButton type="button" onClick={openRemoveEvent}>
                Remove Event
              </St.TertiaryButton>
              <St.SecondaryButton type="button" onClick={() => navigate(-1)}>
                Back
              </St.SecondaryButton>
              <St.PrimaryButton type="submit">Edit Event</St.PrimaryButton>
            </St.Row>
          </St.Form>
        </St.Column>
      </Container>
    </>
  );
};

export default EditEvent;
