type LatLngLiteralArray = google.maps.LatLngLiteral[];
export const getPolygonCoords = (
  geometry:
    | google.maps.Data.Geometry
    | google.maps.Data.GeometryCollection
    | google.maps.Data.MultiPolygon,
): LatLngLiteralArray[] => {
  if (geometry instanceof google.maps.Data.Polygon) {
    const paths = geometry.getAt(0).getArray();
    return [paths.map((path) => ({ lat: path.lat(), lng: path.lng() }))];
  }

  if (geometry instanceof google.maps.Data.GeometryCollection) {
    return geometry
      .getArray()
      .flatMap((innerGeometry) => getPolygonCoords(innerGeometry));
  }

  if (geometry instanceof google.maps.Data.MultiPolygon) {
    return geometry.getArray().flatMap((polygon) => getPolygonCoords(polygon));
  }

  console.warn('Unexpected geometry type:', geometry.getType());
  return [];
};

export const pointInAnyPolygon = (
  point: google.maps.LatLngLiteral,
  polygonArrays: LatLngLiteralArray[],
): boolean => {
  for (const polygonCoords of polygonArrays) {
    if (pointInPolygon(point, polygonCoords)) {
      return true;
    }
  }
  return false;
};

export const filterEntitiesInCoalitionBounds = <T>(
  entities: T[],
  getEntityCoords: (entity: T) => google.maps.LatLngLiteral,
  datalayer: google.maps.Data.Feature[],
): T[] => {
  return entities.filter((entity) => {
    const point = getEntityCoords(entity);
    return datalayer.some((feature) =>
      pointInAnyPolygon(point, getPolygonCoords(feature.getGeometry())),
    );
  });
};

export const pointInPolygon = (
  point: google.maps.LatLngLiteral,
  polygonCoords: google.maps.LatLngLiteral[],
): boolean => {
  const x = point.lng;
  const y = point.lat;
  let inside = false;

  for (
    let i = 0, j = polygonCoords.length - 1;
    i < polygonCoords.length;
    j = i++
  ) {
    const xi = polygonCoords[i].lng,
      yi = polygonCoords[i].lat;
    const xj = polygonCoords[j].lng,
      yj = polygonCoords[j].lat;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};
