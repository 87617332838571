import { DropdownItem } from '../components/CheckboxDropdown';
// import CoalitionDropdown from '../components/CoalitionDropdown';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';

export const HOUSTON_LATLNG_BOUNDS = {
  north: 30.110409,
  east: -95.053467,
  south: 29.523056,
  west: -95.788363,
};

export const DROPDOWN_NO_DOMAINS: DropdownItem[] = [
  {
    id: null,
    name: 'Join domains to see them here',
    checked: false,
  },
];

export const DROPDOWN_NO_COALITIONS: DropdownItem[] = [
  {
    id: null,
    name: 'Join coalitions to see them here',
    checked: false,
  },
];

export const DROPDOWN_NO_COALITIONS_2: CoalitionDetailsDTO[] = [
  {
    _id: '',
    id: '',
    name: 'Join coalitions to see them here',
    region: '',
    description: '',
    church_ids: [],
    zip_codes: [],
  },
];
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const SECONDS_PER_YEAR = SECONDS_PER_DAY * 365;
