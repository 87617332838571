import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import UserConfigStep from '../../../types/UserConfigStep';
import { SignUpStepProps } from '../index';
import '../styles.css';
import AreYouDomainLeader from './AreYouDomainLeader';
import JoinChurches from './JoinChurches';
import JoinCoalitions from './JoinCoalitions';
import JoinDomains from './JoinDomains';
import RequestPartnerDomains from './RequestPartnerDomains';

export interface StepProps {
  setStep: Dispatch<SetStateAction<UserConfigStep>>;
}

const UserConfiguration: React.FC<SignUpStepProps> = () => {
  const [step, setStep] = useState(UserConfigStep.JoinChurches);

  const stepMap = useMemo(
    () => ({
      [UserConfigStep.AreYouDomainPartner]: (
        <AreYouDomainLeader setStep={setStep} />
      ),
      [UserConfigStep.RequestPartnerDomains]: (
        <RequestPartnerDomains setStep={setStep} />
      ),
      [UserConfigStep.JoinDomains]: <JoinDomains setStep={setStep} />,
      [UserConfigStep.JoinCoalitions]: <JoinCoalitions setStep={setStep} />,
      [UserConfigStep.JoinChurches]: <JoinChurches setStep={setStep} />,
    }),
    [],
  );

  return stepMap[step];
};

export default UserConfiguration;
