import * as St from 'components/Style.styled';
import routes from 'config/routes';
import { ToastContext } from 'contexts/ToastContext';
import CoalitionDetailsDTO from 'dtos/CoalitionDetailsDTO';
import { useJoinCoalition } from 'hooks/updateHooks';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useGetCurrentUser } from 'store/hooks';
import styled from 'styled-components';
import apis from '../api';
import { QuestionMarkRed } from '../config/icons';
import { updateCurrentUser } from '../store/helpers';
import { stringNullOrEmpty } from '../utils/strings';
import Tooltip from './Tooltip';
import useUserAccess from 'hooks/useUserAccess';

const MARGIN = '5px';

const Container = styled.div<{ margin: string }>`
  width: calc(50% - (${(props) => props.margin} * 2));
  min-width: 450px;
  background: rgba(217, 217, 217, 0.2);
  margin: ${(props) => props.margin};
  cursor: pointer;
  padding: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  padding: 3px 10px 3px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
`;

interface CoalitionCardProps {
  coalition: CoalitionDetailsDTO;
  tooltipMessage?: string;
  isMember?: boolean;
  isAdmin?: boolean;
}

const CoalitionCard: React.FC<CoalitionCardProps> = ({
  coalition,
  tooltipMessage,
  isMember = false,
  isAdmin = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const { onJoinCoalition } = useJoinCoalition();
  const { show } = useContext(ToastContext);
  const [showAdminRequestButton, setShowAdminRequestButton] = useState(false);
  const { churchIdIfAdmin } = useUserAccess();
  const church_id = churchIdIfAdmin();

  const buttonText = () => {
    if (!isMember) return 'Join';
    if (!!isAdmin) return 'Admin';
    return 'Joined';
  };

  const joinCoalition = async (e: any) => {
    if (!church_id) return alert('You must be a church admin to join a coalition');

    e.stopPropagation();
    await onJoinCoalition(church_id, coalition._id, currentUser._id);
    show('joined-coalition-success');
  };

  const renderTooltip = () => {
    if (stringNullOrEmpty(tooltipMessage)) return null;

    return <Tooltip text={tooltipMessage} src={QuestionMarkRed} />;
  };

  const onClickEllipsis = (e: any) => {
    e.stopPropagation();
    setShowAdminRequestButton(!showAdminRequestButton);
  };

  const onClickRequestAdmin = (e: any) => {
    e.stopPropagation();
    apis.coalitions
      .request_admin_status(coalition.id)
      .then(() => {
        show('requested-coalition-admin');
        updateCurrentUser(dispatch);
      })
      .catch(() => {
        show('requested-coalition-admin-failed');
      });
  };

  return (
    <Container
      margin={MARGIN}
      onClick={() =>
        navigate(routes.HOME.href + `?coalition_id=${coalition._id}`)
      }
    >
      <St.Row justifyContent="space-between">
        <St.Text color="var(--color-hr-red)" fontWeight="800">
          {coalition.name}
        </St.Text>
        {/* <St.Div
          ml="auto"
          style={{
            opacity: showAdminRequestButton ? 1 : 0,
            visibility: showAdminRequestButton ? 'visible' : 'hidden',
            transition: 'opacity 0.2s ease-in-out, visibility 0.2s',
          }}
        >
          <St.PrimaryButton
            ml="auto"
            mr="10px"
            type="button"
            onClick={onClickRequestAdmin}
            disabled={isMember}
          >
            {membership?.level === CoalitionUserRole.PENDING_ADMIN
              ? 'Partner request pending'
              : 'Request coalition partner'}
          </St.PrimaryButton>
        </St.Div> */}

        {/* {isAdmin && (
          <St.Button onClick={onClickEllipsis}>
            <St.Icon src={EllipsisRed} width="15px" />
          </St.Button>
        )} */}
        {/* <St.Div mx="5px">{renderTooltip()}</St.Div> */}
        {!!church_id && (
          <St.PrimaryButton
            type="button"
            disabled={!!isMember}
            onClick={joinCoalition}
          >
            {buttonText()}
          </St.PrimaryButton>
        )}
      </St.Row>
      <Description>{coalition.description}</Description>
    </Container>
  );
};

export default CoalitionCard;
