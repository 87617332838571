import * as St from 'components/Style.styled';
import { defaultMapFilters, MapFilters } from 'dtos/DemographicFiltersDTO';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import Demographic from 'types/Demographic';
import { LeftArrowIcon, RightArrowIcon } from '../../config/icons';
import eventManager from '../../utils/eventManager';
import {
  defaultFilterDemographic,
  demographicTypeInfo,
  incomeIcons,
} from './config/config';
import { updateMapFilters } from './utils';

const Legend: React.FC = () => {
  const [filterDemographic, setFilterDemographic] = useState(
    defaultFilterDemographic,
  );
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<MapFilters>(defaultMapFilters);
  const filterDemographicInfo = demographicTypeInfo[filterDemographic];

  useEffect(() => {
    eventManager.on('filterDemographic:update', setFilterDemographic);
    eventManager.on('mapFilters:update', setFilters);
    return () => {
      eventManager.off('filterDemographic:update', setFilterDemographic);
      eventManager.off('mapFilters:update', setFilters);
    };
  }, []);

  const onCheck = (
    event: React.MouseEvent<HTMLInputElement>,
    band: Demographic,
  ) => {
    event.stopPropagation();
    if (!filters[filterDemographicInfo.key].includes(band)) {
      const updatedFilters = {
        ...filters,
        [filterDemographicInfo.key]: [
          ...filters[filterDemographicInfo.key],
          band,
        ],
      };
      updateMapFilters(cloneDeep(updatedFilters));
    } else {
      const updatedFilters = {
        ...filters,
        [filterDemographicInfo.key]: filters[filterDemographicInfo.key].filter(
          (f) => f !== band,
        ),
      };
      updateMapFilters(cloneDeep(updatedFilters));
    }
  };

  return (
    <div id="legend">
      <St.Column
        alignItems="flex-end"
        p="10px"
        m="7px"
        style={{ backgroundColor: 'white' }}
        onClick={() => setOpen(!open)}
        className="hover:cursor-pointer"
      >
        <St.Row alignItems="center" mb="5px" mr="auto">
          <St.Icon
            src={open ? RightArrowIcon : LeftArrowIcon}
            mr="10px"
            height="10px"
          />
          <St.Text fontWeight="800" fontSize="14px">
            {demographicTypeInfo[filterDemographic].label}
          </St.Text>
        </St.Row>
        {filterDemographicInfo.fields.map((item) => (
          <St.Row
            key={item.demographic}
            mb="5px"
            width="100%"
            height="15px"
            justifyContent="flex-start"
          >
            <St.Row width="35px">
              {open && (
                <St.Input
                  type="checkbox"
                  checked={filters[filterDemographicInfo.key].includes(
                    item.demographic,
                  )}
                  onClick={(e) => onCheck(e, item.demographic)}
                  readOnly
                />
              )}
              <St.Icon
                src={incomeIcons[item.demographic]}
                mr="10px"
                ml="auto"
              />
            </St.Row>
            <St.Text>{item.name}</St.Text>
          </St.Row>
        ))}
      </St.Column>
    </div>
  );
};

export default Legend;
