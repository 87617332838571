import { useCallback } from 'react';
import { useGetChurches } from '../store/hooks';

export const useOnGetChurchById = () => {
  const churches = useGetChurches();
  const onGetChurchById = useCallback(
    (churchId: string) => churches.find((church) => church.id === churchId),
    [churches],
  );
  return { onGetChurchById };
};

export default useOnGetChurchById;
