import * as St from 'components/Style.styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PostDetailsDTO from '../dtos/PostDetailsDTO';

import {
  useAppDispatch,
  useGetChurchById,
  useGetCoalitionById,
  useGetDomainById,
} from '../store/hooks';
const Container = styled(St.Row)`
  width: 305px;
  height: 90px;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 4px;

  :hover {
    cursor: pointer;
  }
`;

interface UnstructuredPostCardProps {
  post: PostDetailsDTO;
}

const UnstructuredPostCard: React.FC<UnstructuredPostCardProps> = ({
  post,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const church = useGetChurchById(post.church_id);
  const coalition = useGetCoalitionById(post.coalition_id);
  const domain = useGetDomainById(post.domain_id);
  const parentName = church?.name || coalition?.name || domain?.name;

  const handleNavigate = (event) => {
    event.stopPropagation();
    navigate(`/posts/${post._id}`);
  };

  return (
    <Container onClick={handleNavigate}>
      <Container
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <St.Column width={'100%'} mx="20px" alignItems="flex-start">
          <St.Text textDecorationLine="underline" fontWeight="800">
            {post.title}
          </St.Text>
          <St.Text>{parentName}</St.Text>
        </St.Column>
      </Container>
    </Container>
  );
};

export default UnstructuredPostCard;
