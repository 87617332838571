import { GridAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer';
import { flatten, uniq, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import env from 'config/env';
import { createRoot } from 'react-dom/client';
import Filter from '../components/Map/Filter';
import FiltersColumn from '../components/Map/FiltersColumn';
import Legend from '../components/Map/Legend';
import ChurchDetailsDTO from '../dtos/ChurchDetailsDTO';
import CoalitionDetailsDTO from '../dtos/CoalitionDetailsDTO';
import MapMarkerDTO from '../dtos/MapMarkerDTO';
import PointOfInterestDetailsDTO from '../dtos/PointOfInterestDetailsDTO';
import ProgramDetailsDTO from '../dtos/ProgramDetailsDTO';
import {
  useGetChurches,
  useGetPrograms,
  useGetProgramsLoaded,
} from '../store/hooks';
import {
  getFilteredChurches,
  newChurchMarker,
  newCommunityMarker,
  newPointOfInterestMarker,
  newProgramMarker,
  programCoalitionFilter,
  programDomainFilter,
} from '../utils/mapUtils';
import useOnGetChurchById from './useOnGetChurchById';
import useOnGetDomainById from './useOnGetDomainById';
import useUserAccess from './useUserAccess';
import { filterEntitiesInCoalitionBounds } from '../utils/geoUtils';

export const useFilteredPrograms = (
  selectedCoalitions: CoalitionDetailsDTO[],
  selectedDomainIds: string[],
  datalayer: google.maps.Data.Feature[],
): ProgramDetailsDTO[] => {
  const programsLoaded = useGetProgramsLoaded();
  const programs = useGetPrograms();
  const churches = useGetChurches();

  const [filteredPrograms, setFilteredPrograms] =
    useState<ProgramDetailsDTO[]>(programs);

  useEffect(() => {
    const zipFilteredPrograms = filterEntitiesInCoalitionBounds(
      programs,
      (program) => {
        const hasCustomLocation = !!program.latitude && !!program.longitude;
        const church = churches.find((ch) => ch._id === program.church_id);
        return hasCustomLocation
          ? { lat: program.latitude, lng: program.longitude }
          : { lat: church?.latitude, lng: church?.longitude };
      },
      datalayer,
    );

    const relationFilteredPrograms = programs.filter(
      (program) =>
        programDomainFilter(program, selectedDomainIds) &&
        programCoalitionFilter(program, selectedCoalitions),
    );

    setFilteredPrograms(
      uniqBy(zipFilteredPrograms.concat(relationFilteredPrograms), (p) => p.id),
    );
  }, [programsLoaded, selectedDomainIds, selectedCoalitions, datalayer]);

  return filteredPrograms;
};

export const useFilteredChurches = (
  selectedCoalitions,
  selectedDomainIds,
  datalayer: google.maps.Data.Feature[],
): ChurchDetailsDTO[] => {
  const churches = useGetChurches();
  const [filteredChurches, setFilteredChurches] =
    useState<ChurchDetailsDTO[]>(churches);

  useEffect(() => {
    const zipFilteredChurches =
      filterEntitiesInCoalitionBounds<ChurchDetailsDTO>(
        churches,
        (church) => ({ lat: church.latitude, lng: church.longitude }),
        datalayer,
      );

    const relationFilteredChurches = getFilteredChurches(
      selectedCoalitions.length === 0 ? churches : zipFilteredChurches,
      selectedCoalitions,
      selectedDomainIds,
    );

    setFilteredChurches(
      uniqBy(
        zipFilteredChurches.concat(relationFilteredChurches),
        (c) => c._id,
      ),
    );
  }, [churches, selectedCoalitions, selectedDomainIds, datalayer]);

  return filteredChurches;
};

export const useInitMap = (
  options: google.maps.MapOptions,
): google.maps.Map => {
  const [map, setMap] = useState<google.maps.Map>(null);
  useEffect(() => {
    const newMap = new google.maps.Map(document.getElementById('map'), options);
    setMap(newMap);
  }, []);
  return map;
};

export const useInitClusters = (map: google.maps.Map): MarkerClusterer => {
  const [clusterer, setClusterer] = useState<MarkerClusterer>(null);
  const algorithm = new GridAlgorithm({
    gridSize: isNaN(parseInt(env['gridSize'])) ? 60 : parseInt(env['gridSize']),
  });

  useEffect(() => {
    if (!map) return;
    const newClusterer = new MarkerClusterer({
      map,
      markers: [],
      algorithm,
    });
    setClusterer(newClusterer);
  }, [map]);

  return clusterer;
};

export const useInitInfoWindow = (): google.maps.InfoWindow => {
  const infoWindow = useMemo(
    () =>
      new google.maps.InfoWindow({
        content: '',
        ariaLabel: '',
      }),
    [],
  );

  return infoWindow;
};

export const useInitControls = (map: google.maps.Map) => {
  const mapLoaded = !!map;

  useEffect(() => {
    if (!mapLoaded) return;
    const legendDiv = document.createElement('div');
    createRoot(legendDiv).render(<Legend />);
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      legendDiv,
    );
    const filterDiv = document.createElement('div');
    createRoot(filterDiv).render(<Filter width="125px" />);
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(filterDiv);

    const domainFilterDiv = document.createElement('div');
    createRoot(domainFilterDiv).render(<FiltersColumn />);
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      domainFilterDiv,
    );
  }, [mapLoaded]);
};

export const useNewMarker = (
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
) => {
  const { isChurchMemberOf } = useUserAccess();
  const { onGetDomainById } = useOnGetDomainById();
  const { onGetChurchById } = useOnGetChurchById();
  const onNewChurchMarker = (dto: ChurchDetailsDTO) => {
    return newChurchMarker(
      dto,
      isChurchMemberOf(dto),
      onGetDomainById,
      map,
      infoWindow,
    );
  };

  const onNewPointOfInterestMarker = (poi: PointOfInterestDetailsDTO) => {
    return newPointOfInterestMarker(poi, map, infoWindow);
  };

  const onNewCommunityMarker = (dto: MapMarkerDTO) => {
    return newCommunityMarker(dto, map, infoWindow);
  };

  const onNewProgramMarker = (dto: ProgramDetailsDTO) => {
    return newProgramMarker(
      dto,
      onGetDomainById,
      onGetChurchById,
      isChurchMemberOf,
      map,
      infoWindow,
    );
  };

  return {
    onNewChurchMarker,
    onNewPointOfInterestMarker,
    onNewCommunityMarker,
    onNewProgramMarker,
  };
};

export const useMapDataLayer = (
  map: google.maps.Map,
  selectedCoalitions: CoalitionDetailsDTO[],
): google.maps.Data.Feature[] => {
  const [geoJsonLayer, setGeoJsonLayer] = useState<google.maps.Data.Feature[]>(
    [],
  );

  useEffect(() => {
    if (!map) return;
    geoJsonLayer.forEach((feature) => {
      map.data.remove(feature);
    });
    setGeoJsonLayer([]);
    const zipCodesUrls = uniq(
      flatten(selectedCoalitions.map((c) => c.zip_codes)),
    ).map((zipCode) => `/zip_codes/${zipCode}.json`);
    zipCodesUrls.forEach((url) => {
      map.data.loadGeoJson(url, { idPropertyName: 'ah' }, (features) => {
        setGeoJsonLayer((prev) => [...prev, ...features]);
      });
    });
  }, [!!map, selectedCoalitions]);

  return geoJsonLayer;
};
