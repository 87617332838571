import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import apis from 'api';
import Page from '../components/Page';
import env from '../config/env';

const UpdateChurch: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    primary_contact: '',
    primary_phone: '',
    primary_email: '',
  });

  useEffect(() => {
    apis.churches.get_by_id(id).then((church) => {
      setForm({
        name: church.name,
        address1: church.address1,
        address2: church.address2,
        city: church.city,
        state: church.state,
        zip_code: church.zip_code,
        primary_contact: church.primary_contact,
        primary_phone: church.primary_phone,
        primary_email: church.primary_email,
      });
    });
  }, [id]);

  const submit = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    console.log({
      name: form.name,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      state: form.state,
      zip_code: form.zip_code,
      primary_contact: form.primary_contact,
      primary_phone: form.primary_phone,
      primary_email: form.primary_email,
    });
    await apis.churches.update(id, {
      name: form.name,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      state: form.state,
      zip_code: form.zip_code,
      primary_contact: form.primary_contact,
      primary_phone: form.primary_phone,
      primary_email: form.primary_email,
    });

    navigate('/churches/' + id);
  };

  const HOUSTON_LATLNG_BOUNDS = {
    north: 30.110409,
    east: -95.053467,
    south: 29.523056,
    west: -95.788363,
  };

  const options = {
    types: ['church'],
    fields: ['address_components', 'name'],
    bounds: HOUSTON_LATLNG_BOUNDS,
    strictBounds: true,
  };

  const onFormInput = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    if (field in form) {
      setForm({ ...form, [field]: value });
    }
  };

  const onAutocomplete = (place) => {
    const placeAddress = place.address_components;
    setForm({
      ...form,
      name: place.name,
      address1: `${placeAddress[0].long_name} ${placeAddress[1].long_name}`,
      city: placeAddress[3].long_name,
      state: placeAddress[5].long_name,
      zip_code: placeAddress[7].long_name,
    });
  };

  return (
    <Page style={{ paddingLeft: '10%', paddingRight: '10%' }}>
      <div className="text-2xl font-bold">Edit Church</div>
      <form onSubmit={submit} onChange={onFormInput}>
        <div className="relative z-0 mb-6 w-full group">
          <Autocomplete
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            apiKey={env.googleMapsApiKey}
            options={options}
            onPlaceSelected={onAutocomplete}
            value={form.name}
            name="name"
            placeholder=""
          />
          <label
            htmlFor="name"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Church Name
          </label>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="text"
              name="address1"
              id="address1"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={form.address1}
            />
            <label
              htmlFor="address1"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Address 1
            </label>
          </div>
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="text"
              name="address2"
              id="address2"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              value={form.address2}
            />
            <label
              htmlFor="address2"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Address 2
            </label>
          </div>
        </div>
        <div className="relative z-0 mb-6 w-full group">
          <input
            type="text"
            name="city"
            id="city"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            value={form.city}
          />
          <label
            htmlFor="city"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            City
          </label>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="text"
              name="state"
              id="state"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={form.state}
            />
            <label
              htmlFor="state"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              State
            </label>
          </div>
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="text"
              name="zip_code"
              id="zip_code"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={form.zip_code}
            />
            <label
              htmlFor="zip_code"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Zip Code
            </label>
          </div>
        </div>
        <div className="relative z-0 mb-6 w-full group">
          <input
            type="text"
            name="primary_contact"
            id="primary_contact"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            value={form.primary_contact}
          />
          <label
            htmlFor="primary_contact"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Primary contact
          </label>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="text"
              name="primary_email"
              id="primary_email"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={form.primary_email}
            />
            <label
              htmlFor="primary_email"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Primary email
            </label>
          </div>
          <div className="relative z-0 mb-6 w-full group">
            <input
              type="tel"
              name="primary_phone"
              id="primary_phone"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={form.primary_phone}
            />
            <label
              htmlFor="primary_phone"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Primary phone
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Edit
        </button>
      </form>
    </Page>
  );
};

export default UpdateChurch;
