import admin_api_set, { AdminApi } from 'api/admin';
import churches_api_set, { ChurchesApi } from 'api/churches';
import user_api_set, { UserApi } from 'api/users';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import church_statistics_api_set, {
  ChurchStatisticsApi,
} from './churchStatistics';
import coalitions_api_set, { CoalitionsApi } from './coalitions';
import domains_api_set, { DomainsApi } from './domains';
import events_api_set, { EventsApi } from './events';
import general, { GeneralApi } from './general';
import households_api_set, { HouseholdsApi } from './households';
import map_api_set, { MapApi } from './map';
import points_of_interest_api_set, {
  PointsOfInterestApi,
} from './pointsOfInterest';
import posts_api_set, { PostsApi } from './posts';

export interface Apis {
  general: GeneralApi;
  users: UserApi;
  churches: ChurchesApi;
  households: HouseholdsApi;
  domains: DomainsApi;
  admin: AdminApi;
  church_statistics: ChurchStatisticsApi;
  map: MapApi;
  points_of_interest: PointsOfInterestApi;
  events: EventsApi;
  coalitions: CoalitionsApi;
  posts: PostsApi;
}

const apis: Apis = {
  general,
  users: user_api_set,
  churches: churches_api_set,
  households: households_api_set,
  points_of_interest: points_of_interest_api_set,
  domains: domains_api_set,
  admin: admin_api_set,
  church_statistics: church_statistics_api_set,
  map: map_api_set,
  events: events_api_set,
  coalitions: coalitions_api_set,
  posts: posts_api_set,
};

export const getAxios = () => {
  const instance = axios.create({
    baseURL: 'https://72pt3qd2fj.execute-api.us-east-1.amazonaws.com/dev',
    headers: {
      post: {
        'X-Api-Key': 'GhljG0rYjURun1QPcYhN8ap0EsdFvLp8AHG7eiS9',
        'Content-Type': 'application/json',
      },
      get: {
        'X-Api-Key': 'GhljG0rYjURun1QPcYhN8ap0EsdFvLp8AHG7eiS9',
        'Content-Type': 'application/json',
      },
      put: {
        'X-Api-Key': 'GhljG0rYjURun1QPcYhN8ap0EsdFvLp8AHG7eiS9',
        'Content-Type': 'application/json',
      },
      delete: {
        'X-Api-Key': 'GhljG0rYjURun1QPcYhN8ap0EsdFvLp8AHG7eiS9',
        'Content-Type': 'application/json',
      },
    },
  });

  createAuthRefreshInterceptor(instance, async () => {
    await instance.post('/users/refresh_token');
    return await Promise.resolve();
  });

  return instance;
};

export async function executor<T>(
  action: any,
  name: string,
): Promise<T | undefined> {
  try {
    return await action();
  } catch (error) {
    return Promise.reject(error);
  }
}

export default apis;
