import React, { useState, createContext } from 'react';

const ToastContext = createContext({
  toastList: new Set(),
  show: (toastId: string) => null,
  close: (toastId: string) => null,
  closeAll: () => null,
});

const ToastContextProvider = ({ children }) => {
  const [toastList, setToastList] = useState(new Set());
  const show = (toastId: string) => {
    const newToastList = new Set(toastList);
    newToastList.add(toastId);
    setToastList(newToastList);
  };
  const close = (toastId: string) => {
    const newToastList = new Set(toastList);
    newToastList.delete(toastId);
    setToastList(newToastList);
  };

  const closeAll = () => {
    const newToastList = new Set();
    setToastList(newToastList);
  };

  return (
    <ToastContext.Provider value={{ toastList, show, close, closeAll }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastContextProvider };
