import ChurchDetailsDTO from 'dtos/ChurchDetailsDTO';
import { defaultMapFilters, MapFilters } from 'dtos/DemographicFiltersDTO';
import MapMarkerDTO from 'dtos/MapMarkerDTO';
import MapViewportStateDTO from 'dtos/MapViewportStateDTO';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import apis from '../../api';
import CoalitionDetailsDTO from '../../dtos/CoalitionDetailsDTO';
import { PointOfInterestType } from '../../dtos/PointOfInterestDetailsDTO';
import {
  useFilteredChurches,
  useFilteredPrograms,
  useInitControls,
  useInitInfoWindow,
  useInitMap,
  useMapDataLayer,
  useNewMarker,
} from '../../hooks/mapHooks';
import useFilteredTabs from '../../hooks/useFilteredTabs';
import {
  useAppDispatch,
  useGetChurches,
  useGetDomains,
  useGetPointsOfInterestState,
} from '../../store/hooks';
import eventManager from '../../utils/eventManager';
import { clearMarkers } from '../../utils/mapUtils';
import CoalitionDropdown from '../CoalitionDropdown';
import * as St from '../Style.styled';
import Tabs from '../Tabs';
import UnstructuredChurchCard from '../UnstructuredChurchCard';
import UnstructuredProgramCard from '../UnstructuredProgramCard';
import {
  defaultFilterDemographic,
  incomeIcons,
  MAP_OPTIONS,
} from './config/config';
import domainPointsOfInterest from './config/domainPointsOfInterest';
import { Band7Icon, ChurchGreyIcon, LegendIcon } from './Icons';
import POIVisibilityButton from './POIVisibilityButton';
import { updateFilteredDomainWithDomains } from './utils';
import { updatePointsOfInterest } from 'store/helpers';
import { set_points_of_interest } from 'store/pointsOfInterest';
import { filterEntitiesInCoalitionBounds } from 'utils/geoUtils';

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;
const center: LatLngLiteral = { lat: 29.7604, lng: -95.3698 }; // houston
const TABS = ['Churches', 'Programs / Assets'];
const MapContainer = styled.div`
  display: flex;
  height: 66vh;
`;

const MapAndFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const churches = useGetChurches();
  const domains = useGetDomains();
  const map = useInitMap(MAP_OPTIONS);
  const infoWindow = useInitInfoWindow();
  const {
    onNewChurchMarker,
    onNewPointOfInterestMarker,
    onNewCommunityMarker,
    onNewProgramMarker,
  } = useNewMarker(map, infoWindow);
  useInitControls(map);
  const [mapCenter, setMapCenter] = useState({ lat: 29, lng: -95 });
  const [churchMarkers, setChurchMarkers] = useState<google.maps.Marker[]>([]);
  const [communityMarkers, setCommunityMarkers] = useState<
    google.maps.Marker[]
  >([]);
  const [poiMarkers, setPoiMarkers] = useState<google.maps.Marker[]>([]);
  const poiChurchMarkersRef = useRef(null);
  const [programMarkers, setProgramMarkers] = useState<google.maps.Marker[]>(
    [],
  );
  const [households, setHouseholds] = useState<MapMarkerDTO[]>([]);
  const { data: pois, loaded: poisLoaded } = useGetPointsOfInterestState();
  const tabState = useState(0);
  const [activeFilters, setActiveFilters] =
    useState<MapFilters>(defaultMapFilters);
  const [selectedDomainIds, setSelectedDomainIds] = useState<string[]>([]);
  const [legendVisible, setLegendVisible] = useState(true);
  const [poisVisible, setPoisVisible] = useState(false);
  const [householdsVisible, setHouseholdsVisible] = useState(true);
  const [overlayTarget, setOverlayTarget] = useState<
    ChurchDetailsDTO | MapMarkerDTO
  >(null);
  const [filterDemographic, setFilterDemographic] = useState(
    defaultFilterDemographic,
  );
  const [mouseHoveringOverlay, setMouseHoveringOverlay] = useState(false);
  const [selectedCoalitions, setSelectedCoalitions] = useState<
    CoalitionDetailsDTO[]
  >([]);
  const tabs = useFilteredTabs(TABS, selectedCoalitions, selectedDomainIds);
  const datalayer = useMapDataLayer(map, selectedCoalitions);
  const filteredChurches = useFilteredChurches(
    selectedCoalitions,
    selectedDomainIds,
    datalayer,
  );

  const filteredPrograms = useFilteredPrograms(
    selectedCoalitions,
    selectedDomainIds,
    datalayer,
  );

  const handleMapChanged = () => {
    if (!map || !householdsVisible) return;

    // Convert LatLng to LatLngLiteral
    const center = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
    // Check if the new center is significantly different from the last center
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        center,
        new window.google.maps.LatLng(mapCenter.lat, mapCenter.lng),
      );
    const zoom = map.getZoom();

    if ((distance < 10000 && zoom < 15) || (distance < 500 && zoom >= 15)) {
      return;
    }

    setMapCenter(center);
    const dto: MapViewportStateDTO = {
      bounds: map?.getBounds().toJSON(),
      zoom: map.getZoom(),
      filters: activeFilters,
      current_demographic: filterDemographic,
    };
    apis.map.get_viewport_households(dto).then((res) => {
      if (res) {
        setHouseholds(res);
      }
    });
  };
  const debouncedHandleMapChanged = useCallback(
    debounce(handleMapChanged, 100),
    [!!map, activeFilters, filterDemographic, mapCenter],
  );

  const onClickDomain = (domainId: string) => () => {
    const newSelectedDomains = selectedDomainIds.includes(domainId)
      ? selectedDomainIds.filter((id) => id !== domainId)
      : [domainId];

    setSelectedDomainIds(newSelectedDomains);
    updateFilteredDomainWithDomains({
      filteredDomainIds: newSelectedDomains,
      domains,
    });
  };

  useEffect(() => {
    eventManager.on('filteredDomains:update', setSelectedDomainIds);
    eventManager.on('mapFilters:update', setActiveFilters);
    eventManager.on('filterDemographic:update', setFilterDemographic);
    return () => {
      eventManager.off('filteredDomains:update', setSelectedDomainIds);
      eventManager.off('mapFilters:update', setActiveFilters);
      eventManager.off('filterDemographic:update', setFilterDemographic);
    };
  }, []);

  const closeOverlayWindow = () => {
    setOverlayTarget(null);
    setMouseHoveringOverlay(false);
  };

  const refreshProgramMarkers = () => {
    if (!map) return;
    programMarkers.forEach((marker, index) => {
      if (marker) {
        marker.setMap(null);
        programMarkers[index] = null;
      }
    });

    setProgramMarkers(filteredPrograms.map(onNewProgramMarker));
  };

  const refreshChurchMarkers = () => {
    if (!map) return;

    churchMarkers.forEach((marker, index) => {
      marker.setMap(null);
      churchMarkers[index] = null;
    });

    const coalitionChurches = churches.filter((ch) =>
      selectedCoalitions.some((coalition) => coalition._id === ch.coalition_id),
    );
    let nonCoalitionChurches: any[] = churches.filter(
      (ch) =>
        !selectedCoalitions.some(
          (coalition) => coalition._id === ch.coalition_id,
        ),
    );
    nonCoalitionChurches = nonCoalitionChurches.map((ch) => {
      return { ...ch, iconColor: 'grey' };
    });
    const allChurches = selectedCoalitions.length
      ? coalitionChurches.concat(nonCoalitionChurches)
      : churches;
    setChurchMarkers(allChurches.map(onNewChurchMarker));
  };

  const refreshCommunityMarkers = () => {
    if (!map) return;

    if (!householdsVisible) {
      clearMarkers(communityMarkers);
      setCommunityMarkers([]);
      return;
    }
    const newMarkerIds = households.map((h) => h.id);
    const existingMarkersToClear = communityMarkers.filter(
      (m) => !newMarkerIds.includes(m['id']),
    );
    const previousMarkerIds = communityMarkers.map((m) => m['id']);
    const newMarkersToRender = households.filter(
      (m) => !previousMarkerIds.includes(m.id),
    );
    clearMarkers(existingMarkersToClear);
    const newlyRenderedMarkers = newMarkersToRender.map(onNewCommunityMarker);
    const existingRenderedMarkers = communityMarkers.filter((m) =>
      newMarkerIds.includes(m['id']),
    );
    existingRenderedMarkers.forEach((marker) => {
      const foundMarker = households.find((h) => h.id === marker['id']);
      if (!foundMarker) {
        return;
      }

      const markerSize = foundMarker.size || 40;
      const icon = {
        url: incomeIcons[foundMarker.income],
        scaledSize: new google.maps.Size(markerSize, markerSize), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(markerSize / 2, 0), // anchor
      };

      marker.setLabel(foundMarker.text);
      marker.setIcon(icon);
      return marker;
    });

    const allHouseholdMarkers =
      existingRenderedMarkers.concat(newlyRenderedMarkers);
    setCommunityMarkers(allHouseholdMarkers);
  };

  const refreshPois = () => {
    if (!map) return;

    if (!selectedDomainIds.length) {
      return clearMarkers(poiMarkers);
    }

    const churchPois = pois[PointOfInterestType.CHURCH];
    if (selectedDomainIds.length === 0 && churchPois.length === 0) return;

    const poiDtos = selectedDomainIds
      .flatMap((domainId) => pois[parseInt(domainId)])
      .concat(churchPois);
    clearMarkers(poiMarkers);
    setPoiMarkers(poiDtos.map(onNewPointOfInterestMarker));
  };

  const updatePoiChurches = async () => {
    if (!map) return;

    const res: any[] = await apis.points_of_interest.get_all_church_poi(
      map?.getBounds(),
    );
    const newMarkerIds = res.map((m) => m['id']);
    const existingMarkersToClear =
      (poiChurchMarkersRef.current &&
        poiChurchMarkersRef.current.filter(
          (m) => !newMarkerIds.includes(m['id']),
        )) ||
      [];
    const previousMarkerIds =
      (poiChurchMarkersRef.current &&
        poiChurchMarkersRef.current.map((m) => m['id'])) ||
      [];
    const newMarkersToRender = res.filter(
      (m) => !previousMarkerIds.includes(m.id),
    );
    clearMarkers(existingMarkersToClear);
    const newlyRenderedMarkers = newMarkersToRender.map(
      onNewPointOfInterestMarker,
    );
    const existingRenderedMarkers = communityMarkers.filter((m) =>
      newMarkerIds.includes(m['id']),
    );

    poiChurchMarkersRef.current = newlyRenderedMarkers.concat(
      existingRenderedMarkers,
    );
  };

  useEffect(() => {
    refreshCommunityMarkers();
  }, [!!map, households, householdsVisible]);

  useEffect(() => {
    refreshProgramMarkers();
  }, [!!map, selectedDomainIds, selectedCoalitions, datalayer]);

  useEffect(() => {
    updatePoiChurches();
  }, [datalayer]);

  useEffect(() => {
    let listener = null;

    if (map) {
      listener = map.addListener('idle', () => {
        debouncedHandleMapChanged();
        updatePoiChurches();
      });
    }

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [!!map, debouncedHandleMapChanged, datalayer]);

  useEffect(() => {
    refreshPois();
  }, [!!map, pois]);

  useEffect(() => {
    if (selectedDomainIds.length) {
      updatePointsOfInterest(dispatch, selectedDomainIds);
    } else {
      dispatch(set_points_of_interest([]));
    }
  }, [!!map, selectedDomainIds]);

  useEffect(() => {
    refreshChurchMarkers();
  }, [!!map, selectedCoalitions]);

  useEffect(() => {
    handleMapChanged();
  }, [activeFilters, filterDemographic]);

  useEffect(() => {
    const legend = document.getElementById('legend');
    if (!legend) return;
    legend.style.display = legendVisible ? 'block' : 'none';
  }, [legendVisible]);

  const renderUnstructuredData = () => {
    let dataDiv = [];
    switch (tabState[0]) {
      case 0:
        dataDiv = filteredChurches.map((church) => (
          <UnstructuredChurchCard
            church={church}
            churchMarkers={churchMarkers}
            mapRef={map}
            infoWindow={infoWindow}
            key={church.id}
          />
        ));
        break;
      case 1:
        dataDiv = filteredPrograms.map((program) => (
          <UnstructuredProgramCard
            program={program}
            programMarkers={programMarkers}
            churchMarkers={churchMarkers}
            mapRef={map}
            infoWindow={infoWindow}
            key={program.id}
          />
        ));
    }
    return (
      <St.Div mb="50px">
        <Tabs tabs={tabs} parentTabState={tabState} />
        <St.Row justifyContent="flex-start" gap="17px" mt="20px">
          {dataDiv}
        </St.Row>
      </St.Div>
    );
  };

  const header = () => {
    if (selectedCoalitions.length == 0) return 'Your coalitions';
    if (selectedCoalitions.length === 1) return selectedCoalitions[0]?.name;
    return 'Multiple coalitions';
  };

  return (
    <div className="map-page">
      <St.Header mr="0">
        <St.HeaderText ml="10px" justifySelf="start">
          {header()}
        </St.HeaderText>
        <St.Row gap="22px" mr="10px">
          <POIVisibilityButton
            src={LegendIcon}
            setParentClicked={setLegendVisible}
          >
            {legendVisible ? 'Hide legend' : 'Show legend'}
          </POIVisibilityButton>
          <POIVisibilityButton
            src={Band7Icon}
            setParentClicked={setHouseholdsVisible}
          >
            {householdsVisible ? 'Hide households' : 'Show households'}
          </POIVisibilityButton>
        </St.Row>
        <CoalitionDropdown
          onClickItem={(c) => setSelectedCoalitions(c)}
          width="200px"
          defaultCoalitions={[]}
        />
      </St.Header>
      <MapContainer>
        <St.Row
          width="100%"
          style={{ border: '1px solid #DDDDDD', overflow: 'scroll' }}
        >
          <St.InfoColumn minWidth="175px" style={{ overflow: 'scroll' }}>
            <St.Text color="var(--color-hr-red)" fontWeight="800" mb="10px">
              Domains
            </St.Text>
            <St.Text width="150px" fontSize="10px" fontWeight="500" mb="11px">
              Select a domain to show relevant points of interest and other
              associated points on the map
            </St.Text>
            {domains.map((domain) => (
              <motion.button
                whileHover={{ scale: 1.075 }}
                whileTap={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={onClickDomain(domain.domainId)}
                key={domain.domainId}
              >
                <St.Row key={domain.domainId} mb="15px">
                  {selectedDomainIds.includes(domain.domainId) ? (
                    <St.Dash background="var(--color-hr-red)" />
                  ) : (
                    <St.Dash />
                  )}
                  {domain.name}
                </St.Row>
              </motion.button>
            ))}
          </St.InfoColumn>
          <div id="map"></div>
        </St.Row>
      </MapContainer>
      {renderUnstructuredData()}
    </div>
  );
};

export default React.memo(MapAndFilters);
