import React, { useMemo, useState } from 'react';
import Modal from '../../components/Modal';
import { ModalContext } from '../../contexts/ModalContext';
import SignUpForm from './SignUpForm';
import UserConfiguration from './UserConfiguration';

export interface SignUpStepProps {
  setStep: any;
  step?: number;
}

const SignUpModal: React.FC = () => {
  const [step, setStep] = useState(0);
  const { signUpOpen, toggleSignUpOpen } = React.useContext(ModalContext);

  const currentStepComponent: JSX.Element = useMemo(() => {
    switch (step) {
      case 0:
        return <SignUpForm setStep={setStep} />;
      case 1:
        return <UserConfiguration setStep={setStep} />;
    }
    return null;
  }, [step, setStep]);

  return (
    <Modal
      height="600px"
      isOpen={signUpOpen}
      requestClose={() => {
        setStep(0);
        toggleSignUpOpen();
      }}
    >
      {currentStepComponent}
    </Modal>
  );
};

export default SignUpModal;
