import { executor, getAxios } from 'api';
import CreatePostDTO from '../dtos/CreatePostDTO';
import CreatePostReplyDTO from '../dtos/CreatePostReplyDTO';
import { default as PostDetailsDTO } from '../dtos/PostDetailsDTO';
import UpdatePostDTO from '../dtos/UpdatePostDTO';
import UpdatePostReplyDTO from '../dtos/UpdatePostReplyDTO';
import DomainId from '../types/DomainId';
import PostRepliesDTO from 'dtos/PostRepliesDTO';

export interface PostsApi {
  create_reply: (dto: CreatePostReplyDTO) => Promise<string | undefined>;
  update_reply: (id: string, dto: UpdatePostReplyDTO) => Promise<void>;
  create_post: (dto: CreatePostDTO) => Promise<PostDetailsDTO | undefined>;
  get_all: () => Promise<PostDetailsDTO[]>;
  get_posts_for_church: (id: string) => Promise<PostDetailsDTO[] | undefined>;
  get_posts_for_coalition: (
    id: string,
  ) => Promise<PostDetailsDTO[] | undefined>;
  get_posts_for_domain: (id: DomainId) => Promise<PostDetailsDTO[] | undefined>;
  get_post_by_id: (id: string) => Promise<PostDetailsDTO | undefined>;
  update_post: (id: string, dto: UpdatePostDTO) => Promise<void>;
  get_post_replies: (id: string) => Promise<PostRepliesDTO[]>;
}

const create_reply = async (
  dto: CreatePostReplyDTO,
): Promise<string | undefined> => {
  const action = async (): Promise<string> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'post_replies',
        update: { ...dto },
      },
    });

    return result.data.data;
  };

  return await executor(action, 'create_post_reply');
};

const update_reply = async (
  id: string,
  dto: UpdatePostReplyDTO,
): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'PUT',
      url: `posts/update_reply/${id}`,
      data: dto,
    });
  };

  return await executor(action, 'update_post_reply');
};

const get_all = async (): Promise<PostDetailsDTO[]> => {
  const action = async (): Promise<PostDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'posts',
        query: { _IsActive: { $ne: false } },
      },
    });

    return result?.data?.data ?? [];
  };

  return await executor(action, 'get_all_posts');
};

const create_post = async (
  dto: CreatePostDTO,
): Promise<PostDetailsDTO | undefined> => {
  const action = async (): Promise<PostDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/write',
      data: {
        collection: 'posts',
        update: { ...dto },
      },
    });

    return result.data.data;
  };

  return await executor(action, 'create_post');
};

const get_posts_for_church = async (
  id: string,
): Promise<PostDetailsDTO[] | undefined> => {
  const action = async (): Promise<PostDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: `posts/church/${id}`,
    });

    return result.data;
  };

  return await executor(action, 'get_posts_for_church');
};

const get_posts_for_coalition = async (
  id: string,
): Promise<PostDetailsDTO[] | undefined> => {
  const action = async (): Promise<PostDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: `posts/coalition/${id}`,
    });

    return result.data;
  };

  return await executor(action, 'get_posts_for_coalition');
};

const get_posts_for_domain = async (
  id: DomainId,
): Promise<PostDetailsDTO[] | undefined> => {
  const action = async (): Promise<PostDetailsDTO[]> => {
    const result = await getAxios().request({
      method: 'GET',
      url: `posts/domain/${id}`,
    });

    return result.data;
  };

  return await executor(action, 'get_posts_for_domain');
};

const get_post_by_id = async (
  id: string,
): Promise<PostDetailsDTO | undefined> => {
  const action = async (): Promise<PostDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'posts',
        query: { _id: id },
      },
    });

    if (!!result?.data?.data[0]?.user_id) {
      const user = await getAxios().request({
        method: 'POST',
        url: '/read',
        data: {
          collection: 'users',
          query: { _id: result.data.data[0].user_id },
        },
      });
      if (!!user?.data?.data.length) {
        result.data.data[0].user = user.data.data[0];
      }
    }

    return result.data.data[0];
  };

  return await executor(action, 'get_post_by_id');
};

const update_post = async (id: string, dto: UpdatePostDTO): Promise<void> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: `/write`,
      data: {
        collection: 'posts',
        query: { _id: id },
        update: { ...dto },
      },
    });
  };

  return await executor(action, 'update_post');
};

const get_post_replies = async (post_id: string): Promise<PostRepliesDTO[]> => {
  const action = async (): Promise<PostRepliesDTO[]> => {
    const result = await getAxios().request({
      method: 'POST',
      url: '/read',
      data: {
        collection: 'post_replies',
        query: [
          {
            $match: {
              post_id,
              _IsActive: { $ne: false },
            },
          },
          {
            $addFields: {
              _user_id: {
                $toObjectId: '$user_id',
              },
            },
          },
          {
            $lookup: {
              from: 'users',
              localField: '_user_id',
              foreignField: '_id',
              as: 'user',
            },
          },
          {
            $unwind: '$user',
          },
        ],
      },
    });

    return result.data.data;
  };

  return await executor(action, 'get_post_reply');
};

const posts_api_set: PostsApi = {
  create_reply,
  update_reply,
  create_post,
  get_posts_for_church,
  get_all,
  get_posts_for_coalition,
  get_posts_for_domain,
  get_post_by_id,
  update_post,
  get_post_replies,
};

export default posts_api_set;
