import { getAxios, executor } from 'api';

import CreateCoalitionDTO from 'dtos/CreateCoalitionDTO';
import CoalitionDetailsDTO from 'dtos/CoalitionDetailsDTO';

export interface AdminApi {
  add_coalition: (
    dto: CreateCoalitionDTO,
  ) => Promise<CoalitionDetailsDTO | undefined>;
  remove_coalition: (id: string) => Promise<void | undefined>;
}

const add_coalition = async (
  dto: CreateCoalitionDTO,
): Promise<CoalitionDetailsDTO | undefined> => {
  const action = async (): Promise<CoalitionDetailsDTO> => {
    const result = await getAxios().request({
      method: 'POST',
      url: 'create_coalition',
      data: dto,
    });

    return result.data;
  };

  return await executor(action, 'create_coalition');
};

const remove_coalition = async (id: string): Promise<void | undefined> => {
  const action = async (): Promise<void> => {
    await getAxios().request({
      method: 'POST',
      url: 'delete_coalition/' + id,
    });
  };

  await executor(action, 'remove_coalition');
};

const admin_api_set: AdminApi = {
  add_coalition,
  remove_coalition,
};
export default admin_api_set;
