export default interface AutocompleteDetails {
  place_id: string;
  name: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  photo?: string;
  coords: [number, number];
}

export const defaultAutocompleteDetails: AutocompleteDetails = {
  place_id: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  photo: '',
  coords: [0, 0],
};
