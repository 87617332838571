import React from 'react';

export interface ListActionButtonProps {
  text: string;
  onClick: () => Promise<void>;
}

const ListActionButton: React.FC<ListActionButtonProps> = ({
  text,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className="bg-basicBlue mx-1 w-24 h-8 font-bold text-lg text-basicWhite hover:bg-basicRed rounded-pill"
    >
      {text}
    </button>
  );
};

export default ListActionButton;
