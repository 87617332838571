import React from 'react';
import * as St from 'components/Style.styled';

const PageNotFound: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 32 }}>
      <St.Text fontSize="24px" fontWeight="bold">
        404 Page Not Found
      </St.Text>
      <p>Oops! The page you are looking for does not exist.</p>
    </div>
  );
};

export default PageNotFound;
