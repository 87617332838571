import CoalitionKeyInitiativeDetailsDTO from './CoalitionKeyInitiativeDetailsDTO';

export default interface CoalitionDetailsDTO {
  _id: string;
  id: string;
  name: string;
  description: string;
  region: string;
  church_ids: string[];
  zip_codes: string[];
  key_initiatives?: CoalitionKeyInitiativeDetailsDTO[];
}

export const defaultCoalitionDetailsDTO: CoalitionDetailsDTO = {
  _id: '',
  id: '',
  name: '',
  description: '',
  region: '',
  church_ids: [],
  zip_codes: [],
  key_initiatives: [],
};
