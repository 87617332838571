import ChurchCard from 'components/ChurchCard';
import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import styled from 'styled-components';
import Tabs from '../components/Tabs';
import routes from '../config/routes';
import {
  useAppSelector,
  useGetChurches,
  useGetChurchStatisticTypes,
} from '../store/hooks';
import useUserAccess from 'hooks/useUserAccess';
import { FilterIcon } from 'components/Map/Icons';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const churchImages = [
  // '/images/churches/1.png',
  '/images/churches/2.png',
  // '/images/churches/3.png',
  // '/images/churches/4.png',
  // '/images/churches/5.png',
];

const TABS = ['All Churches', 'Your Church'];

const Churches: React.FC = () => {
  const { isChurchMemberOf } = useUserAccess();
  const isAdmin = useAppSelector((state) => state.user.is_site_admin);
  const churches = useGetChurches();
  const [searchTerm, setSearchTerm] = useState('');
  const tabState = useState(0);
  const statisticTypes = useGetChurchStatisticTypes();
  const [_, setSelectedIndex] = tabState;

  const filteredChurches =
    tabState[0] === 1
      ? churches
          .filter((church) => !!isChurchMemberOf(church))
          .map((church) => ({ church, message: undefined }))
      : churches
          .filter(
            (e) =>
              e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              !searchTerm,
          )
          .map((church) => ({ church, message: undefined }));

  const emptyMessage = () => {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <St.Text ml="20px" mb={'8px'}>
          {tabState[0] === 1
            ? 'No church available. Join a church for it to show up here.'
            : 'No church found.'}
        </St.Text>
        <St.SecondaryButton onClick={() => setSelectedIndex(0)}>
          <St.Text>Find a church</St.Text>
        </St.SecondaryButton>
      </div>
    );
  };

  return (
    <>
      <St.Header>
        <St.HeaderText ml="10px">Churches</St.HeaderText>
        {tabState[0] === 0 && (
          <St.Row
            justifyContent="start"
            width="100%"
            maxWidth={325}
            marginRight={12}
          >
            <St.Icon src={FilterIcon} style={{ paddingRight: 12 }} />
            <St.TextInput
              placeholder="Search for a church"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
          </St.Row>
        )}

        {isAdmin && (
          <St.PrimaryButtonLink
            to={routes.RETOOL.href}
            p="10px"
            target="_blank"
          >
            <St.Row>
              <St.Icon src="/images/edit-white.svg" height="18px" mr="10px" />
              Manage Churches
            </St.Row>
          </St.PrimaryButtonLink>
        )}
      </St.Header>
      <Tabs tabs={TABS} parentTabState={tabState} />
      <Container>
        {filteredChurches.length > 0
          ? filteredChurches.map(
              (obj, i) =>
                obj.church && (
                  <ChurchCard
                    key={obj.church._id}
                    church={obj.church}
                    tooltipMessage={obj.message}
                    churchStatisticTypes={statisticTypes}
                    placeholder={churchImages[i % churchImages.length]}
                  />
                ),
            )
          : emptyMessage()}
      </Container>
    </>
  );
};

export default Churches;
