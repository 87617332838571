import * as St from 'components/Style.styled';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

const TooltipContainer = styled(St.Div)`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.span<{ show: boolean; anchorTopRight?: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: ${({ anchorTopRight }) => (anchorTopRight ? 'unset' : '50%')};
  right: ${({ anchorTopRight }) => (anchorTopRight ? '0' : 'unset')};
  width: 200px;
  transform: ${({ anchorTopRight }) =>
    anchorTopRight ? 'none' : 'translateX(-50%)'};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

interface TooltipProps {
  text: string;
  src: string;
  anchorTopRight?: boolean;
}

const Tooltip: React.FC<TooltipProps & SpaceProps> = ({
  text,
  src,
  anchorTopRight,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipContainer
      {...props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <St.AnimatedIcon src={src} size="25px" />
      <TooltipText show={showTooltip} anchorTopRight={anchorTopRight}>
        {text}
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
