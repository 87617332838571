import React from 'react';
import { MapContextProvider } from './contexts/MapContext';
import { ModalContextProvider } from './contexts/ModalContext';
import { ToastContextProvider } from './contexts/ToastContext';

const Providers = ({ children }) => {
  return (
    <ToastContextProvider>
      <ModalContextProvider>
        <MapContextProvider>{children}</MapContextProvider>
      </ModalContextProvider>
    </ToastContextProvider>
  );
};

export default Providers;
