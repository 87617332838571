import * as St from 'components/Style.styled';
import React, { useCallback } from 'react';
import apis from '../../api';
import { CoalitionsIconBlack } from '../../config/icons';
import CoalitionUserDetailsDTO from '../../dtos/CoalitionUserDetailsDTO';
import { useGetCoalitionById } from '../../store/hooks';
import CoalitionUserRole from '../../types/CoalitionUserRole';
import { formatDuration } from '../../utils/date';

interface Props {
  coalitionUser: CoalitionUserDetailsDTO;
  refreshCoalitionUserRequests: () => void;
}

const CoalitionRequestNotificationCard: React.FC<Props> = ({
  coalitionUser,
  refreshCoalitionUserRequests,
}) => {
  const acknowledged = coalitionUser.level !== CoalitionUserRole.PENDING_ADMIN;

  const coalition = useGetCoalitionById(coalitionUser.coalition_id);
  const onApproveUserRequest = useCallback(async () => {
    await apis.coalitions.approve_admin_status(
      coalitionUser.coalition_id,
      coalitionUser.user_id,
    );
    refreshCoalitionUserRequests();
  }, []);
  const onDenyUserRequest = useCallback(async () => {
    await apis.coalitions.deny_admin_status(
      coalitionUser.coalition_id,
      coalitionUser.user_id,
    );
    refreshCoalitionUserRequests();
  }, []);

  const timeSinceCreated = formatDuration(
    (Date.now() - Date.parse(coalitionUser.created_at)) / 1000,
    false,
    true,
  );

  const buttonText = () => {
    if (coalitionUser.level === CoalitionUserRole.PENDING_ADMIN)
      return 'Approve Admin';
    if ([CoalitionUserRole.ADMIN].includes(coalitionUser.level))
      return 'Approved';
    console.log('coalitionUser.role', coalitionUser.role);
    return 'Denied';
    // regular user requests shouldnt be here
  };

  return (
    <St.NotificationCard>
      <St.Icon
        src={CoalitionsIconBlack}
        size="20px"
        mr="10px"
        style={{ display: 'inline' }}
      />
      <p style={{ marginRight: 'auto' }}>
        <b>{coalitionUser.user?.name}</b>
        <span>{' requested admin access to '}</span>
        <b>{coalition?.name}</b>
      </p>
      <St.Text fontSize="12px" opacity={0.5} nowrap mx="10px">
        {timeSinceCreated} ago
      </St.Text>

      {acknowledged ? (
        <St.PrimaryButton type="button" disabled={acknowledged}>
          {buttonText()}
        </St.PrimaryButton>
      ) : (
        <St.Row gap="10px">
          <St.SecondaryButton onClick={onDenyUserRequest}>
            Deny
          </St.SecondaryButton>
          <St.PrimaryButton type="button" onClick={onApproveUserRequest}>
            {buttonText()}
          </St.PrimaryButton>
        </St.Row>
      )}
    </St.NotificationCard>
  );
};

export default CoalitionRequestNotificationCard;
