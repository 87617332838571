import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckboxDropdown from '../../components/CheckboxDropdown';
import EventCard from '../../components/EventCard';
import * as St from '../../components/Style.styled';
import { LeftArrowIcon, RightArrowIcon } from '../../config/icons';
import routes from '../../config/routes';
import { useFilteredEvents } from '../../hooks/filterHooks';
import { useGetCoalitions, useGetDomains } from '../../store/hooks';
import {
  dropdownGetSelected,
  dropdownSelectMany,
} from '../../utils/dropdownUtils';
import firstOrNull from '../../utils/firstOrNull';
import toDropdownItems from '../../utils/toDropdownItems';
import { MONTHS } from './config';

const ShowHiddenButton = styled.div`
  margin-left: 5px;
  transition: 0.2s;
  padding: 5px;
  border-radius: 4px;
  :hover {
    background-color: var(--color-light-grey);
    cursor: pointer;
  }
`;

const Calendar: React.FC = () => {
  const now = useMemo(() => new Date(), []);
  const [year, setYear] = useState(now.getFullYear());
  const [showPreviousMonths, setShowPreviousMonths] = useState(false);
  const domains = useGetDomains();
  const coalitions = useGetCoalitions();
  const domainFilterState = useState(toDropdownItems(domains));
  const coalitionFilterState = useState(toDropdownItems(coalitions));
  const selectedDomains = dropdownGetSelected(domainFilterState);
  const selectedCoalitions = firstOrNull(
    dropdownGetSelected(coalitionFilterState),
  );
  const events = useFilteredEvents(
    selectedDomains.map((item) => item.id),
    selectedCoalitions?.id ? [selectedCoalitions?.id] : [],
  );

  const months: number[] = [];
  for (let i = 0; i < 12; i++) {
    months.push(i % 12);
  }
  const increaseYear = () => setYear(year + 1);
  const decreaseYear = () => setYear(year - 1);
  const toggleShowPreviousMonths = () =>
    setShowPreviousMonths(!showPreviousMonths);

  const renderMonth = (monthIndex: number, year: number) => {
    const monthEvents = events.filter((e) => {
      const eventDate = new Date(e.scheduled);
      return (
        eventDate.getMonth() === monthIndex && eventDate.getFullYear() === year
      );
    });
    return (
      <St.Column alignItems="flex-start" width="100%">
        <div style={{ borderBottom: 'solid 1px', width: '100%' }}>
          <St.Text>{MONTHS[monthIndex]}</St.Text>
        </div>

        {monthEvents.map((e) => (
          <St.Div mt="10px" key={e.id}>
            <EventCard event={e} />
          </St.Div>
        ))}
      </St.Column>
    );
  };

  const renderYear = () => {
    const isCurrentYear = now.getFullYear() === year;
    return (
      <St.Div width="100%">
        <St.Row justifyContent="flex-start">
          <St.AnimatedIcon
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 30 }}
            src={LeftArrowIcon}
            size="15px"
            onClick={decreaseYear}
          />
          <St.Text fontWeight={800} fontSize="18px" my="10px">
            {year}
          </St.Text>
          <St.AnimatedIcon
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 30 }}
            src={RightArrowIcon}
            size="15px"
            onClick={increaseYear}
          />
          {isCurrentYear && (
            <ShowHiddenButton onClick={toggleShowPreviousMonths}>
              {showPreviousMonths ? 'Hide previous months' : 'Show hidden'}
            </ShowHiddenButton>
          )}
        </St.Row>
        <St.Column width="100%" gap="20px" alignItems="flex-start">
          {months.map((monthIndex) => {
            const isPrevMonth = monthIndex < now.getMonth();
            const shouldRender =
              !isPrevMonth || !isCurrentYear || showPreviousMonths;
            return shouldRender && renderMonth(monthIndex, year);
          })}
        </St.Column>
      </St.Div>
    );
  };

  return (
    <>
      <St.Header>
        <St.HeaderText ml="10px">Calendar</St.HeaderText>
      </St.Header>
      <St.Column alignItems="flex-start" width="100%">
        <St.Row justifyContent="flex-start" gap="10px">
          <CheckboxDropdown
            items={domainFilterState[0]}
            width="200px"
            onClickItem={(i) => dropdownSelectMany(i, domainFilterState)}
          >
            {selectedDomains.length > 0
              ? selectedDomains.length === 1
                ? selectedDomains[0].name
                : `${selectedDomains.length} domains`
              : 'All Domains'}
          </CheckboxDropdown>
          <CheckboxDropdown
            items={coalitionFilterState[0]}
            width="250px"
            onClickItem={(i) => dropdownSelectMany(i, coalitionFilterState)}
          >
            {selectedCoalitions?.name ?? 'All Coalitions'}
          </CheckboxDropdown>
          <St.PrimaryButtonLink to={routes.CREATE_EVENT.href} p="10px">
            <St.Row>
              <St.Icon src="/images/plus.png" height="18px" mr="10px" />
              Create Event
            </St.Row>
          </St.PrimaryButtonLink>
        </St.Row>
        {renderYear()}
      </St.Column>
    </>
  );
};

export default Calendar;
