import * as St from 'components/Style.styled';
import React from 'react';

interface EditButtonsProps {
  onClickEdit?: () => void;
  onClickAdd?: () => void;
  white?: boolean;
}

const EditButtons: React.FC<EditButtonsProps> = ({
  onClickEdit,
  onClickAdd,
  white,
}) => {
  const plus = white ? '/images/plus-white.svg' : '/images/plus-black.svg';
  const edit = white ? '/images/edit-white.svg' : '/images/edit.svg';
  return (
    <St.Row mr="13px" gap="13px">
      {onClickAdd && (
        <St.AnimatedIcon
          src={plus}
          onClick={onClickAdd}
          size="16px"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'tween', duration: 0.1 }}
        />
      )}
      {onClickEdit && (
        <St.AnimatedIcon
          src={edit}
          onClick={onClickEdit}
          size="16px"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'tween', duration: 0.1 }}
        />
      )}
    </St.Row>
  );
};

export default EditButtons;
